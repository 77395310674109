import * as PIXI from 'pixi.js';
import '../utils/pixiErrorPatches';

// test...
// import myWorker from './workers/test.worker';

import Viewport from 'pixi-viewport';

// import uuid from 'node-uuid';

import { PixiUtils } from 'utils/PixiUtils';
import { ServerStore, APP_PAUSED_EVENT, APP_RESUMED_EVENT } from 'utils/ServerStore';
import { PushNotifyService } from 'utils/PushNotifyService';

// Scenes...
import Scene              from './scenes/Scene'; // for type checking
import SampleBasic        from './scenes/SampleBasic';
import WelcomeScene       from './scenes/WelcomeScene/WelcomeScene';
import KittyAndBalls      from './scenes/KittyAndBalls';
import BasicKittyScene    from './scenes/BasicKittyScene';
import BasicBorderedScene from './scenes/BasicBorderedScene';
import KittyFlySleep      from './scenes/KittyFlySleep';
import LoginScene         from './scenes/LoginScene/LoginScene';

import { MatterSimulationStub } from './MatterSimulationStub';
import { LoaderUtil } from '../utils/LoaderUtil';

// import Stats from 'stats-js';

/**
 * Main class managing the PIXI app and the associated viewport
 *
 * @class Game
 * @extends {EventEmitter}
 */
class Game extends MatterSimulationStub {

	/** 
	 * @property {PIXI.Application} app
	 * This is the `PIXI.Application` instance running the game
	 */
	app = null;
	
	/** 
	 * @property {Viewport} viewport
	 * This is the instance of `Viewport` from `pixi-viewport` that manages the game content
	 */
	viewport = null;

	/** 
	 * @property {PIXI.Container} gameContainer
	 * This container holds ALL visible things for our main game (viewport and on-screen controls)
	 * This is NOT for "other" screens (start/end screens, etc)
	 * Levels, however, are all rendered inside gameContainer and switched in/out using appros methods.
	 */
	gameContainer = null;

	/** 
	 * @property {PIXI} PIXI
	 * This a reference to the PIXI namespace so scenes which receive this instance can be lazy
	 */
	PIXI = PIXI;

	/** 
	 * @property {PixiUtils} PixiUtils
	 * This a reference to the PixiUtils class so scenes which receive this instance can be lazy
	 */
	PixiUtils = PixiUtils;

	/**
	 * @property {React.Component} current overlay on top of the game
	 * Use `setReactOverlay()` to change
	 * @readonly
	 * @memberof Game
	 */
	currentReactOverlay = null;

	/**
	 * By default, the currentReactOverlay is cleared when setCurrentScene is called.
	 * Set this to true, or pass a true argument as the 2nd arg to setReactOverlay to leave the react overlay 
	 * visible between scene changes.
	 *
	 * @memberof Game
	 */
	preserveReactOverlayBetweenSceneChanges = false;


	/**
	 * Creates an instance of Game.
	 * @param {PIXI.Application} app
	 * @throws Throws `Error` if `app` arg not given or not a `PIXI.Application` instance
	 * @memberof Game
	 */
	constructor(app=PIXI.Application) {
		super();

		if(!(app instanceof PIXI.Application))
			throw new Error("Invalid app object type:" + typeof(app));
		
		// Store ref
		this.app = app;

		// Init our game 
		this.init();

		// Start the correct push notification plugins for the current platform (web/PhoneGap/etc)
		// The service will listen to ServerStore for the LOGIN_EVENT and then boot the proper 
		// notification plugin, register it with our server to this device, and handle incoming push notifications.
		// Note that we pass the game reference for use in the click action handler (to set a scene)
		this.pushService = new PushNotifyService(this);

		// test
		// console.log("[testing myWorker]", myWorker);
		// const worker = new myWorker();
		// const worker = new Worker('/fake.worker.js');
		// console.log("[testing myWorker]", worker);
		// worker.postMessage(1337);
		// console.log("[testing myWorker] posted!");
		// worker.addEventListener('message', event => console.log("[myWorker] received event from myWorker:", event));

		// const inlineWorkerCode = `
		
		// 	/* eslint-disable no-restricted-globals */
		// 	console.warn("fake.worker booted");
		// 	self.addEventListener("message", startCounter);

		// 	function startCounter(event) {
		// 		console.log(event.data, self)
		// 		let initial = event.data;
		// 		console.log("fake.worker counter started:", initial);
				
		// 		setInterval(() => this.postMessage(initial++), 10000);
		// 	}
		// `;

		// const worker = new Worker(URL.createObjectURL(new Blob([inlineWorkerCode], {type: 'text/javascript'})));
		// worker.addEventListener('message', event => console.warn("[mainThread:fake.worker] received event from fake.worker:", event));
		// worker.postMessage(1337);
		// console.log("[testing fake.worker] posted!");

	}


	/** 
	 * @property {object} scenes
	 * Hash of scenes available - used for `setScene()`
	 */
	scenes = {
		// matter: SampleMatter,
		test: SampleBasic,
		KittyAndBalls,
		BasicBorderedScene,
		BasicKittyScene,
		KittyFlySleep,
		WelcomeScene,
		LoginScene
	};
	
	/**
	 * Inits the game object
	 * @private
	 * @memberof Game
	 */
	async init() {
		// Main container for UI controls and the viewport
		this.gameContainer = new PIXI.Container();
		this.gameContainer.filters = [ new PIXI.filters.AlphaFilter() ];
		this.app.stage.addChild(this.gameContainer);
		
		this.setupViewport();

		// This is inherited from MatterSimulationStub
		// Wait for it to decide if the webworker will boot or if we
		// must use a main-thread fallback simulation
		await this.setupMatterEngine();

		// Normalize pause/resume events (e.g. from browser PageVisibility API and cordova pause/resume events)
		// We'll normalize a metric here into ServerStore and use ServerStore to emit an appros event so 
		// KittyFlySleep can pause things if needed.
		// We'll also auto-pause/resume matter and PIXI ticker here
		// Delay with setTimeout so listeners can be setup for APP_PAUSED_EVENT if fired from here immediately on setup
		setTimeout(() => {
			this.setupPauseResumeDetection();
		}, 100);

		// this.setScene('KittyFlySleep');
		// this.setScene('WelcomeScene');

		// LoginScene will check/confirm FB login, then route to WelcomeScene
		this.setScene('LoginScene');

		// this.setScene('BasicKittyScene');


		// test: SampleBasic,
		// KittyAndBalls,
		// BasicBorderedScene,
		// BasicKittyScene,
		// KittyFlySleep,
		// WelcomeScene,
		// LoginScene
		// this.setScene('BasicBorderedScene');
		// LoaderUtil.loaded();


		// Just for debugging
		window.game = this;
	}

	async setupPauseResumeDetection() {
		const defaultDocumentTitle = 'Sleepy Cat';

		// When the app pauses, set the title.
		// This shows the paused
		const pauseApp = () => {
			// Notify the rest of the app
			ServerStore.emit(APP_PAUSED_EVENT)

			// Simple paused indicator
			document.title = '[Paused] ' + defaultDocumentTitle;

			// Freeze matter thread
			this.postToMatter('freezeMatter', true);

			// Stop PIXI shared ticker
			this.app.ticker.stop();

			// Post normalized metric
			ServerStore.metric("app.paused");
			
			// Dump any pending metrics to server
			// Note: This will use keepalive:true on fetch to ensure delivery
			ServerStore.postMetrics(true);
		};
			
		// When the app resumes, set the title.
		const resumeApp = () => {
			// Post normalized metric
			ServerStore.metric("app.resumed");
		
			// Reset document title for browsers
			document.title = defaultDocumentTitle; 

			// Unfreeze matter simulation
			this.postToMatter('freezeMatter', false);

			// Restart shared PIXI ticker
			this.app.ticker.start();

			// Notify the rest of the app
			ServerStore.emit(APP_RESUMED_EVENT)
		};

		window.addEventListener("unload", function (e) {
			
			// Notify the rest of the app
			// just incase anybody is listening that needs to dump metrics
			// (e.g. KittyFlySleep -> KittyActor)
			ServerStore.emit(APP_PAUSED_EVENT)

			// Log metric
			ServerStore.metric("app.exited");

			// Dump any pending metrics to server
			// Note: This will use keepalive:true on fetch to ensure delivery
			// Per https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch:
			// 		The keepalive option can be used to allow the request to outlive the page. 
			// 		Fetch with the keepalive flag is a replacement for the Navigator.sendBeacon() API. 
			ServerStore.postMetrics(true);
			
		}, false);
		
		// The pause/resume events only apply to PhoneGap (e.g. only native apps, not the webpage)
		if(window.isPhoneGap) {
			document.addEventListener('pause', this._pgPaused = async () => {
				pauseApp();
			});
			document.addEventListener('resume', this._pgResume = () => {
				resumeApp();
			});
		} else {
			// Most of the feature-specific code below copied from https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API#Example
			// Set the name of the hidden property and the change event for visibility
			let hidden, visibilityChange; 
			if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support 
				hidden = "hidden";
				visibilityChange = "visibilitychange";
			} else if (typeof document.msHidden !== "undefined") {
				hidden = "msHidden";
				visibilityChange = "msvisibilitychange";
			} else if (typeof document.webkitHidden !== "undefined") {
				hidden = "webkitHidden";
				visibilityChange = "webkitvisibilitychange";
			}
			

			// If the page is hidden, pause the video;
			// if the page is shown, play the video
			function handleVisibilityChange() {
				if (document[hidden]) {
					pauseApp();
				} else {
					resumeApp();
				}
			}

			// Warn if the browser doesn't support addEventListener or the Page Visibility API
			if (typeof document.addEventListener === "undefined" || hidden === undefined) {
				// console.log("This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.");
				// console.log("This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.");
				console.warn("Page Visibility API not supported on this browser.");
			} else {
				// Handle page visibility change   
				document.addEventListener(visibilityChange, handleVisibilityChange, false);
				
				// detect initial visibility (e.g. loaded in background)
				handleVisibilityChange();
			}

			// Intercept blur/focus events to ensure complete coverage
			if(process.env.NODE_ENV === 'production') {
				// We use console a lot during development and we still want the app to run
				// while in the console, but clicking console blurs, so we only run these
				// listeners during production.
				window.addEventListener('focus', resumeApp)
				window.addEventListener('blur',  pauseApp);
			}
		}
	}

	/**
	 * Requests the hosting react view to show this element over the game
	 *
	 * @param {React.Component} reactElement
	 * @param [preserveReactOverlayBetweenSceneChanges=false] If true, overlay will not be removed when changing scenes (until another overlay is set)
	 * @memberof Game
	 */
	setReactOverlay(reactElement, preserveReactOverlayBetweenSceneChanges=false) {
		this.currentReactOverlay = reactElement;
		this.preserveReactOverlayBetweenSceneChanges = preserveReactOverlayBetweenSceneChanges;
		// console.log("[Game.setReactOverlay] reactElement:", reactElement);

		this.emit('setReactOverlay', reactElement);
	}

	/**
	 * Sets the current scene to the given sceneClass - must be a subclass of `Scene`
	 * Note: No fade done here. If you want fade in/out, use `setScene()` instead.
	 *
	 * @param {Scene} sceneClass - Subclass of Scene to set
	 * @memberof Game
	 */
	setCurrentScene(sceneClass, externalOptions=undefined) {
		if(!(sceneClass.prototype instanceof Scene))
			throw new Error("Invalid scene:" + sceneClass + " (type:" + typeof(sceneClass) + ")");

		this._destroyCurrentScene();

		setTimeout(() => {
			this.currentScene = new sceneClass(this);
			this.currentScene.init(externalOptions);
		}, 1000/30);
	}

	_destroyCurrentScene() {
		if (this.currentScene) {
			this.currentScene.destroy();
			if(!this.preserveReactOverlayBetweenSceneChanges)
				this.setReactOverlay(null);
			this.currentScene = null;
		}
	}

	/**
	 * Set the current scene, and optionally fade in/out between scenes.
	 *
	 * @param {Scene|string} name of the scene from `this.scenes` or a `Scene` subclass
	 * @param {boolean} [fadeInOut=true] If false, the scenes will be hard-cut (e.g straight destroy/init, no fade.)
	 * @param {number} [fadeSpeed=333] Speed in milliseconds of fade in/out
	 * @memberof Game
	 */
	async setScene(name, externalOptions=undefined, fadeInOut=true, fadeSpeed=333) {
		this._setSceneTid && clearTimeout(this._setSceneTid);
		this._setSceneTid = setTimeout(() => {
			console.log("[Game.setScene]", { name, externalOptions })
			
			// For auto-reinit if MatterWorker crashes
			this._currentSceneName = name;
			this._currentSceneOpts = externalOptions;

			const sceneClass = name.prototype instanceof Scene || this.scenes[name];
			if(!sceneClass)
				throw new Error("Invalid scene name:" + name);

			// if(fadeInOut && this.currentScene) {
			// 	PixiUtils.fadeOut(this.gameContainer, fadeSpeed)
			// 		.then(() => {
			// 			this.setCurrentScene(sceneClass, externalOptions);
			// 			PixiUtils.fadeIn(this.gameContainer, fadeSpeed)
			// 		});
			// } else {
				this.setCurrentScene(sceneClass, externalOptions);
			// }
		}, 33);
	}

	/**
	 * Shuts down the game - the game should/is unusable after this.
	 * @private
	 * @memberof Game
	 */
	shutdown() {
		window.removeEventListener("resize", this.resizeHandler);
	}

	/**
	 * Initalizes the `.viewport` property with a `Viewport` instance and setups up resize handlers for the game and for the PIXI app
	 * @private
	 * @memberof Game
	 */
	setupViewport() {
		// Get for resizing
		const renderer = this.app.renderer;

		// create viewport
		var viewport = new Viewport({
			screenWidth:  window.innerWidth,
			screenHeight: window.innerHeight,
			worldWidth:   window.innerWidth,
			worldHeight:  window.innerHeight,

			// the interaction module is important for wheel() to work properly when renderer.view is placed or scaled
			interaction: this.app.renderer.plugins.interaction 
		});

		// activate plugins
		viewport
			// .bounce({
			// 	time: 500
			// })
			.drag()
			.pinch()
			.wheel()
			.clamp({ direction: 'all' })
			// .decelerate();

		// Store ref for use later
		this.viewport = viewport;

		// add the viewport to the stage
		this.gameContainer.addChild(viewport);

		// Used to resize when window resizes
		const resizeHandler = () => {
			const p = this.p || 0;
			const newWidth  = window.innerWidth + p;
			const newHeight = window.innerHeight + p;

			renderer.view.style.width  = `${newWidth}px`;
			renderer.view.style.height = `${newHeight}px`;

			viewport.screenHeight = newHeight;
			viewport.screenWidth  = newWidth;

			renderer.resize(newWidth, newHeight);

			this.emit('windowResized', newWidth, newHeight);
		};

		// Listen for changes
		window.addEventListener('resize', this.resizeHandler = resizeHandler, false);
		
		// Make sure viewport fits window at start
		resizeHandler();

		// Set starting values
		// viewport.ensureVisible(0, 0, window.innerWidth, window.innerHeight);
		// viewport.zoom(window.innerWidth);

		this.setupViewportMask();
	}

	/**
	 * Sets a mask on the viewport so objects rendered outside of the world coords are not visible. Can be called multiple times, for example, world size changes.
	 * Can be called by scenes.
	 * @public
	 * @memberof Game
	 */
	setupViewportMask() {
		const viewport = this.viewport;
		if(this.outerMask)
			viewport.removeChild(this.outerMask);

		// from http://www.html5gamedevs.com/topic/28506-how-to-crophide-over-flow-of-sprites-which-clip-outside-of-the-world-boundaries/
		// MASK (clip things outside the background border)
		const outerMask = new PIXI.Graphics();
		outerMask.beginFill(0xFFFFFF);
		outerMask.drawRect(0, 0, viewport.worldWidth, viewport.worldHeight);
		outerMask.endFill();
		viewport.addChild(outerMask);
		viewport.mask = outerMask;
		this.outerMask = outerMask;
	}

	/**
	 * Removes any mask set with the `setupViewportMask()` routine 
	 *
	 * @memberof Game
	 */
	removeViewportMask() {	
		const viewport = this.viewport;
		if(this.outerMask)
			viewport.removeChild(this.outerMask);
		
		viewport.mask = null;
	}
}

/**
 * This is the public singleton factory to access the current instance of the `Game` class running
 *
 * @export
 * @class GameFactory
 */
export class GameFactory {
	
	/**
	 * @property {Game} Current running `Game` instance
	 * @memberof GameFactory
	 */
	game = null;

	/**
	 * Call this when you are not sure if the game has been booted. If it has been booted,
	 * `callback` will be called immediately. If not booted, the callback will be executed 
	 * once the game host element has started the game.
	 * @static
	 * @param {Function} callback
	 * @memberof GameFactory
	 */
	static waitForBoot(callback) {
		if(this.game) {
			callback(this.game);
		} else {
			this._waitingForBoot.push(callback);
		}
	}
	
	static _waitingForBoot = [];
	
	/**
	 * Creates a new `Game` for the given `PIXI.Application` instance
	 *
	 * @throws {Error} Throws error if already called and `shutdownGame()` has not been called
	 * @static
	 * @param {PIXI.Application} pixiApp
	 * @returns Game
	 * @memberof GameFactory
	 */
	static setupGame(element) {
		if(this.game)
			throw new Error("Game already booted");
		
		this._mountPixiApp(element)
		this.game = new Game(this.app);

		// Allow other modules to wait for the game to be initalized
		this._waitingForBoot.forEach(callback => callback(this.game));

		// this.game.stats = new Stats();
		// if (element)
		// 	element.appendChild(this.game.stats.dom);

		return this.game;
	}

	static _mountPixiApp(element) {
		// const FirstLoadKey = 'kitty-renderer-firstLoad';
		// const isFirstLoad = !(window.localStorage.getItem(FirstLoadKey));
		// window.localStorage.setItem(FirstLoadKey, 1);
		
		// The application will create a renderer using WebGL, if possible,
		// with a fallback to a canvas render. It will also setup the ticker
		// and the root stage PIXI.Container
		this.app = new PIXI.Application({
			antialias: true,

			// NB WebGL has a bug on FIRST LOAD 
			// on a NEW DEVICE that renders only a ~1px-wide-strip on the left.
			// To fix that, you would have to exit and re-load the app - NOT a good first-user
			// experience. Chrome Devtools on Google Pixel 3 still report ~50fps (as opposed to 61fps WebGL)
			// and ~90% CPU usage (almost the same as WebGL)
			//
			// After further testing, even if we load canvas first, WebGL still has the same problem,
			// sometimes even after multiple loads. Not sure, so disabling now forcefully until we can find the bug.
			// Update: on PG, let's try Android auto-detect
			forceCanvas: true,
			// window.isPhoneGap 
			// 	// && window.cordova.platformId === 'android' 
			// 	? false : true, //isFirstLoad ? true : false,

			// DPI
			resolution: window.devicePixelRatio || 1,
		});

		// https://codepen.io/osublake/pen/ORJjGj
		const isWebGL = this.app.renderer instanceof PIXI.WebGLRenderer

		if (!isWebGL) {
			this.app.renderer.context.mozImageSmoothingEnabled = false
			this.app.renderer.context.webkitImageSmoothingEnabled = false
		}

		/*
		* Fix for iOS GPU issues
		*/
		this.app.renderer.view.style['transform'] = 'translatez(0)'


		// this.app.view is created by PIXI - probably a canvas 
		if (element)
			element.appendChild(this.app.view);
	}

	/**
	 * Shuts down the running game
	 *
	 * @static
	 * @memberof GameFactory
	 */
	static shutdownGame() {
		if(this.game) {
			this.game.shutdown();
			this.game = null;
		}
	}

	/**
	 * Say hello on the console like PIXI does, giving credit to PIXI and turning off the default PIXI hello routine.
	 *
	 * @static
	 * @memberof GameFactory
	 */
	static sayHello() {
		// We're not being a "jerk face" (https://github.com/pixijs/pixi.js/issues/1900)
		// We still tell everyone PixiJS is powering us (see below)
		PIXI.utils.skipHello()

		return ServerStore.appVersion().then(ver => {
			// console.log({ver});

			// Console code is copied from PIXI's "sayHello()" routine
			if (!window.isPhoneGap && navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
				var args = [
					'\n%c %c %c =^._.^= SleepyCat ' + ver.runningVer + " =^._.^=  %c  %c  Made by Josiah Bryan <josiahbryan@gmail.com> https://sleepycatgame.com/  %c %c \u2665%c\u2665%c\u2665 (Graphics powered by PixiJS - http://www.pixijs.com/)\n\n", 
					'background: #FF5599; padding:5px 0;', 
					'background: #FF5599; padding:5px 0;', 
					'color: #FF5599; background: #030307; padding:5px 0;', 
					'background: #FF5599; padding:5px 0;', 
					'background: #ffc3dc; padding:5px 0;', 
					'background: #FF5599; padding:5px 0;', 
					'color: #ff2424; background: #fff; padding:5px 0;', 
					'color: #ff2424; background: #fff; padding:5px 0;', 
					'color: #ff2424; background: #fff; padding:5px 0;'
				];
				window.console.log.apply(console, args);
			} else if (window.console) {
				window.console.log('=^._.^= SleepyCat ' + ver.runningVer + ' =^._.^=  Made by Josiah Bryan <josiahbryan@gmail.com> - Graphics powered by PixiJS - http://www.pixijs.com/');
			}
			if(ver.needsUpdated) {
				window.console.log(" ** Version is different on the server (" + ver.serverVer + "), consider reloading or updating ...\n\n");
			}

			return ver;
		});
	}
}
