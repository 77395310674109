import React, { Component } from 'react';

// import { GameFactory } from '../../game/Game';

import HammerTouchEmulator from 'hammer-touchemulator';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as spinnerIcon } from '@fortawesome/free-solid-svg-icons';

import Swal from 'sweetalert2/dist/sweetalert2.js'
import withReactContent from 'sweetalert2-react-content';
import 'sweetalert2/src/sweetalert2.scss'

// Load Dimbo font into browser
import './styles/FontDimboRegular.css';

// Load layout styles for view
import './styles/layout.scss';

import { setStatusBarColor } from '../../utils/MobileStatusBarColor';

// We have to use a dedicated component for the OverlayView because this component
// by design will not re-render on prop changes
import OverlayView from './OverlayView';
import { randomAffirmation } from '../../utils/randomAffirmation';

// import { AdMobUtil } from '../../utils/AdMob';
import  'utils/AdMob';
import { ServerStore } from '../../utils/ServerStore';

const DISCLAIMER_KEY = 'kitty-justUpdated';

window.AlertManager = withReactContent(Swal);
// window.alert = text => window.AlertManager.fire(text);

export default class KittyGame extends Component {	
	constructor(props) {
		super(props);

		// Clear the timeout set in index.html since we have entered the app safely
		clearTimeout(window.autoupgradeDeadmanTimeout);
		
		if(!window._hammerTouchEmulatorAdded) {
			window._hammerTouchEmulatorAdded = true;
			HammerTouchEmulator();
		}

		/// Top color in loading gradient
		setStatusBarColor("#db4983")

		this.wasUpdated = process.env.NODE_ENV !== 'production' || window.localStorage.getItem(DISCLAIMER_KEY);
		if(this.wasUpdated) {
			window.localStorage.removeItem(DISCLAIMER_KEY);
			this.affirmation = randomAffirmation(); // rendered in update message
		}

		// Attempt to combat IOS not honoring config
		if (window.StatusBar)
			window.StatusBar.hide();
	}

	// https://codepen.io/chles/pen/aNxMxQ

	setupGame(element) {
		this.element = element;
		
		this.startFontCheck();
	}

	// Wait for Dimbo-Regular to load so PIXI has the font available
	startFontCheck() {
		if(!document.fonts || !document.fonts.check)
			return this.bootGame();

		const startedAt = Date.now(),
			maxWaitTime = 2000,
			timeoutTime = 100;
			
		const check = () => {
			if(!document.fonts.check('1em Dimbo-Regular')) {
				if(Date.now() - startedAt < maxWaitTime) {
					setTimeout(check, timeoutTime);
				} else {
					this.bootGame();
				}
			} else {
				this.bootGame();
			}
		};

		check();
	}

	// cacheAliases used for handling development server - in dev, the chunk is 0.js, in prod it's 1.js
	async downloadAppManifestUpgrades(manifestServerOverride, cacheAliases={"0.js":"1.js"}) {
		// download asset-manifest, extract main.js/main.css, and chunks:
		// "main.css": "./static/css/main.f309e651.chunk.css",
		// "main.js": "./static/js/main.6e8268ac.chunk.js",
		// "static/css/1.8866556e.chunk.css": "./static/css/1.8866556e.chunk.css",
		// "static/js/1.d455662b.chunk.js": "./static/js/1.d455662b.chunk.js",
		// Store in app-boot-upgrades

		const cache = {},
			manifestServer = manifestServerOverride ? manifestServerOverride : 
				process.env.NODE_ENV === 'production' ?
					'https://sleepycatgame.com' :
					'https://' + window.location.hostname + ':3000',
			manifestFile   = '/asset-manifest.json',
			manifestUrl    = [manifestServer, manifestFile, '?_=', Date.now()].join(''),
			manifest       = (await fetch(manifestUrl)
				.then(body => body ? body.json() : {})
				.catch(err => {
					console.warn("Error downloading manifest:", err);
					return {};
				})) || {};

		Object.keys(manifest).forEach( key => {
			const file = manifest[key];

			// This regex takes something like "./static/js/main.6e8268ac.chunk.js" and returns:
			// m[1] = "main"
			// m[2] = "css"
			const m = file.match(/static\/(?:.*\/)?([^/]+?)(?:\.[^.]+)?\.chunk\.(js|css)$/);
			if(m) {
				const fileKey = m[1];
				const fileType = m[2];
				const storageKey = `${fileKey}.${fileType}`;

				cache[storageKey]  =  file.startsWith('http') ? file :
					manifestServer + (file.startsWith('./')   ? file.replace(/^.\//, '/') : file);

				// Alias if specified
				if(cacheAliases[storageKey])
					cache[cacheAliases[storageKey]] = cache[storageKey];
					
			} else {
				// console.warn("[installUpdates] Unable to match regex with:" + file);
			}
		});

		console.log("[installUpdates] Storing upgrade list:", cache); //, manifest); // + JSON.stringify(cache));

		window.localStorage.setItem("app-boot-upgrades", JSON.stringify(cache));
	}

	async bootGame() {
		// This removes our Loading span
		if (this.element)
			this.element.removeChild(this.element.querySelector('#dimbo-font-load-trigger'));

		// Try to make use of code splitting, still not confident this works, I can't seem to find a separate chunk after build
		const { GameFactory } = await import(/* webpackChunkName: "game-core" */ '../../game/Game');

		// Output version on console and check software version on frontend server
		const ver = await GameFactory.sayHello();

		// First-touch metric (no user, but records deviceId)
		const device = await ServerStore.deviceInfo(); // already cached...
		ServerStore.metric('app.booted.' + device.brand); // categorize by brand

		// this.downloadAppManifestUpgrades();
		
		// For easy debugging
		window.downloadAppManifestUpgrades = url => this.downloadAppManifestUpgrades(url);
		window.updateFrom = url => {
			window.localStorage.setItem(DISCLAIMER_KEY, 'updated');
			this.downloadAppManifestUpgrades(url);
			window.location.reload();
		}


		if(ver.needsUpdated) {

			// Used to show DISCLAIMER on loading
			window.localStorage.setItem(DISCLAIMER_KEY, 'updated');
			
			if(window._pgStarted) {
				let latestUpdatedVerAttemptedKey = 'pg-kitty-serverVer',
					latestUpdatedVerAttempted = window.localStorage.getItem(latestUpdatedVerAttemptedKey),
					latestUpdatedVerAttemptedCountKey = 'pg-kitty-serverVerAttemptCount',
					latestUpdatedVerAttemptedCount = parseFloat(window.localStorage.getItem(latestUpdatedVerAttemptedCountKey));

				if(latestUpdatedVerAttempted !== ver.serverVer) {
					window.localStorage.setItem(latestUpdatedVerAttemptedCountKey,
						latestUpdatedVerAttemptedCount = 0
					);
				} else {
					if(isNaN(latestUpdatedVerAttemptedCount))
						latestUpdatedVerAttemptedCount = 0;

					window.localStorage.setItem(latestUpdatedVerAttemptedCountKey,
						latestUpdatedVerAttemptedCount ++
					);

					window.localStorage.setItem(latestUpdatedVerAttemptedKey, ver.serverVer);
				}

				// By using localStorage to store the version we attempted to upgrade,
				// when we hit this particular block again (assuming it's the same ver),
				// we allow 3 attempts to get it boot, then we continue booting like normal.
				// This is different than the deadman's timeout because this guards against
				// failures in index.html to register updates and prevents a boot cycle lock-in.
				// If a new ver comes out, then the latestUpdatedVerAttempted will not match ver.serverVer again,
				// and latestUpdatedVerAttemptedCount will be reset in that case and we can try with the new ver.
				if(latestUpdatedVerAttemptedCount < 3) {
					// Download asset-manifest from frontend server and store upgrade paths in localStorage
					await this.downloadAppManifestUpgrades();

					// Await .flush to ensure metrics are dumped to server before reload
					await ServerStore.metric('app.upgraded.phonegap', null, ver).flush();

					// // Notify user
					// if(window.confirm("Sleepy Cat game has been updated to version " + ver.serverVer.toUpperCase() + ", press OK to download update"))
					// 	// Reload to get index.html to boot new files straight from server
						window.location.reload();
				} else {
					// Notify server of failsafe failure
					ServerStore.metric('app.upgraded.phonegap.rejected.reboot_lock_failsafe', null, {
						latestUpdatedVerAttemptedCount,
						latestUpdatedVerAttempted,
						ver
					});
				}
	
			} else {

				// Clear service worker caches
				window.caches && window.caches.keys().then(keys => { 
					keys.forEach(key => {
						// if(key.includes("temp")) {
							console.log("Removing cache for new version update from ", key);
							window.caches.delete(key);
						// }
					})
				});
				
				// Await .flush to ensure metrics are dumped to server before reload
				await ServerStore.metric('app.upgraded.browser', null, ver).flush();

				// // Notify user
				// if(window.confirm("Sleepy Cat game has been updated to version " + ver.serverVer.toUpperCase() + ", press OK to download update"))
				// 	// Reload page
					window.location.reload();
			}
		} else {
	
			// Create the game object
			this.game = GameFactory.setupGame(this.element.querySelector('#game-container'));

			this.GameFactory = GameFactory; // store for shutdown later
		}
	}

	componentWillUnmount() {
		// this.game.off('setReactOverlay', this._overlayHandler);

		this.GameFactory.shutdownGame();
	}

	shouldComponentUpdate() {
		// This component does not react to prop changes - all state managed internally in the PIXI app
		return false;
	}

	render() {
		return <div id="game-wrap"
				ref={ref => this.setupGame(ref)}>

			<div id="game-container">
				{/* setupGame() will add main PIXI stage here */}
			</div>

			<span id="dimbo-font-load-trigger">
				{/* Have to have this here to trigger the Dimbo font to actually load */}
				Loading...
			</span>

			<div id="react-view-overlay">
				<OverlayView/>
			</div> 

			<div id="basic-loading-indicator">
				<FontAwesomeIcon icon={spinnerIcon} spin size="3x"/>
				
				{this.wasUpdated && <div className="updated-text">
					<p>We just updated, so this may take a minute to download the changes.</p>
					<p>Your kitty is drinking coffee and thinking, "{this.affirmation}"</p>
				</div>}
			</div>

			
		</div>;
	}
}
