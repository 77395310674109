import { ServerModelProxy } from './ServerModelProxy';

export class Level extends ServerModelProxy {
	static serviceRoot() {
		return '/levels';
	}
}


export class Cat extends ServerModelProxy {
	static serviceRoot() {
		return '/cats';
	}

	static async inflate(data) {
		if(!data)
			return null;
			
		const cat = await super.inflate(data);
		// cat.level = parseFloat(cat.level) || 1;
		// if(isNaN(cat.level))
		// 	cat.level = 1;
		if(!cat.level) {
			cat.level = { id: null };
		}
		return cat;
	}
}