import * as PIXI from 'pixi.js';
// import * as Matter from 'matter-js';
// import TWEEN from '@tweenjs/tween.js';
// import { PixiUtils } from './PixiUtils';
import { GameFactory } from '../game/Game';

const RENDER_FPS = 12;

export class PixiMatterContainer extends PIXI.Container {

	static EditorMode = false;
	
	/**
	 * Creates an instance of PixiMatterContainer.
	 * @param {string} [shape='rectangle'] - Type of `MatterJS` shape to create. Defaults to `rectangle` 
	 * @param {object} [size=[0, 0, 100, 100]] - Shape of the Matter body to create - MUST SET - cannot be changed after creating.
	 * @param {object} [options] - Options passed directly to the `MatterJS` body creator, with the exception of the following keys:
	 * @param {object} [options.addToWorld=true] - If set to false, YOU MUST ADD the body to a matter world. By defaults, adds it to `engine.world`
	 * @param {object} [options.altPhysicalSize] - Used to alter the shape used by Matter - useful if the "physical" part of your sprite should be offset from the explicit coords that Matter uses. (Matter expects all coords to anchor 50%/50% - center of the object). 
	 * @param {number} [options.altPhysicalSize.offsetX] - Adjust X coords from the `MatterJS` body by this many pixels
	 * @param {number} [options.altPhysicalSize.offsetY] - Adjust Y coords from the `MatterJS` body by this many pixels
	 * @memberof PixiMatterContainer
	 */
	constructor(shape = 'rectangle', size = [0, 0, 100, 100], options = {
		altPhysicalSize: null
	}, engine) {
		super();
		
		// if (!engine)
		// 	throw new Error("Engine required");
		if(engine)
			throw new Error("Code upgrade needed");

		this.game = GameFactory.game;
		
		
		const altPhysicalSize = options.altPhysicalSize || {
			offsetX: 0,
			offsetY: 0,
			width: 0,
			height: 0,
		};
		
		delete options.altPhysicalSize;
		this.altPhysicalSize = altPhysicalSize;
		
		if (altPhysicalSize.width)
			size[2] = altPhysicalSize.width;
		if (altPhysicalSize.height)
			size[3] = altPhysicalSize.height;
		
		this._body = this.game.postToMatter('addBody', { shape, size, options });

		// Store ref on the _body back to PixiMatterContainer for use in collision events
		this._body.pixiContainer = this;
		this._body.onUpdate = this._updateLogic; //_matterUpdate;
		this._body.onRender = this._pixiRender;
		
		// this.on('added', () => {
		// 	this.game.on('matterUpdate', this._matterUpdate);
		// });
		// this.on('removed', () => {
		// 	this.game.off('matterUpdate', this._matterUpdate);
		// });

		this.setPosition({x: size[0], y: size[1]});
	}

	/**
	 * Adds `this.body` to the MatterSimulation - does NOT add this object to any parent PIXI objects though
	 * @private
	 * @memberof PixiMatterContainer
	 */
	matterAdd() {
		// if(!this.matterRemoved)
		// 	return;

		// if(this.matterRemoved)
		// 	this.game.on('matterUpdate', this._matterUpdate);

		this.matterRemoved = false;

		// Just re-add the same body with same id, already exists in engine
		this.game.postToMatter('addBody', { id: this._body.id });
	}

	/**
	 * Removes `this.body` from the MatterSimulation - does NOT remove this object from any parent PIXI objects though.
	 * @private
	 * @memberof PixiMatterContainer
	 */
	matterRemove() {
		if (this.matterRemoved)
			return;

		this.matterRemoved = true;
		
		this._postToMatter('removeBody');
		// this.game.off('matterUpdate', this._matterUpdate);
	}

	// sleepStart() {
	// 	// this.setAngle(0);
	// 	// overload to do anything useful
	// }

	/**
	 * Accessor for the underlying `MatterJS` body
	 *
	 * @readonly
	 * @memberof PixiMatterContainer
	 */
	get body() {
		return this._body;
	}

	_postToMatter(cmd, data={}) {
		if(this.isDestroyed && !['destroyBody','removeBody'].includes(cmd))
			return;

		this.game.postToMatter(cmd, { id: this._body && this._body.id, ...data });
	}

	/**
	 * Set arbitrary `MatterJS` values on the underlying body
	 * @see {@link http://brm.io/matter-js/docs/classes/Body.html#method_set}
	 * From the docs: [You should] use the actual setter functions in performance critical situations.
	 * Also, some of our `PixiMatterContainer` setters also sync the underlying PIXI object, so use setters on `PixiMatterContainer` if they exist.
	 *
	 * @param {string} option
	 * @param {any} value
	 * @memberof PixiMatterContainer
	 */
	setMatter(option, value) {
		this._postToMatter('setMatter', { option, value });
		// Emulate it locally
		this._body[option] = value;
	}

	/**
	 * Calls `Matter.Body.setPosition` and sets `this.x` and `this.y` to the given position.
	 * @see {@link http://brm.io/matter-js/docs/classes/Body.html#method_setPosition} for more information.
	 *
	 * @param {object} position
	 * @param {number} position.x
	 * @param {number} position.y
	 * @memberof PixiMatterContainer
	 */
	setPosition(position) {
		if(this.isDestroyed || this.matterRemoved || !position)
			return;

		this._postToMatter('setPosition', { position });
		this.x = position.x + this.altPhysicalSize.offsetX;
		this.y = position.y + this.altPhysicalSize.offsetY;

		// Make sure internal ref stays in sync with new MatterSimulation
		this.body.x = position.x;
		this.body.y = position.y;
	}
	
	/**
	 * Calls `Matter.Body.setAngle` and sets `this.rotation` to the given `angle`
	 * @see {@link http://brm.io/matter-js/docs/classes/Body.html#method_setAngle} for additional info
	 *
	 * @param {number} angle
	 * @memberof PixiMatterContainer
	 */
	setAngle(angle) {
		this.rotation = angle;
		this._postToMatter('setAngle', { angle });
	}

	
	/**
	 * Shortcut for `Matter.Body.setAngularVelocity` with `this.body` as the body.
	 * @param {number} velocity
	 * @see {@link http://brm.io/matter-js/docs/classes/Body.html#method_setAngularVelocity}
	 */
	setAngularVelocity(velocity) {
		this._postToMatter('setAngularVelocity', { velocity });
	}

	/**
	 * Shortcut for `Matter.Body.setVelocity` with `this.body` as the body. ()
	 */
	setVelocity(velocity) {
		this._postToMatter('setVelocity', { velocity });
	}


	/**
	 * Shortcut for `Matter.Body.scale` with `this.body` as the body. ()
	 */
	scaleMatter(scale) {
		this._postToMatter('scale', { scaleX: scale, scaleY: scale });
	}

	/**
	 * Shortcut for `Matter.Body.setInertia` with `this.body` as the body. 
	 * @param {number} [inertia=Infinity]
	 * @see {@link http://brm.io/matter-js/docs/classes/Body.html#method_setInertia}
	 */
	setInertia(inertia=Infinity) {
		this._postToMatter('setInertia', { inertia });
	}

	/**
	 * Shortcut for `Matter.Body.applyForce` with `this.body` as the body.
	 */
	applyForce(a, b) {
		this._postToMatter('applyForce', { a, b });
	}
	
	/**
	 * Updates the state of this container (position and rotation) based on the `MatterJS` body we are tracking.
	 * Calls `onUpdate(x,y,angle)` before applying the update (if that property is defined on this object).
	 * * If `onUpdate()` returns false (`returnValue===false`, not just a falsy value), then the update is not applied.
	 * * If `onUpdate()` returns a 3-element array (any other length array or any other return value is ignored), the 3 elements will be read as new [x,y,angle] to be used
	 * * If `onUpdate()` returns true (`returnValue===true`) or anything other than `false` or the 3-element-array mentioned previously, the update will be applied as-is, no changes
	 * This allows `onUpdate()` to do any custom application of the values if desired.
	 *
	 * @returns
	 * @memberof PixiMatterContainer
	 */
	_matterUpdate = () => {
		if (this.isDestroyed   || 
			this.matterRemoved || 
			PixiMatterContainer.EditorMode)
			return;

		// let { x, y } = this._body.position;
		// const angle  = this._body.angle;
		const { x, y, angle } = this._body;

		if(!this._matterVec)
			this._matterVec = [0,0,0]; //new Array(3);

		this._matterVec[0] = x;
		this._matterVec[1] = y;
		this._matterVec[2] = angle;

		if(!this._renderTid)
			this._renderTid = setInterval(this._sendToRenderer, 1000 / RENDER_FPS);
	}

	_sendToRenderer = () => {

		let [ x, y, angle ] = this._matterVec;

		x = x - this.altPhysicalSize.offsetX;
		y = y - this.altPhysicalSize.offsetY;

		if (this.onUpdate) {
			const result = this.onUpdate(x, y, angle);
			if(result === false) {
				return;
			} else
			if(result && Array.isArray(result) && result.length === 3) {
				const [ x1, y1, a1 ] = result;
				x = x1;
				y = y1;
				angle = a1;
			}
			// Anything other than `false` or `result.length` === 3 is ignored
		}

		// const norm = x => x.toFixed(0);
		// if (norm(x) !== norm(this.x) || norm(y) !== norm(this.y) || norm(this.rotation) !== norm(angle)) {
		// 	clearTimeout(this._sleepTid);
		// 	this._sleepTid = setTimeout(() => this.sleepStart(), 250); //1000 / 60 * 10);
		// 	if (this.moved)
		// 		this.moved();
		// }

		const newUpdate = { x, y, angle };

		if(this._lastRenderUpdate) {
			// const update = this._lastRenderUpdate;

			this._applyRenderUpdate(newUpdate);
			
			// const tweenTo = new TWEEN.Tween(update)
			// 	.to(newUpdate, 1000 / RENDER_FPS) // same fps as renderTid for length
			// 	// .easing(TWEEN.Easing.Elastic.InOut)
			// 	.onUpdate(this._applyRenderUpdate);
				
			// tweenTo.start();

			// PixiUtils.touchTweenLoop();
			
		} else {
			// this.x = x;
			// this.y = y;
			// this.rotation = angle;
			this._applyRenderUpdate(newUpdate);
		}

		this._lastRenderUpdate = newUpdate;
	}

	_applyRenderUpdate = (update) => {
		this.x = update.x;
		this.y = update.y;
		this.rotation = update.angle;
	}

	_updateLogic = () => {
	
		if (this.isDestroyed   || 
			this.matterRemoved || 
			PixiMatterContainer.EditorMode)
			return;

		let { x, y, angle } = this._body;

		x = x - this.altPhysicalSize.offsetX;
		y = y - this.altPhysicalSize.offsetY;

		if (this.onUpdate) {
			const result = this.onUpdate(x, y, angle);
			if(result === false) {
				return;
			} else
			if(result && Array.isArray(result) && result.length === 3) {
				const [ x1, y1, a1 ] = result;
				x = x1;
				y = y1;
				angle = a1;
			}
			// Anything other than `false` or `result.length` === 3 is ignored
		}

		if(!isNaN(x))
			this.x = x;
		if(!isNaN(y))
			this.y = y;
		if(!isNaN(angle))
			this.rotation = angle;
		
		// return { x, y, angle };
		
	}

	_pixiRender = (x, y, angle) => {
		this.x = x;
		this.y = y;
		this.rotation = angle;
	}

	/**
	 * Destroys both this PIXI object and the underlying `MatterJS` body
	 * @param {boolean|object} options to pass thru to `PIXI.DisplayObject.destroy`
	 */
	destroy(options=true) {
		if(this.isDestroyed)
			return;

		if(this._renderTid)
			clearInterval(this._renderTid);
		
		this.isDestroyed = true;
		// this.matterRemove();
		this._postToMatter('destroyBody');
		
		if (this._texture)
			// If no texture, we are guaranteed to get an error,
			// but I'm not sure why _texture would be missing - I just know I get errors sometimes.
			super.destroy(options);
	}

	tempDestroy() {
		// if(this.body.label === '<star>')
		// 	console.log("[doneBreaking] ", this.body.label, this.objectCompleted);

		// Removing seems to break things
		// this.parent.removeChild(this);
		
		// Calling this.destroy() breaks things
		// this.destroy(true);

		// This is the only way to "remove" it from the scene for now
		this.matterRemove();
		this.alpha = 0;
		this.interactiveChildren = false;
		this.interactive = false;

		// Leave it in the parent with 0 alpha so when scene is destroyed, it is destroyed as well
	}
}
