import * as PIXI from 'pixi.js';
import React from 'react';
import {PixiUtils} from 'utils/PixiUtils';
import {PixiMatterContainer} from 'utils/PixiMatterContainer';

import { GameFactory }  from './Game';
import { ServerStore } from 'utils/ServerStore';

import SoundManager from 'utils/SoundManager';

import Scene from './scenes/Scene'; // for removeResourcesFromPixi


import crosshairs from 'assets/crosshairs/pngs_blck/simple_ch_1_white.png';

// import part_highlight    from 'assets/gametiles/tiles/layers/highlight.png';
// import kitty_eyes_closed from 'assets/kitty-v1/kitty-eyes-closed.png';
// import kitty_eyes_blank  from 'assets/kitty-v1/kitty-eyes-blank2.png';

// // import kitty_ear_left from 'assets/kitty-ear-left.png';
// // import kitty_ear_right from 'assets/kitty-ear-right.png';
// // import kitty_ears from 'assets/kitty-ears.png';
// // import kitty_eyes_default from 'assets/kitty-eyes-default.png';
// // import kitty_nose_hillbilly from 'assets/kitty-nose-hillbilly.png';
// import kitty_body            from 'assets/kitty-v1/kitty-silver-ear-base.png'
// import kitty_eyes_pupils     from 'assets/kitty-v1/kitty-eyes-pupils2.png';
// import kitty_nose            from 'assets/kitty-v1/kitty-nose.png';
// import kitty_nose_up         from 'assets/kitty-v1/kitty-nose-up.png';
// import kitty_nose_fangs      from 'assets/kitty-v1/kitty-nose-fangs.png';
// import kitty_nose_mouth_open from 'assets/kitty-v1/kitty-nose-mouth-ah-j-k.png';
// import kitty_nose_mouth_grin from 'assets/kitty-v1/kitty-nose-toothy_grin.png';


import kitty_body            from 'assets/kitty-v2/body.png';
import kitty_eyes_blank      from 'assets/kitty-v2/eyes-open.png';
import kitty_eyes_closed     from 'assets/kitty-v2/eyes-closed.png';
import kitty_eyes_pupils     from 'assets/kitty-v2/pupils.png';
import kitty_nose            from 'assets/kitty-v2/mouth-neutral.png';
import kitty_nose_up         from 'assets/kitty-v2/mouth-sad.png';
import kitty_nose_mouth_open from 'assets/kitty-v2/mouth-happy.png';

import kitty_body_desat            from 'assets/kitty-v2/body-desat.png';
import kitty_eyes_closed_desat     from 'assets/kitty-v2/eyes-closed-desat.png';
import kitty_nose_mouth_open_desat from 'assets/kitty-v2/mouth-happy-desat.png';


import scuba_mask    from 'assets/kitty-v2/scuba-mask.png';
import space_helmet_top    from 'assets/misc/space-helmet-top.png';
import space_helmet_bottom from 'assets/misc/space-helmet-bottom.png';

// import bed_topper from 'assets/bed-topper.png';
import extra_tiara   from 'assets/misc/tiara.png';
import extra_bowtie  from 'assets/misc/bowtie.png';
import extra_cowbell from 'assets/misc/cowbell_icon.png';

import scuba_bubble  from 'assets/bubble/pngs/bubble-128px.png'; // size TBD

// import anim_firework3yellow_sheet     from 'assets//firework3/yellow/firework3-yellow.json';
// import anim_firework3yellow_img       from 'assets//firework3/yellow/firework3-yellow.png';

import anim_sleep_sheet     from 'assets/statuseffects/compiled/status-sleep.json';
import anim_sleep_img       from 'assets/statuseffects/compiled/status-sleep.png';

import anim_health_sheet    from 'assets/statuseffects/compiled/status-health.json';
import anim_health_img      from 'assets/statuseffects/compiled/status-health.png';

import anim_heart_sheet     from 'assets/statuseffects/compiled/status-heart.json';
import anim_heart_img       from 'assets/statuseffects/compiled/status-heart.png';

import anim_stars_sheet     from 'assets/statuseffects/compiled/status-star.json';
import anim_stars_img       from 'assets/statuseffects/compiled/status-star.png';

import anim_cloud_sheet     from 'assets/statuseffects/compiled/status-green-cloud.json';
import anim_cloud_img       from 'assets/statuseffects/compiled/status-green-cloud.png';

import anim_flame_sheet     from 'assets/projectiles/anim_flame/flame.json';
import anim_flame_img       from 'assets/projectiles/anim_flame/flame.png';

import anim_sparkle_sheet   from 'assets/sparkleeffect/compiled/sparkle.json';
import anim_sparkle_img     from 'assets/sparkleeffect/compiled/sparkle.png';

import anim_explosion_sheet from 'assets/explosioneffect/bigexplosion.json';
import anim_explosion_img   from 'assets/explosioneffect/bigexplosion.png';

import sparkle_static_img   from 'assets/extralife/sparkle_static.png';

import power_bomb    from 'assets/projectiles/pngs/bomb/bomb_projectile.png';
import power_grenade from 'assets/projectiles/pngs/grenade/grenade_icon.png';
import shield_blue   from 'assets/shield/keyframes/colored-shield/100-percent-shield-effect-colored.png';

// Imported here just for the URL for the item list images
import icon_power  from 'assets/uigameicon/face_on_blue_power_icon.png';
// import pill_red    from 'assets/powerpill/red_pill.png';
import pill_blue   from 'assets/powerpill/blue_pill.png';
import power_scale  from 'assets/powerpill/purple_yellow_spots_pill.png';
// import pill_pink from 'assets/powerpill/pink_pill.png';
// import pill_yellow from 'assets/powerpill/yellow_pill.png';

import power_gravity_float  from 'assets/powerpill/pink_yellow_spots_pill.png';
import power_speed  from 'assets/powerpill/pink_green_pill.png';


// import kitty_pink from 'assets/kittyblock-pink.png';

import sub_no_ads_icon from 'assets/gameicons/icons_256/sound_off.png';

import EventEmitter from 'events';

import TWEEN from '@tweenjs/tween.js';
import {BulgePinchFilter} from '@pixi/filter-bulge-pinch';

import { angle, rotate, toRadians, mag } from 'utils/geom';

import AssetLoader from 'utils/AssetLoader';

// import { BubbleText } from 'utils/BubbleText';
import { ShowOnePopupHelper } from './scenes/KittyFlySleep';

import MarketItemList from './MarketItemList';
import merge from 'deepmerge';
import { defer } from 'utils/defer';

export const MAX_POWER  = 2147483647; //mysql max int
export const MAX_HEALTH = 100;

// Surface friction in various modes
export const SURFACE_FRICTION_WATER    = 0.01;
export const SURFACE_FRICTION_SPACE    = 0.001;
export const SURFACE_FRICTION_DEFAULT  = 0.999;
export const SURFACE_FRICTION_DEFAULT_JUMPING = 0.0001;

// Amounts used to modify scale one unit of user input into velocity values
export const MOVEMENT_CONSTANT_DEFAULT = 12.5;
export const MOVEMENT_CONSTANT_WATER   = MOVEMENT_CONSTANT_DEFAULT * .333;
export const MOVEMENT_CONSTANT_SPACE   = MOVEMENT_CONSTANT_DEFAULT * .333;

// Emitted when in editor mode
export const EDIT_MOVE_EVENT = 'editorActorMove';
			

export const TutorialKeys = {};
[
	'welcome1',
	'endOfLevel',
	'swipe',
	'lasers',
	'sleep',
	'star',
	'pill'
].map(x => TutorialKeys[x] = 'tut-' + x);


const vibrate = (...args) => {
	if(navigator.vibrate)
		navigator.vibrate(...args);
}


// console.log(MarketItemList);

export const KITTY_ITEMS = merge(MarketItemList, {
	sub_no_ads: {
		customOfferTitle: "Don't like ads in your game?",
		customOfferText:
		<>
			<p>Neither do we! Sadly, we need to pay the bills.</p>
			<p>You turn off ads forever (and help us pay the bills) by paying the one-time price of $.99!</p>
		</>,
		customBuyLabel: 'Turn Off Ads for $.99',

		// Only iconUrl is given (no iconRes) because it's not an item for the cat,
		// but we can/could render the item in the market/shopwidget
		iconRes: null,
		iconUrl: sub_no_ads_icon,

		// NB: No setEnabled() or action()
		// because owning this is simply a "flag" to disable ads elsewhere in the game
	},

	tiara: {
		iconRes: 'extra_tiara',
		iconUrl: extra_tiara,
		customOfferText: <p>
			Your kitty will feel <i>fabulous</i> in this gold-polished tiara! Add this tiara to your kitty for an extra-special play time!
		</p>,
		setEnabled(actor, flag) {
			
			PixiUtils[flag ? 'fadeIn' : 'fadeOut'](actor.sprites.extra_tiara, 200);
			// actor.sprites.extra_tiara.alpha = flag ? 1:0;
		}
	},

	bowtie: {
		iconRes: 'extra_bowtie', // used for PIXI sprites
		iconUrl: extra_bowtie, // used for React <ShopPopup>
		customOfferText: <p>
			Your kitty will look dapper and quite studious in this 
			handsome bowtie, ready for a night out, hunting stars and conquering worlds!
		</p>,
		setEnabled(actor, flag) {
			PixiUtils[flag ? 'fadeIn' : 'fadeOut'](actor.sprites.extra_bowtie, 200);
			// actor.sprites.extra_bowtie.alpha = flag ? 1:0;
		}
	},

	cowbell: {
		iconRes: 'extra_cowbell', // used for PIXI sprites
		iconUrl: extra_cowbell, // used for React <ShopPopup>
		customOfferText: <p>
			Your kitty has a fever! And the only prescription ... is more cowbell! Why don't you lay down that cowbell right now. With your kitty. Together.
			<br/>
			<small><a href='https://en.wikipedia.org/wiki/More_Cowbell' target='_blank' rel="noopener noreferrer">
				(In Memoriam: Gene Frenkle: 1950-2000)
			</a></small>
		</p>,
		// setEnabled(actor, flag) {
		// 	PixiUtils[flag ? 'fadeIn' : 'fadeOut'](actor.sprites.extra_cowbell, 200);
		// 	// actor.sprites.extra_bowtie.alpha = flag ? 1:0;
		// }
		baseTint: 0x485877,
		action(actor, scene) {
			const p = defer();

			vibrate(250);
			actor.setMouth('open');
			PixiUtils.fadeIn(actor.sprites.extra_cowbell, 200);
			
			setTimeout(() => {
				if (scene.actor.anims.explosion)
					scene.actor.anims.explosion.play();
				
				setTimeout(() => scene.explosion(0.75, 2048), 250);
				setTimeout(() => PixiUtils.fadeOut(actor.sprites.extra_cowbell, 200), 250);
				setTimeout(() => p.resolve(), 1000); // give explosion time to settle
			}, 333);

			return p;
		}
	},

	lasers: {
		iconRes: 'icon_power',
		iconUrl: icon_power,
		customOfferText: <p>
			A cat with laser beams attached to it's head? Why not! Shoot lasers at anything and everything on each level. For more fun, get the Explosions too!
		</p>,
		action: actor => actor.shoot(),
		baseTint: 0x00CCFF,
	},
	explosion: {
		iconRes: 'power_bomb',
		iconUrl: power_bomb,
		meterIconScale: 0.125,
		baseTint: 0xFF7F2A,
		customOfferText: <p>
			Your kitty will have an <i>explosive</i> good time as it blows you (and the level) away with these special kitty-bombs!
		</p>,
		action(actor, scene) {
			const p = defer();
			vibrate(250);
			if (scene.actor.anims.explosion)
				scene.actor.anims.explosion.play();
			scene.actor.setMouth('open');
			setTimeout(() => scene.explosion(), 250);
			setTimeout(() => p.resolve(), 1000); // give explosion time to settle
			return p;
		}
	},
	implosion: {
		iconRes: 'pill_blue',
		iconUrl: pill_blue,
		meterIconScale: 0.125,
		baseTint: 0x1ABFBF,
		customOfferText: <p>
			Your kitty will have even more implosive fun it blows the level up with these special kitty-bombs!
		</p>,
		action(actor, scene) {
			const p = defer();
			vibrate(250);
			if (scene.actor.anims.explosion)
				scene.actor.anims.explosion.play();
			scene.actor.setMouth('fangs');
			setTimeout(() => scene.explosion(-0.1, 256 * 1.5), 250);
			setTimeout(() => p.resolve(), 1000); // give explosion time to settle
			return p;
		}
	},
	shield: {
		iconRes: 'shield_blue',
		iconUrl: shield_blue,
		meterIconScale: 0.0325,
		baseTint: 0x4F9D9C,
		customOfferText: <p>
			This special kitty-shaped photonic uberplexed shield protects your kitty and enables it to slice through levels, immune to any dangers for 15 seconds at a time.
		</p>,
		action(actor, scene) {
			const p = defer();
			vibrate(250);
			if (actor.sprites.shield_blue)
				actor.sprites.shield_blue.play();
			actor.shieldActive = true;
			actor.setMouth('open');
			// show UI timer, resolves when done
			scene.startTimerMeter(15 * 1000).then(() => {
				actor.shieldActive = false;
				actor.sprites.shield_blue.stop()
				// p.resolve();
			});
			// Instead of resolving at end of action, debounce a bit
			// then resolve
			setTimeout(() => p.resolve(), 1000);
			return p;
		}
	},

	go_big: {
		iconRes: 'power_scale',
		iconUrl: power_scale,
		meterIconScale: 0.125,
		baseTint: 0xAA179A,
		customOfferText: <p>
			Go big or go home! Every time you use the Big Purple Pill, your kitty will grow 2x it's size for short time, then shrink back to normal. Combine this with the <i>photonic shield</i>, <i>lasers</i>, or even a couple well-timed <i>explosions</i> to destroy every level!
		</p>,
		action(actor, scene) {
			const p = defer();
			vibrate(250);
			const currentScale = actor.obj.scale.x,
				newScale = currentScale * 2;
			// actor.shieldActive = true;
			// actor.setMouth('open');
			actor.multScaleEffect(newScale, true);
			// show UI timer, resolves when done
			scene.startTimerMeter(15 * 1000).then(() => {
				actor.multScaleEffect(1, true);
				// actor.shieldActive = false;
				// p.resolve();
			});
			// Instead of resolving at end of action, debounce a bit
			// then resolve
			setTimeout(() => p.resolve(), 1000);
			return p;
		}
	},
	grenade: {
		iconRes: 'power_grenade',
		iconUrl: power_grenade,
		meterIconScale: 0.125,
		baseTint: 0x507933,
		customOfferText: <>
			<p>A hand grenade forged to smite the powers of evil. Instructions: Pull pin, count to three, throw. </p>
			<p>
				<small>A Reading from the Book of Armaments, Chapter 4, Verses 16 to 20:</small><br/>
				<i>&quot;Then did he raise on high the Holy Hand Grenade of Antioch, saying, "Bless this, O Lord, that with it thou mayst blow thine enemies to tiny bits, in thy mercy." And the people did rejoice and did feast upon the lambs and toads and tree-sloths and fruit-bats and orangutans and breakfast cereals ... <br/><br/>Now did the Lord say, "First thou pullest the Holy Pin. Then thou must count to three. Three shall be the number of the counting and the number of the counting shall be three. Four shalt thou not count, neither shalt thou count two, excepting that thou then proceedeth to three. Five is right out. Once the number three, being the number of the counting, be reached, then lobbest thou the Holy Hand Grenade in the direction of thine foe, who, being naughty in my sight, shall snuff it."&quot;</i>
			</p>
		</>,
		action(actor, scene) {
			const p = defer();
			vibrate(250);
			if (scene.actor.anims.explosion)
				scene.actor.anims.explosion.play();
			scene.actor.setMouth('open');
			// setTimeout(() => scene.explosion(), 250);
			setTimeout(() => scene.explosion(0.8, 256), 250);
			setTimeout(() => p.resolve(), 1000); // give explosion time to settle
			return p;
		}
	},

	floater: {
		iconRes: 'power_gravity_float',
		iconUrl: power_gravity_float,
		meterIconScale: 0.125,
		baseTint: 0xFF0066,
		customOfferText: <p>
			Anti-gravity pills make everything not anchored down to the level float to the top of the world! Give your kitty a fun challenge with this anti-gravity addition to their games.
		</p>,
		action(actor, scene) {
			const p = defer();
			vibrate(250);
			scene.setGravity(-1);
			// show UI timer, resolves when done
			scene.startTimerMeter(15 * 1000).then(() => {
				scene.setGravity(1);
				// p.resolve();
			});
			// Instead of resolving at end of action, debounce a bit
			// then resolve
			setTimeout(() => p.resolve(), 1000);
			return p;
		}
	},

	speed_boost: {
		iconRes: 'power_speed',
		iconUrl: power_speed,
		meterIconScale: 0.125,
		baseTint: 0x83CA28,
		customOfferText: <p>
			Double your kitty's speed with this speed boost so you can finish levels twice as fast and collect all the stars!
		</p>,
		action(actor, scene) {
			const p = defer();
			vibrate(250);
			const oldSpeed = actor.movementScalingConstant;
			actor.multScaleEffect(.95, true);
			actor.movementScalingConstant = MOVEMENT_CONSTANT_DEFAULT * 2;
			// show UI timer, resolves when done
			scene.startTimerMeter(15 * 1000).then(() => {
				actor.multScaleEffect(1, true);
				actor.movementScalingConstant = oldSpeed;
				// p.resolve();
			});
			// Instead of resolving at end of action, debounce a bit
			// then resolve
			setTimeout(() => p.resolve(), 1000);
			return p;
		}
	}


});

Object.keys(KITTY_ITEMS).forEach(itemId => {
	const def = KITTY_ITEMS[itemId];
	// Normalize the URL for PhoneGap
	def.iconUrl = AssetLoader.normalizeUrl(def.iconUrl);
});

// for testing from console
window.KITTY_ITEMS = KITTY_ITEMS;

const resourceKeys = {
	// NB: This is now loaded by main scene as well, so no need to re-load
	sparkle_static_img,//: AssetLoader.normalizeUrl(sparkle_static_img),

	anim_flame_img, // has to be in primary list because must be layered behind the kitty_body
	kitty_body,
	kitty_body_desat,
	kitty_eyes_closed,
	kitty_eyes_closed_desat,
	kitty_eyes_blank,
	kitty_eyes_pupils,
	kitty_nose,
	kitty_nose_up,
	// kitty_nose_fangs,
	// kitty_nose_hillbilly,
	// kitty_nose_mouth_grin,
	kitty_nose_mouth_open,
	kitty_nose_mouth_open_desat,
	// part_highlight,

	scuba_mask,	
	space_helmet_top,
	space_helmet_bottom,

	shield_blue,

	// here instead of secondary because I'm lazy right now
	crosshairs,
	extra_tiara,
	extra_bowtie,
	extra_cowbell
};

// These resources only loaded AFTER primary body loaded and created (from resourceKeys above)
const secondaryResourceKeys = {

	// anim_firework3yellow_img,
	
	anim_sleep_img,
	anim_health_img,
	anim_stars_img,
	anim_cloud_img,
	// anim_smoke_img,
	anim_heart_img,
	// weapon_taser,

	scuba_bubble,
	
	anim_sparkle_img,

	anim_explosion_img
};

// This AimingLine class based on http://www.html5gamedevs.com/topic/28098-simple-lines-with-pixijs/?do=findComment&comment=161647
class AimingLine extends PIXI.Graphics {
    constructor(points=[0,0,0,0], lineSize=2.5, lineColor="0xffff00", shadowPercent=1.75) {
        super();
        
        this.lineWidth = lineSize  || 2.5;
		this.lineColor = lineColor || "0xffff00";
		this.shadowPercent = shadowPercent || 1.75;
        
		this.updatePoints(points)
    }
    
    updatePoints(p) {
        this.points = p;
        
		const { shadowPercent, lineWidth, lineColor, points } = this;
		
		this.clear();

		if(shadowPercent > 1.0) {
			const shadowWidth = lineWidth * shadowPercent;

			this.lineStyle(shadowWidth, "0x000000");
			this.moveTo(points[0], points[1]);
			this.lineTo(points[2], points[3]);
		}

        this.lineStyle(lineWidth, lineColor);
        this.moveTo(points[0], points[1]);
        this.lineTo(points[2], points[3]);
    }
}


class Bubble extends PIXI.Sprite {
	constructor(actor, texture) {
		super(texture);

		if(actor.destroyed)
			return;

		// Store for use in setPositionFromActor
		this.actor = actor;

		const bubbleScale = 0.75;
		this.scale = new PIXI.Point(bubbleScale * actor.scale, bubbleScale * actor.scale);
		this.anchor.x = 0.5;
		this.anchor.y = 0.5;
		
		// Set correct start position
		this.setPositionFromActor();
		
		// .speed and .accel used to update in bubble();
		this.speed = {
			x: 1 * (Math.random() - 0.5),
			y: 2 + 3 * Math.random()
		};
		this.accel = 0.1 + Math.random();
		
		// Add to *parent* of actor so that it's position is independent of the actor
		this.actor.obj.parent.addChild(this);
	}

	setPositionFromActor() {
		if(this.actor.destroyed)
			return;

		// start at top-right of actor - tweak for actual scuba mask exit point with tweak*
		const {actor} = this, 
			{ obj: { scale }, baseBlockSize } = actor,
			w = baseBlockSize * scale.x * actor.scale,
			h = baseBlockSize * scale.y * actor.scale,
			t1 = 0.1, // magic number to align with snorkle exit point, found by trial-and-error
			tweakX = baseBlockSize * scale.x * actor.scale * t1, 
			tweakY = baseBlockSize * scale.y * actor.scale * t1;

		this.x = actor.obj.x + w/2 + tweakX;
		this.y = actor.obj.y - h/2 - tweakY - (Math.random() - 0.5);
	}

	destroy(args) {
		this.isDestroyed = true;
		return this.destroy(args);
	}

	bubble() {
		if(!this.transform || this.isDestroyed || !this.speed)
			return;
		// Basic update logic from Pixi 'storm brewing' demo
		this.x += 0.5 * this.speed.x;
		this.y -= this.speed.y;
		// Speed up each frame
		this.speed.y += 0.0050 * this.accel;
		// decay alpha
		this.alpha = this.alpha * 0.9975;
		// console.log("a: ", this.alpha);
		// Reset state if dead
		if(-this.height > this.y) {
			this.setPositionFromActor();
			this.alpha = 1;
			this.speed.y = 1;
		}
	}
}


export class KittyActor extends EventEmitter {
	
	// Cleanup PIXI.loader so the next time we are created, the loader doesn't complain about the keys already being loaded
	destroy() {
		if(!this.destroyed) {
			this.destroyed = true;
			this.obj.destroy(true);
			Scene.removeResourcesFromPixi(resourceKeys);
			clearInterval(this._collisionTid);
			clearInterval(this.sleepHealthTimer);
			this._collisionHandler && this.game.off('matterCollision', this._collisionHandler);
			this._handlersToDestroy.forEach(d => this.off(d.event, d.handler));
			this._destroyBubbles();
		}
	}

	// for external scale calcs
	static baseBlockSize = 256;

	// Creat the kitty
	constructor(opts={
		scale: 1,
		kittyColor: 0xFF5599,
		physicalOptions: {},
		isStatic: false
	}) {
		super();

		this._handlersToDestroy = [];

		const { scale, isStatic } = opts;
		this.scale = scale;
		this.baseBlockSize = this.constructor.baseBlockSize;
		this.isStatic = isStatic;

		// Manually set by aim()
		this.aimingVectorSource = 'last-velocity';

		// This is the default movement scaling constant for "normal" worlds.
		// Water and Space change this to simulate different atmosphere
		this.movementScalingConstant = MOVEMENT_CONSTANT_DEFAULT;
		
		// Store ref
		this.game = GameFactory.game;

		// Make this easier
		this.isTutorial = this.game.currentScene.isTutorial;

		// Store ref
		this.db = ServerStore.currentCat || {};
		if(!this.db.id) {
			this.isStatic = true;
			console.warn("KittyActor created without a currentCat in ServerStore, nothing will persist");
		}

		if(!opts.kittyColor)
			opts.kittyColor = 0xFF5599;

		let storedColor = this.db.color; //parseInt(window.localStorage.getItem(COLOR_KEY), 16);
		if(isNaN(storedColor) || !storedColor)
			storedColor = opts.kittyColor;

		this.kittyColor = storedColor;

		// not static? this is loaded by the game then
		const filtered = { ...resourceKeys };
		if(!this.isStatic)
			delete filtered.sparkle_static_img;

		// console.log({ filtered });

		const resourcePromise = AssetLoader
			.addAll(filtered)
			.then(resources => {
				this.resourcesHandle = resources;

				if(!resources.kitty_body) {
					setTimeout(() => window.confirm("Error detected, reloading page to attempt to fix, press OK") && window.location.reload(), 10);
					throw new Error("No ear loaded, reloading page");
				}

				return resources.kitty_body.texture;
			});
		
		const obj = PixiUtils.createPhysicalTexture(resourcePromise, {x:0,y:0}, { scale }, {
			restitution: 0.625,
			friction:    0.02,
			density:     0.33,
			frictionAir: 0.01, // .025
			// inertia:     Infinity,
			// altPhysicalSize: {
			// 	width:  this.baseBlockSize * scale,
			// 	height: this.baseBlockSize * scale
			// },
			...opts.physicalOptions,

			// Doesn't seem to be working
			// plugin: {
			// 	attractors: [
			// 		(bodyA, bodyB) => {
			// 			// bodyA = the body for KittyActor
			// 			// bodyB is EVERYTHING else (in sequence)
			// 			// if(!this.isMagnet) 
			// 			// 	return null;
			// 			// if(bodyB.label !== '<star>')
			// 			// 	return null;

			// 			return {
			// 				x: (bodyA.position.x - bodyB.position.x) * 1e-6,
			// 				y: (bodyA.position.y - bodyB.position.y) * 1e-6,
			// 			};
			// 		}
			// 	]
			// },
			label: "[KittyActor]",

			shape:      'circle',
			shapeArgs: [
				// circle requires explicit shape args - createPhysicalTexture() doesn't do circles natively.
				// Also, we reduce the radius by 85% to account for the visual size of the kitty being smaller than
				// the full size of the texture.
				this.baseBlockSize, this.baseBlockSize, (this.baseBlockSize/2) * scale * 0.975,
			]
		});


		// Store for restoration when done moving
		obj.body._originalInertia = obj.body.inertia;
		
		// Sprite is only set after promise resolves 
		resourcePromise.then( texture => {
			this.sprite = obj.sprite;

			this.obj.altPhysicalSize = {
				...this.obj.altPhysicalSize,
				offsetX: 0,//((kittyTexture.orig.width - 256) * gridTileScaleDownFactor) / 2,
				offsetY: ((texture.orig.height  - this.baseBlockSize) * scale) / 2,
				// width:  baseBlockSize * scale,
				// height: baseBlockSize  * scale,
			};

			this._setupSprite();

			this._createSecondarySprites(this.resourcesHandle);
		});

		this.obj = obj;
		// this.obj.body.label = "[KittyActor]";

		if(!this.isStatic) {
			// Reference for use by world-managed breakable objects
			this.obj._gameActor = this;

			this._setupObj();
			this._setupHitCheck();
			this._loadSounds();

			let storedHealth = this.db.health;
			if(isNaN(storedHealth) || !storedHealth || storedHealth < 0)
				storedHealth = 100;
			// Sync UI/PIXI props
			this.setHealth(storedHealth);

			this.currentStars = this.db.stars;
			if(isNaN(this.currentStars))
				this.currentStars = 0;

			const items = this.items(true);
			// if(items && items.length > 0)
			// 	this.setActiveItem(items[0].itemDef);
			this.setActiveItem(this._getStoredActiveItemId() || (items && items.length && items[0].itemDef));
		} else {
			// Do this even if static so we can drop the angle
			this.obj.onUpdate = (x, y/*, angle*/) => {
				// const obj = this.obj;
				// obj.x = x;
				// obj.y = y;
				// return false;
				return [x, y, 0];
			}
		}

		window.kittyActor = this;

	}

	_loadSounds() {
		SoundManager.use(SoundManager.JUMP);

		// Auto-detection of category would put this as 'music' (for volume purposes),
		// but this really is an effect - labeled as music so it loops
		SoundManager.use(SoundManager.MUSIC_THRUSTER).setCategory('effects');
	}

	_setupHitCheck() {
		this.collisionPipe = [];
		this.collisionData = {};

		// This event handler runs EVERY time there is a collision ANYWHERE IN THE WORLD (MatterJS current scene).
		// This means we must do whatever we do QUICKLY and be done to allow the rest of world to continue running.
		//
		// Therefore, we use a collisionPipe (processed below) to move the processing of collision data we care about
		// outside of the event handler and to filter/smooth the collision events. See notes on all that below.
		// Here we just figure out if it was "our body" that hit something, and if that "something" was something
		// we need to care about. Our convention is that "things we care about" are labeled with brackets,
		// like <star> or <health>. If the label doesn't start with a bracket, we ignore the collision.
		this.game.on('matterCollision', this._collisionHandler = list => {
			if(PixiMatterContainer.EditorMode)
				return;

			// console.warn("[collisionStart]", event);

			// const list = event.list; //event.source.pairs.list || [];
			for(let i=0; i <list.length; i++) {

				// Remember, we get ALL collision events int the world, so first we have to
				// figure out if it was our body that hit something
				const { bodyA, bodyB } = list[i];
				if(bodyA === this.obj.body || bodyB === this.obj.body) {
				
					// It was us alright, so figure out what we hit
					const otherBody = bodyA === this.obj.body ? bodyB : bodyA,
						label = otherBody.label.toString();
						
					// We only care about this hit if the label starts with a bracket due to convention (see comments above)
					// if(label.startsWith('<') &&
					// 	// only bullets hit silver right now
					// 	// and bullets are handled in the main scene
					// 	label !== '<silver>') {

						// The collisionPipe is processed in a setInterval() call below
						this.collisionPipe.push([ Date.now(), label, otherBody ]);
					// }
				};
			}
		});

		// This interval runs every ~250ms (at time of this comment writing), and processes the list of collisions accumulated
		// in the past 250ms. This allows us to smooth out the collision processing and to move the processing out of the critical
		// path of the world simulation.
		this._collisionTid = setInterval( () => {

			// Pipe could be empty, which shift() will just return null and we'll exit
			let ref;
			while((ref = this.collisionPipe.shift())) {

				// Get the data added to the pipe above with array destructuring
				const [ time, label, body ] = ref, 
					id = body.id,
					method = (body.pixiContainer || {}).powerType || { id: 'lasers' },
					consumptionData = [ label, method, id ];

				// collisionData holds the time and id of the last collision that we PROCESSED
				// with a block with the same label (for example, the last ID and timestamp we collided with a <bad> block)
				// Why PROCESSED time? Not the time of just the last collision?
				// This allows us to "space out" the effects because the MatterJS could send many (tens or hundreds) of collision
				// events within a few seconds - we don't want to "do" whatever that was tens or hundreds of a times a second -
				// for example, we don't want to decrease health if hit a <bad> block tens or hundreds of times a second.
				// So, we record the last time we processed a collision with a <bad> block (for example) and that block's id.
				// Then, if matter keeps sending us more collision events for the same block, we'll decrease health AGAIN but ONLY
				// after an appropriate time passes - which then we'll update the timestamp of the last processing time, in effect, 
				// starting the "timer" over again. Of course, if the kitty moves and hits a *different* <bad> block (e.g. different id)
				// then the delta is ignored and we process anyway - but then the delta applies for that same block.
				const data = 
					this.collisionData[label] || (
					this.collisionData[label] = { time: 0, id: null });

				// Time since last processing a collision with this block label
				const delta = time - data.time;

				// Update id of most recent collision with this type of block
				data.id = id;

				// console.log({ label, delta, id, dataId: data.id });

				let consumed = false;

				const { shieldActive } = this;

				// Only decrease health if the <bad> block is a different ID
				// or more than 3 seconds since last health decrease on this same block
				if(label === "<bad>" && (shieldActive || id !== data.id || delta > 1000)) {
					if(shieldActive) {
						consumed = true;
					} else {
						consumed = this.tryToConsume(...consumptionData);
						vibrate([250]);
						// console.warn(" XX ", label);
					}
					data.time = time;
				} else
				// Only increase stars if the <star> block is a different ID
				// or more than 100ms since last star increase on this same block
				if(label === "<star>") {//} && (id !== data.id || delta > 100)) {
					consumed = this.tryToConsume(...consumptionData);
					// vibrate([100,50,100,50,100,50]);
					// console.warn(" ** ", label);
					data.time = time;
				} else
				// Only increase power if the <power> block is a different ID
				// or more than 100ms since last star increase on this same block
				if(label === "<power>" && (shieldActive || id !== data.id || delta > 100)) {
					consumed = this.tryToConsume(...consumptionData) || shieldActive;
					// vibrate([100,50,100,50,100,50]);
					// console.warn(" ** ", label);
					data.time = time;
				} else
				// Only increase health if the <health> block is a different ID
				// or more than 500ms since last health increase on this same block
				if(label === "<health>" && (shieldActive || id !== data.id || delta > 500)) {
					consumed = this.tryToConsume(...consumptionData) || shieldActive;
					// vibrate([50,50,50,50,50,50]);
					// console.warn(" ++ ", label);
					data.time = time;
				} else
				// Final "effect" of consuming the these blocks is handled in "brokeBlock" for interaction with bullets as well
				if(label === "<doorblock>" || label === "<powerpill>") {
					consumed = true;
					data.time = time;
				} else
				if(label === "<silver>" && (shieldActive || delta > 750)) { //(id !== data.id || delta > 1000)) {
					// Dont destroy silver right now - let bullets do that
					if(shieldActive)
						consumed = true;
					data.time = time;
				} else
				if(label === '#glass' && shieldActive) {
					consumed = true;
					data.time = time;
				} else {
					// NOOP
					// vibrate(150);
				}

				if(consumed) {
					// Should be a breakable block - so damage the block
					if (body.pixiContainer &&
						body._breakable) {
							// console.log("Consumed body with pixiContainer:", body);
							if(shieldActive && label === '<silver>') 
								body.pixiContainer.finalDamage(this.obj, method);
							else
								body.pixiContainer.damage(this.obj, method);
						}
				}
			}
		}, 100);
	}


	setAngle(angle) {
		// this.rotation = angle;
		// Matter.Body.setAngle(this.obj.body, angle);
		throw new Error("Where is this coming from?");
	}

	_setupObj() {

		this.distanceAccumulator = 0;
		let lastPos = {};

		this.obj.onUpdate = (x, y, angle) => {
			if(this.flagRaisingMode) // end of level
				return false;

			const obj = this.obj;

			// console.log("[kitty.onUpdate]", { x,y, angle});

			if(this.checkBounds) {
				const r = this.checkBounds(x, y);
				if(r.x !== x || r.y !== y) {
					// Tell Matter we want THIS position
					this.setPosition({ x, y });

					// Reject the current position update, and wait for the new positions above to filter back thru here
					return false;
				}

				// x = r.x;
				// y = r.y;
			}

			// For metrics
			if(lastPos.x && lastPos.y) {
				const vec = {
					x: x - lastPos.x,
					y: y - lastPos.y
				};
				const dist = Math.sqrt(vec.x*vec.x + vec.y*vec.y);
				this.distanceAccumulator += dist;
			}
			lastPos = { x, y };
			// obj.x = x;
			// obj.y = y;
			// obj.rotation = angle;

			
			// if(obj.rotation !== 0)
			// 	this.obj.setAngle(0);


			const velY = parseFloat(obj.body.vy).toFixed(2);
			const velX = parseFloat(obj.body.vx).toFixed(2);

			if (velY >= 0 && velY <= 0.01) {
				if (this.isJumping) {
					this.isJumping = false;
					// clearInterval(this.spinTid);
					obj.setMatter('friction', 
						this.underwaterMode ? SURFACE_FRICTION_WATER : 
						this.spaceMode      ? SURFACE_FRICTION_SPACE : 
						SURFACE_FRICTION_DEFAULT);

					// this.lastDirection = 'up';

					if(obj.rotation !== 0)
						this.obj.setAngle(0);
				// }
				}

			// if(velX >= 0 && velX <= 0.01) {
				if (this.isMoving) {
					this.isMoving = false;
					obj.setInertia(obj._originalInertia || Infinity);
				}
			}

			if (velX >= 0 && velY <= 0.02 &&
				velY >= 0 && velY <= 0.02) {

				// Notify metric server
				const time = this._movementStarted ? Date.now() - this._movementStarted : null;
				// Only long-ish movements are relevant
				if(time > 500) {
					clearTimeout(this._moveMetricTid);
					clearTimeout(this._hitAccumulatorsFlushTimer);
					this._lastMoveAccumTimeVal = time;
					this._moveMetricTid = setTimeout(this._flushAvgMoveVel, 3000);
				}
			}

			if (velY >= 0 && velY <= 0.2) {
				if(!this.isSleeping)
					this.setSleeping(true);
			} else {
				if (this.isSleeping)
					this.setSleeping(false);

				
				if (this.sprites &&
					this.sprites.kitty_eyes_pupils &&
					this.sprites.kitty_eyes_pupils.transform) {
					const scale = this.scale,
						xRange = (8 + 1/3) * scale,
						yo     = this.sprites.kitty_eyes_pupils._originalY,
						yRange = 3 * scale,
						yMin   = yo - yRange,
						yMax   = yo + yRange;

					this.sprites.kitty_eyes_pupils.x = 
						Math.min(xRange, Math.max(-xRange, 
							this.sprites.kitty_eyes_pupils.x + obj.body.vx));

					this.sprites.kitty_eyes_pupils.y = 
						Math.min(yMax, Math.max(yMin, 
							this.sprites.kitty_eyes_pupils.y + obj.body.vy));
				}
				
				// Store for shooting
				// this.lastVelocity = this.obj.body.velocity;
			} 
			
			// Return x and y and 0 angle because we don't want rotation on this sprite
			return [x, y, 0];
		};

	}

	_flushAvgMoveVel = ( ) => {
		// ServerStore.metric("game.level.kitty.movement.stopped", time);
		if(this._movementVecAvg) {
			const time = this._lastMoveAccumTimeVal;
			const sec = time / 1000, vec = [
				this._movementVecAvg.x / sec,
				this._movementVecAvg.y / sec
			], props = {
				// NB: { sec, x, y } are all expanded props in data - stored also in their own columns
				// in the db for easy querying via SQL. 'num' is also a valid expandable prop, not used in this metric though
				sec,
				// Convert toFixed(3) and parseFloat() to save space in output string size in db
				x: parseFloat(vec[0].toFixed(3)),
				y: parseFloat(vec[1].toFixed(3))
			};
			ServerStore.metric("game.level.kitty.movement.avg_vel", parseFloat(mag(vec).toFixed(5)), props);
		}

		this._movementVecAvg = null;
		this._movementStarted = null;

		// Also flush this here since we cleared the timer when we started moving
		this._flushHitAccumulators();
	
	}

	jump(direction='up', amount=1.0) {
		if(this.flagRaisingMode) // end of level
			return;

		if(PixiMatterContainer.EditorMode) {
			return this.emit(EDIT_MOVE_EVENT, direction);
		}

		// if(direction === 'up'  && this.lastDirection !== 'up')
		// 	direction = this.lastDirection;

		// this.obj.applyForce({ x: 0, y: 0 }, { x: 0, y: -12.15 });
		amount *= this.movementScalingConstant;

		this.setJumping({
			x: direction === 'left'  ? -amount : 
			   direction === 'right' ? +amount : 
			   this.isMoving ? this.obj.body.vx : 0, 
			//    this.isMoving ? (this.lastVelocity || this.obj.body.velocity).x : 0, 
			y: direction === 'down' ? amount : -amount
		});

		this.showThrusters(true, 250);
		SoundManager.play(SoundManager.JUMP);

		
	}

	setJumping(velocity, disableSound=false) {
		if (this.isSleeping)
			this.setSleeping(false);
			
		this.isJumping = true;
		this.setVelocity(velocity);
		this.obj.setMatter('friction', 
			this.underwaterMode ? SURFACE_FRICTION_WATER : 
			this.spaceMode      ? SURFACE_FRICTION_SPACE : 
			SURFACE_FRICTION_DEFAULT_JUMPING);

		// clearInterval(this.spinTid);
		// this.spinTid = setInterval(() => {
		// 	this.obj.rotation += .5;
		// 	// console.log("[this.obj.rotation]=", this.obj.rotation);
		// }, 1000 / 60);

		this._recordMovementStarted(velocity);
	}

	_recordMovementStarted(velocity) {
		// Notify metric server
		// Updated: Not marking every movement.started metric because can result in thousands of entries per level which really isn't relevant
		// ServerStore.metric("game.level.kitty.movement.started", mag([velocity.x, velocity.y]), velocity);

		// Store for movement.avg_vel measurements
		if(!this._movementStarted)
			this._movementStarted = Date.now();

		if(!this._movementVecAvg) {
			this._movementVecAvg = { ...velocity }; // make a copy so we dont change velocity
		} else {
			this._movementVecAvg.x += velocity.x;
			this._movementVecAvg.y += velocity.y;
		}
	}

	move(direction='right', amount=1.0) {
		if(this.flagRaisingMode) // end of level
			return;
			
		if(PixiMatterContainer.EditorMode) {
			return this.emit(EDIT_MOVE_EVENT, direction);
		}

		// Could use isJumping for this redirection,
		// but isJumping is "too careful" about when to mark "not" jumping,
		// and for our purposes here, we want a slightly-less-sensitive heuristic
		// to decide between when to jump or actually just move
		// if(parseFloat(this.obj.body.vy.toFixed(2)) > 0.2) {
		// 	return this.jump(direction, amount);
		// }
		// this.lastDirection = direction;
		amount *= this.movementScalingConstant;
		this.setMoving({
			x: direction === 'left'  ? -amount : 
			   direction === 'right' ? +amount : 0, 
			// y: this.isJumping ? (this.lastVelocity || this.obj.body.velocity).y : 0,
			y: this.isJumping ? this.obj.body.vy : 0,
		});

		this.showThrusters(true, 250);
	}

	setMoving(velocity) {
		if (this.isSleeping)
			this.setSleeping(false);
		this.isMoving = true;
		this.obj.setInertia(Infinity);
		// this.obj.setMatter('friction', 0.99999);
		this.setVelocity(velocity);
		if (this.sprites &&
			this.sprites.aimingLine)
			this.sprites.aimingLine.show();

		// Notify metric server
		this._recordMovementStarted(velocity);
	}


	_setupSprite() {
		// this.obj.interactive = true;
		// this.obj.buttonMode = true;
		// this.obj.click = this.obj.tap = event => {
		// 	// this.setVelocity({ x: Math.random() * 30 - 5, y: -1 * Math.random() * 30 });
		// 	// this.jump(Math.random() > .5 ? 'left' : 'right');
		// 	// if(this.hasPowerPill) {
		// 	// 	this.game.currentScene.explosion();
		// 	// }
		// };

		// Disabling for now due to CPU usage
		// this.setupBreathAnim();
	}

	setupBreathAnim() {
		

		const bStart = { radius: 128, strength: 0 };
		const bBig = { radius: 128, strength: 0.125/2 };
		const bSmall = { radius: 128, strength: -.125 };
		if(!this.breathFocus)
			this.breathFocus = [0.5, 0.67];

		// Composite body and ears for low-health situations so ears don't show thru
		this._staticFilters = [ new PIXI.filters.AlphaFilter(1) ];

		const tweenUpdater = () => {
			// console.log("[tweenUpdater] strength=", bStart.strength);
			// if(this.sprite)
				this.obj.filters = [
					...this._staticFilters,
					new BulgePinchFilter(this.breathFocus, bStart.radius, bStart.strength)
				];
		};

		const tweenTo = new TWEEN.Tween(bStart)
			.to(bSmall, 1250)
			// .easing(TWEEN.Easing.Elastic.InOut)
			.onUpdate(tweenUpdater);

		const tweenFrom = new TWEEN.Tween(bStart)
			.to(bBig, 1250)
			// .easing(TWEEN.Easing.Elastic.InOut)
			.onUpdate(tweenUpdater);

		tweenTo.chain(tweenFrom);
		tweenFrom.chain(tweenTo);
		tweenTo.start();

		PixiUtils.touchTweenLoop();
	}

	shoot() {
		if(this.flagRaisingMode) // end of level
			return;
			
		if(!this.obj.transform)
			return;

		if (this.sprites &&
			this.sprites.aimingLine) {
			this.sprites.aimingLine.show();
			this.sprites.aimingLine.hide(); // auto-delays 1sec
		}

		if(!this.shotCountAccumulator)
			this.shotCountAccumulator = 0;

		if(!this.shotCountTimeStart)
			this.shotCountTimeStart = Date.now();
		
		// Actual bullets handled by the scene
		this.emit('spawnBullet', { x: this.obj.x, y: this.obj.y, dir: this.aimingVector });


		clearTimeout(this._shotMetricTid);
		this._shotMetricTid = setTimeout(() => {
			if(this.shotCountAccumulator > 0) {
				const time = Date.now() - this.shotCountTimeStart,
					sec = time / 1000;
				
				ServerStore.metric("game.level.kitty.item.lasers.avg_shots",  
					parseFloat((this.shotCountAccumulator / sec).toFixed(3)),
					{ sec, num: this.shotCountAccumulator });
					// NB: { sec, num } - special expanded props, stored in data_sec and data_num in the db for easy querying (as well as `data`) 
				this.shotCountAccumulator = 0;
				this.shotCountTimeStart   = null;
			}
		}, 2500);

	}

	setSpaceMode(flag=true) {
		this.spaceMode = flag;
		if (this.sprites &&
			this.sprites.space_helmet_bottom && 
			this.sprites.space_helmet_top)
			this.sprites.space_helmet_top.alpha =
			this.sprites.space_helmet_bottom.alpha = flag ? 1:0;

		this.obj.setMatter('friction', flag ? SURFACE_FRICTION_SPACE : SURFACE_FRICTION_DEFAULT);
		this.movementScalingConstant = flag ? MOVEMENT_CONSTANT_SPACE : MOVEMENT_CONSTANT_DEFAULT;
	}

	setUnderwaterMode(flag=true, numBubbles) {
		this.underwaterMode = flag;

		this.obj.setMatter('friction',  flag ? SURFACE_FRICTION_WATER  : SURFACE_FRICTION_DEFAULT);
		this.movementScalingConstant  = flag ? MOVEMENT_CONSTANT_WATER : MOVEMENT_CONSTANT_DEFAULT;

		// Store on `this` so WelcomeScene can change
		this.NUM_BUBBLES = numBubbles || this.NUM_BUBBLES || 50;
		this.BUBBLE_WAIT_TIME = 1000;
			
		if(this.underwaterMode) {
			this._bubbleStartTid = setTimeout(() => {
				this.bubbles = [];

				// Silently fail if texture not loaded
				if (!this.resourcesHandle ||
					!this.resourcesHandle.scuba_bubble)
					return;

				const bubbleTexture = this.resourcesHandle.scuba_bubble.texture;
				const addBubble = () => {
					if(this.bubbles.length < this.NUM_BUBBLES) {
						this.bubbles.push(new Bubble(this, bubbleTexture));
						this._bubbleStartTid = setTimeout(addBubble, this.BUBBLE_WAIT_TIME);
					}
				};
				addBubble();

				this.game.app.ticker.add(this._bubbleTick = (time) => {
					if(this.destroyed)
						return;

					for(var i=0; i<this.bubbles.length; i++)
						if (this.bubbles[i])
							this.bubbles[i].bubble();
				});
			}, this.BUBBLE_WAIT_TIME);

			if (this.sprites &&
				this.sprites.scuba_mask)
				this.sprites.scuba_mask.alpha = 1;
		} else {
			this._destroyBubbles();
			if (this.sprites &&
				this.sprites.scuba_mask)
				this.sprites.scuba_mask.alpha = 0;
		}
	}

	_destroyBubbles() {
		this.game.app.ticker.remove(this._bubbleTick);
		for(var i=0; i<(this.bubbles || []).length; i++) {
			if(!this.bubbles[i])
				continue;
			try {
				this.bubbles[i].destroy();
			} catch(e) {
				this.bubbles[i].alpha = 0;
				delete this.bubbles[i];
			}
		}
		clearTimeout(this._bubbleStartTid);
	}

	async _createSecondarySprites(resources) {

		
		const buildBodySprites = () => {
			// Remove sprite temporarily so we can change ordering
			// this.obj.removeChild(this.sprite);

			const sprites = {};
			const nonStatic = this.isStatic ? '' : ['sparkle_static_img'];
			// Adding sparkle_static_img manually because we dont load the asset here anymore,
			// it's loaded by the main scene, but we use it here
			[...nonStatic, ...Object.keys(resourceKeys)].forEach(key => {
				if(!key.startsWith('anim_') && key !== 'scuba_bubble') {
					const s = sprites[key] = new PIXI.Sprite(resources[key].texture);
					s.scale = new PIXI.Point(this.scale, this.scale);
					s.anchor.x = .5;
					s.anchor.y = .5;
				}
			});

			sprites.aimingLine = new AimingLine();
			sprites.aimingLine.alpha = 0;

			const order = [
				
				this.anims.flame,
				sprites.aimingLine,
				
				sprites.space_helmet_bottom,
				
				this.sprite,
				sprites.kitty_body_desat,
				
				// looks better with the botwie/tiara UNDER the sparkle at end of level anim
				sprites.extra_tiara,
				
				sprites.sparkle_static_img,
				
				sprites.kitty_eyes_closed,
				sprites.kitty_eyes_closed_desat,
				sprites.kitty_eyes_blank,
				sprites.kitty_eyes_pupils,
				
				sprites.kitty_nose,
				// sprites.kitty_nose_fangs,
				// sprites.kitty_nose_hillbilly,
				// sprites.kitty_nose_mouth_grin,
				sprites.kitty_nose_mouth_open,
				sprites.kitty_nose_mouth_open_desat,
				sprites.kitty_nose_up,
				
				// sprites.part_highlight,

				// moved bowtie here, above part-highlight, because bleed-over of the highlight didn't look right
				sprites.extra_bowtie,
				sprites.extra_cowbell,

				sprites.space_helmet_top,
				sprites.scuba_mask,
				
				sprites.crosshairs,
				sprites.shield_blue,

				// sprites.weapon_taser,
				
			];

			// console.warn( order );
			order.forEach(sprite => this.obj.addChild(sprite));
			
			const scaledSize = (this.baseBlockSize * this.scale);

			window.scaledSize = scaledSize; // just for debugging

			// sprites.kitty_eyes_blank.y  = 
			// sprites.kitty_eyes_pupils.y = -scaledSize * 0.07;

			sprites.kitty_eyes_closed_desat.y =
			sprites.kitty_eyes_closed.y =
			sprites.kitty_eyes_blank.y  = scaledSize * 0.0725;;

			sprites.kitty_eyes_pupils.y = scaledSize * 0.145;
			sprites.kitty_eyes_pupils._originalY = sprites.kitty_eyes_pupils.y;
			
			// sprites.kitty_ear_left.y = sprites.kitty_ear_right.y = -(this.baseBlockSize * this.scale) * 0.5;
			// sprites.kitty_ear_left.x = sprites.kitty_ear_right.y = -(this.baseBlockSize * this.scale) * 0.5;
			// sprites.kitty_ears.y = -scaledSize * 0.5;
			sprites.kitty_nose.anchor.y =
			sprites.kitty_nose_up.anchor.y =
			sprites.kitty_nose_mouth_open.anchor.y = 
			sprites.kitty_nose_mouth_open_desat.anchor.y = 0;
			sprites.kitty_nose.y =  
			sprites.kitty_nose_up.y =  
			sprites.kitty_nose_mouth_open_desat.y =
			sprites.kitty_nose_mouth_open.y =  scaledSize * 0.08;
			// sprites.kitty_nose_mouth_open.y =  scaledSize * 0.035;

			// this.mouths = {
			// 	default:   sprites.kitty_nose,
			// 	up:        sprites.kitty_nose_up,
			// 	fangs:     sprites.kitty_nose_fangs,
			// 	// hillbilly: sprites.kitty_nose_hillbilly,
			// 	grin:      sprites.kitty_nose_mouth_grin,
			// 	open:      sprites.kitty_nose_mouth_open
			// };


			// v2: only 3 mouths - (neutral, sad, happy) = (_nose, _nose_up, _nose_mouth_open)
			this.mouths = {
				default:   sprites.kitty_nose,
				up:        sprites.kitty_nose_up,
				open:      sprites.kitty_nose_mouth_open,
				openDesat: sprites.kitty_nose_mouth_open_desat,
			};
			
			Object.keys(this.mouths).forEach(key => {
				if(key !== 'default') {
					this.mouths[key].alpha = 0;
					// this.mouths[key].y = sprites.kitty_nose.y + scaledSize * .2;
				}
			});

			
			// console.log(scaledSize);
			

			// sprites.part_highlight.y = scaledSize * 0.09;

			// Custom scale to change appearance
			// sprites.kitty_eyes_closed.scale = new PIXI.Point(0.3,0.7);
			sprites.kitty_eyes_closed.alpha = 0;

			// Tint ears and body
			// this.sprite.tint = this.kittyColor;
			// sprites.kitty_ears.tint = this.kittyColor;
			

			// sprites.sparkle_static_img.y = -scaledSize * 0.1;
			sprites.sparkle_static_img.scale.x *= 1.5;
			sprites.sparkle_static_img.scale.y *= 1.5;
			sprites.sparkle_static_img.alpha = 0;
			sprites.sparkle_static_img.play = (delay = 1300) => {
				SoundManager.use(SoundManager.POSITIVE).setVolumeModifier(0.5).play();
				let ticker;
				const s = sprites.sparkle_static_img;
				PixiUtils.fadeIn(s, 300);//.then(() => sound.play());
				this.game.app.ticker.add(ticker = () => {
					if(!s || !s.transform)
						return;
					s.rotation += 0.05;
				});
				return new Promise(resolve =>
					setTimeout(() => PixiUtils
					.fadeOut(s, 200)
					.then(() => {
						this.game.app.ticker.remove(ticker);
						resolve();
					})
					, delay || 1300)
				);
			}

			
			sprites.shield_blue.scale = new PIXI.Point(this.scale * .67, this.scale * .67);
			
			sprites.shield_blue.play = () => {
				const s = sprites.shield_blue;
				s.is_active = true;
				PixiUtils.fadeIn(s, 300);
				this.game.app.ticker.add(s.__ticker = () => {
					if(!s || !s.transform)
						return;
					s.rotation += 0.0075;
				});
			}
			sprites.shield_blue.stop = () => {
				const s = sprites.shield_blue;
				s.is_active = false;
				PixiUtils
					.fadeOut(s, 200)
					.then(() => s.__ticker && 
						this.game.app.ticker.remove(s.__ticker));
			}

			sprites.shield_blue.alpha = 0;


			// Starts off hidden, enable with setUnderwaterMode
			sprites.scuba_mask.x = scaledSize * 0.07;
			sprites.scuba_mask.y = scaledSize * 0.1;
			sprites.scuba_mask.alpha = 0;
			
			// Adjust the helmet to rough center the kitty and align the two parts over top of each other
			sprites.space_helmet_bottom.x =  scaledSize * 0.125;
			sprites.space_helmet_top.x    =  scaledSize * 0.025;

			// Enable with setSpaceMode
			sprites.space_helmet_top.alpha = 0;
			sprites.space_helmet_bottom.alpha = 0;

			// Extras

			// TODO: make a way to enable these
			sprites.extra_tiara.y = -scaledSize * 0.4;
			sprites.extra_tiara.x =  scaledSize * 0.0125;
			sprites.extra_tiara.alpha = 0;

			sprites.extra_bowtie.y = scaledSize * 0.575;
			sprites.extra_bowtie.alpha = 0;
			sprites.extra_bowtie._originalY = sprites.extra_bowtie.y;
			sprites.extra_bowtie._mouthOpenY = scaledSize * 0.75;

			sprites.extra_cowbell.y = scaledSize * 0.575;
			sprites.extra_cowbell.alpha = 0;
			sprites.extra_cowbell.scale.x = sprites.extra_cowbell.scale.y = 0.125;
			


			// Shown on aiming line
			{
				const crosshairsContainer = new PIXI.Container();
				this.obj.addChild(crosshairsContainer);
				crosshairsContainer.x = 0; // set by aiming routine
				crosshairsContainer.y = 0; 
				// iconMaskBg.alpha = 0.625;

				const iconMaskBg = new PIXI.Sprite(resources.crosshairs.texture);
				iconMaskBg.tint = 0x0;
				// iconMaskBg.alpha = 0.625;
				iconMaskBg.anchor.x = 0.5;// better centering...
				iconMaskBg.anchor.y = 0.5; 
				iconMaskBg.scale = new PIXI.Point(this.scale * .8, this.scale *.8);
				iconMaskBg.x = 0; //this.buttons.shop.width  / 2;
				iconMaskBg.y = 0; //this.buttons.shop.height / 2;
				crosshairsContainer.addChild(iconMaskBg);
				crosshairsContainer.iconBg = iconMaskBg;

				const iconMask = sprites.crosshairs;
				iconMask.tint = 0xffff00;
				iconMask.anchor.x = 0.5;
				iconMask.anchor.y = 0.5;
				iconMask.scale = new PIXI.Point(this.scale * .75, this.scale * .75);
				iconMask.x = 0;
				iconMask.y = 0;
				crosshairsContainer.addChild(iconMask);
				crosshairsContainer.icon = iconMask;

				sprites.crosshairs = crosshairsContainer;
				sprites.crosshairs.alpha = 0;
			}

			// sprites.crosshairs.alpha = 0;
			// sprites.crosshairs.scale = new PIXI.Point(this.scale * .75, this.scale * .75);
			// // .tint not working?
			// sprites.crosshairs.tint = 0xffff00;

			// // Adjust weapon size and location
			// sprites.weapon_taser.x = scaledSize * 1.1;
			// // pivot seems to have no effect
			// // sprites.weapon_taser.pivot = new PIXI.Point(0, sprites.weapon_taser.texture.height / 2);
			// sprites.weapon_taser.scale = new PIXI.Point(0.22,0.22);
			// sprites.weapon_taser._baseScale = 0.22; // for use elsewhere
			
			// // Hide till we implement
			// sprites.weapon_taser.alpha = 0; 

			this.sprites = sprites;

			// Hide desat'd versions until setKittyColor called to show them
			this.sprites.kitty_body_desat.alpha = 0;
			this.sprites.kitty_eyes_closed_desat.alpha = 0;
			this.sprites.kitty_nose_mouth_open_desat.alpha = 0;



			if(this.spaceMode)
				this.setSpaceMode(true);
		
			if(this.underwaterMode)
				this.setUnderwaterMode(true);

			if(this.db.activeItems) {
				// Apply active items
				Object.keys(this.db.activeItems).forEach(id => {
					const itemDef = KITTY_ITEMS[id];
					if (itemDef.setEnabled)
						itemDef.setEnabled(this, this.db.activeItems[id]);
				})
			}

			let hidingTid = 0;
			this.sprites.aimingLine.show = () => {
				if(!this.sprites.aimingLine._aimed)
					return;

				if (this.flagRaisingMode || !this.activeItem || this.activeItem.id !== 'lasers') {
					this.sprites.aimingLine.alpha = 0;
					this.sprites.crosshairs.alpha = 0;
					return;
				}

				// console.warn("showing");
				clearTimeout(hidingTid);
				// this.sprites.aimingLine.alpha = 0.625;
				PixiUtils.fadeAlpha(this.sprites.aimingLine, 0, 0.625, 200);
				PixiUtils.fadeAlpha(this.sprites.crosshairs, 0, 0.625, 200);
			}
			
			this.sprites.aimingLine.hide = (instant) => {
				if(!this.sprites.aimingLine._aimed)
					return;
				if(instant) {
					this.sprites.aimingLine.alpha = 0;
					this.sprites.crosshairs.alpha = 0;
				}

				// console.warn("hiding");
				clearTimeout(hidingTid);
				hidingTid = setTimeout(() => {
					PixiUtils.fadeOut(this.sprites.aimingLine, 200);
					PixiUtils.fadeOut(this.sprites.crosshairs, 200);
				}, 1000);
			}
			this.sprites.aimingLine.aim = dir => {
				this.sprites.aimingLine._aimed = true;

				// const size = Math.max(window.innerWidth, window.innerHeight);
				this.sprites.aimingLine.show();

				const rotationAngle = angle(0, 0, dir.x, dir.y) * -1;

				const aimingPoint = rotate(0, 0, 256 * this.scale * 1.5, 0, rotationAngle);

				// console.log({ rotationAngle, aimingPoint, dir });

				this.sprites.aimingLine.updatePoints([
						0, //this.sprite.width/2,
						0, //this.sprite.height/2,
						aimingPoint[0], // dir.x * size,
						aimingPoint[1], // dir.y * size
				]);

				this.sprites.crosshairs.x = aimingPoint[0];
				this.sprites.crosshairs.y = aimingPoint[1];
			}
		};

		this.anims = {};
	
		// Add "flame" animation - await because we have to layer the body on top
		await PixiUtils.getAnimatedSprite(anim_flame_sheet, resources.anim_flame_img).then(sprite => {
			if(!sprite) {
				console.warn("Error loading anims.flame");
				return;
			}

			sprite.x     = 0;// this.sprite.width/2;
			sprite.y     = 0;// this.sprite.height/2;
			sprite.anchor.y = 0.5;
			sprite.anchor.x = -0.25; 
			sprite.scale = new PIXI.Point(2 * this.scale, 2 * this.scale);
			sprite.speed = .5;

			sprite.stop();
			sprite.alpha = 0;

			// PixiUtils.setupShortAnim(sprite, {
			// 	fadeIn: 300,
			// 	play: 1000,
			// 	fadeOut: 300
			// });

			// this.obj.addChild(sprite);
			this.anims.flame = sprite;

			// Can't build body until flame anim is loaded
			buildBodySprites();

			// Notify welcome scene if listening
			if (this.onLoad)
				this.onLoad();

			// apply color to sprites
			this.setKittyColor(this.kittyColor);

		});

		// return;

		// Now body is built, can do secondary load
		// Object.keys(secondaryResourceKeys).forEach(key => {
		// 	if(!PIXI.loader.resources[key])
		// 		PIXI.loader.add(key, secondaryResourceKeys[key])
		// });

		// // Wait for secondary resources
		// await new Promise(resolve => PIXI.loader.load(() => resolve()));

		await AssetLoader.addAll(secondaryResourceKeys);

		// Now build list of promises for those resources
		const promises = [];

		
		// Add sleeping animation
		promises.push(PixiUtils.getAnimatedSprite(anim_sleep_sheet, resources.anim_sleep_img).then(sprite => {
			if(!sprite) {
				console.warn("Error loading anims.sleep");
				return;
			}

			sprite.x    = this.sprite.texture.width / 8;
			// sprite.y = 0; 
			sprite.scale = new PIXI.Point(0.75, 0.75);
			sprite.tint  = this.kittyColor; // 0xFF5599;
			// sprite.speed = .5;
			
			this.obj.addChild(sprite);

			this.anims.sleep = sprite;
			sprite.tint = 0xFF08CE; // default kitty color
			
			// Only show when sleeping in setSleeping
			sprite.alpha = 0;
			// sprite.stop();
			
		}));

		// Add "Green cloud" animation
		promises.push(PixiUtils.getAnimatedSprite(anim_cloud_sheet, resources.anim_cloud_img).then(sprite => {
			if(!sprite) {
				console.warn("Error loading anims.cloud");
				return;
			}

			sprite.x     = this.sprite.texture.width / 8;
			sprite.scale = new PIXI.Point(0.5, 0.5);
			// sprite.speed = .5;
			sprite.tint  = 0xFF0000;

			PixiUtils.setupShortAnim(sprite, {
				fadeIn: 300,
				play: 1000,
				fadeOut: 300
			});

			
			this.obj.addChild(sprite);
			this.anims.cloud = sprite;
		}));

		

		// Add "health" animation
		promises.push(PixiUtils.getAnimatedSprite(anim_health_sheet, resources.anim_health_img).then(sprite => {
			if(!sprite) {
				console.warn("Error loading anims.health");
				return;
			}

			sprite.x     = 0;
			sprite.scale = new PIXI.Point(0.5, 0.5);
			// sprite.speed = .5;

			PixiUtils.setupShortAnim(sprite, {
				fadeIn: 300,
				play: 1000,
				fadeOut: 300
			});

			this.obj.addChild(sprite);
			this.anims.health = sprite;
		}));

		// Add "stars" animation
		promises.push(PixiUtils.getAnimatedSprite(anim_stars_sheet, resources.anim_stars_img).then(sprite => {
			if(!sprite) {
				console.warn("Error loading anims.stars");
				return;
			}

			sprite.x     = 0;
			sprite.scale = new PIXI.Point(0.5, 0.5);
			// sprite.speed = .5;

			PixiUtils.setupShortAnim(sprite, {
				fadeIn:  100,
				play:    750,
				fadeOut: 300
			});

			this.obj.addChild(sprite);
			this.anims.stars = sprite;
		}));

		// Add "heart" animation
		promises.push(PixiUtils.getAnimatedSprite(anim_heart_sheet, resources.anim_heart_img).then(sprite => {
			if(!sprite) {
				console.warn("Error loading anims.heart");
				return;
			}

			sprite.x     = 0;
			sprite.scale = new PIXI.Point(0.5, 0.5);
			// sprite.speed = .5;

			PixiUtils.setupShortAnim(sprite, {
				fadeIn:  150,
				play:    2000,
				fadeOut: 150
			});

			this.obj.addChild(sprite);
			this.anims.heart = sprite;
		}));

		// Add "sparkle" animation
		promises.push(PixiUtils.getAnimatedSprite(anim_sparkle_sheet, resources.anim_sparkle_img).then(sprite => {
			if(!sprite) {
				console.warn("Error loading anims.sparkle");
				return;
			}

			sprite.x     = 0;
			sprite.y     = 0;
			sprite.anchor.x = 0.5;
			sprite.anchor.y = 0.5;
			sprite.scale = new PIXI.Point(0.5 * this.scale * 1.5, 0.5 * this.scale * 1.5);
			// sprite.speed = 1.5;

			PixiUtils.setupShortAnim(sprite, {
				fadeIn:  200,
				play:    900,
				fadeOut: 200
			});

			this.obj.addChild(sprite);
			this.anims.sparkle = sprite;
		}));

		// Add "explosion" animation
		promises.push(PixiUtils.getAnimatedSprite(anim_explosion_sheet, resources.anim_explosion_img).then(sprite => {
			if(!sprite) {
				console.warn("Error loading anims.explosion");
				return;
			}

			sprite.x     = 0;
			sprite.y     = 0;
			sprite.anchor.x = 0.4825; // looks more centered than .5
			sprite.anchor.y = 0.5;
			sprite.scale = new PIXI.Point(this.scale * 2, this.scale * 2);
			// sprite.speed = 1.5;
			sprite.animationSpeed = 0.33;
			sprite.alpha = 0;
			sprite.stop();

			// Stop after one time thru
			sprite.loop = true;
			sprite.onLoop = () => {
				sprite.stop();
				sprite.alpha = 0;
			};

			// Auto-show on play
			const play = sprite.play.bind(sprite);
			sprite.play = () => {
				play();
				sprite.alpha = 1;
			};
			
			this.obj.addChild(sprite);
			this.anims.explosion = sprite;
		}));

		// Add firework animation
		// promises.push(PixiUtils.getAnimatedSprite(anim_firework3yellow_sheet, resources.anim_firework3yellow_img).then(sprite => {
		// 	if(!sprite) {
		// 		console.warn("Error loading anims.firework3yellow");
		// 		return;
		// 	}
			
		// 	const scaledSize = (this.baseBlockSize * this.scale);

		// 	sprite.x = scaledSize * .33; //this.sprite.texture.width / 8;
		// 	sprite.y = scaledSize * 2; 
		// 	sprite.scale = new PIXI.Point(0.5, 0.5);
		// 	// sprite.tint  = this.kittyColor; // 0xFF5599;
		// 	// sprite.speed = .5;
			
		// 	this.obj.addChild(sprite);

		// 	this.anims.firework3yellow = sprite;
			
		// 	// Only show when wanted
		// 	sprite.alpha = 0;
		// 	// sprite.stop();

		// 	PixiUtils.setupShortAnim(sprite, {
		// 		fadeIn:  50,
		// 		play:    950,
		// 		fadeOut: 50
		// 	});
		// }));


		try {
			await Promise.all(promises).catch(e => {
				console.warn("Error with secondary kitty resources:", e);
			});
			// we were using .finally on Promise.all, but iOS Safari 11 didn't seem to support it
			// so now we just await
		
			this.anims._isLoaded = true;

			// test failure
			// this.anims = {};
			
			// Still will have to check for individual anims
			this._isLoaded = true;
	
			// apply color to sprites
			this.setKittyColor(this.kittyColor);

			if (this.onSecondaryLoad)
				this.onSecondaryLoad();

			this.setupEditorMode(PixiMatterContainer.EditorMode);
			
		} catch(e) {
			console.warn("Error caught inside Promise.all block for KittyActor:", e);
		}
		
	}

	isLoaded() {
		return this._isLoaded;
	}

	showThrusters(flag, autoOffTime=0) {
		if ((flag && this.flagRaisingMode) ||
			!this.anims || 
			!this.anims.flame)
			return;

		if(flag) {

			if (this.sprites &&
				this.sprites.aimingLine)
				this.sprites.aimingLine.show();

			// Compute angle of velocity acting on the body and rotate the flame animation in the opposite direction
			// so as to visually simulate a thruster 
			const velY = parseFloat(this.obj.body.vy);//.toFixed(5);
			const velX = parseFloat(this.obj.body.vx);//.toFixed(5);
			const rotationAngle = angle(0, 0, velX, velY);
			this.anims.flame.rotation = toRadians(rotationAngle - 180);

			if(!this.thrustersVisible) {
				this.thrustersVisible = true;
				this.anims.flame.play();
				this.anims.flame.alpha = 1;
				SoundManager.play(SoundManager.MUSIC_THRUSTER).setVolumeModifier(0.33);
			}

			// Auto-stop after X ms if requested
			if(autoOffTime) {
				if(this._autoOffThrustTid)
					clearTimeout(this._autoOffThrustTid);
				this._autoOffThrustTid = setTimeout(() => this.showThrusters(false), autoOffTime);
			}
		} else {
			this.thrustersVisible = false;
			this.anims.flame.alpha = 0;
			this.anims.flame.stop();
			SoundManager.getPlayer(SoundManager.MUSIC_THRUSTER).stop();
		}
	}

	setupEditorMode(flag) {
		if(!this._isLoaded)
			return;

		this.setSleeping(!flag, flag);

		setTimeout(() => {

			// this.sprites.kitty_ears.alpha = 
			// this.sprites.kitty_eyes_closed.alpha =
			this.sprites.kitty_eyes_blank.alpha =
			this.sprites.kitty_eyes_pupils.alpha =
			this.sprites.kitty_nose.alpha =
			// this.sprites.part_highlight.alpha  = 
				flag ? 0 : 1;

			this.sprite.alpha = flag ? 0.625 : 1;
		}, 10);


	}

	setKittyColor(color=0xFF08CE /*0xFF5599*/, persist=true) {
		this.kittyColor = color;

		let desatAlpha = 1, colorAlpha = 0;
		if(color === 0xFF08CE) {
			// Reset to defaults
			color = 0xffffff;
			desatAlpha = 0;
			colorAlpha = 1;
			this._useDesatAssets = false;
		} else {
			this._useDesatAssets = true;
		}

		// hide/show desaturated versions for colorization
		this.sprites.kitty_body_desat.alpha = 
		this.sprites.kitty_eyes_closed_desat.alpha = desatAlpha;
		// this.sprites.kitty_nose_mouth_open_desat.alpha = desatAlpha;

		// hide/show default colored versions 
		this.sprite.alpha = 
		this.sprites.kitty_eyes_closed.alpha = colorAlpha;
		// this.sprites.kitty_nose_mouth_open.alpha = colorAlpha;
		
		// set proper tint on desat'd versions (and other assets)
		// Note: If default color, we use a white tint to reset to default color of asset
		if (this.anims &&
			this.anims.sleep)
			this.anims.sleep.tint = this.kittyColor;
			
		this.sprites.kitty_body_desat.tint =
		this.sprites.kitty_nose_mouth_open_desat.tint =
		this.sprites.kitty_nose.tint = 
		this.sprites.kitty_nose_up.tint =
		this.sprites.kitty_nose_mouth_open_desat.tint =
		this.sprites.kitty_eyes_closed_desat.tint = color;
		
		// Note: Stored as hex since convention is colors are in hex
		// window.localStorage.setItem(COLOR_KEY, this.kittyColor.toString(16));

		if(persist === undefined || persist === true)
			this.db && this.db.patch && this.db.patch({ color: this.kittyColor }, -1); // -1 = don't transmit, let world handle storage batching
	}

	setMouth(name, time=3000) {
		if(!this.mouths || !this.sprites)
			return;
		
		if(['fangs','grin'].includes(name))
			name = 'open';

		let alsoDesatOpen = false;
		if(this._useDesatAssets && name === 'open')
			alsoDesatOpen = true;

		const mouth = this.mouths[name],
			   base = this.sprites.kitty_nose,
			   reset = () => {
					// PixiUtils.fadeOut(this._currentMouth, 200);
					// PixiUtils.fadeIn(base, 200);
					if (this._currentMouth) {
						this._currentMouth.alpha = 0;
						
						if(this._currentMouth.alsoDesatOpen)
							this.mouths.openDesat.alpha = 0;

						this._currentMouth = null;
						base.alpha = 1;

						this.sprites.extra_bowtie.y = this.sprites.extra_bowtie._originalY;
						// console.log('[setMouth]', name, '*reset*');
					}
			   };

		// console.log('[setMouth]', name, mouth ? true : null, time);
			
		if(name === 'default') {
			if(this._currentMouth) {
				reset();
			}
			return;
		} else 
		if(mouth) {
			
			if(this._currentMouth !== mouth) {

				if(!this._currentMouth)
					// PixiUtils.fadeOut(base, 200);
					base.alpha = 0;
				else {
					// PixiUtils.fadeOut(this._currentMouth, 200);
					this._currentMouth.alpha = 0;
					if(this._currentMouth.alsoDesatOpen)
						this.mouths.openDesat.alpha = 0;
				}

				// PixiUtils.fadeIn(mouth, 200);
				mouth.alpha = 1;
				this._currentMouth = mouth;

				if(alsoDesatOpen) {
					mouth.alsoDesatOpen = true;
					this.mouths.openDesat.alpha = 1;
				}

				if(name !== 'up') {
					this.sprites.extra_bowtie.y = this.sprites.extra_bowtie._mouthOpenY;
				} else {
					this.sprites.extra_bowtie.y = this.sprites.extra_bowtie._originalY;
				}
			}

			clearTimeout(this.mouthTimer);
			this.mouthTimer = setTimeout(() => {
				reset();
			}, time);
		}
	}

	setSleeping(flag, instant=false) {
		// return false;
		
		// Start if not already started, noop if started
		this.autoChangeHealthFromSleep();
		
		if(!this._isLoaded) {
			this.isSleeping = flag;
			return;
		}

		const setAlpha = a => {
			
			if (this._isLoaded) {
				this.sprites.kitty_eyes_closed.alpha = 1-a;
				this.sprites.kitty_eyes_blank.alpha = 
				this.sprites.kitty_eyes_pupils.alpha = a;
			}
		}

		if(flag) {
			this.setMouth('default');

			if(this.tutHasMoved) {
				ShowOnePopupHelper.tutorialPopup(TutorialKeys.sleep, <>
					<h2>Sleeping</h2>
					<p>Hint: Sleeping gives your kitty energy (energy is shown at the top-left of the screen), and moving uses energy.</p>
				</>);
			}

			this.aimingVectorSource = 'last-velocity';
			if (this.sprites &&
				this.sprites.aimingLine)
				this.sprites.aimingLine.hide();

			this.isSleeping = flag;

			if(this._isLoaded) {
				this.sprites.kitty_eyes_pupils.y = this.sprites.kitty_eyes_pupils._originalY;
				this.sprites.kitty_eyes_pupils.x = 0;
			}
			// this.sprites.kitty_eyes_blank.alpha = this.sprites.kitty_eyes_pupils.alpha = 0;
			const value = { alpha: 1 };
			// this.anims.sleep.play();

			new TWEEN.Tween(value)
				.to({alpha: 0}, instant ? 0 : 300)
				// .easing(TWEEN.Easing.Elastic.InOut)
				.onUpdate(() => setAlpha(value.alpha))
				.start();
			
			PixiUtils.touchTweenLoop();

			clearTimeout(this._zTid);
			this._zTid = setTimeout(() => {
				if (this.anims.sleep)
					this.anims.sleep.alpha = 1;
			}, instant ? 0 : 500);

		} else {
			if (this.sprites &&
				this.sprites.aimingLine)
				this.sprites.aimingLine.show();

			this.isSleeping = flag;
			
			const value = { alpha: 0 };
		
			new TWEEN.Tween(value)
				.to({alpha: 1}, instant ? 0 : 300)
				.onUpdate(() => setAlpha(value.alpha))
				// .onComplete(() => this.anims.sleep.stop())
				.start();

			PixiUtils.touchTweenLoop();

			clearTimeout(this._zTid);
			this._zTid = setTimeout(() => {
				if (this.anims.sleep)
					this.anims.sleep.alpha = 0;
			}, instant ? 0 : 500);
		}
	}

	autoChangeHealthFromSleep() {
		if(!this.autoHealthDecrease)
			this.autoHealthDecrease = 0.75;
		if(!this.autoHealthIncrease)
			this.autoHealthIncrease = 1;
		if(!this.sleepTimeAccumulator)
			this.sleepTimeAccumulator = 0;
		if(!this.wakeTimeAccumulator)
			this.wakeTimeAccumulator = 0;

		if(!this.sleepHealthTimer &&
		   !this.isStatic) {
			let lastTime = Date.now();
			this.sleepHealthTimer = setInterval(() => {
				if(!PixiMatterContainer.EditorMode) {
					const delta = (this.isSleeping ? +this.autoHealthIncrease : -this.autoHealthDecrease);
					this.setHealth(this.currentHealth + delta, delta > 0);

					this[this.isSleeping ? 'sleepTimeAccumulator' : 'wakeTimeAccumulator'] += (Date.now() - lastTime) / 1000;
					lastTime = Date.now();
				}
			}, 500);
		}
	}

	dumpPendingMetrics() {
		const [ 
			wakeTime,
			sleepTime,
			distance
		] = [
			parseFloat((this.wakeTimeAccumulator  || 0).toFixed(3)),
			parseFloat((this.sleepTimeAccumulator || 0).toFixed(3)),
			parseFloat((this.distanceAccumulator  || 0).toFixed(3))
		];
		
		if(wakeTime !== 0)
			ServerStore.metric("game.level.kitty.sleep.wake_time",   wakeTime);
		if(sleepTime !== 0)
			ServerStore.metric("game.level.kitty.sleep.sleep_time",  sleepTime);
		if(distance !== 0)
			ServerStore.metric("game.level.kitty.movement.distance", distance);

		this._flushHitAccumulators();
		this._flushAvgMoveVel();

		this.sleepTimeAccumulator = 0;
		this.wakeTimeAccumulator  = 0;
		this.distanceAccumulator  = 0;
	}

	stopAutoChangeHealthFromSleep() {
		clearInterval(this.sleepHealthTimer);
		this.sleepHealthTimer = null;
	}

	setPosition(pos) {
		if(this.checkBounds)
			pos = this.checkBounds(pos);
		this.obj.setPosition(pos);
	}

	setVelocity(vel) {
		this.lastVelocity = vel;
		// console.log(vel);
		this.obj.setVelocity(vel);
		if(this.aimingVectorSource === 'last-velocity') {
			this.aimingVector = vel;
			if (this.sprites &&
				this.sprites.aimingLine) {
				this.sprites.aimingLine.show();
				this.sprites.aimingLine.aim(vel);
			}
		}

		if(this.isTutorial) {
			clearTimeout(this._tutHasMovedTid);
			this._tutHasMovedTid = setTimeout(() => this.tutHasMoved = true, 1000);
		}
	}

	aim(dir) {
		if(dir) {
			this.aimingVectorSource = 'manual';
			this.aimingVector = dir;
			if (this.sprites &&
				this.sprites.aimingLine) {
				this.sprites.aimingLine.show();
				this.sprites.aimingLine.aim(dir);
			}
		} else {
			this.aimingVectorSource = 'last-velocity';
			this.aimingVector = this.lastVelocity;
			if (this.sprites &&
				this.sprites.aimingLine) {
				this.sprites.aimingLine.show();
				this.sprites.aimingLine.aim(this.aimingVector);
			}
		}
	}

	setHealth(health, anim=true) {
		if(this.isStatic) {
			if(process.env.NODE_ENV !== 'production')
				console.trace("[setHealth] this.isStatic, not changing health");
			return;
		}
		
		const newHealth = Math.min(MAX_HEALTH, Math.max(0, health));
		if(newHealth !== this.currentHealth) {

			if(this._isLoaded && anim) {
				if(newHealth < this.currentHealth) {
					if (this.anims.cloud)
						this.anims.cloud.play();
				} else {
					if (this.anims.health)
						this.anims.health.play();
				}
			}

			this.currentHealth = newHealth;
		
			// window.localStorage.setItem(HEALTH_KEY, this.currentHealth);
			this.db.patch({ health: this.currentHealth }, -1); // -1 = don't transmit, let world handle storage batching
			this.emit('health', this.currentHealth);

			if(this.currentHealth < 100) {
				this.obj.alpha = this.currentHealth / 100;
			} else {
				this.obj.alpha = 1;
			}

			return true;
		}
	}

	get currentPower() {
		if(!this.activeItem) {
			// if(process.env.NODE_ENV !== 'production')
			// 	console.trace("[setPower] No activeItem set, cannot read power");

			return 0;
		}

		return this.itemAmount(this.activeItem);

	}

	setPower(power, itemDef) {
		if(this.isStatic) {
			if(process.env.NODE_ENV !== 'production')
				console.trace("[setPower] this.isStatic, not changing power");
			return;
		}

		if(!this.activeItem) {
			if(process.env.NODE_ENV !== 'production')
				console.trace("[setPower] No activeItem set, cannot change power");

			return;
		}

		return this.setItemAmount(this.activeItem, power);
		
	
		
		// const newPower = Math.min(MAX_POWER, Math.max(0, power));
		// if(newPower !== this.currentPower) {

		// 	if(this._isLoaded) {
		// 		if(newPower < this.currentPower) {
		// 			// if (this.anims.cloud)
		// 			// 	this.anims.cloud.play();
		// 		} else {
		// 			// if (this.anims.health)
		// 			// 	this.anims.health.play();
		// 		}
		// 	}

		// 	this.currentPower = newPower;
		
		// 	// window.localStorage.setItem(POWER_KEY, this.currentPower);
		// 	this.db.patch({ power: this.currentPower }, -1); // -1 = don't transmit, let world handle storage batching
		// 	this.emit('power', this.currentPower);

		// 	return true;
		// }
	}


	itemEnabled(itemId) {
		const itemDef = itemId.id ? itemId : KITTY_ITEMS[itemId];
		if(!itemDef) {
			console.trace("[setItemEnabled] invalid item:", itemId);
			return;
		}

		if(!this.db.activeItems)
			this.db.activeItems = {};
		
		const currentFlag = this.db.activeItems[itemDef.id];
		return currentFlag ? true : false;
	}

	setItemEnabled(itemId, flag) {
		const itemDef = itemId.id ? itemId : KITTY_ITEMS[itemId];
		if(!itemDef) {
			console.trace("[setItemEnabled] invalid item:", itemId);
			return;
		}

		if(!this.db.activeItems)
			this.db.activeItems = {};
		
		const currentFlag = this.db.activeItems[itemDef.id];
		const newFlag = flag ? true : false;
	
		if(newFlag !== currentFlag) {
			this.db.activeItems[itemDef.id] = newFlag;
			this.db.patch({ activeItems: this.db.activeItems }); // patch internally since may be called from ShopPopup in WelcomeScene

			this.emit('itemEnabled', { itemDef, flag: newFlag });

			if (itemDef.setEnabled) {
				itemDef.setEnabled(this, flag);
			}

			return true;
		}
	}

	setItemAmount(itemId, amount=1) {
		if(!itemId)
			return false;

		const itemDef = itemId.id ? itemId : KITTY_ITEMS[itemId];
		
		if(itemDef) {
			if(!this.db.items)
				this.db.items = {};
			
			const currentAmount = (parseFloat(this.db.items[itemDef.id]) || 0);
			const newAmount = Math.min(itemDef.maxItems, Math.max(0, amount));
		
			if(newAmount !== currentAmount) {
				this.db.items[itemDef.id] = newAmount;
				this.db.patch({ items: this.db.items }); // patch internally since may be called from ShopPopup in WelcomeScene
				// -1 = don't transmit, let world handle storage batching

				if(itemDef === this.activeItem) {
					this.emit('power', { amount: newAmount, itemDef });

					if(newAmount <= 0) {
						// Decided I don't want it auto-switching active item when depleted
						// const remainingItems = this.items(true);
						// this.setActiveItem(remainingItems.length > 0 ? remainingItems[0].itemDef : null);
					}
				}

				if(this.itemEnabled(itemId) && newAmount <= 0) {
					this.setItemEnabled(itemId, false);
				}

				return true;
			}
		}
		return false;
	}

	itemAmount(itemId) {
		if(!this.db.items || !itemId)
			return 0;
			
		const itemDef = itemId.id ? itemId : KITTY_ITEMS[itemId];
		if(!itemDef)
			return 0;
		
		return (parseFloat(this.db.items[itemDef.id]) || 0);
	}

	items(onlyItemsWithAmounts=false, filter='actions') {
		if(!this.db.items)
			return [];
		
		return Object
			.keys(this.db.items)
			.map(itemId => ({ 
				amount:  parseFloat(this.db.items[itemId]) || 0,
				itemDef: KITTY_ITEMS[itemId]
			}))
			.filter(item =>
				item.itemDef && // fix for https://sentry.io/organizations/sleepy-cat-game/issues/987016840/
				(onlyItemsWithAmounts ? item.amount > 0 : true) &&
				(filter === 'actions' ? typeof(item.itemDef.action) === 'function' : true)
			)
			.sort((a, b) =>
				a.itemDef.seqNum - b.itemDef.seqNum
			);
	}

	numItems() {
		if(!this.db.items)
			return 0;
		
		return Object
			.values(this.db.items)
			.filter(num => num > 0)
			.length;
	}

	_forceHideAimingLine() {
		if(!this.sprites)
			return;

		if (this.sprites.aimingLine) {
			this.sprites.aimingLine.alpha = 0;
			this.sprites.aimingLine.hide();
		}

		if (this.sprites.crosshairs)
			this.sprites.crosshairs.alpha = 0;
			
	}

	_storeActiveItemId(itemId) {
		const { db } = this,
			settings = db.settings || {};
		
		settings.activeItemId = itemId;

		this.db.patch({ settings }, -1); // -1 = don't transmit, let world handle storage batching
	}

	_getStoredActiveItemId() {
		const { db } = this,
			settings = db.settings || {};

		return settings.activeItemId || null;
	}

	setActiveItem(itemId) {
		if(this.activeItem) {
			// if(this.activeItem.id )
			// Deactive anything needed
		}

		if(!itemId) {
			this.activeItem = null;
			
			// for restoring on next level/next session
			this._storeActiveItemId(null);

			this._forceHideAimingLine();

			this.emit('power', { amount: 0, itemDef: null });
			return;
		}

		const itemDef = itemId.id ? itemId : KITTY_ITEMS[itemId];
		if(!itemDef) {
			console.trace("[setActiveItem] invalid item:", itemId);
			return;
		}

		// for restoring on next level/next session
		this._storeActiveItemId(itemDef.id);


		// Notify metric server
		// Update: Not storing metric here because setActiveItem() is also called on load, and we just want user choices
		// if(itemDef && (!this.activeItem || this.activeItem.id !== itemDef.id)) {
			// ServerStore.metric("game.level.kitty.item." + itemDef.id + ".selected");
		// }

		this.activeItem = itemDef;
		itemDef._lastActivated = null;

		// no need to show, auto-shown when not sleeping
		if(itemDef.id !== 'lasers') {
			this._forceHideAimingLine();
		}

		// if(itemDef.id === )
		// TODO: active anything needed

		// Probably updates meter with new icon....?
		this.emit('power', { amount: this.itemAmount(itemDef), itemDef });
	}

	setStars(stars, isReward=false) {
		if(this.statsFrozen) {
			if(process.env.NODE_ENV !== 'production')
				console.trace("[setStars] this.statsFrozen, not changing health");
			return;
		}

		const newStars = Math.floor(Math.max(0, stars));

		if(newStars !== this.currentStars) {

			if(this._isLoaded) {
				if(newStars > this.currentStars) {
					// const levelUpAmount = 1000;
					
					// Lets save this until we actually do something with it
					// if (Math.ceil(newStars          / levelUpAmount) !== 
					// 	Math.ceil(this.currentStars / levelUpAmount)) {
					// 	if (this.anims.heart)
					// 		this.anims.heart.play();
					// } else {
						if (isReward &&
							this.anims.stars)
							this.anims.stars.play();
					// }
				}
			}

			this.currentStars = newStars;
		
			this.db.patch({ stars: this.currentStars }, -1); // -1 = don't transmit, let world handle storage batching
			this.emit('stars', this.currentStars);	

			return true;
		}
	}

	addPoints(points = 0) {
		if(this.statsFrozen) {
			if(process.env.NODE_ENV !== 'production')
				console.trace("[setStars] this.statsFrozen, not changing points");
			return;
		}

		const newPoints = Math.floor(Math.max(0, points + this.db.points));

		if(newPoints !== this.db.points) {
			this.db.patch({ points: newPoints }, -1); // -1 = don't transmit, let world handle storage batching
			this.emit('points', newPoints);	

			return true;
		}
	}

	decreaseHealth() {
		// ServerStore.metric("game.level.kitty.hit.bad");

		this.setMouth('up');
		return this.setHealth(this.currentHealth - 5);
	}

	rewardHealth() {
		// ServerStore.metric("game.level.kitty.hit.health");
		
		this.setMouth('grin');
		return this.setHealth(this.currentHealth + 10);
	}

	rewardStars() {
		// ServerStore.metric("game.level.kitty.hit.star");

		this.setMouth('open');
		return this.setStars(this.currentStars + 1, true);
	}

	// decreasePower() {
	// 	return this.setPower(this.currentPower - 10);
	// }

	rewardPower(powerType={id:'lasers'}) {
		this.setMouth('grin');

		// NO items yet, so add an empty item
		if(!this.itemAmount(powerType.id)) {
			this.setItemAmount(powerType.id, 0);
		}

		const itemDef = KITTY_ITEMS[powerType.id] || { unitSize: 3 };
		
		if(!this.activeItem || (
			 (Date.now() - this.activeItem._lastActivated) > 10 * 1000 &&
			 itemDef.id !== 'lasers' && 
			 !this._autoActionPromise
		))
			this.setActiveItem(powerType.id);

		const tutKey = '_tut_' + powerType.id;
		if (this.isTutorial && !this[tutKey]) {
			this[tutKey] = true;
			const item = KITTY_ITEMS[powerType.id] || { name: powerType.id.replace('_', ' ') };
			ShowOnePopupHelper.tutorialPopup(TutorialKeys[powerType.id] || ('tut-' + powerType.id), <>
				<h2>Wow!</h2>
				<p>You found {item.name}! Use the button on the bottom-left side of the screen to activate {item.name}{powerType.id === 'lasers' ? ', and move to aim the crosshairs at what you want to shoot' : ''}.</p>
			</>);
		}

		const didIncrease = this.setItemAmount(itemDef, this.itemAmount(itemDef) + itemDef.unitSize);

		// Execute action
		if (itemDef.id !== 'lasers' && !this._autoActionPromise) {
			this._autoActionPromise = itemDef.action(this, this.game.currentScene);
			this._autoActionPromise.then(() => this._autoActionPromise = null);
			// Todo: Reduce quantity?
			// this.setItemAmount(itemDef, amount - 1);
			return true;
		} else {
			return didIncrease;
		}

		// return this.setPower(this.currentPower + itemDef.unitSize, true);
	}

	on(event, handler) {
		this._handlersToDestroy.push({ event, handler });
		super.on(event, handler);
	}


	tryToConsume(blockLabel, powerType={id:'lasers'}/*, matterBodyId*/) {
		if(blockLabel === '<star>')
			return true; //this.rewardStars();
		else
		if(blockLabel === '<bad>')
			return this.decreaseHealth();
		else
		if(blockLabel === '<power>')
			return this.rewardPower(powerType);
		else
		if(blockLabel === '<health>')
			return this.rewardHealth();
		else
			return false;
	}

	_accumulateHitMetric(metric) {
		metric = "game.level.kitty.hit_avg." + metric;

		if(!this._hitAccumulators)
			this._hitAccumulators = {};

		if(!this._hitAccumulators[metric])
			this._hitAccumulators[metric] = {
				metric,
				start: null,
				count: 0,
				tid:   null
			};

		const data = this._hitAccumulators[metric];
			
		data.count ++;
		if(!data.start)
			data.start = Date.now();
		data.lastTime  = Date.now();

		clearTimeout(this._hitAccumulatorsFlushTimer);
		this._hitAccumulatorsFlushTimer = setTimeout(this._flushHitAccumulators, 5500);
	}

	_flushHitAccumulators = () => {
		clearTimeout(this._hitAccumulatorsFlushTimer);
		if(!this._hitAccumulators)
			return;

		Object.values(this._hitAccumulators).forEach(data => {
			if(data.count > 0) {
				const time = data.lastTime - data.start,
					sec = time / 1000;
				
				ServerStore.metric(data.metric,
					parseFloat((data.count / sec).toFixed(3)),
					{ sec, num: data.count });
					// NB: { sec, num } - special expanded props, stored in data_sec and data_num in the db for easy querying (as well as `data`) 
				data.count = 0;
				data.start = null;
			}
		});
	}

	brokeBlock(block, methodOfDamage=null) {
		// this.setMouth('grin');

		const methodAppend = methodOfDamage === '#bullet' ? '.shot' : '.touch';

		// console.log("[broke block] block=", block,", type=", block.breakableObjectType);
		if(block.breakableObjectType === '<health>') {
			this._accumulateHitMetric("health" + methodAppend);
		} else
		if(block.breakableObjectType === '<bad>') {
			// Record both bullets and touches for kpis, even though bullets don't affect the actor
			this._accumulateHitMetric("bad" + methodAppend);
		} else
		if(block.breakableObjectType === '<power>') {
			const powerType = block.powerType || {id:'lasers'};
			this._accumulateHitMetric(powerType.id + methodAppend);

			// console.trace("KittyActor.brokeBlock:", block.breakableObjectType, block, methodOfDamage);
		} else
		if(block.breakableObjectType === '<star>') {
			this._accumulateHitMetric("star" + methodAppend);

			if(this.isTutorial && !this._starTut) {
				this._starTut = true;
				ShowOnePopupHelper.tutorialPopup(TutorialKeys.star, <>
					<h2>Stars</h2>
					<p>Nice! Collect all the stars you can so you can unlock more levels and buy upgrades for your kitty!</p>
				</>);
			}
			// console.warn("[broke block]  +++ reward stars");
			return this.rewardStars();
		} else
		if(block.breakableObjectType === '<doorblock>') {
			// Notify metric server
			this._accumulateHitMetric("doorblock");

			this.game.currentScene.gotoNextLevel();
		} else {
			// console.warn("Unknown breakableObjectType")
		}
		// if(block.breakableObjectType === '<powerpill>') {
		// 	// Notify metric server
		// 	this._accumulateHitMetric("powerpill" + methodAppend);

		// 	// this.hasPowerPill = true;
			// this.setKittyColor(0xC63636, false);
		// 	// this.game.currentScene.buttons.powerpill.tint = 0xC63636;
		// 	this.setItemAmount('explosion', this.itemAmount('explosion') + 1);

		// 	if(this.isTutorial && !this._pillTut) {
		// 		this._pillTut = true;
		// 		ShowOnePopupHelper.tutorialPopup(TutorialKeys.pill, <>
		// 			<h2>Explosions</h2>
		// 			<p>Ohhh! You found an explosive pill...use the fire button (the X button) to trigger the explosion! Don't worry, your kitty won't be affected by it.</p>
		// 		</>);
		// 	}
		// }
	}

	playSparkle(length) {
		this.anims && 
			this.anims.sparkle && 
			this.anims.sparkle.play();
		return this.sprites &&
			this.sprites.sparkle_static_img && 
			this.sprites.sparkle_static_img.play(length);
	}

	multScaleEffect(newScale=1, tween=false) {
		if(tween) {
			const value = { scale: this.obj.scale.x };
			new TWEEN.Tween(value)
				.to({scale: newScale}, tween === true ? 500 : tween)
				.easing(TWEEN.Easing.Elastic.InOut)
				.onUpdate(() => this.multScaleEffect(value.scale, false))
				.start();
			
			return PixiUtils.touchTweenLoop();
		}

		const scaleMult = newScale / this.obj.scale.x;
		this.obj.scale.x  *= scaleMult;
		this.obj.scale.y  *= scaleMult;
		this.obj.scaleMatter(scaleMult);
	}
};