import { server } from './ServerUtil';
import gtag from './GoogleAnalytics';
import { Cat, Level } from './models';
import { EventEmitter } from 'events';
import * as Sentry from '@sentry/browser';
import { DeviceInfo } from './DeviceInfo';
import { avg } from './geom';
import { mixpanel } from './mixpanel';
import { isPhoneGap } from './isPhoneGap';

// Load for ad conversion tracking
import './trackingPixels';

// NOTE: We include buildTime from a .json file instead of a simple .txt file because
// webpack will embed the JSON data at build time and so the bundle will end up with
// "buildTime={...}". If we had used a ".txt" file - while they are easier to generate
// in package.json, instead webpack would have given us a static assset URL, e.g.
// "buildTime='./static/assets/build-time.txt'" or something like that. We could fetch that,
// yes, but the purpose of the buildTime var is to indicate when the RUNNING SOFTWARE
// was built, NOT whatever is on the server. So, by embedding JSON in the bundle,
// we will "freeze" the time the bundle was built in stone inside the bundle itself,
// as buildTime.date (see package.json for how this file is generated)
import buildTime from './build-time.json';
import { defer } from './defer';

// Must match what's used on the server
export const FB_APP_ID = "154311568550166";

// for reuse elsewhere, such as LoginScene
export { gtag };

// For local storage of FB token
const TOKEN_KEY     = 'kitty-fb-accessToken';

// For common definition
export const LOGIN_EVENT = "login-event";

// For common definition
export const APP_PAUSED_EVENT  = "app-paused-event";
export const APP_RESUMED_EVENT = "app-resumed-event";

// Hide Josiah from MixPanel (still log metrics to server though)
const HIDE_USER_ID_FROM_MIXPANEL = 3;


export class ServerStore {
	// Patch with info incase we boot before the script in index.html boots
	static isPhoneGap = isPhoneGap;

	static currentCat  = null;
	static currentUser = null;

	// Only valid for this session (till user closes the app)
	static appSession  = {
		playingTime: 0,
		levelsCompleted: 0,
	};

	static _events = new EventEmitter();
	static on(event, callback) {
		this._events.on(event, callback);
	}

	static off(event, callback) {
		this._events.off(event, callback);
	}

	static emit(event, data) {
		this._events.emit(event, data);
	}

	static models() {
		return { Cat, Level };
	}

	static server() { return server };

	static _lastSuccessfulHomeResult = null;
	static _pendingHomeScreenData = null;
	static async getHomeScreenData() {
		if(this._pendingHomeScreenData) {
			// console.log("[INFO:ServerStore.getHomeScreenData] Found outstanding request pending, returning deferred promise");
			return await this._pendingHomeScreenData;
		}
		
		this._pendingHomeScreenData = defer();
		
		// return cached home data for max Xms after returning, otherwise assume cache dirty and re-request
		const homeDataExpiration = 5000; 
		if(this._lastSuccessfulHomeResult && 
			(Date.now() - this._lastSuccessfulHomeResult.__received) < homeDataExpiration) {
			// console.log("[INFO:ServerStore.getHomeScreenData] return cached dataset:", this._lastSuccessfulHomeResult);
			return this._lastSuccessfulHomeResult;
		} else {
			// const age = this._lastSuccessfulHomeResult ? (Date.now() - this._lastSuccessfulHomeResult.__received) : null;
			// console.log("[INFO:ServerStore.getHomeScreenData] Data too old or no cache:", { age } , this._lastSuccessfulHomeResult);			
		}

		// Request new home data set with auto-retry and error catching
		let error, home = (await ServerStore.server().get('/level_status/home', null, { autoRetry: 3 }).catch(e => error = e)) || null;
		
		// If error, return cached data anyway (if exists)
		if((error || !home || home.error) && this._lastSuccessfulHomeResult) {
			home = this._lastSuccessfulHomeResult;
			if (home && !home.error && error)
				home.error = error;
		}

		// Cache the result
		this._lastSuccessfulHomeResult = home || {};
		this._lastSuccessfulHomeResult.__received = Date.now();

		// Resolve cache promise if anyone listening
		this._pendingHomeScreenData.resolve(home);
		this._pendingHomeScreenData = null;

		// Return to OG requestor
		return home;
	}

	static _levelStateCache = null;
	static async getLevelState(levelId=null) {
		if(!levelId) {
			levelId = (ServerStore.currentCat.level || {}).id;
		}
		// console.log("[getLevelState] -start- - loading for levelId=", levelId);

		if(!levelId) {
			console.warn("[ServerStore.getLevelState] cat.level wasn't valid, game probably wont work");
			return { level: {}, levelState: {}, worldState: {} };
		}

		// TODO get from local storage for offline

		// If cached, return cached status we loaded from server or persisted.
		// This is useful for things like where the WelcomeScene needs to know if the level
		// is started to bypass the screen (and calls getLevelState()) and immediately after
		// sending to the KittyFlySleep scene, that scene also needs to call getLevelState(),
		// so this cache prevents multiple hits to the /level_status endpoint if not needed
		if (this._levelStateCache &&
			(this._levelStateCache.level || {}).id === levelId) {
			// console.log("[getLevelState] +cache HIT+ loading for levelId=", levelId);
			return this._levelStateCache;
		}

		// console.warn("[getLevelState] *CACHE MISS* loading for levelId=", levelId);
			
		// Not cached or the level is different than the cache, so load from server
		const result = await server.get('/level_status', { levelId }, { autoRetry: true })

		return this._cacheLevelState(result);
	}

	static async _cacheLevelState(result) {
		if(!result)
			return;

		const { level: levelData, levelState, worldState } = result;

		// // Bug with water in production - will debug tomorrow
		// if(levelData.bg === 'water')
		// 	levelData.bg = 'stars';
		

		// We return Level as an object (but not levelState)
		// because editor can use the Level object to persist changes,
		// however, levelState is only persisted thru persistLevelState(), below,
		// no URL endpoint even exists for the levelState data structures other than /level_status
		const level = await Level.inflate(levelData);

		// Cache and return
		return this._levelStateCache = { level, levelState, worldState };
	}

	static async persistLevelState(levelId, levelStatusData={}) {
		// TODO persist in local storage for offline

		// freshen cache
		if (this._levelStateCache.levelStatusData)
			Object.assign(this._levelStateCache.levelState,  levelStatusData || {})

		// Include cat updates (stars, health, level, etc) in this update,
		// because the KittyActor is designed to NOT post updates to the server to preserve traffic.
		// It just queues them with .patch({...}, -1), and we get them with pendingUpdates()
		const pendingCatUpdates = ServerStore.currentCat.pendingUpdates();

		// POST to the server
		const result = await server.post('/level_status', { levelId, levelStatusData, pendingCatUpdates }, { autoRetry: true });

		// console.log("Got result in persistLevelState:", result);

		// Return updated object (NOT from server, just with local changes and the response from the server incase we use it in future)
		return { levelState: this._levelStateCache.levelState, serverResult: result };
	}

	static async completeLevel(levelId) {
		const result = await server.post('/level_status/completed', { levelId }, { autoRetry: true });
		if(result.noNextLevel || result.statePacket) {
			if(result.statePacket) {
				// console.warn("[ServerStore.completeLevel] noNextLevel:", result);
				const state  = await this._cacheLevelState(result.statePacket);
				this.currentCat.level = state.level;
			}

			return result; // contains additional info
		}

		const state  = await this._cacheLevelState(result);
		this.currentCat.level = state.level;
		return result;
	}

	static async replayWorld(world) {
		const result = await server.post('/level_status/home/replay', { world: world.id }, { autoRetry: true });

		// Replay failed for some reason, expect and inspect result.error
		if(!result.level)
			return result;

		// /replay should make life easy for us by returning a state packet with level and levelStatus data already included
		// so we can cache it to prevent another round-trip for the first level in the newly reset world
		// This assumes reset succeeded - if not, we don't even hit this (quick return above)
		const state  = await this._cacheLevelState(result);
		this.currentCat.level = state.level;

		return result;
	}

	static async unlockWorld(world, unlockOptions) {
		const result = await server.post('/level_status/unlock', { world: world.id, unlockOptions }, { autoRetry: true });

		// Unlock failed for some reason - expect and inspect result.error 
		if(!result.success)
			return result;

		// /unlock should make life easy for us by returning a state packet with level and levelStatus data already included
		// so we can cache it to prevent another round-trip for the first level in the newly unlocked world
		// This assumes unlock succeeded - if not, we don't even hit this (quick return above)
		const state  = await this._cacheLevelState(result);
		this.currentCat.level = state.level;

		// stars probably changed if we spent stars
		this.currentCat.stars = result.newCatStars;

		return result;
	}

	static async purchaseItem(itemDef, unlockOptions) {
		const result = await server.post('/level_status/purchase_item', { itemDefId: itemDef.id, unlockOptions }, { autoRetry: true });

		// Unlock failed for some reason - expect and inspect result.error 
		if(!result.success)
			return result;

		// /purchase_item should make life easy by returning the new .items hash so we can just set it here
		// This assumes unlock succeeded - if not, we don't even hit this (quick return above)
		this.currentCat.items = result.items;

		// stars probably changed if we spent stars - it's up to the calling code to ensure UI is updated
		this.currentCat.stars = result.newCatStars;

		return result;
	}


	static async countMetric(metric, value=1) {
		
		this.metric(metric + '.count', value, {}, true/*dontSendToMixpanel*/);

		// For now, just dumps to mixpanel and fakes it (must sum() serverside later) in local metric
		if (mixpanel) {
			if(this.currentUser && this.currentUser.id === HIDE_USER_ID_FROM_MIXPANEL)
				return;
			
			mixpanel.people.increment(metric, value);

			// special-case spending count for
			// logging as shown in https://developer.mixpanel.com/docs/javascript#section-tracking-revenue
			// This metric is currently logged in MarketUtils in BuyItemButton.processPurchaseToken
			if (metric === 'game.count.dollars_spent') {
				mixpanel.people.track_charge(value);
			}
		}
	}

	static metric(metric, value, data={}, dontSendToMixpanel=false) {
		(this.metrics || (this.metrics = [])).push({
			// NB user, cat, and level all applied server-side to this item
			// based on the auth token and cat state in the db
			datetime: new Date(),
			epoch:    Date.now(),
			
			metric,
			value,
			data,
		});
		this._touchMetricInterval();

		// Upload to mixpanel as well
		if (mixpanel && !dontSendToMixpanel) {
			if(!this.currentUser || this.currentUser.id !== HIDE_USER_ID_FROM_MIXPANEL) {
				let props;
				if((value !== undefined && value !== null) || Object.keys(data || {}).length > 0) {
					props = { value, ...(data || {})};
				}

				mixpanel.track(metric, props);
			}
		}

		return {
			flush: this._flushMetrics || (this._flushMetrics = this.postMetrics.bind(this)),
		};
	}

	static _touchMetricInterval() {
		if(this._metricInterval)
			return;

		this._metricInterval = setInterval(() => {
			this.postMetrics();
		}, 1000);
	}

	
	static async postMetrics(keepalive=false) {
		const metrics = (this.metrics || []);
		if(metrics.length > 0) {
			const deviceInfo = await this.deviceInfo();

			// Make a copy and then reset .metrics instead of resetting after tx
			// because the tx is async and doesn't block the rest of the program,
			// so metrics could be added (and then lost) during the tx if we waited
			// to reset after the post finished.
			const batch = metrics.slice();
			this.metrics = [];
			// If not logged in yet, post to an unauth'd route
			const preAuth = ServerStore.currentUser ? '' : '/pre';
			// NB: Not using { autoRetry: true } arg on server.post
			// because we just catch errors and re-buffer the metrics for later posting
			// at the next call of the _metricInterval interval timer
			await server.post('/level_status/metrics' + preAuth, { 
				deviceId: deviceInfo.deviceId, 
				batch
			}, { 
				// Options in this hash passed directly to fetch()
				// Per https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch:
				// 		The keepalive option can be used to allow the request to outlive the page. 
				// 		Fetch with the keepalive flag is a replacement for the Navigator.sendBeacon() API. 
				// Since we could be calling postMetrics() in onbeforeonload (or other page-ending circumstances),
				// this ensures that the metrics hit the server.
				// We have to use fetch() instead of sendBeacon() because we need headers
				// to contain our auth data so the correct user is tracked with the metrics as well (if logged in)
				keepalive
			}).catch(error => {
				// Put metrics back on the stack if an error occurred
				this.metrics.unshift(...batch);
				console.warn("Error posting metrics to server:", error);
			});
		}
	}

	static updateStreak(streakIdent, { 
		reset=false, 
		set=null, 
		name=null, 
		minimize=false,
		inc=1,
		maxHistory=25, // max of 25 history records / avgs (the avg world size)
		seqNum=0, // sorting
		replace=false, //replace last value in history
	} = { 
		reset:false, 
		set:null, 
		name:null, 
		minimize:false,
		inc:1,
		maxHistory:25, // max of 25 history records / avgs (the avg world size)
		seqNum:0, // sorting
		replace:false
	}) {
		const cat = this.currentCat;
		if(!cat.stats)
			cat.stats = {};

		if(!cat.stats.streaks)
			cat.stats.streaks = {};
		
		const streak =
			 cat.stats.streaks[streakIdent] ||
			(cat.stats.streaks[streakIdent] = { id: streakIdent });

		// Set name if given (allow name not be specified and keep old name)
		if(name !== null)
			streak.name = name;

		// Set sensible defaults
		if(!streak.record)
			streak.record = 0;

		if(!streak.current)
			streak.current = 0;
		
		// Set seqNum
		streak.seqNum = seqNum;

		if(!streak.history)
			streak.history = [];
		
		// old data
		if(streak.longest)
			delete streak.longest;
		if(streak.noun)
			delete streak.noun;

		// Apply changes
		if(set !== null && !isNaN(set)) {
			streak.wasSet = true;
			streak.current = set;
		} else
		if(reset) {
			streak.wasReset = true;
			streak.current = 0;
		} else {
			streak.wasReset = false;
			streak.wasSet   = false;
			streak.current += inc;
		}

		// If replacing, remove last value off of history and
		// the new one will be pushed in it's place
		if(replace)
			streak.history.pop();
			
		// Add to history
		streak.history.push(streak.current);

		// remove oldest if longer than max
		if (streak.history.length > maxHistory)
			streak.history.shift();

		// Get avg
		streak.avg = avg(streak.history);

		// Calc records
		if (minimize ? 
				(streak.current < streak.record || streak.record === 0): 
				 streak.current > streak.record) {
			streak.record       = streak.current;
			streak.newRecord    = true;

			// These times are for sorting
			streak.lastRecordAt = streak.recordAt;
			streak.recordAt     = Date.now();
		} else {
			streak.newRecord = false;
		}

		// Update _current index
		if(!cat.stats._current)
			cat.stats._current = {};

		// The current value of the stats are stored in the special "stats._current" key.
		// We expand this key (see defs/cat) with each of the known IDs of stats we're currently tracking.
		// This is done so we can do 'order by' queries in SQL on groups of cats (e.g. friends)
		// to determine ranking without having to JSON.parse() each cat's stats before sorting.
		// This works because fields detailed in the 'expand: {}' attribute of t.object() fields
		// are automatically destructured and stored directly in the table (in addition to being
		// stored in the originating JSON field) - again, this is purely done for ease of SQL
		// querying, since the data is automatically kept in sync (one way, from stats > db,
		// never read back from the expanded fields into stats)
		cat.stats._current[streakIdent] = streak.current;

		cat.patch({ stats: cat.stats }, -1); // patch flushed to server in patchLevelState
		return streak;
	}

	
	static async authenticated(authData) {
		// console.log("[authenticated] authData=", authData, typeof(authData), authData.length )
		
		this.authData = authData;
		server.setToken(authData.token);

		this.currentCat = await Cat.inflate(authData.cat);
		this.currentUser = authData.user;

		// Update GoogleAnalytics with userId
		gtag('set', { 'user_id': this.currentUser.id }); // Set the user ID using signed-in user_id.

		// Update Sentry with user data
		this._setupSentry(authData.user);

		// Update MixPanel with user data
		this._setupMixpanel(authData.user);
		
		// Count this metric
		this.countMetric('app.user.login');

		// Notify anyone listening
		this.emit(LOGIN_EVENT, this.currentUser);

		return this;
	}

	static _setupSentry(user) {
		Sentry.configureScope(scope => {
			const { name, id, email } = user,
				sentryId = '#'+id+':'+name;

			scope.setUser({ email, id: sentryId });
		});
	}

	static async _setupMixpanel(user) {
		if(mixpanel) {
			const { name, id, email } = user,
				sentryId = '#'+id+':'+name;

			const deviceInfo = await this.deviceInfo();

			mixpanel.identify(sentryId);
			mixpanel.people.set({ 
				name,
				email,
				deviceBrand: deviceInfo.brand,
				deviceClass: deviceInfo.deviceClass,
			});
		}
	}

	static async linkFb(accessToken) {
		// Store for future auth without asking FB
		window.localStorage.setItem(TOKEN_KEY, accessToken);

		let updatedUser;
		try {
			updatedUser = await server.post('/user/link_fb', { accessToken }, { autoRetry: true })
			if(updatedUser.error)
				throw updatedUser;
			if(!updatedUser)
				throw new Error("No response from server method");
		} catch(e) {
			console.warn("Error logging in:", e);
			return null;
		}

		// server.call would have already output the error on console
		if(updatedUser.error) {// || confirmation.length) {
			console.warn("Error logging in: ", updatedUser);
			return null;
		}

		// console.warn("[login:confirmation]", { confirmation });
		this.currentUser = updatedUser;
		this._setupSentry(updatedUser);

		return this;
	}

	static async unlinkFb() {
		const deviceInfo = await this.deviceInfo();
		// Store for future auth without asking FB
		window.localStorage.setItem(TOKEN_KEY, deviceInfo.deviceId);


		let updatedUser;
		try {
			updatedUser = await server.post('/user/unlink_fb', { deviceInfo }, { autoRetry: true })
			if(updatedUser.error)
				throw updatedUser;
			if(!updatedUser)
				throw new Error("No response from server method");
		} catch(e) {
			console.warn("Error logging in:", e);
			return null;
		}

		// server.call would have already output the error on console
		if(updatedUser.error) {// || confirmation.length) {
			console.warn("Error logging in: ", updatedUser);
			return null;
		}

		// console.warn("[login:confirmation]", { confirmation });
		this.currentUser = updatedUser;
		
		return this;
	}

	static async login(accessToken) {
		const deviceInfo = await this.deviceInfo();

		// Store for future auth without asking FB
		window.localStorage.setItem(TOKEN_KEY, accessToken);

		let confirmation;
		try {
			confirmation = await server.post('/login/fb', { accessToken, deviceInfo }, { autoRetry: true })
			if(!confirmation || confirmation.error)
				return null;
		} catch(e) {
			console.warn("Error logging in:", e);
			return null;
		}

		// server.call would have already output the error on console
		if(confirmation.error || !confirmation.user) {// || confirmation.length) {
			console.warn("Error logging in: ", confirmation);
			return null;
		}

		// console.warn("[login:confirmation]", { confirmation });
		await this.authenticated(confirmation);

		gtag('event', 'login', { method: 'Facebook' });

		return this;
	}

	static async anonymousLogin() {
		const deviceInfo = await this.deviceInfo();
		
		let confirmation;
		try {
			confirmation = await server.post('/login/fb', { anonymousLogin: true, deviceInfo });
			if(!confirmation)
				return null;
		} catch(e) {
			console.warn("Error logging:", e);
			return null;
		}

		// console.warn("We got this:", confirmation);

		// server.call would have already output the error on console
		if(confirmation.error || !confirmation.user) {
			console.warn("Cannot loginWithName:", confirmation);
			return null;
		}

		// Store for future re-login
		window.localStorage.setItem(TOKEN_KEY, confirmation.user.fbAccessToken);

		// console.warn("[tryTakeoverCode:confirmation]", { confirmation });
		await this.authenticated(confirmation);

		gtag('event', 'login', { method: 'Name' });

		return this;
	}
	
	static async tryTakeoverCode(takeoverCode) {
		const deviceInfo = await this.deviceInfo();
		
		let confirmation;
		try {
			confirmation = await server.post('/login/fb', { takeoverCode, deviceInfo }, { autoRetry: true });
			if(!confirmation)
				return null;
		} catch(e) {
			console.warn("Error logging:", e);
			return null;
		}

		// console.warn("We got this:", confirmation);

		// server.call would have already output the error on console
		if(confirmation.error || !confirmation.user) {
			console.warn("Cannot tryTakeoverCode:", confirmation);
			return null;
		}

		// Store for future re-login
		window.localStorage.setItem(TOKEN_KEY, confirmation.user.fbAccessToken);

		// console.warn("[tryTakeoverCode:confirmation]", { confirmation });
		await this.authenticated(confirmation);

		gtag('event', 'login', { method: 'Takeover' });

		return this;
	}


	static logout() {
		this.authData = null;
		this.currentUser = null;
		this.currentCat = null;
		server.setToken(null);

		return this;
	}

	static async attemptAutoLogin(token) {
		if(!token)
			token = window.localStorage.getItem(TOKEN_KEY);
		if(token) {
			return await ServerStore.login(token);
		}

		return false;
	}

	static async deviceInfo() {
		return   this._cachedDeviceInfo ||
				(this._cachedDeviceInfo  = await DeviceInfo.getDeviceInfo());
	}

	static async storePushToken(token) {
		const deviceInfo = await this.deviceInfo();

		// POST to the server
		// No need to await the result, this is a write-only action
		server.post('/user/store_push_token', { deviceInfo, token }, { autoRetry: true });

		// Make return obviously explicit 
		return null;
	}

	static _cachedServerVer;
	/**
	 * Fetches latest build version from server and compares to the version this code was built with
	 *
	 * @static
	 * @returns {object}  {serverVer: string, runningVer: string, needsUpdated: bool}
	 * @memberof ServerStore
	 */
	static async appVersion() {

		const deviceInfo = await this.deviceInfo();

		// Note: We check ver against front end, not API host. 
		// Front end (powered by Netlify) will have the /version.txt, NOT the API server.
		const verCheckHost = this.isPhoneGap ? 'https://sleepycatgame.com' : '';

		let versionFetchFailed = false,
			serverBuildTime = null;

		const runningVer     = process.env.REACT_APP_GIT_REV,
			runningBuildTime = buildTime.date;

		const serverVer  =
			this._cachedServerVer ? 
			this._cachedServerVer : 
			this._cachedServerVer = await fetch(verCheckHost + '/version.txt')
				.then(data => {
					serverBuildTime = data.headers.get('last-modified');
					return data.text();
				})
				.then(text => ({
					ver: text.trim().replace("\n", ''),
					buildTime: serverBuildTime
				}))
				.catch(()  => versionFetchFailed = true);
				
		const packet = {
			deviceInfo,
			runningVer,
			runningBuildTime,
			serverVer:       versionFetchFailed ? '(unknown)' : serverVer.ver,
			serverBuildTime: versionFetchFailed ? '(unknown)' : serverVer.buildTime,
			needsUpdated:    versionFetchFailed ? false       : serverVer.ver !== runningVer,
		};

		if(!this._printedVersion && (this._printedVersion = true))
			console.log("[ServerStore.appVersion]", packet);

		return packet;
	}
}

window.store = ServerStore;
