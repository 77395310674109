import React from 'react';
import * as Sentry from '@sentry/browser';
import { ServerStore } from './ServerStore';

// Sentry.init({
//  dsn: "https://0b2f820763c04905ba9a410c1e7fa1fb@sentry.io/1401447"
// });
// should have been called before using it here
// ideally before even rendering your react app 

// From https://sentry.io/onboarding/sleepy-cat-game/sleepycatgame/configure/javascript-react
export default class SentryBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    async componentDidCatch(error, errorInfo) {
		
		await ServerStore.metric('app.crashed', null, { 
			error,
			errorInfo
		}).flush();

		this.setState({ error });

		Sentry.withScope(scope => {
			Object.keys(errorInfo).forEach(key => {
				scope.setExtra(key, errorInfo[key]);
			});
			Sentry.captureException(error);
		});
    }

    render() {
        if (this.state.error) {
            //render fallback UI
            return (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a onClick={() => Sentry.showReportDialog()} style={{color:"white"}}>Report feedback</a>
            );
        } else {
            //when there's not an error, render children untouched
            return this.props.children;
        }
    }
}