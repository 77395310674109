import { server } from '../ServerUtil';
import EventEmitter from 'events';

// Private Symbol for this file to prevent new class() from being called
// - use class.inflate() instead
const FROM_INFLATE_SYMBOL = Symbol();

export class ServerModelProxy extends EventEmitter {
	static _cache = {};

	static async inflateValues(data) {
		// TODO
		return data;
	}

	static async inflate(data) {
		if(!data)
			return null;

		const inflatedData = await this.inflateValues(data);

		// Get name of class
		const className = this.name;
		const cache = this._cache[className] || (this._cache[className] = {});


		// Check cache and return same object if .id for this class already cached
		if(cache[inflatedData.id]) {

			const cached = cache[inflatedData.id];

			// Freshen cache
			Object.keys(inflatedData).forEach(field => {
				cached[field] = inflatedData[field];
			});

			// await cached.afterChangeHook();
			return cached;
		}

		const cached = (cache[inflatedData.id] = new this(inflatedData, FROM_INFLATE_SYMBOL));

		return cached;
	}

	constructor(data, constructorAllowed) {
		super();
		
		if(constructorAllowed !== FROM_INFLATE_SYMBOL)
			throw new TypeError("Call class.inflate() instead of new class()");

		this.applyPatch(data);
	}

	toString() {
		return this.id;
	}

	static getUrl(parts) {
		if(!Array.isArray(parts))
			parts = parts ? [parts] : [];
		return this.serviceRoot() + (parts.length ? '/' + parts.join('/') : '');
	}

	static async find(query) {
		// console.warn("[ServerModelProxy>" + this.name + ".get] id=", id);

		const data = await server.get(this.getUrl(), query, { autoRetry: true });
		if(!data) {
			return null;
		}

		const list = data.data || [];

		return await Promise.all(list.map(item => this.inflate(item)));
	}

	static async get(id) {
		// console.warn("[ServerModelProxy>" + this.name + ".get] id=", id);

		const data = await server.get(this.getUrl(id), { autoRetry: true })
		if(!data) {
			return null;
		}

		return this.inflate(data);
	}

	// _updatePipe = [];
	_pendingUpdate = null;
	_updateTid = null;

	async patch(newData, delay=1000) {
		if(delay || delay === -1) {

			// Proactive apply
			this.applyPatch(newData);

			// Merge with any already-pending updates (overwriting)
			this._cachePendingUpdate(newData);

			if(delay > 0) {
				// Set timeout
				clearTimeout(this._updateTid);
				this._updateTid = setTimeout(() => this.storePendingUpdates(), delay);
			}

			return this;
		} else {
			// console.warn("[ServerModelProxy>" + this.constructor.name + ".update] id=", this.id, ", newData=", newData);
			return this.storePendingUpdates(newData);
		}
	}

	_cachePendingUpdate(newData=null) {
		if(!newData)
			return;

		if(!this._pendingUpdate)
			this._pendingUpdate = newData;
		else
			this._pendingUpdate = Object.assign(this._pendingUpdate, newData);
	}

	async storePendingUpdates(newData = null) {
		if(newData)
			// Merge with any already-pending updates (overwriting)
			this._cachePendingUpdate(newData);

		if(!this._pendingUpdate)
			return;

		// console.warn("[ServerModelProxy>" + this.constructor.name + ".update] id=", this.id, ", _pendingUpdate=", this._pendingUpdate);

		const newModel = await server.post(this.constructor.getUrl(this.id), this._pendingUpdate, { autoRetry: true });

		// Apply newly-received data with any changes back onto the model
		this.applyPatch(newModel);

		this._pendingUpdate = null;
	}

	pendingUpdates() {
		return this._pendingUpdate;
	}

	// async update(newData, delay) {
	// 	return this.patch(newData, delay);
	// }

	applyPatch(data) {
		Object.keys(data).forEach(field => {
			this[field] = data[field];
		});

		this.emit('patched', data);
	}
}