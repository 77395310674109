import * as PIXI from 'pixi.js';
import { numberWithCommas } from '../utils/numberWithCommas';

const dpi = window.devicePixelRatio,
	fontSize = 
		dpi <= 1.0 ? 16 :
		dpi <= 2.0 ? 14 :
		dpi <= 3.0 ? 12 : 10;
		
// console.log("[BasicMeter] fontSize:", fontSize);

export class BasicMeter extends PIXI.Container {
	static DefaultOptions = {
		resources: {}, // from the game, preloaded assets
		color: "red",
		icon: "health",
		label: "Health",
		value: 0,
		maxValue: 100, 
		meterWidth: 116,
		indefinite: false, // if true, no text label rendered below, no bar, just text in the bar
		// align: "left", // if right, label and meter will extend to the left of the circle
		textStyle: {
			// This font is loaded in the react <KittyGameView> component
			fontFamily: "Dimbo-Regular, Arial",
			fontSize,
			lineHeight: fontSize*.9, // the .9 helps with vertical centering of the text label
			fill: "white",
			// stroke: '#000000',
			// strokeThickness: 1,
			// align: "right",
			dropShadow: true,
			dropShadowColor: "#000000",
			dropShadowBlur: 5,
			dropShadowAngle: Math.PI / 6,
			dropShadowDistance: 1,
		},
		position: {
			x: 0,
			y: 0,
		}
	};
	
	constructor(options=BasicMeter.DefaultOptions) {
		super();

		this.opts = Object.assign({}, BasicMeter.DefaultOptions, options || {});

		this.buildMeter();
		this.setValue(this.opts.value);
	}

	setValue(newValue, maxValue=null, customLabel=null) {
		if(!maxValue)
			maxValue = this.opts.maxValue;
		else
			this.opts.maxValue = maxValue;

		if(!customLabel)
			customLabel = this.opts.customLabel;
		
		this.value = newValue;
		this.percent = newValue / maxValue;;

		if(!this.opts.indefinite &&
			this.sprites.bar_center_repeating &&
			this.sprites.bar_center_repeating.transform) {
			// TODO
			// from:http://www.html5gamedevs.com/topic/790-tilingsprite-resize/
			// maybe stage.removeChild(sprite);sprite.width = 200;stage.addChild(sprite);?

			const newWidth = (this.sprites.bar_holder_center_repeating.width * this.percent);
				// newWidth = intendedWidth / this.opts.scale;
			
				// console.log("[setValue]", { newWidth, p:this.percent, newValue, maxValue});

			this.sprites.bar_center_repeating.width = newWidth;
			this.sprites.bar_right_edge.x = this.sprites.bar_center_repeating.x + newWidth * this.opts.scale;
		}

		if (this.sprites.textLabel) {
			this.sprites.textLabel.text = customLabel ? customLabel : (
				this.opts.indefinite ?
					[this.opts.label + " ", numberWithCommas(newValue)] :
					[this.opts.label + " ", numberWithCommas(newValue), "/", numberWithCommas(maxValue)]
			).join(" ");
		}
	}

	setIcon(resource, scale=1) {
		if(!resource || !resource.texture) {
			console.warn("[BasicMeter.setIcon] resource given had no texture or null resource:", resource);
			return;
		}

		if (this.sprites.icon) {
			this.sprites.icon.destroy();			
		}

		const sprite = new PIXI.Sprite(resource.texture);
		sprite.scale = new PIXI.Point(scale, scale);
		sprite.anchor.x = 0.5;
		sprite.anchor.y = 0.5;
		sprite.x = (this.sprites.icon_holder.width)  / 2;
		sprite.y = (this.sprites.icon_holder.height) / 2;
		this.addChild(sprite);
		this.sprites.icon = sprite;
		// Store for external confirmation
		this.currentIconResource = resource;
	}

	setTint(tint) {
		Object.keys(this.sprites).forEach(key => 
			!['icon','textLabel'].includes(key) && 
			(this.sprites[key].tint = tint)
		);
	}

	buildMeter() {
		
		const { resources, color, icon, meterWidth, position, textStyle } = this.opts;

		this.sprites = {};

		const scale = 0.09, spriteScale = new PIXI.Point(scale, scale);
		this.opts.scale = scale;
		
		// Build sprites from the resources and color requested
		[
			// NB: The order they are specified here is the order they will be added
			// to this container. This is important to ensure proper layering of the
			// varios parts of the meter.
			// 'meter_text_background_center_repeating_',
			// 'meter_text_background_left_edge_',
			'meter_text_background_',
			// 'meter_text_background_right_edge_',

			'meter_bar_holder_center_repeating_',
			// 'meter_bar_holder_left_edge_',
			'meter_bar_holder_right_edge_',
			
			'meter_bar_center_repeating_',
			// 'meter_bar_left_edge_',
			'meter_bar_right_edge_',

			'meter_icon_holder_',			
		].forEach(base => {

			const key = base + color;
			if(!resources[key]) {
				throw new Error("Asset not loaded - check code. Missing: " + key);
			}

			const isRepeating = key.includes('_repeating_'),
				spriteClass   = isRepeating ? PIXI.extras.TilingSprite : PIXI.Sprite,
				intendedWidth = meterWidth * (key.includes('_text_') ? .75 : 1),
				extraArgs     = isRepeating ? [ 
					intendedWidth / scale,
					resources[key].texture.orig.height,
				] : [];

			// console.log("[buildMeter]", { key, isRepeating, extraArgs });

			const sprite = new spriteClass(resources[key].texture, ...extraArgs);
			sprite.scale = spriteScale;
			
			if(isRepeating)
				sprite._intendedWidth = intendedWidth;
			
				sprite.anchor.x = 0;
			sprite.anchor.y = 0;
			this.addChild(sprite);

			const norm = base.replace('meter_','').replace(/_$/, '');
			this.sprites[norm] = sprite;
		});

		// Setup icon
		if(icon) {
			const key = icon.includes('_') ? icon : 'meter_icon_' + icon;
			if(!resources[key]) {
				throw new Error("Asset not loaded - check code. Missing icon for meter, expected: " + key);
			}
			this.setIcon(resources[key], scale);
		}

		// helper
		const put = (sprite, x, y) => {
			sprite.x = x;
			sprite.y = y;
		}

		// Position components
		// move(this.sprites.text_background_center_repeating, 
		// this.sprites.text_background_left_edge
		// this.sprites.text_background_right_edge
		
		const unscaledBorderRadius = 100; // found by measuring graphic

		// Background of Text
		// put(this.sprites.text_background, 
		// 	this.sprites.bar_holder_center_repeating.x +
		// 	this.sprites.bar_holder_center_repeating._intendedWidth +
		// 	this.sprites.bar_holder_right_edge.width -
		// 	this.sprites.text_background.width * .75 - 1,
		// 	this.sprites.bar_holder_center_repeating.height * scale -  (unscaledBorderRadius * scale));

		put(this.sprites.text_background, 
			this.sprites.icon_holder.width * .5,
			this.sprites.bar_holder_center_repeating.height * scale -  (unscaledBorderRadius * scale));


		const bgScaleX =  (this.sprites.bar_holder_center_repeating._intendedWidth + this.sprites.bar_holder_right_edge.width) / this.sprites.text_background.texture.orig.width;
		this.sprites.text_background.scale.x = bgScaleX;
		// console.warn( { bgScaleX });

	
		// Text label itself
		const textLabel = new PIXI.Text("", textStyle),
			paddingX = unscaledBorderRadius * scale * .75,
			paddingY = textStyle.fontSize * .2;

		// textStyles starts with align right, so we also anchor Y to right edge of text label
		textLabel.anchor.x = 1;
		textLabel.anchor.y = 0;
		this.sprites.textLabel = textLabel;
		this.addChild(textLabel);

		put(textLabel,
			this.sprites.text_background.x + this.sprites.text_background.width - paddingX,
			this.sprites.text_background.y + (this.sprites.text_background.height - (unscaledBorderRadius * scale)) / 2);// + fontSize * .2);
		
		if(this.opts.indefinite) {
			put(textLabel,
				this.sprites.text_background.x + this.sprites.text_background.width - paddingX * 2,
				(this.sprites.text_background.height - (unscaledBorderRadius * scale)) / 2 - paddingY);

			// hide text background
			this.sprites.text_background.alpha = 0;
		}
		
		// Bar holder
		put(this.sprites.bar_holder_center_repeating,
			this.sprites.icon_holder.width * .5, // align with center of circle
			0);
		// left edge not used - hidden behind circle
		// this.sprites.bar_holder_left_edge
		put(this.sprites.bar_holder_right_edge,
			this.sprites.bar_holder_center_repeating.x +
			this.sprites.bar_holder_center_repeating._intendedWidth,
			0);
			
		// // Not needed
		// // this.sprites.icon_holder
		
		// Actual bar graph itself
		put(this.sprites.bar_center_repeating,
			this.sprites.icon_holder.width * .5, // align with center of circle
			0);
	
		put(this.sprites.bar_right_edge,
			this.sprites.icon_holder.width * .5, // align with center of circle
			0);

		if(this.opts.indefinite) {
			this.sprites.bar_center_repeating.alpha =
			this.sprites.bar_right_edge.alpha = 0;
		}
		
		// left edge not used - would be hidden behind circle 
		// this.sprites.bar_left_edge
		// this.sprites.bar_right_edge

		// Set meter position on screen
		this.x = position.x;
		this.y = position.y;

		window.ms = this.sprites;
		window.m = this;
	}
}
