import * as PIXI from 'pixi.js';
import Scene from './Scene';

import bg_img from '../../assets/simplebackgrounds/burst/burst_background_large_screen_blue.png';
// import bg_img from '../../assets/simplebackgrounds/bubbles/bubble_bg_large_screen_blue.png';
// import bg_img from '../../assets/simplebackgrounds/wave/wave_bg_large_screen_blue.png';

export default class BasicBurstScene extends Scene {
	init(args={}) {
		if(!args)
			args = {};

		return this.loadResources({ 
			bg_img,
			...args.otherResources
		}).then(resources => {
			const game = this.game;

			// Add background texture first
			const bgSprite = new PIXI.Sprite(resources.bg_img.texture);
			if(true) {
				bgSprite.anchor.x = 0.5;
				bgSprite.anchor.y = 0.5;

				bgSprite.x = window.innerWidth / 2;
				bgSprite.y = window.innerHeight / 2;

				this.addObject(bgSprite, game.gameContainer);

				game.app.ticker.add(this._tick = (time) => {
					if (bgSprite.transform)
						bgSprite.rotation += 0.001;
				});

				bgSprite._layout = () => {
					bgSprite.x = window.innerWidth / 2;
					bgSprite.y = window.innerHeight / 2;

					const scale = 
						Math.max(window.innerWidth, window.innerHeight) 
						/ Math.min(bgSprite.texture.orig.width, bgSprite.texture.orig.height)
						* 2; // ensure coverage of corners as we rotate

					bgSprite.scale = new PIXI.Point(scale, scale);
				};
				bgSprite._layout();
			}


			// Re-layout these things when window size changes
			const itemsNeedLayoutOnResize = [
				bgSprite,
			];

			window.addEventListener('resize', this._resizeHandler = () => {
				itemsNeedLayoutOnResize.forEach(item => item._layout());
			});

			return resources;
		})
	}
	destroy() {
		window.removeEventListener("resize", this._resizeHandler);
		this.game.app.ticker.remove(this._tick);
		super.destroy();
	}
}