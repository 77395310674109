// https://github.com/nolanlawson/pseudo-worker#usage
import 'pseudo-worker/polyfill';

import * as PIXI from 'pixi.js';

import EventEmitter from 'events';

// Web worker to wrap MatterSimulation in another thread
import MatterWorker from './workers/matter.worker';

import { ServerStore } from '../utils/ServerStore';
import { FpsAutoTuner } from '../utils/FpsAutoTuner';

import {
	MatterSimulation, 
	COLLISION_ELEMENT_SIZE, 
	UPDATE_ELEMENT_SIZE
} from './MatterSimulation';

// For rendering canvas overlay in this thread if not using WebWorker and debugRender
import * as Matter from 'matter-js'

// Update: Start off at 12 fps - the spot at which PIXI goes to 30fps
// We do this to handle low end devices so initial experience is good gameplay.
// - JB 20190430
const UPDATE_HANDLER_FPS = 12;
const LERP_LENGTH = 1000 / UPDATE_HANDLER_FPS;

const MATTER_SIM_REPLAY_ENABLE = false;

//https://github.com/mattdesl/lerp/blob/master/index.js
function lerp(v0, v1, t) {
    return v0*(1-t)+v1*t
}


export class MatterSimulationStub extends EventEmitter {
	
	/** 
	 * @property {boolean} debugRender
	 * @readonly
	 * If this is true, then the game is running in an altered state, likely with an overlay to show matter running.
	 * Set in `setupMatterEngine()`, once set, changes have no effect.
	 */
	// debugRender = true;

	_matterIdCounter = 0x0001;
	_internalBodyMap = [];
	_simulationSetupReplay = [];

	/**
	 * Send commands to the MatterSimulation (either in the Web Worker or main thread, auto-detects.)
	 * 
	 * @param {string} cmd 
	 * @param {object|boolean} [data={}] - Value of data depends on the command, but for almost anything affecting a body, the .id prop is required
	 * @returns If 'addBody', returns a local reference to a object pretends to be like a Matter body, and we will update this object with position/angle/velocity updates as MatterSimulation gives them to us. If not cmd==='addBody', nothing is returned.
	 * @memberof MatterSimulationStub
	 */
	postToMatter(cmd, data={}) {
		// // Make a copy
		// data = JSON.parse(JSON.stringify(data));
		// console.warn("[postToMatter]", cmd, data);

		// We auto-assign an id for addBody if it's a new body, because
		// MatterSimulation (and our stub) uses this id to know which body we're referring to in future updates
		if (cmd === 'addBody' && data) {
			if(!data.id) {
				data.id = (this._matterIdCounter ++);
				data._change = { changeCounter: 0, renderCounter: 0 }; // render usage

				if (MATTER_SIM_REPLAY_ENABLE)
					this._simulationSetupReplay.push({ cmd, data });

			} else {
				// If .id provided in data, then we assume they want to re-add a body previously removed with removeBody
				// instead of creating a new body. MatterSimulation will inspect ._existing, and if true, it will re-add
				// the body from it's internal cache, if it exists.
				data._existing = true;
			}

		} else
		// If our users are lazy and put a 'body' object in data,
		// auto-deref and replace it with the .id of the body.
		if(data.body && data.body.id) {
			data.id = data.body.id;
			delete data.body; // just remove from data packet
		}

		// If we have a ref to our Web Worker thread,
		// send the command to that thread, otherwise,
		// assume we have a local simulation running in this thread.
		if (this._matterWorker) {
			this._matterWorker.postMessage({ cmd, data });
		} else {
			this._matter.processCommand({ cmd, data });
		}

		if(cmd === 'addBody') {
			if(data._existing) {
				return this._internalBodyMap[data.id];
			} else {
				// Create a faux-Matter body and cache it internally
				// By applying .options with the spread operator,
				// we basically re-create what matter would have given us.
				// This has been good enough for our use anyway
				return this._internalBodyMap[data.id] = { 
					...data,
					...(data.options || {}),
				};
			}
		} else
		if(cmd === 'removeBody' || cmd === 'destroyBody') {
			// Intercept removeBody and destroyBody calls
			// to try to prevent memory leaks in our internal cache
			if(data.destroy     || cmd === 'destroyBody') {
				this._internalBodyMap[data.id] &&
					delete this._internalBodyMap[data.id];
			}

			// Push the counter-effect of addBody just so the world setup is correct if we switch
			if(MATTER_SIM_REPLAY_ENABLE) {
				this._simulationSetupReplay.push({ cmd, data });
			}
		}

		return null;
	}

	// For explosions/implosions/filtering
	matterBodyCache() {
		return this._internalBodyMap;
	}

	/**
	 * Initalizes the `matter-js` physics engine
	 * @private
	 * @memberof Game
	 */
	async setupMatterEngine() {
		// Data flows (rendering):
		// 1. MatterJs (WebWorker via message) - as fast as MatterJS runs (60fps in WebWorker) - TODO: Rate-limit messaging?
		// 2. onUpdate - Changed bodies are notified via onUpdate and return { x, y, angle } to render - FPS set via setInterval()
		// 3. onRender - Bodies are rendered via LERP from previous { x, y, angle } to new { x, y, angle} - FPS set via requestAnimationFrame

		this.fpsAutoTuner = new FpsAutoTuner({
			tuningInterval: 3000,
			debug:     false,
			debugTag:  "Main UI Thread",
			fpsTarget: UPDATE_HANDLER_FPS,
			callback:  fps => this.setFpsTarget(fps)
		});

		// this._renderFrame = requestAnimationFrame(this._renderFromUpdateHandlers);
		// this.app.ticker.add(this._renderFromUpdateHandlers); // hook into existing PIXI ticker instead of our own requestAnimationFrame
		
		// setInterval(this._renderFromUpdateHandlers, 1000 / (UPDATE_HANDLER_FPS * (UPDATE_HANDLER_FPS < 15 ? 2 : 1)));

		const USE_WORKER = ('Worker' in window);
		if(!this.debugRender && USE_WORKER) {
			// Try to boot MatterSimulation inside a Web Worker
			// 'await' this function because we don't want the game to boot until 
			// we know if the worker actually boots, or if we fall-back to the 
			// in-thread simulation instead. This works because our caller
			// will 'await' our async promise and not call the first setScene
			// until we return.
			await this._setupMatterWorker();
		} else {
			// Use MatterSimulation directly in this thread
			this._setupMatterFallback();
		}
	}

	setFpsTarget(fps) {
		// console.log("[render loop] fps target set to:", fps, "fps");
		this.fpsTarget = fps;
		this._updateTid && clearInterval(this._updateTid);
		this._updateTid  = setInterval(this._callUpdateHandlers, 1000 / fps);

		if(fps < 13) {
			// https://github.com/pixijs/pixi.js/wiki/v4-Gotchas#pixiticker
			PIXI.settings.TARGET_FPMS = 30 / 1000;
		}
		// else
		// if(fps < 24) {
		// 	PIXI.settings.TARGET_FPMS = 30 / 1000;
		// } else {
		// 	PIXI.settings.TARGET_FPMS = 60  / 1000;
		// }
	}

	async _setupMatterWorker() {
		// If existing sim in this thread, destroy since we're setting up a web worker
		if (this._matter) {
			this._matter.destroy();
			this._matter = null;
		}

		try {
			// Store metric
			// ServerStore.metric("matter.simulation.web_worker.attempted");

			// console.log("Attempting to construct MatterWorker in dedicated thread via class:", MatterWorker);
			this._matterWorker = new MatterWorker();

			// console.log("Constructed MatterWorker in new thread: ", this._matterWorker,", attaching listeners...");

			// Just because MatterWorker() didn't throw an error doesn't mean it actually worked.
			// Right now, cordova doesn't seem to support web workers, but it doesn't give ANY errors,
			// ('Worker' in window is true), so the only way we know the web worker DIDN'T actually start
			// is by waiting to see if we get a message from the worker. If no message in Xms from the worker,
			// then we assume it never started and we will create a MatterSimulation in this thread.
			let resolveSetupPromise, 
				// Use this promise as a semaphore to indicate success or failure.
				// We use a promise because we can 'await' it at the end of this try{} block to check for success/failure.
				setupPromise = new Promise(resolve => resolveSetupPromise = resolve),
				// Use this timer to ensure the promise DOES resolve, even if the worker doesn't boot.
				// If the worker boots before the timer expires, then we'll clear the timeout and 
				// resolve the promise with true instead of false.
				deadmanTimeout = setTimeout(() => {
					resolveSetupPromise(false);
				}, 1500 * 1.5); // typically, comes online in 150-500ms if it's going to actually work
			
			// const startedWaitingAt = Date.now();

			this._matterWorker.addEventListener('message', this._matterWorker._msgRef = event => {
				if(deadmanTimeout) {
					// console.log("MatterWorker is online, clearing deadman timer");

					// Deadman timer still running, so clear the timeout because we'll indicate
					// the worker is alive here by resolving the promise ourselves with a true value
					clearTimeout(deadmanTimeout);
					deadmanTimeout = null;

					// console.log("Info: Matter Web Worker came online in ", (Date.now() - startedWaitingAt), "ms");

					// Store metric
					ServerStore.metric("matter.simulation.web_worker.booted");

					// Just for debugging
					this.simulationThreadMode = 'webWorker';

					// Indicate the web worker came online by resolving true, which 'await'
					// will catch at the end of the try{} block and let the game finish booting
					resolveSetupPromise(true);

					// This message listener will stop the deadman timer, no matter what the message.
					// But if this was just the _booted message from the Worker, we don't need to process
					// it further. However, any other messages will get passed along to the callback, below.
					if(event.data._booted)
						return;
				}
					
				this._matterChangeCallback(event.data);
				
			});

			this._matterWorker.addEventListener('error', this._matterWorker._errorRef = event => {
				console.warn("Error from inside MatterWorker:", event, " - can't do anything about it though.");

				// console.error("Error from inside MatterWorker:", event,", loading fallback and reloading scene");
				
				// // Destroy because we're reloading anyway and ignore any errors
				// try {
				// 	// Destroy scene BEFORE setting up new MatterSimulation in this thread
				// 	// because otherwise the destroy that happens in setScene() will instead be directed
				// 	// to the new simulation instance and cause body-not-found errors on removeBody commands
				// 	// to the new simulation. So, we destroy first so there is nothing to destroy when setScene() is called
				// 	this._destroyCurrentScene();
				// } catch(e) {
				// 	console.warn("Error while destroying scene:", e);
				// }

				// // Setup the MatterSimulation in this thread
				// this._setupMatterFallback();

				// // Reboot current scene
				// // console.error("Reloading currentScene:", this._currentSceneName);
				// this.setScene(this._currentSceneName, this._currentSceneOpts);

			});

			// Wait for _booted message or timer to throw error (which will be caught below)
			const result = await setupPromise;

			// The setTimeout will resolve the promise with 'false',
			// and only a message from the web worker will resolve it with 'true'
			if(!result) {
				// Throw the error here because nothing else has started yet (setScene() wont proceed
				// until we exit this function - it awaits our promise) so by throwing the error here,
				// the next catch() will just setup the MatterSimulation in this thread anyway,
				// we don't have to do the destroy-setup-setScene we do for 'errors' above
				throw new Error("Timed out waiting for MatterWorker to come online")
			}

		} catch(e) {
			// MatterSimulation-in-Worker failed, so create a MatterSimulation in this thread instead
			console.error("Error constructing MatterWorker:", e);
			this._setupMatterFallback();
		}
	}

	_setupMatterFallback() {
		if (this._matterWorker) {
			// console.log("_setupMatterFallback: Destroying MatterWorker");
			this._matterWorker.terminate();
			this._matterWorker = null;
		}


		this._matter = new MatterSimulation();

		// Notify metric server
		ServerStore.metric("matter.simulation.main_thread.booted");

		// just for debugging
		this.simulationThreadMode = 'uiThread';

		if (MATTER_SIM_REPLAY_ENABLE &&
			this._simulationSetupReplay.length > 0) {
			console.log("_setupMatterFallback: Previous setup found, replaying:", this._simulationSetupReplay);
			this._simulationSetupReplay.forEach(packet => this._matter.processCommand(packet));
			this._simulationSetupReplay = [];

			// TODO: Replay latest position/velocity/angle...?
		}

		console.warn("_setupMatterFallback: Running simulation in UI thread:", this._matter);

		this._matter.matterChangeCallback = this._matterChangeCallback; 
		
		const engine = this._matter._engine;

		if(this.debugRender) {
					
			const canvas = document.createElement('canvas');
			// canvas.style.display = 'none';
			// $(canvas).style({
			Object.assign(canvas.style, {
				position: 'fixed',
				left: 0,
				right: 0,
				top: 0,
				bottom: 0,
				zIndex: 999,
				opacity: 0.75,
				pointerEvents: 'none',
			});
			document.body.appendChild(canvas);

			
			setTimeout( () => {

				// var canvas = document.createElement('canvas'),
				const context = canvas.getContext('2d');

				canvas.width = window.innerWidth;
				canvas.height = window.innerHeight;

				// document.body.appendChild(canvas);

				(function render() {
					var bodies = Matter.Composite.allBodies(engine.world);

					window.requestAnimationFrame(render);

					// context.fillStyle = 'rgba(0,0,0,255)';
					// context.fillRect(0, 0, canvas.width, canvas.height);
					context.clearRect(0, 0, canvas.width, canvas.height);

					context.beginPath();

					for (var i = 0; i < bodies.length; i += 1) {
						var vertices = bodies[i].vertices;

						context.moveTo(vertices[0].x, vertices[0].y);

						for (var j = 1; j < vertices.length; j += 1) {
							context.lineTo(vertices[j].x, vertices[j].y);
						}

						context.lineTo(vertices[0].x, vertices[0].y);
					}

					context.lineWidth = 3;
					context.strokeStyle = '#f00';
					context.stroke();
				})();
			}, 500);
		}
	}

	_matterChangeCallback = ({ msg, data }) => {
		if(msg === 'afterUpdate') {
			// if(!this._upCount && data.length) {
			// 	console.warn("[afterUpdate:count]", data);
			// 	this._upCount = true;
			// }
			for(let i=0; i<data.length; i+= UPDATE_ELEMENT_SIZE) {
				const [ id, x, y, vx, vy, angle ] = data.slice(i, i + UPDATE_ELEMENT_SIZE);
				const body = this._internalBodyMap[id];
				if(body) {
					body.x = x;
					body.y = y;
					body.vx = vx;
					body.vy = vy;
					body.angle = angle;
					// if (body.onUpdate)
					// 	body.onUpdate();
					// For use in FPS limiting the render
					body._change.changeCounter ++;
				};
				
			}
			// for(let i=0; i<data.length; i+=UPDATE_ELEMENT_SIZE) {
			// 	const id = data[i], body = this._internalBodyMap[id];
					
			// 	if(body) {
			// 		let x  = i;
			// 		body.x     = data[++x];
			// 		body.y     = data[++x];
			// 		body.vx    = data[++x];
			// 		body.vy    = data[++x];
			// 		body.angle = data[++x];
			// 		// console.log("[debug.update]", { id, d: data.slice(i*UPDATE_ELEMENT_SIZE, x), body, i, x, UPDATE_ELEMENT_SIZE })
			// 	} else {
			// 		if(id !== undefined) {
			// 			console.warn("Invalid body found in update:", id, this._internalBodyMap);
			// 		}
			// 	}
				
			// }
			// this.emit('matterUpdate');
		} else
		if(msg === 'collisionStart') {
			if(!data)
				return;
			// if(!this._colCount && data.length) {
			// 	console.warn("[collisionStart:count]", data);
			// 	this._colCount = true;
			// }
			const list = [];
			for(let i=0; i<data.length; i+=COLLISION_ELEMENT_SIZE) {
				const idA = data[i],
					idB = data[i+1],
					bodies = { 
						bodyA: this._internalBodyMap[idA],
						bodyB: this._internalBodyMap[idB],
					};

				if (bodies.bodyA &&
					bodies.bodyB) {
					
					list.push(bodies);

					if (bodies.bodyA.onCollide)
						bodies.bodyA.onCollide(bodies.bodyB);

					if (bodies.bodyB.onCollide)
						bodies.bodyB.onCollide(bodies.bodyA);
				} else {
					// !bodies.bodyA && console.warn("[Game.collisionStart] bodyA not found:", idA)
					// !bodies.bodyB && console.warn("[Game.collisionStart] bodyB not found:", idB)
				}
			}

			this.emit('matterCollision', list);
		} else {
			console.warn("matterChangeCallback: Unknown msg:", { msg, data });
		}
	}

	_callUpdateHandlers = () => {
		// this.counters.render ++ ;
		this.fpsAutoTuner && this.fpsAutoTuner.countFrame();

		if (this.stats)
			this.stats.begin();

		for(let i=0; i < this._internalBodyMap.length; i++) {
			const body = this._internalBodyMap[i], c = (body || {})._change;
			if(!body)
				continue;

			if (c.changeCounter !== c.lastUpdateCall) {
				c.lastUpdateCall = c.changeCounter;
				if(typeof(body.onUpdate) !== 'function') {
					// console.warn("body.onUpdate not a function:", i, body);
					continue;
				}

				c.lastUpdate = c.nextUpdate;
				c.nextUpdate = body.onUpdate();
				if (c.lastUpdate) {
					c.renderCounter ++;
					c.lerpStart = Date.now();
				}
			} else {
				c.lastRenderCounterCompleted = c.renderCounter;
			}
		}

		if (this.stats)
			this.stats.end();
	}

	_renderFromUpdateHandlers = () => {
		for(let i=0; i < this._internalBodyMap.length; i++) {
			const body = this._internalBodyMap[i], c = (body || {})._change;
			if(!body)
				continue;

			if (c.renderCounter !== c.lastRenderCounterCompleted) {
				const lerpProgress = Date.now() - c.lerpStart;
				const lerpTime = Math.max(0,Math.min(1,lerpProgress / LERP_LENGTH));

				// if(body.label === '[KittyActor]') {
				// 	console.log(c.renderCounter, lerpTime)
				// }

				if(c.lastUpdate && c.nextUpdate) {
					const x     = lerp(c.lastUpdate.x,     c.nextUpdate.x,     lerpTime);
					const y     = lerp(c.lastUpdate.y,     c.nextUpdate.y,     lerpTime);
					const angle = lerp(c.lastUpdate.angle, c.nextUpdate.angle, lerpTime);
					body.onRender && body.onRender(x, y, angle);
				}
			}
		}
	}
}