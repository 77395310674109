import React from 'react';
import * as PIXI from 'pixi.js';
import TWEEN from '@tweenjs/tween.js';
import decomp from 'poly-decomp'; // for matter, import so it's included in bundle because matter-js only includes it at runtime

import {DropShadowFilter} from '@pixi/filter-drop-shadow';

import Hammer from 'hammerjs';

import LevelEndOverlay from './KittyFlySleep/LevelEndOverlay';
import ShopPopup from './KittyFlySleep/ShopPopup';
// import JoystickOverlay from './KittyFlySleep/JoystickOverlay';

// import nipplejs from 'nipplejs';

import BasicKittyScene from './BasicKittyScene';
import { MAX_HEALTH, EDIT_MOVE_EVENT, TutorialKeys, KITTY_ITEMS } from '../KittyActor';

import { ServerStore, gtag, APP_PAUSED_EVENT, APP_RESUMED_EVENT } from 'utils/ServerStore';
import { LoaderUtil } from 'utils/LoaderUtil';
import { mobileDetect } from 'utils/mobile-detect';
// import { Cat, Level } from 'utils/models';

// import map1 from 'assets/maps/map1.txt';
// import map2 from 'assets/maps/map2.txt';
// import map_gauntlet from 'assets/maps/map-gauntlet.txt';
// import map_test from 'assets/maps/map-test.txt';
// import { LevelMapList } from 'assets/maps/index';

import { randomAffirmation, randomEncouragement } from 'utils/randomAffirmation';
import AssetLoader from 'utils/AssetLoader';
import { PixiUtils } from 'utils/PixiUtils';
import { BreakableBlock } from 'utils/BreakableBlock';
import { BasicMeter } from 'utils/BasicMeter';
import { DefaultTextStyles as TextStyles } from 'utils/TextStyles';
import RandomMap from 'utils/RandomMap';
import { buttonHaptic } from 'utils/ButtonHaptic';
import SoundManager from 'utils/SoundManager';
import { numberWithCommas } from 'utils/numberWithCommas';
import levelFeatureAnalysis from 'utils/levelFeatureAnalysis';
import { AdMobUtil } from 'utils/AdMob';

// import pbg_sun from 'assets/parallaxingskybg/parallax_parts/sun.png';
// import tile_silver from 'assets/gametiles/tiles/silver/silver_thick_border.png';
import tile_ice from 'assets/icetiles/pngs/4.png';

// import tile_silver_corner_t from 'assets/tile-silver-corner-br.png';
// import tile_silver_corner_r from 'assets/tile-silver-corner-bl.png';
// import tile_silver_corner_b from 'assets/tile-silver-corner-tl.png';
// import tile_silver_corner_l from 'assets/tile-silver-corner-tr.png';

import tile_glass25 from 'assets/breaksquareglass/block/25_PERCENT.png';
import tile_glass50 from 'assets/breaksquareglass/block/50_PERCENT.png';
import tile_glass75 from 'assets/breaksquareglass/block/75_PERCENT.png';
import tile_glass100 from 'assets/breaksquareglass/block/100_PERCENT.png';

// import anim_glassbreak_sheet from 'assets/breaksquareglass/break-anim.json';
import anim_glassbreak_img from 'assets/breaksquareglass/break-anim.png';

// import anim_smoke_sheet from 'assets/smokepuff/compiled/white.json';
import anim_smoke_img from 'assets/smokepuff/compiled/white.png';

import anim_swipe_right_sheet from 'assets/gestureanims/swipe_right_finger_only/compiled/swipe_right_finger_only.json';
import anim_swipe_right_img   from 'assets/gestureanims/swipe_right_finger_only/compiled/swipe_right_finger_only.png';


import meter_bar_center_repeating_blue from 'assets/bars/blue/meter_bar_center-repeating_blue.png';
import meter_bar_holder_center_repeating_blue from 'assets/bars/blue/meter_bar_holder_center-repeating_blue.png';
import meter_bar_holder_right_edge_blue from 'assets/bars/blue/meter_bar_holder_right_edge_blue.png';
import meter_bar_right_edge_blue from 'assets/bars/blue/meter_bar_right_edge_blue.png';
import meter_icon_holder_blue from 'assets/bars/blue/meter_icon_holder_blue.png';
import meter_text_background_blue from 'assets/bars/blue/meter_text_background_blue.png';

// import meter_bar_center_repeating_green from 'assets/bars/green/meter_bar_center-repeating_green.png';
// import meter_bar_holder_center_repeating_green from 'assets/bars/green/meter_bar_holder_center-repeating_green.png';
// import meter_bar_holder_right_edge_green from 'assets/bars/green/meter_bar_holder_right_edge_green.png';
// import meter_bar_right_edge_green from 'assets/bars/green/meter_bar_right_edge_green.png';
// import meter_icon_holder_green from 'assets/bars/green/meter_icon_holder_green.png';
// import meter_text_background_green from 'assets/bars/green/meter_text_background_green.png';

import meter_bar_center_repeating_red from 'assets/bars/red/meter_bar_center-repeating_red.png';
import meter_bar_holder_center_repeating_red from 'assets/bars/red/meter_bar_holder_center-repeating_red.png';
import meter_bar_holder_right_edge_red from 'assets/bars/red/meter_bar_holder_right_edge_red.png';
import meter_bar_right_edge_red from 'assets/bars/red/meter_bar_right_edge_red.png';
import meter_icon_holder_red from 'assets/bars/red/meter_icon_holder_red.png';
import meter_text_background_red from 'assets/bars/red/meter_text_background_red.png';

import meter_bar_center_repeating_yellow from 'assets/bars/yellow/meter_bar_center-repeating_yellow.png';
import meter_bar_holder_center_repeating_yellow from 'assets/bars/yellow/meter_bar_holder_center-repeating_yellow.png';
import meter_bar_holder_right_edge_yellow from 'assets/bars/yellow/meter_bar_holder_right_edge_yellow.png';
import meter_bar_right_edge_yellow from 'assets/bars/yellow/meter_bar_right_edge_yellow.png';
import meter_icon_holder_yellow from 'assets/bars/yellow/meter_icon_holder_yellow.png';
import meter_text_background_yellow from 'assets/bars/yellow/meter_text_background_yellow.png';

import meter_bar_center_repeating_grayscale from 'assets/bars/grayscale/meter_bar_center-repeating_yellow.png';
import meter_bar_holder_center_repeating_grayscale from 'assets/bars/grayscale/meter_bar_holder_center-repeating_yellow.png';
import meter_bar_holder_right_edge_grayscale from 'assets/bars/grayscale/meter_bar_holder_right_edge_yellow.png';
import meter_bar_right_edge_grayscale from 'assets/bars/grayscale/meter_bar_right_edge_yellow.png';
import meter_icon_holder_grayscale from 'assets/bars/grayscale/meter_icon_holder_yellow.png';
import meter_text_background_grayscale from 'assets/bars/grayscale/meter_text_background_yellow.png';

import meter_bar_center_repeating_pink from 'assets/bars/pink/meter_bar_center-repeating_pink.png';
import meter_bar_holder_center_repeating_pink from 'assets/bars/pink/meter_bar_holder_center-repeating_pink.png';
import meter_bar_holder_right_edge_pink from 'assets/bars/pink/meter_bar_holder_right_edge_pink.png';
import meter_bar_right_edge_pink from 'assets/bars/pink/meter_bar_right_edge_pink.png';
import meter_icon_holder_pink from 'assets/bars/pink/meter_icon_holder_pink.png';
import meter_text_background_pink from 'assets/bars/pink/meter_text_background_pink.png';

import meter_icon_health from  'assets/bars/icons/health.png';
import meter_icon_magic from  'assets/bars/icons/magic.png';
import meter_icon_power from  'assets/bars/icons/power.png';
import meter_icon_shield from  'assets/bars/icons/shield.png';
import meter_icon_stamina from  'assets/bars/icons/stamina.png';
import meter_icon_timer from  'assets/bars/icons/timer.png';
import meter_icon_xp from  'assets/bars/icons/xp.png';

// for spawnBullet
import weapon_projectile from 'assets/spaceprojectiles/pngs_with_blur/yellow/short-ray.png';

import tile_blue      from 'assets/gametiles/tiles/blue/blue_thick_border.png';
import tile_cream     from 'assets/gametiles/tiles/cream/cream_thick_border.png';
import tile_gold      from 'assets/gametiles/tiles/gold/gold_thick_border.png';
import tile_green     from 'assets/gametiles/tiles/green/green_thick_border.png';
import tile_orange    from 'assets/gametiles/tiles/orange/orange_thick_border.png';
import tile_pink      from 'assets/gametiles/tiles/pink/pink_thick_border.png';
import tile_purple    from 'assets/gametiles/tiles/purple/purple_thick_border.png';
import tile_red       from 'assets/gametiles/tiles/red/red_thick_border.png';
import tile_silver    from 'assets/gametiles/tiles/silver/silver_thick_border.png';
import tile_yellow    from 'assets/gametiles/tiles/yellow/yellow_thick_border.png';

// import cupcake_blue    from 'assets/cupcakes/cold-blue.png';
// import cupcake_yellow  from 'assets/cupcakes/blue-yellow.png';
// import cupcake_cyan    from 'assets/cupcakes/dark.png';
// import cupcake_green   from 'assets/cupcakes/green-orange.png';

// import door_block   from 'assets/door-block.png';


import icon_star   from 'assets/uigameicon/face_on_star.png';
import icon_health from 'assets/uigameicon/face_on_plus_health.png';
import icon_cross  from 'assets/uigameicon/face_on_cross.png';
import icon_power  from 'assets/uigameicon/face_on_blue_power_icon.png';
import icon_check  from 'assets/uigameicon/face_on_green_tick.png';
import sparkle_static_img from 'assets/extralife/sparkle_static.png';

// for: addButtons
// import small_pink_button   from 'assets/controls/colored_buttons/small_pink_button.png';
// import small_yellow_button from 'assets/controls/colored_buttons/small_yellow_button.png';
// import small_blue_button   from 'assets/controls/colored_buttons/small_blue_button.png';
// import small_green_button  from 'assets/controls/colored_buttons/small_green_button.png';
import small_red_button  from 'assets/controls/colored_buttons/small_red_button.png';

import slider_bottom  from 'assets/controls/no_transparency/sliders/end_bottom.png';
import slider_top     from 'assets/controls/no_transparency/sliders/end_top.png';
// import slider_left    from 'assets/controls/no_transparency/sliders/end_left.png';
// import slider_right   from 'assets/controls/no_transparency/sliders/end_right.png';

import d_pad_1 from 'assets/controls/no_transparency/d_pad_1.png';
import x_button from 'assets/controls/no_transparency/x_button.png';
import triangle_button_left from 'assets/controls/no_transparency/triangle_button-left.png';
import blank_button from 'assets/controls/no_transparency/blank_button.png';
import square_button from 'assets/controls/no_transparency/square_button.png';
import icon_white_list from 'assets/gameicons/icons_128/list.png';
import icon_white_star from 'assets/gameicons/icons_128/thin_star.png';

import pill_red     from 'assets/powerpill/red_pill.png';
import pill_blue    from 'assets/powerpill/blue_pill.png';
import pill_pink    from 'assets/powerpill/pink_pill.png';
import pill_yellow  from 'assets/powerpill/yellow_pill.png';

import power_scale  from 'assets/powerpill/purple_yellow_spots_pill.png';
import power_gravity_float  from 'assets/powerpill/pink_yellow_spots_pill.png';
import power_speed  from 'assets/powerpill/pink_green_pill.png';
import power_grenade from 'assets/projectiles/pngs/grenade/grenade_icon.png';

import power_bomb   from 'assets/projectiles/pngs/bomb/bomb_projectile.png';
import power_shield from 'assets/shield/keyframes/all-blue/100-percent-shield-effect-all-blue.png';

import anim_firework3white_sheet     from 'assets//firework3/yellow/firework3-yellow.json';
import anim_firework3white_img       from 'assets//firework3/yellow/firework3-yellow.png';

// for: addParallaxBg()
import pbg_fcloud1  from 'assets/parallaxingskybg/parallax_parts/farground_cloud_1.png';
import pbg_fcloud2  from 'assets/parallaxingskybg/parallax_parts/farground_cloud_2.png';
import pbg_mcloud1  from 'assets/parallaxingskybg/parallax_parts/mid_ground_cloud_1.png';
import pbg_mcloud2  from 'assets/parallaxingskybg/parallax_parts/mid_ground_cloud_2.png';
// import pbg_sky_color from 'assets/parallaxingskybg/parallax_parts/sky_color.png';
// import pbg_sky_color_top from 'assets/parallaxingskybg/parallax_parts/sky_color_top.png';
// import pbg_sun from 'assets/parallaxingskybg/parallax_parts/sun.png';

import bg_img_burst_blue from 'assets/simplebackgrounds/burst/burst_background_large_screen_blue.png';
import bg_img_bubble_blue from 'assets/simplebackgrounds/bubbles/bubble_bg_large_screen_blue.png';
import bg_img_wave_blue from 'assets/simplebackgrounds/wave/wave_bg_large_screen_blue.png';

// import bg_img_burst_green from 'assets/simplebackgrounds/burst/burst_background_large_screen_green.png';
// import bg_img_bubble_green from 'assets/simplebackgrounds/bubbles/bubble_bg_large_screen_green.png';
// import bg_img_wave_green from 'assets/simplebackgrounds/wave/wave_bg_large_screen_green.png';

import bg_img_burst_orange from 'assets/simplebackgrounds/burst/burst_background_large_screen_orange.png';
import bg_img_bubble_orange from 'assets/simplebackgrounds/bubbles/bubble_bg_large_screen_orange.png';
import bg_img_wave_blue2 from 'assets/simplebackgrounds/wave/wave_bg_large_screen_blue_2.png';

import bg_img_burst_purple from 'assets/simplebackgrounds/burst/burst_background_large_screen_purple.png';
import bg_img_bubble_purple from 'assets/simplebackgrounds/bubbles/bubble_bg_large_screen_purple.png';
import bg_img_wave_pink from 'assets/simplebackgrounds/wave/wave_bg_large_screen_pink.png';

import bg_img_burst_red from 'assets/simplebackgrounds/burst/burst_background_large_screen_red.png';
import bg_img_bubble_red from 'assets/simplebackgrounds/bubbles/bubble_bg_large_screen_red.png';
import bg_img_wave_red from 'assets/simplebackgrounds/wave/wave_bg_large_screen_red.png';

// import bg_img_burst_yellow from 'assets/simplebackgrounds/burst/burst_background_large_screen_yellow.png';
// import bg_img_bubble_yellow from 'assets/simplebackgrounds/bubbles/bubble_bg_large_screen_yellow.png';
// import bg_img_wave_yellow from 'assets/simplebackgrounds/wave/wave_bg_large_screen_yellow.png';


import bg_img_ice from 'assets/icebg/BACKGROUND1.png';


import pbg_mtn_bg   from 'assets/parallaxingskybg/parallax_parts/mountains/farground_mountains.png';
import pbg_mtn_fg   from 'assets/parallaxingskybg/parallax_parts/mountains/foreground_mountains.png';
import pbg_mtn_mid  from 'assets/parallaxingskybg/parallax_parts/mountains/midground_mountains.png';

import pbg_hill_bg  from 'assets/parallaxingskybg/parallax_parts/mountain_with_hills/farground_mountains.png';
import pbg_hill_fg  from 'assets/parallaxingskybg/parallax_parts/mountain_with_hills/foreground_mountains.png';
import pbg_hill_mid from 'assets/parallaxingskybg/parallax_parts/mountain_with_hills/midground_mountains.png';


// import water_bg_left   from 'assets/waterbg/pngs/left-side.png';
import water_bg_center from 'assets/waterbg/pngs/middle.png';
// import water_bg_right  from 'assets/waterbg/pngs/right-side.png';

import stars_bg        from 'assets/space/Background-4.png';


// import ball_red from 'assets/faceballs/ball-red.png';
// import ball_blue from 'assets/faceballs/ball-blue.png';
// import ball_green from 'assets/faceballs/ball-green.png';
// import ball_yellow from 'assets/faceballs/ball-yellow.png';
import ball_grey from 'assets/faceballs/ball-grey.png';
import ball_eyes from 'assets/faceballs/black/expresionless.png';

import bubble_rect  from 'assets/speechbubbles/greyscale/rect.png';
import bubble_think from 'assets/speechbubbles/greyscale/think-bubble.png';
import bubble_tag   from 'assets/speechbubbles/greyscale/rect-tag-above.png';

import flag_triangle_img   from 'assets/flag/compiled/flag_triangle.png';
import flagpole_gold       from 'assets/flag/compiled/flagpole-gold.png';

import { PixiMatterContainer } from 'utils/PixiMatterContainer';

// import { WELCOME_SCREEN_BYPASS } from './WelcomeScene';
import WelcomeScene from './WelcomeScene/WelcomeScene';

import { angle, toRadians, rotate } from 'utils/geom';

import {setStatusBarColor} from 'utils/MobileStatusBarColor';

import { HideOverActorViewportPlugin } from 'utils/HideOverActorViewportPlugin';

import { BubbleText } from 'utils/BubbleText';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { MarketUtils } from 'utils/MarketUtils';

//https://github.com/liabru/matter-js/issues/339
window.decomp = decomp;

const LEVEL_NUM_LABEL = "Level #";

const DEFAULT_FLOOR_HEIGHT = RandomMap.DEFAULT_FLOOR_HEIGHT;
const DEFAULT_CEILING_INSET = RandomMap.DEFAULT_CEILING_INSET;

// const DPAD_ALPHA = 0.625;
const FIRE_BTN_ALPHA = 0.70;

const SPACE_VELOCITY_CONSTANT = Math.PI;

const MAX_PLAYING_CHANGE_SECONDS = 30;

const TileColorURLs = {
	tile_blue,
	tile_cream,
	tile_gold,
	tile_green,
	tile_orange,
	tile_pink,
	tile_purple,
	tile_red,
	tile_silver,
	tile_yellow
};


const vibrate = (...args) => {
	if(navigator.vibrate)
		navigator.vibrate(...args);
}

//   loader.onProgress.add(() => {});

let lastIdxOfThingKittySaid = 0;

let SessionTotalTimeStatReset = false;

export class ShowOnePopupHelper {
	
	static setPopup(popup) {
		this.popup = popup;
	}

	static async tutorialPopup(showOnceId, text, opts={}) {
		if (await this.show(text, showOnceId, 8000, opts))
			ServerStore.metric("game.level.tutorial.saw." + showOnceId);
	}

	static async show(text, showOnceId=null, timer=7500, opts={}) {
		if (showOnceId &&
			this.hasSeen(showOnceId)) {
			return false;
		}

		if (this.popup)
			await this.popup.show(null, { text, textTimer: timer || 7500, ...opts })

		if (showOnceId)
			this.setSeen(showOnceId);

		return true;
	}

	static _seenHash() {
		try {
			return JSON.parse(window.localStorage.getItem('kitty-bubbleText') || '{}') || {};
		} catch(e) {
			return {};
		}
	}

	static hasSeen(id) {
		return this._seenHash()[id];
	}

	static setSeen(id, flag=true) {
		const hash = this._seenHash();
		if(flag) {
			hash[id] = true;
		} else {
			delete hash[id];
		}
		window.localStorage.setItem('kitty-bubbleText', JSON.stringify(hash));
	}
}

export default class KittyFlySleep extends BasicKittyScene {
	init(externalOptions={ levelId: null }) {
		if(!externalOptions)
			externalOptions = {};

		if(this.game.pushService.enableBackgroundClickAcceptance()) {
			console.log("[KittyFlySleep] not booting because pending background alert click", { externalOptions });
			return;
		}

		LoaderUtil.loaded();
			
		SoundManager.use(SoundManager.START1).setVolumeModifier(0.2);
		SoundManager.use(SoundManager.START2).setVolumeModifier(0.2);
		
		// For simple console access
		window.scene = this;

		const levelId = externalOptions.levelId || ServerStore.currentCat.level.id;
		this.currentLevelId = levelId;

		// This just patches the cat for THIS SESSION (of the app)
		// Actual changing of the level will be done on the server.
		// This is mainly here so that if they reply the tutorial, they can resume the replay
		// if (ServerStore.currentCat.level.id !== levelId)
		// 	ServerStore.currentCat.level = { id: levelId };
		
		let loadingScreen = {
			text: null,
			ball: null,
			binding: null,
			lastTime: null
		};
			
		this.loadingScreen = loadingScreen;
		this.externalOptions = externalOptions;

		const setupLoadingScreen = () => {
			// SoundManager.play(SoundManager.START1);

			const welcomeText = new PIXI.Text(
				`Loading Level...`,
				{
					...TextStyles,
					fontSize: 32, 
					align: "center", 
					wordWrap: true, 
					wordWrapWidth: window.innerWidth * 0.85
				}
			);
			welcomeText.anchor.x = 0.5;
			welcomeText.anchor.y = 0.5;
			welcomeText.x = window.innerWidth / 2;
			welcomeText.y = window.innerHeight / 2;

			this.addObject(welcomeText, this.game.gameContainer);
			loadingScreen.text = welcomeText;

			const sprite = new PIXI.Sprite.from(ball_eyes);
			sprite.anchor.y = 0.5;
			sprite.anchor.x = 0.5;
			sprite.x = window.innerWidth / 2;
			sprite.y = window.innerHeight * 0.8;
			sprite.scale = new PIXI.Point(0.25, 0.25);
			this.addObject(sprite, this.game.gameContainer);
			loadingScreen.ball = sprite;

			sprite._speed = Math.random() * 10;

			this.game.app.ticker.add(this._loadingBallTicker = (time) => {
				if (!sprite.transform)
					return;

				sprite.rotation += 0.25;

				sprite.x += sprite._speed;
				if(sprite.x < 0 || sprite.x > window.innerWidth) {
					sprite._speed *= -1;
				}
			});
		};

		setupLoadingScreen();
		AssetLoader.on('progress', loadingScreen.binding = data => {
			const thingsToSay = [
				'Kitty is napping magically...',
				'Kitty just woke up and is pouting...',
				'Kitty is drinking coffee suspiciously..',
				'Kitty just swiped at the screen...',
				'Kitty is romping in magic fairy dust..',
				'Kitty is chasing unicorns...',
				'Kitty is grumpy, sleeping more...',
				'Kitty just knocked over a lamp...',
				'Kitty wants outside...',
			];

			if (lastIdxOfThingKittySaid === 0)
				lastIdxOfThingKittySaid = Math.floor(Math.random() * thingsToSay.length);

			const now = Date.now(), time = 2000;
			if(!loadingScreen.lastTime || now - loadingScreen.lastTime > time) {
				lastIdxOfThingKittySaid ++;
				loadingScreen.lastTime = now;

				if (lastIdxOfThingKittySaid >= thingsToSay.length)
					lastIdxOfThingKittySaid = 0;
			}

			loadingScreen.text.text = `Loading ${this.currentLevelName} (${Math.ceil(data.progress)}%)\n\n${thingsToSay[lastIdxOfThingKittySaid]}`;
		});

		// this.game.gameContainer.alpha = 1;
		// PixiUtils.fadeIn(loadingScreen.container);

		setTimeout(() => this.loadLevelData(), 1);
	}

	async loadLevelData() {
		const { level, levelState, worldState } = await ServerStore.getLevelState(this.currentLevelId);
		this.level      = level;
		this.level.featureData = levelFeatureAnalysis(level);
		this.levelState = levelState;
		this.worldState = worldState;
		this.levelStateUpdates = {};
		this.currentLevelName = level.world && level.world.name ? 
			`${level.world.name} # ${level.levelNum}` : 
			`Level ${level.levelNum}`;
		
		if(!this.level) {
			alert("Internal error - tell josiahbryan@gmail.com that level " + this.currentLevelId + " doesn't exist");
			throw new Error("Error loading level - doesn't exist");
		}

		this.setupScene();
	}

	resetCurrentLevel() {

		// Reload screen to load next level
		this.game.setScene('KittyFlySleep', { resetLevel: true });
		
	}

	_resetLevelState(incrementRestartCount=false) {
		const oldState = (this.levelState || {}).worldState || {};
		const newState = {};
		
		// Copy the state of any stars ('*') from oldState to newState,
		// then discard everything else
		for(let gy=0; gy<this.level.height; gy++) {
			for(let gx=0; gx<this.level.width; gx++) {
				const row = this.mapIndex.grid.row[gy] || { col: {} };
				const col = row.col[gx] || { code: " " };
				if (col.objectId && 
					col.code === '*' &&
					oldState[col.objectId] === -1) { //!== undefined) {
					newState[col.objectId]   = -1; //oldState[col.objectId];
				}
			}
		}

		const restart = incrementRestartCount ? {
			restartCount: parseFloat(this.levelState.restartCount || 0) + 1,
		} : {};

		console.log("[_resetLevelState] newState=", newState);

		this.patchLevelState({
			worldState: newState, // reset world state to original level state (less any stars consumed)
			// progress: 0,
			...restart
		}, true);
	}

	setupScene() {
		const { externalOptions, level, loadingScreen } = this;

		// Get tile theme, default to silver if not specified
		// mountains
		// bg_img_bubble_blue
		// bg_img_burst_blue
		// bg_img_wave_blue
		// bg_img_bubble_orange
		// bg_img_burst_orange
		// bg_img_wave_blue2
		// bg_img_bubble_purple
		// bg_img_burst_purple
		// bg_img_wave_pink
		// bg_img_ice

		// level.bg = 'stars';
		
		if(!level.tileColor) {
			const bg = level.bg + "";
			if(bg.includes('purple')) {
				level.tileColor = 'purple';
			} else
			if(bg.includes('red') || bg.includes('mountains')) {
				level.tileColor = 'cream';
			} else
			if(bg.includes('blue') || bg.includes('orange')) {
				level.tileColor = 'orange';
			} else
			if(bg.includes('pink')) {
				level.tileColor = 'pink';
			} else
			{ //if(["ice", "mountains", "water", "stars"].includes(bg)) {
				level.tileColor = 'silver';
			}
		}

		let levelTileUrl = TileColorURLs['tile_' + level.tileColor];
		if(!levelTileUrl) {
			levelTileUrl = TileColorURLs.tile_silver;
			level.tileColor = 'silver';
		}
		const tileThemeColors = {
			orange:   '#FF8122',  // orange
			blue:     '#16B6B6', // blue
			purple:   '#A21AC4', // purple,
			red:      '#8F1D21', // red
			silver:   '#F1F1F1', // silver
			pink:     '#EF2F7C', // pink
			cream:    '#EF2F7C', // cream
		};

		let themeColor = tileThemeColors[level.tileColor];
		
		// Sync theme colors better with these specific levels
		const bg = level.bg + "";
		if(bg === 'ice') {
			themeColor = tileThemeColors.silver;
		} else
		if(bg === 'water') {
			themeColor = '#36C8E9';
		} else
		if(bg === 'mountains') {
			themeColor = '#1BD1FF';
		} else
		if(bg === 'stars') {
			themeColor = '#000000';
		}

		if(themeColor) {
			setStatusBarColor(themeColor);
		}

		// Flag used elsewhere
		if(this.level.bg === 'tutorial') {
			this.isTutorial = true;

			// console.warn("Got tutorial level");
			
			// Reset popups
			Object.values(TutorialKeys).map(key => ShowOnePopupHelper.setSeen(key, false));
		}

		// Only load these assets if running tutorial
		const tutorialAssets = this.isTutorial ? {
			anim_swipe_right_img
		} : {};
		

		this.setupBasicKittyScene({
			overrideDebugRender: false,
			addBorderTiles: false,    // we'll add later so layers are right
			addKitty: false,          // we'll add later so layers are right
			kittyRestitution: 0.05,   // make less bouncy
			addSun: false,            // must add after resizing world
			includeSky: false,        // must add after resizing world
			worldWidth:  100,         // we'll change later
			worldHeight: 100,         // we'll change later

			tileTextureUrl: levelTileUrl,

			otherResources: {

				// For BasicMeter
				meter_bar_center_repeating_red,
				meter_bar_holder_center_repeating_red,
				meter_bar_holder_right_edge_red,
				meter_bar_right_edge_red,
				meter_icon_holder_red,
				meter_text_background_red,

				meter_bar_center_repeating_blue,
				meter_bar_holder_center_repeating_blue,
				meter_bar_holder_right_edge_blue,
				meter_bar_right_edge_blue,
				meter_icon_holder_blue,
				meter_text_background_blue,

				// meter_bar_center_repeating_green,
				// meter_bar_holder_center_repeating_green,
				// meter_bar_holder_right_edge_green,
				// meter_bar_right_edge_green,
				// meter_icon_holder_green,
				// meter_text_background_green,

				meter_bar_center_repeating_yellow,
				meter_bar_holder_center_repeating_yellow,
				meter_bar_holder_right_edge_yellow,
				meter_bar_right_edge_yellow,
				meter_icon_holder_yellow,
				meter_text_background_yellow,

				meter_bar_center_repeating_grayscale,
				meter_bar_holder_center_repeating_grayscale,
				meter_bar_holder_right_edge_grayscale,
				meter_bar_right_edge_grayscale,
				meter_icon_holder_grayscale,
				meter_text_background_grayscale,

				meter_bar_center_repeating_pink,
				meter_bar_holder_center_repeating_pink,
				meter_bar_holder_right_edge_pink,
				meter_bar_right_edge_pink,
				meter_icon_holder_pink,
				meter_text_background_pink,


				meter_icon_health,
				meter_icon_magic,
				meter_icon_power,
				meter_icon_shield,
				meter_icon_stamina,
				meter_icon_timer,
				meter_icon_xp,

				// for _spawnBullet
				weapon_projectile,

				// for addButtons
				// small_blue_button,
				// small_yellow_button,
				// small_pink_button,
				// small_green_button,
				small_red_button,

				slider_bottom,
				slider_top,
				// slider_left,
				// slider_right,

				d_pad_1,
				x_button,
				triangle_button_left,
				blank_button,
				square_button,
				icon_white_list,
				icon_white_star,

				pill_red,
				pill_blue,
				pill_pink,
				pill_yellow,

				power_scale,
				power_gravity_float,
				power_speed,

				power_bomb,
				power_shield,
				power_grenade,

				anim_firework3white_img,

				// for addParallaxBg
				pbg_fcloud1,
				pbg_fcloud2,
				pbg_hill_bg,
				pbg_hill_fg,
				pbg_hill_mid,
				pbg_mcloud1,
				pbg_mcloud2,
				pbg_mtn_bg,
				pbg_mtn_fg,
				pbg_mtn_mid,

				bg_img_bubble_blue,
				bg_img_burst_blue,
				bg_img_wave_blue,
				
				// bg_img_bubble_green,
				// bg_img_burst_green,
				// bg_img_wave_green,

				bg_img_bubble_orange,
				bg_img_burst_orange,
				bg_img_wave_blue2,

				bg_img_bubble_purple,
				bg_img_burst_purple,
				bg_img_wave_pink,

				bg_img_bubble_red,
				bg_img_burst_red,
				bg_img_wave_red,

				// bg_img_bubble_yellow,
				// bg_img_burst_yellow,
				// bg_img_wave_yellow,

				bg_img_ice,

				// for map building
				tile_silver,
				// tile_silver_corner_t,
				// tile_silver_corner_r,
				// tile_silver_corner_b,
				// tile_silver_corner_l,
				tile_ice,
				tile_red,
				tile_gold,
				tile_green,
				tile_blue,
				tile_glass100,
				tile_glass25,
				tile_glass50,
				tile_glass75,
				anim_glassbreak_img,
				anim_smoke_img,

				icon_cross,
				icon_health,
				icon_star,
				icon_power,
				icon_check,
				sparkle_static_img,

				flag_triangle_img,
				flagpole_gold,

				// for bouncy balls
				ball_grey,

				// water_bg_left,
				water_bg_center,
				// water_bg_right,

				stars_bg,

				bubble_rect,
				bubble_think,
				bubble_tag,

				// For map building
				// mapData

				// cupcake_blue,
				// cupcake_cyan,
				// cupcake_green,
				// cupcake_yellow

				// door_block

				// For tutorial levels
				...tutorialAssets
			}
		})
		.then(resources => {
			// Make sure not frozen from previous level-end overlay
			// this.game.postToMatter('freezeMatter', false);

			// Freeze until end of transition screen
			this.game.postToMatter('freezeMatter', true);
			
			if(['water','stars'].includes(this.level.bg)) {
				if(this.level.bg === 'water') {
					this.setGravity(0.015);
				} else
				if(this.level.bg === 'stars') {
					this.setGravity(0);
				}
			} else {
				this.setGravity(1);
			}

			this.setWorldTileSizeFromLevel();
			this.addGameOverScreen();
			
			if(['mountains'].includes(this.level.bg)) {
				this.addSky();
				this.addSun();
			} else
			// if(this.level.bg.includes('ice')) {
			// 	this.addSun();
			// } else
			if(['water','stars'].includes(this.level.bg)) {
				this.game.removeViewportMask();	
			}
			
		
			this.addParallaxBg();
			
			this.buildSkyFromLevel();
			this.addBouncyBalls();

			// If final arg (matterOnly) is true, no border actually rendered, MatterJS just has bodies added as borders
			this.addBorderTiles(
				this.ceilingInset || DEFAULT_CEILING_INSET, 
				this.floorHeight  || DEFAULT_FLOOR_HEIGHT,
				true);//['water','stars'].includes(this.level.bg));

			this.addKittyActor(this.kittyStartingPosition || (this.kittyStartingPosition = { 
				x: this.tileSize * 2, 
				y: this.tileSize * ((this.ceilingInset || DEFAULT_CEILING_INSET) + 1)
			}));

			if(['water','stars'].includes(this.level.bg)) {
				if(this.level.bg === 'water') {
					this.actor.setUnderwaterMode(true);
				} else
				if(this.level.bg === 'stars') {
					this.actor.setSpaceMode(true);
				}
			}

			this.addMeters();
			// Buttons after meters because back button goes below power meter
			// and uses coords of power meter to position itself
			this.addButtons();

			
			// NB customizeViewport MUST be done AFTER addButtons/addMeters because
			// the HideOverActorViewportPlugin added in customizeViewport grabs refs to all buttons/meters
			this.customizeViewport();

			this.setupSounds();

			this.listenForPauseResume();

			// PixiMatterContainer.EditorMode = true;
			if(PixiMatterContainer.EditorMode) {
				const { tileSize, ceilingInset } = this;
				setTimeout(() => {
					this.actor.setPosition({ x: tileSize, y: tileSize * (ceilingInset + 1) });
				}, 1000);
			}

			this.setupEditorMode();
			// setTimeout(() => {
			// 	console.log("** testing remove:");
			// 	this.actor.obj.matterRemove();
			// },500);


			// Must do _resetLevelState AFTER buildSkyFromLevel()
			if(externalOptions.isReplay || externalOptions.resetLevel) {
				console.log("[externalOptions.resetLevel|isReplay] resetting");
		
				// console.warn("+ is replay");
				// Notify metric server
				ServerStore.metric("game.level.replayed");

				// reset world state to original level state (less any stars consumed)
				this._resetLevelState();
			}


			const doneWelcomeShowGame = async () => {
				PixiUtils.fadeIn(this.liveGameContainer);

				AssetLoader.off('progress', loadingScreen.binding);
				PixiUtils.fadeOut(loadingScreen.text, 200);
				PixiUtils.fadeOut(loadingScreen.ball, 200);

				// Remove via timeout so it keeps rotating while fading out
				setTimeout(() => {
					this.game.app.ticker.remove(this._loadingBallTicker);
				}, 200);

				// If overlay active (from previous level), hide overlay
				if(LevelEndOverlay.handle)
					await LevelEndOverlay.handle.requestClose();

				// Mount shop popup
				this.game.setReactOverlay(
					<ShopPopup actor={this.actor} handle={handle => this.shopPopupLoaded(handle)}/>
				);
				
				// Unfreeze now that the overlay is done
				this.game.postToMatter('freezeMatter', false);

				// Play starting sound
				SoundManager.play(SoundManager.START1);

				// Start music playing
				this.musicTrack.play();

				// Setup push intercept
				this.game.pushService.customMessageDisplay = async data => {
					// show() will return true only if user clicks close button.
					// Timer expire returns false from await.
					return await this.shopPopup.show(null, { text: <>
							<h3>{data.title}</h3>
							<p>{data.body}</p>
						</>, 
						textTimer: 7500,
						actionIcon: faAngleRight
					});
				};
			};

			// Wait for kitty to finish loading completely before finishing welcome screen
			if(this.actor.isLoaded())
				doneWelcomeShowGame();
			else
				this.actor.onLoad = doneWelcomeShowGame;

			setTimeout(async () => {
				if(this.levelState.isStarted) {
					// const state = JSON.parse(jsonState);
					// console.log("Level already started, restoring state:", this.levelState.worldState);
					this.restoreActiveWorldState(this.levelState.worldState);

					// const state = this.serializeActiveWorldState();
					// console.log("Test serialization:", state);

					// Notify metric server
					if(this.isTutorial)
						ServerStore.metric("game.level.tutorial.resumed");
					
					// Notify metric server
					ServerStore.metric("game.level.resumed", this.game.fpsTarget, await ServerStore.appVersion());

				} else {
					gtag('event', 'level_started', { 
						event_label:    this.currentLevelName,
						event_category: 'game_level'
					});

					if(this.isTutorial)
						// Notify metric server
						ServerStore.metric("game.level.tutorial.started");
					
					// Notify metric server
					ServerStore.metric("game.level.started", this.game.fpsTarget, await ServerStore.appVersion());

					this.patchLevelState({
						progress:    0,
						isStarted:   true,
						timeStarted: new Date(),
						totalPlayingTime: 0
					});
				}

				this.snapshotFriendsList();

				this.startGamePlayTimer();

				this.updateTotalPlayingTime();

				// Resume tuning FPS since level transition completed
				this.game.fpsAutoTuner && this.game.fpsAutoTuner.start();

				// Reset initial number
				this.multiStarStreaks = 0;

			}, 1);
		});
	}

	shopPopupLoaded(handle) {
		this.shopPopup = handle;
		ShowOnePopupHelper.setPopup(handle);

		setTimeout(async () => {
			if(this.isTutorial) {
				await ShowOnePopupHelper.tutorialPopup(TutorialKeys.welcome1, <>
					<h2 style={{margin: '1rem'}}>
						Welcome to Sleepy Cat!
					</h2>
					<p>Collect all the stars on this level to learn more of what {ServerStore.currentCat.name} can do!</p>
					<p>Your sleepy cat loves to sleep, but loves it even more when you play with it!</p>
					<p>Click this message to dismiss, then swipe the screen slowly (or quickly) to start playing with your Sleepy Cat!</p>
				</>, { 
					textTimer: 0,
					panelBottomPercent: '30%'
				});
				this.swipeAnim.play();
			} else {
				// handle.show(null, <h1>Good luck on {this.level.world.name} # {this.level.levelNum}!</h1>, 3333)
				if(this.friendsListSnapshot) {
					// NOOP if already shown
					this.showLevelFriendsMessage();
				}
			}
		}, 1);
	}

	async snapshotFriendsList() {
		const list = await ServerStore.server().get('/friends', null, { autoRetry: true });
		if(Array.isArray(list)) {
			// console.log("Patching friendsListSnapshot:", list);
			this.patchLevelState({ friendsListSnapshot: list }, true);

			this.friendsListSnapshot = list;
			if(!this.isTutorial && this.shopPopup) {
				this.showLevelFriendsMessage();
			}
		} else
		if(list.error)
			console.error("Error loading /friends:", list);
	}

	async showLevelFriendsMessage() {
		if(!this.shopPopup)
			return;

		if(this._showLevelFriendsMessage_shown)
			return;

		if(!this.friendsListSnapshot) {
			console.log("[showLevelFriendsMessage] no friendsListSnapshot loaded, nothing to show");
			return;
		}

		this._showLevelFriendsMessage_shown = true;

		const user        = ServerStore.currentUser,
			userId        = user.id,
			currentPoints = parseFloat(ServerStore.currentCat.points || 0) || 0,
			current       = this.friendsListSnapshot,

			// Get location of our user (as a reference)
			// NB chose to use "==" instead of "===" to allow implicit casting from string (otherUser) to integer (userId)
			// eslint-disable-next-line eqeqeq
			currentRef    = current.find(x => x.otherUser == userId),
			
			// Find the index of our user (so we can check above/below)
			currentIdx    = current.indexOf(currentRef),
			
			// Get refs of friends above/below user in current list
			aboveCurrent  =
				currentIdx > 0 ?
				current[currentIdx - 1] : null,
			belowCurrent  =
				currentIdx < current.length - 1 ?
				current[currentIdx + 1] : null,
			
			// Our structure to fill in with logic, below
			msg = {
				title: '',
				body: ''
			};

		if(aboveCurrent) {
			// encourage to aim to beat the other user
			const [ name ] = aboveCurrent.otherPerson.split(/\s+/),
				points     = parseFloat(aboveCurrent.points),
				diff       = numberWithCommas(points - currentPoints),
				titles     = [
					`You've almost caught up to ${name}!`,
					`${name} is leading...`,
					`${name} almost has the same points!`,
					`You're almost up to ${name}'s points!`,
					`You can do it, almost there!`,
					`Aim high to beat ${name}!`
				];
			
			if(diff < 25) {
				msg.title = titles[Math.floor(Math.random() * titles.length)];
				msg.body = `You're only ${diff} points behind ${name}. ${randomEncouragement()}`;
			}

		} else
		if(belowCurrent) {
			// encourage to keep going!
			const [ name ] = belowCurrent.otherPerson.split(/\s+/),
				points     = parseFloat(belowCurrent.points),
				diff       = numberWithCommas(currentPoints - points),
				titles     = [
					`${name} is catching up!`,
					`${name}'s trailing you...`,
					`${name} is just behind you...`,
					`${name} almost has the same points!`,
					`You're ahead of ${name}!`,
					`Wow, doing good!`,
					`Keep up the good work!`
				];

			if(diff < 25) {
				msg.title = titles[Math.floor(Math.random() * titles.length)];
				msg.body = `You're ${diff} points head of ${name}. ${randomAffirmation()}`;
			}
		}

		// Either nobody above/below or they are "out of range" in terms of points,
		// so give a random present-tense affirmation
		if(!msg.title && !msg.body && Math.random()) {
			const titles   = [
				'You know what?',
				'Hey, just a thought...',
				'I thought you might need to hear this:',
				'I was just thinking...',
				'Your kitty says:',
			];

			msg.title = titles[Math.floor(Math.random() * titles.length)];
			msg.body = randomAffirmation({ activeTense: true });

			const infoMatch = msg.body.match(/#info:(.*)$/);
			if(infoMatch) {
				msg.body = msg.body.replace(/#info:.*$/,'');

				// TODO: Show encouragement/bible
				// const infoType = infoMatch[1] || 'bible';
				// msg.infoOpts = {
				// 	infoAction: () => {
				// 		window.AlertManager.fire({
				// 			type: '',
				// 			html: <>
				// 				<p>
				// 					Info type: {infoType}
				// 				</p>
				// 			</>,
				// 		});
				// 	}
				// }
			}
		}

		if(msg.title && msg.body) {
			this.shopPopup.show(null, { text: <>
					<h2>{msg.title}</h2>
					<p>{msg.body }</p>
				</>, 
				textTimer: 8000,
				...(msg.infoOpts || {}),
			});
		}
	}

	startGamePlayTimer() {
		// Used to add time to totalPlayingTime every so often
		this.levelState._gamePlayTimeMark = Date.now();

		// updateTotalPlayingTime() is called whenever actor moves,
		// but ensure that time updates are sent at minimum of ever 30 seconds (At time of writing)
		this._gamePlayIntervalId = setInterval(() => {
			this.updateTotalPlayingTime();
		}, MAX_PLAYING_CHANGE_SECONDS * 1000);
	}

	setupEditorMode() {
		
		this.actor.on(EDIT_MOVE_EVENT, direction => {
			this.editorMoveActor(direction);
		});

		this.actor.setupEditorMode(PixiMatterContainer.EditorMode);
		this.enableEditorMode(PixiMatterContainer.EditorMode);
	}

	editorMoveActor(direction) {
		if(!this.actor.obj.transform)
			return;
			
		let { x, y } = this.actor.obj;
		// console.log("[editorActorMove] - ", { x, y});
		
		const { tileSize } = this;
		if(direction === 'up')
			y -= tileSize;
		else
		if(direction === 'down')
			y += tileSize;
		else
		if(direction === 'left')
			x -= tileSize;
		else
		if(direction === 'right')
			x += tileSize;
		
		// snap to grid
		x = Math.floor((x + tileSize/2) / tileSize) * tileSize;
		y = Math.floor((y + tileSize/2) / tileSize) * tileSize;

		// if(x / tileSize > this.currentWorldMap.meta.width) {
		// 	x  = tileSize;
		// 	y += tileSize;
		// }

		// console.log("[editorActorMove] x ", { x, y});

		this.actor.setPosition({ x, y });
	}

	getEditorCol() {
		const { tileSize, level } = this;
		const { x, y } = this.actor.obj;
		const gx = Math.floor((x+tileSize/2) / tileSize) - 1;
		const gy = Math.floor((y+tileSize/2) / tileSize) - (level.ceilingInset || DEFAULT_CEILING_INSET) - 1;
		const row = this.mapIndex.grid.row[gy] || (this.mapIndex.grid.row[gy] = { col: {} });
		const col = row.col[gx] || (row.col[gx] = {});
		// console.log({ gx, gy, x, y }, col);
		return col;
	}

	editorModeAction() {
		// todo
		const col = this.getEditorCol();

		col.code = window.prompt("New code:", col.code);
		
		// TODO: Re-render only changed tile
		this.rerenderEditorWorldFromIndex();
	}

	rerenderEditorWorldFromIndex() {
		const { level, mapIndex }  = this,
			data = mapIndex.stringify();

		level.data = data;
		
		this.rerenderEditorWorld();

		// Delay 1sec between patches
		level.patch({ data, numStars: this.objectsToClearLevelStartingLength }, 1000);
	}

	// TODO: Re-render only changed tile
	rerenderEditorWorld() {
		// Reset to start when starting editor
		this.clearWorldTiles();
		this.renderCurrentWorldMap();
		this.addBouncyBalls();

		this.addObject(this.actor.obj);
	}

	onKeyPress(event) {
		if(event.key === "E") {
			this.enableEditorMode(!PixiMatterContainer.EditorMode);
			return;
		} else
		if(['q','e'].includes(event.key)) {
			this.buttons.fire.nextItem(event.key === 'q' ? 'up' : 'down');
		} else
		if(!PixiMatterContainer.EditorMode) {
			if(event.keyCode === 32 || event.keyCode === 13) {
				this.buttons.fire.click();
			} else {
				// console.log("not consuming:", event.key);
			}

			return;
		}

		// If key is a valid tile code, intercept, edit, and re-render
		const tileCodeKeys = ' #tlbrx*^+podk~c';
		if(tileCodeKeys.includes(event.key)) {
			const col = this.getEditorCol();
			col.code = event.key;
			console.log(" * ", col);
			
			this.rerenderEditorWorldFromIndex();
			this.editorMoveActor('right');
			return false;
		} else
		// disable legacy key nav while editing
		if("uiojkl".includes(event.key)) {
			return false;
		}
	}

	enableEditorMode(flag) {
		PixiMatterContainer.EditorMode = flag;
		this.actor.setupEditorMode(PixiMatterContainer.EditorMode);
		
		if(flag) {
			// this.buttons.dpad.alpha = 0;
			this.buttons.fire.alpha = 0;
		} else {
			// this.buttons.dpad.alpha = DPAD_ALPHA;
			this.buttons.fire.alpha = FIRE_BTN_ALPHA;

			this.actor.setPosition(this.kittyStartingPosition);
		}

		// Reset to start when starting/stopping editor
		this.clearWorldTiles();
		this.renderCurrentWorldMap();
		this.addBouncyBalls();
		this.addObject(this.actor.obj);

		// if(flag) {
		// 	setTimeout(() => {
		// 		this.actor.setPosition({ x: tileSize, y: tileSize * (ceilingInset + 1) });
		// 	}, 500);
		// }
	}

	resizeLevel(width, height) {
		if(!PixiMatterContainer.EditorMode) {
			// alert("Do this in editor mode")
			// return;
		}

		const { level } = this;

		if(!width)
			width = level.width;
		if(!height)
			height = level.height;

		if(!window.confirm("This will erase the current map, are you sure?"))
			return;
		
		level.patch({ width, height, data: RandomMap.generateBlankMap(width, height) }, 0);
		this.game.setScene('KittyFlySleep', { mapName: '-' });
		// window.location.search = '?' + WELCOME_SCREEN_BYPASS + '=' + Date.now();
	}

	randomizeLevel(asMaze=false, useBg = false) {
		// if(!PixiMatterContainer.EditorMode) {
		// 	// alert("Do this in editor mode")
		// 	// return;
		// }
		
		if(!window.confirm("This will erase the current map, are you sure?"))
			return;
		
		const { level } = this;

		const gen = asMaze ? 'generateRandomMazeMap' : 'generateRandomMap';
		const generated = RandomMap[gen](level.width-1, level.height-1);
		if(useBg) {
			level.patch({ data: generated.buffer, bg: generated.bg }, 0);
			this.game.setScene('KittyFlySleep', { mapName: '-' });
		} else {
			level.patch({ data: generated.buffer }, 1000);
			this.rerenderEditorWorld();
		}
	}

	randomBg() {
		const { level } = this;
		level.patch({ bg: RandomMap.randomBgImageType() }, 0);
		this.game.setScene('KittyFlySleep');
	}

	setupSounds() {
		// Don't *have* to call use if we are just going to call play right away
		// .use is just a preload
		this.musicTrack = SoundManager
			.use(SoundManager.MUSIC_FOREST)
			.setVolumeModifier(0.75);

		SoundManager.use(SoundManager.SHOT).setVolumeModifier(0.25);
		SoundManager.use(SoundManager.ACHIEVEMENT2).setVolumeModifier(0.2);
	}

	listenForPauseResume() {
		// These events emitted by normalized handlers in Game.js
		ServerStore.on(APP_PAUSED_EVENT, this._appPausedHandler = async () => {

			// Pause matter and various timers
			this.pauseGameplay();

			// Dump accumulators to metrics because the app could be discard in the background
			this.actor.dumpPendingMetrics();
			
			//
			// Update: The metric logging and await is not needed here since we now handle this in Game.js
			//
			// // Notify metric server
			// ServerStore.metric("cordova.paused");

			// // Dump any pending metrics to server before letting game go to background
			// await ServerStore.postMetrics();
			
			// Stop the music *last* because the act of having active sound keeps the app running
			// in the background, so this will give the app time to persist to the server
			this.musicTrack.stop();
		});

		ServerStore.on(APP_RESUMED_EVENT, this._appResumedHandler = () => {
			//
			// Update: The metric logging is not needed here since we now handle this in Game.js
			//
			// Notify metric server
			// ServerStore.metric("cordova.resumed");

			this.musicTrack.play();
			
			// Resume timers
			this.resumeGameplay();
		});
	}

	async pauseGameplay() {
		// Stop simulation
		this.game.postToMatter('freezeMatter', true);

		// Stop the timer so we don't count time in background as playing time
		clearInterval(this._gamePlayIntervalId);

		// Make sure world is persisted incase app gets shut off
		this._worldStateLikelyChanged = true;
		await this.patchLevelState({}, true); // note await so we don't stop music till server returns

		// Pause tuning FPS while we transition levels
		this.game.fpsAutoTuner && this.game.fpsAutoTuner.stop();

		this.actor.stopAutoChangeHealthFromSleep();
	}

	resumeGameplay() {
		this.startGamePlayTimer();
		this.actor.autoChangeHealthFromSleep();

		// Pause tuning FPS while we transition levels
		this.game.fpsAutoTuner && this.game.fpsAutoTuner.start();
		
		// Restart simulation
		this.game.postToMatter('freezeMatter', false);
	}

	addGameOverScreen() {
		this.liveGameContainer = new PIXI.Container();
		this.addObject(this.liveGameContainer, this.game.gameContainer);

		// Force live game to composite everything for fade outs of entire game
		// this.liveGameContainer.filters = [ new PIXI.filters.AlphaFilter() ];
		this.liveGameContainer.alpha = 0;

		// Put viewport into the sub-container
		this.game.gameContainer.removeChild(this.viewport);
		this.liveGameContainer.addChild(this.viewport);

		// Now create the actual game-over screen
		const gameOverContainer = new PIXI.Container();
		const gameOverText = new PIXI.Text(
			'Health at 0%\n' + 
			'Click Here\n' +
			'to Try Again.',
			{ ...TextStyles, fontSize: 48, fill: "red", align: "center" }
		);
		gameOverContainer.addChild(gameOverText);
		
		gameOverText.anchor.x = 0.5;
		gameOverText.anchor.y = 0.5;
		gameOverText.x = window.innerWidth / 2;
		gameOverText.y = window.innerHeight / 2;
		gameOverText.buttonMode = true;
		gameOverText.interactive = true;
		gameOverText.click = gameOverText.tap = async () => {
			this.actor.setHealth(999); // actor will cap it correctly
			this.actor.setPosition(this.actor.startingPosition);
			
			// set this so _persistLevelState() doesn't override our worldState patch
			this._worldStateLikelyChanged = false;

			// reset world state to original level state (less any stars consumed)
			this._resetLevelState(true/*incrementRestartCount*/);

			// Update google analytics
			gtag('event', 'level_restart', { 
				event_label:    this.currentLevelName,
				event_category: 'game_level'
			});

			// Reload screen to reset world state
			this.game.setScene('KittyFlySleep');
		};
		
		gameOverContainer.alpha = 0;
		gameOverContainer.interactiveChildren = false;

		this.addObject(gameOverContainer, this.game.gameContainer);
		this.gameOverContainer = gameOverContainer;
	}

	
	addKittyActor(position) {
		super.addKittyActor(position);

		if (this.gravity === 0) {
			this.actor.obj.setMatter('frictionAir', 0.0001);
		}

		this.actor.on('spawnBullet', ({ x, y, dir }) => {
			this.spawnBullet({ x, y }, dir);
		});

		// Only prepareInterstitial if user has NOT purchased sub_no_ads
		if(!this.actor.itemAmount(KITTY_ITEMS.sub_no_ads)) {
			AdMobUtil.prepareInterstitial();
		}
	}

	// In theory, the bullet cache is a good idea.
	// However, in practice, I found that when we reused bullets from the cache,
	// the didn't fire correctly - they seemed to not live long enough or matter didn't simulate them correctly
	// or something - either way, right now this is not a priority - if bullet memory usage becomes a problem,
	// revisit this idea in the future.
	// If _bulletCache is not set on 'this', the bullet cache will not be used by code below. Therefore,
	// commenting out this next line is sufficient to disable the bullet cache.
	// _bulletCache = [];

	_bulletsLive = 0;
	_spawnBullet(startPoint, velocity, rotationAngle) {
		const { resources } = this;

		const MAX_BULLET_LIFE = 1000 * 4;
		this._bulletsLive ++;
		
		if(this._bulletCache) {
			// Grab a dead bullet from the cache (if any available)
			// If available, reuse that bullet instead of adding more bullets to the scene
			const deadBullet = this._bulletCache.find(bullet => bullet._dead);
			if(deadBullet) {
				deadBullet._dead = false;
				deadBullet.matterAdd();
				deadBullet.alpha = 1;
				deadBullet.setPosition(startPoint);
				deadBullet.setVelocity(velocity);
				deadBullet.setAngle(toRadians(rotationAngle + 90));

				// Enforce lifespan of bullet
				setTimeout(() => {
					deadBullet.finalDamage();
				}, MAX_BULLET_LIFE);
				return;
			}
		}

		// Create physical projectile with our sprite
		const block = PixiUtils.createPhysicalTexture(
			resources.weapon_projectile.texture, 
			startPoint,
			{ scale: 1 }, {
				isStatic: false,
				restitution: 1,
				friction:    0,
				frictionAir: 0,
				density:     0.00001,
				// This label is required for collision detection in the world elsewhere in this file
				label:       "#bullet"
			});

		// Tint white
		// block.sprite.tint = 0xFFFFF;

		// This label is required for collision detection in the world elsewhere in this file
		// block.body.label = "#bullet";

		// Start the bullet moving
		block.setVelocity(velocity);
		
		// Set initial rotation
		block.setAngle(toRadians(rotationAngle + 90));

		const worldRect = this.innerWorldRect();

		block.onUpdate = (x, y) => {

			// physics engine sometimes lets bullets go thru walls and out of bounds - kill bullet if that happens
			if(block.x < worldRect.xMin || block.y < worldRect.yMin || block.x > worldRect.xMax || block.y > worldRect.yMax) {
				block.finalDamage();
				return false;
			}

			// Rotate bullet to match direction - in case of ricochet
			const velY = parseFloat(block.body.vy);//.toFixed(5);
			const velX = parseFloat(block.body.vx);//.toFixed(5);
			const rotationAngle = angle(0, 0, velX, velY);
			return [ x, y, toRadians(rotationAngle + 90) ];
		};

		block.finalDamage = () => {
			block.matterRemove();
			block.alpha = 0;
			block._dead = true;
			this._bulletsLive --;
		};

		// Enforce lifespan of bullet
		setTimeout(() => {
			block.finalDamage();
		}, MAX_BULLET_LIFE);

		// Add to simulation
		this.addObject(block);

		// Store for reuse
		this._bulletCache && this._bulletCache.push(block);
	}

	static DefaultNumBullets = 2;
	levelBulletsFired = 0;
	spawnBullet(startPoint=null, direction=null, spawnCount=KittyFlySleep.DefaultNumBullets) {
		if(PixiMatterContainer.EditorMode) {
			this.editorModeAction();
			return;
		}

		const { tileSize } = this;

		// Limit total bullets in world to limit processing power required in physics simulation
		// Bullets are time-limited below
		const MAX_BULLETS_ALIVE = 12;

		// Enforce max bullet count
		if(this._bulletCache && this._bulletCache.filter(b => !b._dead).length > MAX_BULLETS_ALIVE) {
			// Notify metric server
			ServerStore.metric("game.level.kitty.item.lasers.nope.max_bullets_alive");
			return;
		}

		if(this._bulletsLive > MAX_BULLETS_ALIVE) {
			// Notify metric server
			ServerStore.metric("game.level.kitty.item.lasers.nope.max_bullets_alive");
			return;
		}

		if(this.actor.currentPower <= 0) {
			// Notify metric server
			ServerStore.metric("game.level.kitty.item.lasers.nope.no_bullets");

			console.error("[spawnBullet] no bullets");
			return;
		} else {
			this.actor.setPower(this.actor.currentPower - 1);

			// Notify metric server
			this.actor.shotCountAccumulator ++;

			// Counter for streak
			this.levelBulletsFired ++;
		}


		// Automatically doesn't play if it already is playing
		SoundManager.play(SoundManager.SHOT);

		// Default direction
		if(!direction)
			direction={x: 1, y:0};

		// Default spawnCount
		if(!spawnCount)
			spawnCount = KittyFlySleep.DefaultNumBullets;

		// Calculate aiming angle so we can normalize velocity ourselves
		const rotationAngle = angle(0, 0, direction.x, direction.y) * -1;
		// Rotate a fixed-length vector based on the direction of aim to get the velocity for the bullets
		// Note: We use the tile size (size of the actor) scaled by 1.25 to start the bullets
		// just a little bit outside the actor's geometry
		const aimingVector  = rotate(0, 0, tileSize * 0.75, 0, rotationAngle);
		// Build our velocity object to pass around
		const velocity      = { x: aimingVector[0], y: aimingVector[1] };

		// Move bullet out from under the actor to prevent blowback
		startPoint.x += velocity.x;
		startPoint.y += velocity.y;

		// Rotation angle passed in here for initial direction
		// (to visually rotate projectile - actual firing direction is controlled by velocity)

		// Spawn request # of bullets - physics engine will handle stacking, etc
		for(let i=0; i<spawnCount; i++) {
			this._spawnBullet(startPoint, velocity, rotationAngle);
		}
	}

	_makeBouncyBall(startPoint, randomRestartPoint=true) {
		const { tileSize, scale, resources } = this;
		if(!startPoint) {
			console.warn("[_makeBouncyBall] no startPoint given...");
			return;
		}
		
		const block = PixiUtils.createPhysicalTexture(
			resources.ball_grey.texture, 
			startPoint,
			{ scale }, {
				label:       "[bouncyBall]",
				isStatic:    false,
				restitution: 1,
				friction:    0.0001,
				frictionAir: 0.005,
				density:     0.01,
				shape:      'circle',
				shapeArgs: [
					// circle requires explicit shape args - createPhysicalTexture() doesn't do circles natively.
					// Also, we reduce the radius by 45% to account for the visual size of the ball being smaller than
					// the full size of the texture.
					startPoint.x, startPoint.y, tileSize * .45,
				]
			});

		// Tint white
		// block.sprite.tint = 0xFFFFF;
		block.startPoint = startPoint;
		
		block.sprite.interactive = true;
		block.sprite.click = block.sprite.tap = () => {
			block.setVelocity({ x: (Math.random() * 100 - 25), y: (Math.random() * 100 - 25) });
		};

		block.onUpdate = (x1, y1, angle) => {
			
			const { x, y } = this.constrainToWorld(x1, y1);
			if(x1 !== x || y1 !== y) {
				return [ x, y, angle ];
			}
			
			if (Math.ceil(x) !== Math.ceil(block.x) ||
				Math.ceil(y) !== Math.ceil(block.y))
				block.resetDeadmanTimer();
				
			return true;
		};

		block.resetDeadmanTimer = () => {
			clearTimeout(block._maybeDeadTid);
			block._maybeDeadTid = setTimeout(() => {
				block.dead();
			}, 250);
		}

		block.dead = () => {
			if(Math.random() > 0.75)
				block.resetBall();
			else
				block.randomizeVelocity();
		}

		block.randomizeVelocity = () => {
			const vel = { x: Math.random() * SPACE_VELOCITY_CONSTANT, y: Math.random() * SPACE_VELOCITY_CONSTANT};
			// console.warn("[_makeBouncyBall.randomizeVelocity] vel=", vel);
			block.setVelocity(vel);
		};

		block.resetBall = () => {
			let newStartPoint = randomRestartPoint ? this.findEmptyBlock(false) : startPoint;
			if(!newStartPoint) {
				this._findEmptyExcludeHash = {};
				newStartPoint = this.findEmptyBlock(false);
			}

			PixiUtils.fadeOut(block).then(() => {
				block.setPosition(newStartPoint);
				block.randomizeVelocity();
				PixiUtils.fadeIn(block);
			});
		};

		block.randomizeVelocity();

		return block;
	}

	setGravity(y=1.0) {
		this.gravity = y;
		this.game.postToMatter('setGravity', { x:0, y: y === 0 ? 0.0001 : y });
	}

	addBouncyBalls() {
		// console.warn("Not adding bouncy balls right now while debugging")
		// return;

		const { worldTileWidth } = this;
		const blocks = [];

		// ballSpawnPoints from map - but if none included, we make a few random ones below
		if((this.ballSpawnPoints || []).length > 0) {
			this.ballSpawnPoints.forEach(startPoint => {
				blocks.push(this.addObject(this._makeBouncyBall(startPoint, false)));
			});
		} else {
			// const worldRect =  this.innerWorldRect();
			// Load the bouncy balls
			const factor = 0.3 * Math.random();
			for(let i=0; i<worldTileWidth-3; i += 1/factor) {
				// for(let j=0;j<5*factor; j++) {
				for(let j=0;j<1; j++) {
					
					// const startPoint = { x:  tileSize * (i + 2), y: worldRect.yMin + tileSize };
					const startPoint = this.findEmptyBlock(false);
					if(startPoint) {
						// console.log("Found start point for ball:", startPoint, excludePoints);
						const block = this._makeBouncyBall(startPoint, true);
						this.addObject(block);
						blocks.push(block);
					}
				}
			}
		}

		this.bouncyBalls = blocks;
	}

	_parseMapBuffer(buffer={data:""}) {
		if(buffer.worldMap)
			return buffer.worldMap;

		return buffer.worldMap = RandomMap.parseMapBuffer(buffer.data);
	}

	setWorldTileSizeFromMapBuffer(buffer={data:""}) {
		const map = this._parseMapBuffer(buffer);
		this.ceilingInset = map.meta.ceilingInset || DEFAULT_CEILING_INSET;
		this.floorHeight  = map.meta.floorHeight || DEFAULT_FLOOR_HEIGHT;
		this.mapBg        = map.meta.bg;
		this.setWorldTileSize(map.worldTileWidth, map.worldTileHeight + this.ceilingInset - this.floorHeight);
		
	}

	buildSkyFromMapBuffer(buffer={data:""}) {
		if(!buffer)
			return;

		//testing
		// return;

		const map = this._parseMapBuffer(buffer);
		this.currentWorldMap = map;

		this.mapIndex = {
			grid: {
				row: {}
			},
			world: {
				row: {}
			},
			add: (code, gx, gy, wx, wy, obj) => {
				const datum = { code, gx, gy, wx, wy, obj };
				if(!this.mapIndex.grid.row[gy]) {
					this.mapIndex.grid.row[gy] = { 
						col: {}
					};
				}

				if(!this.mapIndex.world.row[wy]) {
					this.mapIndex.world.row[wy] = { 
						col: {}
					};
				}

				datum.objectId = [gx,gy,code].join(':');

				this.mapIndex.grid.row[gy].col[gx] = datum;
				this.mapIndex.world.row[wy].col[wx] = datum;

			},
			stringify: () => {
				const lines = [JSON.stringify(map.meta)];
				for(let gy=0; gy<map.meta.height; gy++) {
					const currentRow = [];
					for(let gx=0; gx<map.meta.width; gx++) {
						const row = this.mapIndex.grid.row[gy] || { col: {} };
						const col = row.col[gx] || { code: " " };
						currentRow.push(col.code);
					}
					lines.push(currentRow.join(""));
				}
				return lines.join("\n");

			},
		};

		// console.log("[buildSkyFromMapBuffer] got:", {map, buffer});
		this.renderCurrentWorldMap();

		this._setupHitCheck();
	}

	setWorldTileSizeFromLevel() {
		const { level } = this;
		this.ceilingInset = level.ceilingInset || DEFAULT_CEILING_INSET;
		this.floorHeight  = level.floorHeight || DEFAULT_FLOOR_HEIGHT;
		this.mapBg        = level.bg;
		if(level.bg === 'water' || level.bg === 'stars') {
			this.ceilingInset = 3;
			this.floorHeight  = 2;
		}
		// console.log(level);
		this.setWorldTileSize(level.width, level.height + this.ceilingInset + this.floorHeight);
		
	}

	buildSkyFromLevel() {
		
		this.mapIndex = {
			grid: {
				row: {}
			},
			world: {
				row: {}
			},
			add: (code, gx, gy, wx, wy, obj) => {
				const datum = { code, gx, gy, wx, wy, obj };
				if(!this.mapIndex.grid.row[gy]) {
					this.mapIndex.grid.row[gy] = { 
						col: {}
					};
				}

				if(!this.mapIndex.world.row[wy]) {
					this.mapIndex.world.row[wy] = { 
						col: {}
					};
				}

				datum.objectId = [gx,gy,code].join(':');

				this.mapIndex.grid.row[gy].col[gx] = datum;
				this.mapIndex.world.row[wy].col[wx] = datum;

			},
			stringify: () => {
				const lines = []; //JSON.stringify(map.meta)];
				for(let gy=0; gy<this.level.height; gy++) {
					const currentRow = [];
					for(let gx=0; gx<this.level.width; gx++) {
						const row = this.mapIndex.grid.row[gy] || { col: {} };
						const col = row.col[gx] || { code: " " };
						currentRow.push(col.code);
					}
					lines.push(currentRow.join(""));
				}
				return lines.join("\n");

			},
		};

		// console.log("[buildSkyFromMapBuffer] got:", {map, buffer});
		this.renderCurrentWorldMap();

		this._setupHitCheck();
	}

	clearWorldTiles() {
		for(let gy=0; gy<this.level.height; gy++) {
			for(let gx=0; gx<this.level.width; gx++) {
				const row = this.mapIndex.grid.row[gy] || { col: {} };
				const col = row.col[gx] || { code: " " };
				if(col.obj) {
					if (col.obj.tempDestroy)
						col.obj.tempDestroy();
				}
			}
		}

		(this.bouncyBalls || []).forEach(ball => ball.tempDestroy());
		// if (this.cupcake)
		// 	this.cupcake.tempDestroy();
			
		if (this.doorblock)
			this.doorblock.tempDestroy();
	
		if (this.powerpill)
			this.powerpill.tempDestroy();

		this.ballSpawnPoints = [];
		this.objectsToClearLevel = [];
		this.objectsToDestroy    = [];
	}

	renderCurrentWorldMap() {
		const { resources, 
				scale: gridTileScaleDownFactor,
				// worldHeight, 
				// worldWidth,
				// worldTileHeight, 
				// worldTileWidth,
				tileSize,
				ceilingInset,
				// currentWorldMap: map,
				level,
			} = this;

		// const mapData = map.mapData;
		const mapData = level.data.split("\n");
		this.ballSpawnPoints = [];

		this.objectsToClearLevel = [];
		this.objectsToDestroy    = [];

		this._findEmptyExcludeHash = {};

		const ceilingInsetPx = ceilingInset * tileSize;

		// Keep objects inside the world rect
		const _scene = this, constrainToWorldHelper = function (x1, y1, angle) {
			const { x, y } = _scene.constrainToWorld(x1, y1);
			if(x1 !== x || y1 !== y) {
				this.setPosition({ x, y });
				// return [ x, y, angle ];
				return false;
			}
			return true;
		};

		// let foundKittyStart = false, cupcake = null;
		let foundKittyStart = false, doorblock = null;//, powerpill = null;

		for(let gy=0; gy<mapData.length; gy++) {
			
			const encodedRow = mapData[gy];

			for(let gx=0; gx<encodedRow.length; gx++) {
				const tileCode = encodedRow[gx].toLowerCase();

				const x = (gx + 1) * tileSize;
				const y = (gy + 1) * tileSize + ceilingInsetPx;

				let objToCollect = null, mapObj = null, objToDestroy = null;
				if(tileCode === '#') {
					objToDestroy = this.addObject(mapObj = new BreakableBlock(gridTileScaleDownFactor, '<silver>',  resources, { x, y }, 
						'tileTextureUrl',// this.bgType === 'bg_img_ice' ? 'tile_ice' : 'tile_silver'
					));
					if(['water','stars'].includes(level.bg)) {
						mapObj.alpha = 0.625; // start basic blocks slightly transparent in water
					}
				} else
				if(['t','r','b','l'].includes(tileCode)) {
					// console.warn("TODO: Add tile corner support to MatterSimulation");
					
					// const poly = 
					// 	tileCode === 'b' ?  [
					// 		{x, y}, // top-left
					// 		{x: x+tileSize, y}, //top-right
					// 		{x, y: y+tileSize},//bottom-left
					// 	] :
					// 	tileCode === 'l' ?  [
					// 		{x, y}, //top-left
					// 		{x: x+tileSize, y},//top-right
					// 		{x: x+tileSize, y: y+tileSize},//bottom-right
					// 	] :
					// 	tileCode === 't' ?  [
					// 		{x, y: y + tileSize},//bottom-left
					// 		{x: x+tileSize, y},//bottom-right
					// 		{x: x+tileSize, y: y+tileSize},//top-right
					// 	] :
					// 	tileCode === 'r' ?  [
					// 		{x, y},//top-left
					// 		{x, y: y+tileSize}, //bottom-left
					// 		{x: x+tileSize, y: y+tileSize},//bottom-right
					// 	] : [];

					// const adjust = 12.5/72 * tileSize; // magic
					// const offsetX = ['b','r'].includes(tileCode) ? adjust : -adjust,
					// 	  offsetY = ['b','l'].includes(tileCode) ? adjust : -adjust;
					// console.log({ tileSize, adjust, offsetX, offsetY, tileCode });

					// const obj = this.addObject(mapObj = PixiUtils.createPhysicalTexture(resources['tile_silver_corner_' + tileCode].texture,
					// 		{ x, y }, 
					// 		{ scale: gridTileScaleDownFactor },
					// 		{ isStatic: true }));

					// Matter.Body.setVertices(obj.body, poly);
					// Matter.Body.translate(obj.body, { x: -offsetX, y: -offsetY });
					// obj.sprite.x = offsetX;
					// obj.sprite.y = offsetY;

					// if(PixiMatterContainer.EditorMode) {
					// 	obj.x -= offsetX;
					// 	obj.y -= offsetY;
					// }
				}
				else
				if(tileCode === '~') {
					this.addObject(mapObj = new BreakableBlock(gridTileScaleDownFactor, '#glass', resources, { x, y }));
				} else 
				if(tileCode === 'x') {
					this.addObject(mapObj = new BreakableBlock(gridTileScaleDownFactor, '<bad>',  resources, { x, y }));
				} else 
				if(tileCode === '*') {
					objToCollect = this.addObject(mapObj = new BreakableBlock(gridTileScaleDownFactor, '<star>',  resources, { x, y }));
				} else
				if(tileCode === '^') {
					this.addObject(mapObj = new BreakableBlock(gridTileScaleDownFactor, '<power>',  resources, { x, y }));
				// } else 
				// if(tileCode === 'c') {
				// 	console.log("tileCode c, adding at ", { x, y });
				// 	this.addObject(cupcake = mapObj = new BreakableBlock(gridTileScaleDownFactor, '<cupcake>',  resources, { x, y }));
				// } else
				} else 
				if(tileCode === 'd') {
					if(doorblock) {
						console.warn("There can be only one door, found another door block at ", { gx, gy })
					} else {
						this.addObject(doorblock = mapObj = new BreakableBlock(gridTileScaleDownFactor, '<doorblock>',  resources, { x, y }));
					}
				} else
				if(tileCode === 'p') {
					// this.addObject(powerpill = mapObj = new BreakableBlock(gridTileScaleDownFactor, '<powerpill>',  resources, { x, y }));
					this.addObject(mapObj = new BreakableBlock(gridTileScaleDownFactor, '<powerpill>',  resources, { x, y }));
				} else
				if(tileCode === '+') {
					this.addObject(mapObj = new BreakableBlock(gridTileScaleDownFactor, '<health>',  resources, { x, y }));
				} else
				// TODO
				// if(tileCode === 'g') {
				// 	this.addObject(new BreakableBlock(gridTileScaleDownFactor, '<gun>',  resources, { x, y }));
				// } else
				if(tileCode === 'o') {
					this.ballSpawnPoints.push(mapObj = { ballSpawnPoint: true, x, y });
				} else
				if(tileCode === 'k') {
					this.kittyStartingPosition = mapObj = { kittyStartingPosition: true, x, y };//: x + tileSize/2, y: y + tileSize / 2 };
					foundKittyStart = true;
				} else {
					// blank space
					mapObj = { code: " " };
				}

				this.mapIndex.add(tileCode, gx, gy, x, y, mapObj);

				if(objToCollect) {
					this.objectsToClearLevel.push(objToCollect);
					objToCollect.objectCompleted = this._objectCompletedHandler;
					objToCollect.onUpdate = constrainToWorldHelper;
				}

				if(objToDestroy) {
					this.objectsToDestroy.push(objToDestroy);
					objToDestroy.objectCompleted = this._objectDestroyedHandler;
					objToDestroy.onUpdate = constrainToWorldHelper;
				}
				
				if(this.gravity === 0 &&
					mapObj && 
					mapObj.body && 
					mapObj.setVelocity &&
					!mapObj.body.isStatic) {
					mapObj.setVelocity({ x: Math.random() * SPACE_VELOCITY_CONSTANT, y: Math.random() * SPACE_VELOCITY_CONSTANT });
					mapObj.setMatter('frictionAir', 0.0001);
				}
			}
		}

		// const kittyFlag = Math.random() > 0.5;

		if(!foundKittyStart) {
			// console.log("No kitty start, finding empty block...");
			const p = this.findEmptyBlock(true); //kittyFlag); //, { debug: true });
			// console.log("found:",p);
			if(p) {
				this.kittyStartingPosition = p;
			} else {
				console.warn("Could not find empty block on map...");
			}
		}

		// if(!cupcake) {
		// 	const p = this.findEmptyBlock(false);
		// 	console.log("No cupcake, making at ", p);
			
		// 	if(p) {
		// 		this.addObject(cupcake = new BreakableBlock(gridTileScaleDownFactor, '<cupcake>',  resources, p));
		// 	} else {
		// 		console.warn("Could not find empty block on map...");
		// 	}
		// }

		if(true) {
			if(!doorblock) {
				const p = this.findEmptyBlock(false); //!kittyFlag);
				// console.log("No doorblock, making at ", p);
				
				if(p) {
					this.addObject(doorblock = new BreakableBlock(gridTileScaleDownFactor, '<doorblock>',  resources, p));
				} else {
					console.warn("Could not find empty block on map...");
				}
			}

			// if(!powerpill) {
			// 	const p = this.findEmptyBlock(false); //!kittyFlag);
			// 	console.log("No powerpill, making at ", p);
				
			// 	if(p) {
			// 		this.addObject(powerpill = new BreakableBlock(gridTileScaleDownFactor, '<powerpill>',  resources, p));
			// 	} else {
			// 		console.warn("Could not find empty block on map...");
			// 	}
			// }
		}


		// this.cupcake = cupcake;
		this.doorblock = doorblock;
		// this.powerpill = powerpill;

		// The block will use this to notify us when it's been hit via setFlagRaisingMode() etc
		if (doorblock) {
			doorblock.scene = this;
		}

		// For status updates
		this.objectsToClearLevelStartingLength = this.objectsToClearLevel.length;
		this.objectsToDestroyStartingLength = this.objectsToDestroy.length;

		// if (this.textLevelCompletion)
		// 	this.textLevelCompletion.text = "Stars Remaining: " + this.objectsToClearLevel.length;
		// if (this.meters.level)
		// 	this.meters.level.setValue(0, this.objectsToClearLevel.length, "Stars Remaining: " + this.objectsToClearLevel.length);

		// Update the star meter
		if (this.meters && 
			this.meters.level)
			this.meters.level.updateStarCount();
	}

	_findEmptyExcludeHash = {};
	findEmptyBlock(fromBottom=false) {
		for(
			let gy =
				fromBottom ? this.level.height-2 : 0 ; 
				fromBottom ? gy>=0 : gy < this.level.height-1 ; 
				fromBottom ? gy-- : gy ++) {

			for(let gx =
				fromBottom ? 0 : this.level.width-2; 
				fromBottom ? gx <this.level.width-1 : gx>=0; 
				fromBottom ? gx++ : gx--) {

				const row = this.mapIndex.grid.row[gy] || { col: {} };
				const col = row.col[gx] || { code: " " };
				const key = col.wx + 'x' + col.wy;
				if(col.code ===  " " && col.wy && !this._findEmptyExcludeHash[key]) {
					// if(exclude.debug)
					// 	console.warn("Found empty block for start:", col);
					this._findEmptyExcludeHash[key] = true;

					return { x: col.wx, y: col.wy };
				}
			}
		}
	}

	findAllNearest(worldPos={wx:0, wy:0}, code='*') {

		const dist_between = (a,b) => {
			// euclidian distance
			return Math.sqrt(
				Math.pow((a.wx - b.wx), 2) +
				Math.pow((a.wy - b.wy), 2)
			);
		}
	
		const found = [];
		for(
			let gy = 0 ; 
				gy < this.level.height-1 ; 
				gy ++) {

			for(
				let gx = 0 ;
					gx < this.level.width-1 ;
					gx ++) {

				const row = this.mapIndex.grid.row[gy] || { col: {} };
				const col = row.col[gx] || { code: " " };
				if (col.code ===  code && col.wy) {
					col.__searchDist = dist_between(worldPos, col);
					found.push(col);
				}
			}
		}

		return found.sort((a,b) => a.__searchDist - b.__searchDist); //.map(x => (delete x.__searchDist, x));
	}

	findPathTo(exec=true) {
		// scene.findAllNearest({wx:kittyActor.obj.x, wy:kittyActor.obj.y})
		// findPath
		const list = this.findAllNearest({wx:this.actor.obj.x, wy:this.actor.obj.y});
		const goal = {
			x: list[0].gx,
			y: list[0].gy
		};
		const ec = this.getEditorCol(),
			start = {
				x: ec.gx,
				y: ec.gy,
			};
		console.log("[findPathTo]", { ec, start, goal, list });
		const path = RandomMap.findPath(start, goal, this.level.data).reverse();

		const nodeToPos = node => {
			const c = this.mapIndex.grid.row[node.y].col[node.x];
			console.log("[nodeToPos]",  {node, c });
			return { x: c.wx + this.tileSize/2, y: c.wy + this.tileSize/2 };
		}

		let p = Promise.resolve();
			// pos = nodeToPos(path[0]);
		path.forEach((node, idx) => { 
			p = p.then(() => {
				const dest = nodeToPos(node);
				console.log("[findPathTo] exec:", idx, dest, node);
				if(exec)
					this.actor.setPosition(dest);
				return new Promise(resolve => {
					setTimeout(() => resolve(), 500 )
				});
				// return new Promise(resolve => {
				// 	new TWEEN.Tween(pos)
				// 		.to(dest, 500)
				// 		.easing(TWEEN.Easing.Quadratic.Out)
				// 		.onUpdate(() => {
				// 			this.actor.setPosition({
				// 				x: pos.x + this.actor.obj.width/2,
				// 				y: pos.x + this.actor.obj.height/2,
				// 			});
				// 		})
				// 		.onComplete(() => resolve())
				// 		.start();
				// });
			});
		});

		return path;
	}

	restoreActiveWorldState(objectStates={}) {
		// console.warn("[restoreActiveWorldState] objectStates=", objectStates);
		if(!objectStates)
			return;

		for(let gy=0; gy<this.level.height; gy++) {
			for(let gx=0; gx<this.level.width; gx++) {
				const row = this.mapIndex.grid.row[gy] || { col: {} };
				const col = row.col[gx] || { code: " " };
				if(col.objectId && '#~x*^+'.includes(col.code)) {
					// const state = objectStates[col.objectId] = {};
					const state = objectStates[col.objectId];
					if(state !== undefined) {
						if(state === -1) {
							const obj = col.obj;
							obj._doneBreaking = true;
							obj.tempDestroy();
							if(col.code === '*') {
								this.objectsToClearLevel = this.objectsToClearLevel.filter(x => x!==obj);
							} else
							if(['#'].includes(col.code)) {
								this.objectsToDestroy = this.objectsToDestroy.filter(x => x!==obj);
							}
						} else {
							const obj = col.obj;
							if(state.dc && obj.showDamageFrame)
								obj.showDamageFrame(state.dc);
							if(state.a !== undefined && !isNaN(state.a))
								obj.setAngle(state.a);
							// if(state.av !== undefined)
							// 	obj.setMatter('angularVelocity', state.av);
							if(state.v)
								obj.setVelocity(state.v);
							if(state.p) {
								obj.setPosition(state.p);

								// If position vector encoded, body was not static at time of encoding, so remove static flag
								if(obj.body.isStatic) {
									obj.setMatter('isStatic', false);
								}
							}
						}
					}
				}
			}
		}

		if (this.meters && 
			this.meters.level)
			this.meters.level.updateStarCount();

		if(objectStates.a && !this.externalOptions.resetActor)
			this.actor.setPosition(objectStates.a);

		// if(this.objectsToClearLevel.length <= 0) {
		// 	if(window.confirm(
		// 		"It looks like you already played this level, do you want to reset this level and play it again (click OK) or skip it and go to the next level (click CANCEL)?"
		// 	)) {
		//		this.resetCurrentLevel();
		// 	} else {
		// 		this.gotoNextLevel();
		// 	}
		// }

		// console.log("[restoreActiveWorldState] restored from ", objectStates);
	}

	serializeActiveWorldState() {
		
		const objectStates = {}, quant = (num, amount) => {
			// console.log("* ", num);
			return {
				x: parseFloat(num.x.toFixed(amount)),
				y: parseFloat(num.y.toFixed(amount))
			}
		};

		for(let gy=0; gy<this.level.height; gy++) {
			for(let gx=0; gx<this.level.width; gx++) {
				const row = this.mapIndex.grid.row[gy] || { col: {} };
				const col = row.col[gx] || { code: " " };
				if(col.objectId && '#~x*^+'.includes(col.code)) {
					if(col.obj._doneBreaking) {
						objectStates[col.objectId] = -1;
					} else
					if(!col.obj.body.isStatic ||
						col.obj.damageCount) {
						const state = objectStates[col.objectId] = {};

						if(col.obj.damageCount)
							state.dc = col.obj.damageCount;

						if(!col.obj.isStatic) {
							if(col.obj.body.angle !== 0)
								state.a  = col.obj.body.angle;

							// if(col.obj.body.angularVelocity !== 0)
							// 	state.av = col.obj.body.angularVelocity;
						
							if(col.obj.body.vx || col.obj.body.vy)
								state.v = quant({
									x: col.obj.body.vx,
									y: col.obj.body.vy
							}, 5);

							if(col.obj.body.x !== undefined && col.obj.body.y !== undefined)
								state.p = quant(col.obj.body || {}, 2);
						}
					}
				}
			}
		}

		objectStates.a = {
			x: parseFloat(this.actor.obj.x.toFixed(2)),
			y: parseFloat(this.actor.obj.y.toFixed(2)),
		};

		// console.log("[serializeActiveWorldState] serialized to ", objectStates);
		
		return objectStates;
	}

	calculateCurrentPoints() {
		const percentLeft   = this.objectsToClearLevel.length / this.objectsToClearLevelStartingLength,
			starsCollected  = this.objectsToClearLevelStartingLength - this.objectsToClearLevel.length;
		// const tenPercentRounded = (Math.floor((percentLeft*100)/10)*10)/100;
		const totalPlayingTime = parseFloat(this.levelState.totalPlayingTime || 0),
			TIME_BUCKET_1  = 10,
			TIME_BUCKET_2  = 30,
			avgSecPerStar  = starsCollected <= 0 ? null : totalPlayingTime / starsCollected,
			bucket1Percent = avgSecPerStar / TIME_BUCKET_1,
			bucket2Percent = avgSecPerStar / TIME_BUCKET_2,
			bucketPercent  = 
				bucket1Percent <= 1 ? bucket1Percent :
				bucket2Percent <= 1 ? bucket2Percent :
				1.0,
			fd        = this.level.featureData || {difficulty:0.1},
			maxPoints = 10 / ( 1 - fd.difficulty),
			points    = Math.max(0.1, (1 - bucketPercent)) * Math.max(0.1, (1 - percentLeft)) * maxPoints;

		return {
			points, 
			bucketPercent,
			maxPoints,
			avgSecPerStar,
			totalPlayingTime,
			starsCollected,
			percentLeft,
		};
	}


	updateActivePointsDisplay() {
		this.actor.emit('points', this.calculateCurrentPoints().points);
	}


	applyStreakUpdates() {
		// Increment this session's streak counter
		const streaks = [];
		
		const {
			points, 
			avgSecPerStar,
			totalPlayingTime,
		} = this.calculateCurrentPoints();
		

		// Increment counter for this user (mainly for MixPanel's benefit, but could be useful later...)
		ServerStore.countMetric("game.count.points_earned", points);

		// Increment counter for this user (mainly for MixPanel's benefit, but could be useful later...)
		ServerStore.countMetric("game.count.playing_time_in_seconds", totalPlayingTime);

		// eslint-disable-next-line no-unused-vars
		const _example = {
			avgSecPerStar: 4.779799999999994,
			bucketPercent: 0.47797999999999935,
			maxPoints: 6.166135863067503,
			percentLeft: 0,
			points: 3.218846243238502,
			starsCollected: 10,
			totalPlayingTime: 47.79799999999994,
		};
				
		// const timeOverPercent = (500 - totalPlayingTime) / (percentLeft > 0.1 ? percentLeft : 0.1); //(1 - percentLeft);

		// streaks.push(ServerStore.updateStreak('perfect_levels',  {
		// 	name: "Pefect Levels in a row",
		// 	reset: this.objectsToClearLevel.length > 0,
		// }));


		let seqNum = 0;

		// Only valid for this session (till user closes the app)
		streaks.push(ServerStore.updateStreak('playing_time', {
			name: 'Minutes of Total Time Playing',
			set: Math.floor((ServerStore.appSession.playingTime / 60).toFixed(1)),
			seqNum: seqNum ++,
			replace: SessionTotalTimeStatReset
		}));

		// Only valid for this session (till user closes the app)
		streaks.push(ServerStore.updateStreak('session_levels_completed', {
			name: 'Levels Completed in a Session',
			set: ++ ServerStore.appSession.levelsCompleted,
			seqNum: seqNum ++,
			replace: SessionTotalTimeStatReset
		}));

		SessionTotalTimeStatReset = true;

		streaks.push(ServerStore.updateStreak('points_per_level', {
			name: 'Points per Level',
			set:  Math.ceil(points),
			seqNum: seqNum ++,
		}));
		
		if(avgSecPerStar > 0 && avgSecPerStar !== Infinity) {
			streaks.push(ServerStore.updateStreak('sec_per_star', {
				name: 'Seconds per Star',
				set:  parseFloat(avgSecPerStar.toFixed(1)),
				minimize: true,
				seqNum,
			}));
		}
		seqNum ++;
		
		streaks.push(ServerStore.updateStreak('sec_per_level', {
			name: 'Seconds per Level',
			set: Math.floor(totalPlayingTime),
			minimize: true,
			seqNum: seqNum ++,
		}));

		
		const numNotDestroyed  = this.objectsToDestroy.length - this.objectsToDestroy.filter(x => !x.body.isStatic || x.damageCount > 0).length,
			destructionPercent = 1 - (numNotDestroyed  / this.objectsToDestroyStartingLength);

		streaks.push(ServerStore.updateStreak('destruction', {
			name: "Percent Destruction",
			set:  Math.ceil(destructionPercent * 100),
			seqNum: seqNum ++,
		}));

		streaks.push(ServerStore.updateStreak('multi_stars', {
			name: "Multi-Star Streaks",
			set:  this.multiStarStreaks,
			seqNum: seqNum ++,
		}));

		
		
		// streaks.push(ServerStore.updateStreak('no_lasers', {
		// 	name: "Levels Without Lasers",
		// 	reset: this.levelBulletsFired  > 0,
		// 	seqNum: seqNum ++,
		// }));
		
		// streaks.push(ServerStore.updateStreak('lasers_per_level', {
		// 	name: 'Lasers Fired in a Level',
		// 	set: this.levelBulletsFired,
		// 	seqNum: seqNum ++,
		// }));


		// Decided we don't want to track this anymore
		{
			const data = ServerStore.currentCat.stats.streaks;
			data.perfect_levels && 
				delete data.perfect_levels;
			data.stars_vs_speed && 
				delete data.stars_vs_speed;
			data.speed && 
				delete data.streaks.speed;
			// Mark deleted so not visible in UI but keep data
			data.no_lasers && 
				( data.no_lasers.isDeleted = true );
			data.lasers_per_level && 
				( data.lasers_per_level.isDeleted = true );
		}

		// Update level state with the stats we captured here
		// This is flushed later in the game-end sequence
		this.patchLevelState({
			stats: ServerStore.currentCat.stats,
		});

		// Tell the actor to add points to the db
		// This is flushed to the server along with the level state in game-end
		this.actor.addPoints(points);

		// Store metrics for each streak
		streaks.forEach(streakInfo => {	
			if(streakInfo.newRecord) {
				ServerStore.metric("game.streaks." + streakInfo.id + ".new_record", streakInfo.current);
			} else {
				ServerStore.metric("game.streaks." + streakInfo.id + ".current", streakInfo.current, { num: streakInfo.record });
			}
		});
		
		// Sort streaks to decide what to tell the player
		streaks.sort((a,b) => {
			if(a.notifiedAt !== b.notifiedAt)
				return (a.notifiedAt || 0) - (b.notifiedAt || 0);

			if(a.newRecord && !b.newRecord)
				return -1;
			if(!a.newRecord && b.newRecord)
				return +1;

			// if(a.wasReset && !b.wasReset)
			// 	return +1;
			// if(!a.wasReset && b.wasReset)
			// 	return -1;


			// // if(!a.lastRecordAt && b.lastRecordAt)
			// // 	return -1;
			// // if(a.lastRecordAt && !b.lastRecordAt)
			// // 	return +1;
		
			// if(a.lastRecordAt !== b.lastRecordAt)
				return (a.lastRecordAt || 0) - (b.lastRecordAt || 0);
		
			// if(a.recordAt !== b.recordAt)
			// 	return a.recordAt - b.recordAt;
				
			// return b.record - a.record;
		});

		// streaks.forEach((streak, idx) => {
		// 	console.log(`[${idx}] `, streak);
		// });

		// console.log(streaks);
		const levelEndStreakInfo = streaks[0];

		// Save time so we notify something new next time
		levelEndStreakInfo.notifiedAt = Date.now();

		// Patch since we changed .stats by reference 
		ServerStore.currentCat.patch({ stats: ServerStore.currentCat.stats }, 0);

		return levelEndStreakInfo;
	}

	async gotoNextLevel() {
		// console.warn("Debug, not moving");
		// return;

		// Freeze MatterJS timer loop so nothing keeps going on behind the overlay
		this.game.postToMatter('freezeMatter', true);

		// Pause tuning FPS while we transition levels
		this.game.fpsAutoTuner && this.game.fpsAutoTuner.stop();

		// Fade out music - https://github.com/goldfire/howler.js/#fadefrom-to-duration-id
		// This we don't have music playing during overlay and the ACHIEVEMENT2 sound can be clearly heard
		const music = SoundManager.getPlayer(SoundManager.MUSIC_FOREST).sound;
		// Fade from effectiveVolume so if music was set quiet, don't jump to 100% and fade back down
		// music.fade(music.effectiveVolume || 1, 0, 500);
		music.stop();

		// Play end-of-level sound
		// SoundManager.play(SoundManager.ACHIEVEMENT2);

		// Fade out game (the overlay will render on top as soon as server returns)
		PixiUtils.fadeOut(this.liveGameContainer, 1000);
		this.liveGameContainer.interactiveChildren = false;

		// Update streaks and decide what to show in overlay
		const levelEndStreakInfo = this.applyStreakUpdates();

		// Render the overlay immediately before doing any await stuff 
		// to avoid the perception of any delay. The use of the handle prop
		// allows us to update the overlay with new information once the server
		// responds with how to proceed.
		let levelEndOverlay = null;

		// Only showInterstitial if user has NOT purchased sub_no_ads
		// AND not at end of tutorial
		if (!this.isTutorial &&
			!this.actor.itemAmount(KITTY_ITEMS.sub_no_ads)) {
			AdMobUtil.showInterstitial();
		}
		
		this.game.setReactOverlay(
			<LevelEndOverlay 
				handle={h => levelEndOverlay = this.game.levelEndOverlay = h} 
				game={this.game} 
				streakInfo={levelEndStreakInfo}
			/>
			// true == leave overlay active even after THIS scene goes away (we'll clear it later)
			,true); 
		
		// Persist world state back to server immediately (for stats, etc)
		this._worldStateLikelyChanged = true;
		await this.patchLevelState({}, true);
				
		// Update google analytics - this is async, so no delay is incurred here other than the cost of a .push() operation that gtag uses (e.g. no cost at all)
		gtag('event', 'level_completed', { 
			event_label:    this.currentLevelName,
			event_category: 'game_level'
		});

		// Dump accumulators to metrics before we destroy the actor in the setScene() call
		this.actor.dumpPendingMetrics();

		// Notify metric server
		ServerStore.metric("game.level.completed", this.game.fpsTarget, await ServerStore.appVersion());

		// Increment counter for this user (mainly for MixPanel's benefit, but could be useful later...)
		ServerStore.countMetric('game.count.levels_played')

		// Wait for metrics for THIS level to post because server uses current cat level in metric posting
		await ServerStore.postMetrics();

		// Call server logic to determine next level or if the next world needs unlocked, etc
		const result = await ServerStore.completeLevel(this.currentLevelId);

		console.log("[KittyFlySleep] level completed, next level result:", result, ", gameplay fps was:", this.game.fpsTarget, "fps");

		// Update overlay with server response of any ranking changes
		if (result.rankingChanges &&
			levelEndOverlay)
			levelEndOverlay.setRankingChanges(result.rankingChanges);

		// If result.unlockable.nextWorld is set by the server,
		// this is the first time the user has earned enough stars to unlock this world (nextWorld)
		// so give them the option. If they don't, no worries, just play on thru.
		let unlockActionTaken = false;
		if (result.unlockable && 
			result.unlockable.nextWorld) {

			const { nextWorld } = result.unlockable;

			ServerStore.metric("game.new_unlockable_world.prompted", null, nextWorld);
			
			const unlockResult = await MarketUtils.unlockWorld(nextWorld, {
				title: 'Guess what?',
				text: <>
					<p>You collected enough stars to unlock {nextWorld.name}! {nextWorld.description}</p>
					<p>Do you want to unlock it now and start exploring those levels with {ServerStore.currentCat.name}?</p>
				</>
			});

			if(unlockResult) {
				// Notify metric server
				ServerStore.metric("game.new_unlockable_world.unlocked", null, nextWorld);
	
				this.game.setScene('KittyFlySleep', { levelId: unlockResult });

				unlockActionTaken = true;
			} else {
				// Notify metric server
				ServerStore.metric("game.new_unlockable_world.not_unlocked", null, nextWorld);
			}

		}

		if(!unlockActionTaken) {
			if(result.noNextLevel || !ServerStore.currentCat.level) {
				WelcomeScene.disableAutoStart = true;
				this.game.setScene('WelcomeScene', { fromResult: result });
			} else {
				this.game.setScene('KittyFlySleep', { levelId: ServerStore.currentCat.level.id });
			}
		}
		
	}

	jumpToLevel(levelId) {
		// set this so _persistLevelState() doesn't override our worldState patch
		this._worldStateLikelyChanged = false;

		// // Persist world state back to server (and cache)
		// this.patchLevelState({
		// 	worldState: {}, // reset world state to original level state
		// 	progress: 0,
		// }, true);

		// throw new Error("Check on this code");

		// Patch immediately on server - dont wait 
		ServerStore.currentCat.patch({ level: { id: levelId } }, 0);

		// Notify metric server
		ServerStore.metric("game.level.jump_to_level", levelId);

		// Reload screen to load next level
		this.game.setScene('KittyFlySleep', {  resetLevel: true });
	}

	_objectDestroyedHandler = (obj) => {
		this.objectsToDestroy = this.objectsToDestroy.filter(x => x !== obj);
	}

	_checkMultipleStarsCollected = () => {
		if(this._multStarCollector) {
			const count = Object.keys(this._multStarCollector.idHash).length;

			if(count > 1) {
				// Large anim overlay
				this.multiStarRewardSparkle.play({ rewardNumber: count });

				// Sparkle on actor
				this.actor.playSparkle();

				// Count for end-of-level streaks
				// Give them credit for each unit of 2 stars since min of 2 stars to make a streak
				this.multiStarStreaks += Math.ceil(count / 2);
			}

			// if(count > 1 &&
			// 	this.actor.anims &&
			// 	this.actor.anims.firework3white)
			// 	this.actor.anims.firework3white.play();
			// if(count > 1)
			// 	console.warn("[_checkMultipleStarsCollected] + got ", count, this._multStarCollector.idHash);
			// else
			// 	console.log( "[_checkMultipleStarsCollected] . got ", count, this._multStarCollector.idHash);

			this._multStarCollector = null;
		} else {
			// console.error("[_checkMultipleStarsCollected] called but no _multStarCollector defined");
		}
	}	



	_objectCompletedHandler = (obj) => {
		const { body } = obj,
			{ id } = body;
		
		// console.log("[_objectCompletedHandler] <" + id + ">");

		if(!this._multStarCollector) {

			// Longer = more time to get more than 1 star
			// Shorter = less time, harder
			const timeToCollectMultiStars = 450;

			this._multStarCollector = {
				started: Date.now(),
				idHash: { },
				reset: id => {
					this._multStarCollector.idHash[id] = 1;
					clearTimeout(this._multStarCollector.tid);
					this._multStarCollector.tid = setTimeout(this._checkMultipleStarsCollected, timeToCollectMultiStars);
					// console.log("[_objectCompletedHandler] <" + id + "> _multStarCollector reset with tid:", this._multStarCollector.tid)
				},
			};
		} 

		
		// Guard against this function being called more than once on the same object
		if (this.objectsToClearLevel.includes(obj)) {

			// Reset the reward counter
			this._multStarCollector.reset(id);

			// Update central list
			this.objectsToClearLevel = this.objectsToClearLevel.filter(x => x !== obj);
			
			// Update meter
			this.meters.level.updateStarCount();

			// Update server-side levelState - persisted in _saveState() automatically
			const percent = Math.floor(100 * (1 - (this.objectsToClearLevel.length / this.objectsToClearLevelStartingLength)));
			this.levelStateUpdates.progress = percent;
			this.levelStateUpdates.starsCollected = this.objectsToClearLevelStartingLength - this.objectsToClearLevel.length;

			// Collected all the staras, play anim to notify
			if(this.objectsToClearLevel.length <= 0) {
				// Large anim overlay
				this.endOfLevelSparkle.play();

				// Sparkle on actor
				this.actor.playSparkle();

				// Tutorial text
				if(this.isTutorial) {
					ShowOnePopupHelper.tutorialPopup(TutorialKeys.endOfLevel, <>
						<h2>Good job!</h2>
						<p>You've collected ALL the stars on this level! Now go touch the flag pole to exit the level...</p>
					</>);
				}
			}
		}
		
	}

	_setupHitCheck() {
		this.collisionPipe = [];
		this.collisionData = {};

		// This event handler runs EVERY time there is a collision ANYWHERE IN THE WORLD (MatterJS current scene).
		// This means we must do whatever we do QUICKLY and be done to allow the rest of world to continue running.
		//
		// Therefore, we use a collisionPipe (processed below) to move the processing of collision data we care about
		// outside of the event handler and to filter/smooth the collision events. See notes on all that below.
		// Here we just figure out if it we need to care about. Our convention is that "things we care about" 
		// in the context of this scene start with a '#' sign. If the label doesn't start with a #, we ignore the collision.
		// Matter.Events.on(this.game.engine, 'collisionStart', this._collisionHandler = event => {
		this.game.on('matterCollision', this._collisionHandler = list => {
			if(this.isDestroyed)
				return;

			if(PixiMatterContainer.EditorMode)
				return;

			// console.warn("[collisionStart]", event);

			// const list = event.source.pairs.list || [];
			for(let i=0; i <list.length; i++) {

				// Remember, we get ALL collision events int the world, so first we have to
				// figure out if it was our body that hit something
				const { bodyA, bodyB } = list[i];
				if(bodyA.label.startsWith('#')) {
					// The collisionPipe is processed in a setInterval() call below
					this.collisionPipe.push([ Date.now(), bodyA.id, bodyA, bodyB ]);
				}

				if(bodyB.label.startsWith('#')) {
					// The collisionPipe is processed in a setInterval() call below
					this.collisionPipe.push([ Date.now(), bodyB.id, bodyB, bodyA ]);
				}
			}
		});

		// This interval runs every ~250ms (at time of this comment writing), and processes the list of collisions accumulated
		// in the past 250ms. This allows us to smooth out the collision processing and to move the processing out of the critical
		// path of the world simulation.
		this._collisionTid = setInterval( () => {

			// Pipe could be empty, which shift() will just return null and we'll exit
			let ref;
			while((ref = this.collisionPipe.shift())) {

				// Get the data added to the pipe above with array destructuringleft-wall
				const [ time, id, body, otherBody ] = ref;

				const data = 
					this.collisionData[id] || (
					this.collisionData[id] = { time: 0, otherBody });

				// Time since last processing a collision with this body
				const delta = time - data.time;

				// console.log({ label, delta, id, dataId: data.id });

				if(otherBody !== data.otherBody || delta > (body.pixiContainer.collisionTimeDelay || 500)) {
					if (body.label === '#glass') {
						body.pixiContainer.damage(otherBody.pixiContainer);
					} else
					if (body.label === '#bullet') {
						let consumed = false;
						// Note: Glass damage happens automatically because of the dual-.push() above in the event handler
						// Note: The KittyActor will ignore #bullets by default because they don't start with a bracket (<)
						//	so when multiplayer, will have to handle here or update KittyActor
						const hit = otherBody.label;
						if(hit.startsWith('<')) {
							if(hit !== '<doorblock>') {
								// Note: kitty can "damage" the health/star/bad blocks,
								// but only bullets damage <silver> blocks
								// Should be a breakable block - so damage the block
								if (otherBody.pixiContainer &&
									otherBody._breakable) {

									if(hit === '<silver>') {
										// silver blocks - let normal damage, take multiple hits
										otherBody.pixiContainer.damage(this.actor.obj, body.label);
									} else {
										otherBody.pixiContainer.finalDamage(this.actor.obj, body.label);
										if(hit !== '<bad>')
											this.actor.tryToConsume(hit, otherBody.pixiContainer.powerType);
									}
								}

								consumed = true;
							} else {
								// console.log("#bullet shot <doorblock>, ignoring");
							}
						} else
						// Let's see how bullets work with NOT resetting balls
						// if(hit === '[bouncyBall]') {
						// 	if (otherBody.pixiContainer)
						// 		otherBody.pixiContainer.resetBall();
							
						// 	consumed = true;
						// } else
						if(hit.startsWith('$')) { // game wall from BasicBorderedScene
							// consumed = true;
							// we don't do anything to the wall 
							// Changed mind - allow ricochet
						}

						if(consumed) {
							clearTimeout(body.__hitTid);
							body.__hitTid = setTimeout(() => {
								body.pixiContainer.finalDamage();
							}, 0);
						}
						
					} else {
						console.warn("Don't know what todo with this body:", body.label, body);
					}

					data.time = time;
				} else {
					
				}
			}
		}, 33);
	}

	async addMeters() {
		const { liveGameContainer: gameContainer, resources } = this;
		
		let highScore = window.localStorage.getItem('kitty-highscore') || 0;
		// const remainingStars = (this.objectsToClearLevel || []).length;
		
		let startLayoutY = 12, layoutY = startLayoutY, lastMeter = null;
		const paddingX = startLayoutY, paddingY = paddingX / 2;

		// Adjust for notch
		
		if(mobileDetect.hasNotch) {
			layoutY = startLayoutY = 32 + paddingY;
		}

		this.meters = {};
		this.meters.health = this.addObject(lastMeter = new BasicMeter({ resources,
			color: "red",
			label: "Energy",
			icon: "health",
			value: this.actor.currentHealth,
			maxValue: MAX_HEALTH,
			position: {
				x: paddingX,
				y: layoutY,
			}
		}), gameContainer);
		
		layoutY += paddingY + lastMeter.height;

		this.meters.power = this.addObject(lastMeter = new BasicMeter({ resources,
			color: "grayscale",
			label: "Lasers", // updated in updatePowerMeter
			icon:  "power", // updated in updatePowerMeter
			value: 0, // set in updatePowerMeter
			maxValue: 0, // set in updatePowerMeter
			position: {
				x: paddingX,
				y: layoutY,
			}
		}), gameContainer);

		setTimeout(() => {
			this.updatePowerMeter(this.actor.currentPower, this.actor.activeItem);
		}, 500);

		layoutY += paddingY + lastMeter.height;

		// NB: We are putting the timer "meter" RIGHT OVER the power meter,
		// and hiding it until used, so that's why we don't change the layoutY
		// Update: Now we are moving it below...

		this.meters.timer = this.addObject(lastMeter = new BasicMeter({ resources,
			color: "grayscale",
			label: "Time Remaining", // set by start timer
			icon:  "timer", 
			value: 0,  // set by start timer
			maxValue: 0,  // set by start timer
			position: {
				x: paddingX,
				y: layoutY,
			}
		}), gameContainer);

		// slightly oragnish tint for timer meeter
		this.meters.timer.setTint(0xFFD42A);
		// create timer routine to animate the meter value,
		// returns a promise that resolves when timer expires.
		// Auto-hites power meter when timer starts, auto-shows at end
		this.meters.timer.resolvers = [];
		this.meters.timer.startTimer = (length = 10000)  => {
			const self = this.meters.timer;
			return new Promise(resolve => {
				clearInterval(self.tid);
				this.meters.timer.resolvers.push(resolve);
				self.setValue(length, length);
				if(!self.fadeInStarted) {
					self.fadeInStarted = true;
					PixiUtils.fadeIn(self);
					// Hide power beter because text background layer in
					// meter is slightly transparent so the power meter's
					// text shows thru the timer meter's text background
					// PixiUtils.fadeOut(this.meters.power);

					// set these pluginHidingLocked flags here and lower
					// to prevent the relevant viewport plugin from re-showing the 
					// power meter when the actor moves behind it while the timer is running
					// this.meters.power.pluginHidingLocked = true;
					// this.meters.power.__hidden = true;

					this.meters.timer.pluginHidingLocked = false;
					this.meters.timer.__hidden = false;
				}
				
				const start = Date.now();
				self.fadeOutStarted = false;
				self.tid = setInterval(() => {

					const elapsed  = Date.now() - start,
						percent    = elapsed / length,
						meterValue = Math.max(0, (1 - percent) * length);

					if(meterValue) {
						const seconds = Math.ceil(meterValue / 1000);
						self.setValue(meterValue, length, `Time Remaining: 0:${seconds < 10 ? '0' : ''}${seconds}`);

						if(meterValue < 250 && !self.fadeOutStarted) {
							self.fadeOutStarted = true;
							PixiUtils.fadeOut(self);
							// PixiUtils.fadeIn(this.meters.power);
						}
					} else {
						self.alpha = 0;
						self.fadeInStarted = false;
						
						clearInterval(self.tid);
						// resolve();
						this.meters.timer.resolvers.forEach(resolve => resolve());
						this.meters.timer.resolvers = [];

						// this.meters.power.pluginHidingLocked = false;
						// this.meters.power.__hidden = false;

						this.meters.timer.pluginHidingLocked = true;
						this.meters.timer.__hidden = true;
					}
				}, 1000 / 24);
			});
		};

		// Set the pluginHidingLocked so the relevant plugin doesn't try to show
		// the timer meter if not running
		this.meters.timer.pluginHidingLocked = true;
		this.meters.timer.__hidden = true;

		// shown by start timer
		this.meters.timer.alpha = 0;

		
		// layoutY += paddingY + lastMeter.height;

		// reset, moving the following 2 meters to right side of screen
		layoutY = startLayoutY;

		// this.meters.score = this.addObject(lastMeter = new BasicMeter({ resources,
		// 	color: "yellow",
		// 	label: "Stars",
		// 	icon: "icon_star", // TODO: determine if this works
		// 	value: this.actor.currentScore,
		// 	maxValue: highScore,
		// 	// indefinite: true,
		// 	position: {
		// 		x: window.innerWidth - paddingX - lastMeter.width,
		// 		y: layoutY,
		// 	}
		// }), gameContainer);

		// // Override scaling to fit non-standard icon into meter
		// this.meters.score.sprites.icon.scale.x = 0.0625;
		// this.meters.score.sprites.icon.scale.y = 0.0625;
		// // Fix vertical centering appearance
		// this.meters.score.sprites.icon.y = this.meters.score.sprites.icon_holder.height * .48;
		
		// layoutY += paddingY + lastMeter.height;
		
		// this.meters.level = this.addObject(lastMeter = new BasicMeter({ resources,
		// 	color: "pink",
		// 	label: LEVEL_NUM_LABEL,
		// 	icon: "xp",
		// 	value: 0,
		// 	maxValue: (this.objectsToClearLevel || []).length, // percent will be set with objectsToClearLevel.length later
		// 	customLabel: LEVEL_NUM_LABEL + " " + this.level.levelNum + ": " + (this.objectsToClearLevel || []).length + " stars",
		// 	position: {
		// 		x: window.innerWidth - paddingX - lastMeter.width,
		// 		y: layoutY,
		// 	}
		// }), gameContainer);
		
		this.meters.level = this.addObject(lastMeter = new BasicMeter({ resources,
			color: "yellow",
			label: LEVEL_NUM_LABEL,
			icon: "icon_star", // TODO: determine if this works
			value: 0,
			maxValue: (this.objectsToClearLevel || []).length, // percent will be set with objectsToClearLevel.length later
			customLabel: LEVEL_NUM_LABEL + " " + this.level.levelNum + ": " + (this.objectsToClearLevel || []).length + " stars",
			// indefinite: true,
			position: {
				x: window.innerWidth - paddingX - lastMeter.width,
				y: layoutY,
			}
		}), gameContainer);

		// Override scaling to fit non-standard icon into meter
		this.meters.level.sprites.icon.scale.x = 0.0625;
		this.meters.level.sprites.icon.scale.y = 0.0625;
		// Fix vertical centering appearance
		this.meters.level.sprites.icon.y = this.meters.level.sprites.icon_holder.height * .48;

		// Update custom label and value at same time
		this.meters.level.updateStarCount = () => {
			
			// Number of stars remaining vs stars at start
			const start = this.objectsToClearLevelStartingLength,
				now     = this.objectsToClearLevel.length,
				left    = start - now,
				// How hard the level is
				fd      = this.level.featureData || {difficulty:0.1},
				hard    = Math.ceil(fd.difficulty * 100),
				// Curret level #
				level   = this.level.levelNum;

			this.meters.level.setValue(
				left, 
				start,
				`#${level} (${hard}% hard): ${left}/${start} stars`,
			);
		};

		// Tutorial swipe overlay
		if(this.isTutorial) {
			const root = new PIXI.Container();
			this.addObject(root, gameContainer);

			root.animPromise = await PixiUtils.getAnimatedSprite(anim_swipe_right_sheet, resources.anim_swipe_right_img).then(sprite => {
				if(!sprite) {
					console.warn("Error loading anim_swipe_right");
					return;
				}
	
				sprite.x     = 0;// this.sprite.width/2;
				sprite.y     = 0;// this.sprite.height/2;
				sprite.anchor.y = 0.5;
				sprite.anchor.x = 0.5; 
				sprite.scale = new PIXI.Point(.5, .5); // TBD
				sprite.animationSpeed = 0.1;
	
				sprite.stop();
				// sprite.alpha = 0;

				root.addChild(sprite);
				root.anim = sprite;
	
			});

			root.x = window.innerWidth / 2;//  - root.width/2; //(this.sprites.icon_holder.width)  / 2;
			root.y = window.innerHeight * .67;// - root.height/2; //(this.sprites.icon_holder.height) / 2;
			root.alpha = 0;

			root.play = async () => {
				await root.animPromise;
				root.anim.play();
				PixiUtils.fadeIn(root);
			}
			root.interactive = true;
			root.click = root.tap = async () => {
				await PixiUtils.fadeOut(root);
				root.anim.stop();
			};

			this.swipeAnim = root;

		}

		// End-of-level check animation 
		{
			const root = new PIXI.Container();
			this.addObject(root, gameContainer);

			const scale = .33;
			

			const sparkle = new PIXI.Sprite(resources.sparkle_static_img.texture);
			root.addChild(sparkle);
			
			// sprites.sparkle_static_img.y = -scaledSize * 0.1;
			sparkle.scale.x = scale * 3;
			sparkle.scale.y = scale * 3;
			sparkle.anchor.x = 0.5;
			sparkle.anchor.y = 0.5;
			
			sparkle.alpha = 0.85;
			sparkle.play = () => {
				let ticker;
				const s = sparkle;
				PixiUtils.fadeIn(s, 300);
				this.game.app.ticker.add(ticker = () => {
					if(!s || !s.transform)
						return;
					s.rotation += 0.05;
				});
				sparkle.ticker = ticker;
				return true;
			}

			sparkle.stop = () => {
				return PixiUtils
					.fadeOut(sparkle, 500)
					.then(() => {
						this.game.app.ticker.remove(sparkle.ticker);
					});
			}


			const icon = new PIXI.Sprite(resources.icon_check.texture);
			root.addChild(icon);
			icon.scale = new PIXI.Point(scale, scale);
			icon.anchor.x = 0.5;
			icon.anchor.y = 0.65; // better centers the "Body" of the check over center of sparkle	
			icon.x = 0;
			icon.y = 0;
			icon.alpha = 0.85;

			this.endOfLevelSparkle = root;
			root.x = window.innerWidth / 2;//  - root.width/2; //(this.sprites.icon_holder.width)  / 2;
			root.y = window.innerHeight * .67;// - root.height/2; //(this.sprites.icon_holder.height) / 2;
			
			root.sparkle = sparkle;
			root.icon = icon;
			
			// sparkle.play();


			root.playTx = () => {
				if(this.isDestroyed || !this.meters.level)
					return;
					
				const current = {
					scale: 1,
					x: window.innerWidth/2,
					y: window.innerHeight * .67,
				};

				const dest = {
					scale: .25,
					x: this.meters.level.x + this.meters.level.width  * .17, // move over top of icon
					y: this.meters.level.y + this.meters.level.height * .6
				};

				const time = 1000;
				
				const tweenPromise = new Promise(resolve => {
					new TWEEN.Tween(current)
						.to(dest, time)
						.easing(TWEEN.Easing.Elastic.InOut)
						.onUpdate(() => {
							try { // fix https://sentry.io/organizations/sleepy-cat-game/issues/960100267
								root.scale.x = current.scale;
								root.scale.y = current.scale;
								root.position.x = current.x;
								root.position.y = current.y;
							} catch(e) {
								console.warn("Problem tweening:", e);
							}
						})
						.onComplete(() => resolve())
						.start();
					
					PixiUtils.touchTweenLoop();
				});

				tweenPromise.then(() => {
					sparkle.stop();
				})
		
				return tweenPromise;
						 
			}

			root.alpha = 0;
			root.play = () => sparkle.play() && PixiUtils.fadeIn(root)
				.then(() => {
					setTimeout(() => {
						if(this.isDestroyed)
							return;

						root.playTx();
					}, 150);
				});
		}

		// Multiple-star-at-once reward animation
		{
			const root = new PIXI.Container();
			this.addObject(root, gameContainer);

			const scale = .33;
			

			const sparkle = new PIXI.Sprite(resources.sparkle_static_img.texture);
			root.addChild(sparkle);
			
			// sprites.sparkle_static_img.y = -scaledSize * 0.1;
			sparkle.scale.x = scale * 3;
			sparkle.scale.y = scale * 3;
			sparkle.anchor.x = 0.5;
			sparkle.anchor.y = 0.5;
			
			sparkle.alpha = 0; //0.85;
			sparkle.play = () => {
				let ticker;
				const s = sparkle;
				PixiUtils.fadeIn(s, 300);
				this.game.app.ticker.add(ticker = () => {
					if(!s || !s.transform)
						return;
					s.rotation += 0.05;
				});
				sparkle.ticker = ticker;
				return true;
			}

			sparkle.stop = () => {
				return PixiUtils
					.fadeOut(sparkle, 500)
					.then(() => {
						this.game.app.ticker.remove(sparkle.ticker);
					});
			}

			const fireworks = await PixiUtils.getAnimatedSprite(anim_firework3white_sheet, resources.anim_firework3white_img);
			root.addChild(fireworks);
		
			// const scaledSize = (this.baseBlockSize * this.scale);

			fireworks.x = 0;//scaledSize * .33; //this.sprite.texture.width / 8;
			fireworks.y = 0;//-1016 * .25; //1016 is base size of fireworks frame;// scaledSize * 2; 
			fireworks.scale = new PIXI.Point(1,1); //0.5, 0.5);
			fireworks.anchor.x = 0.45;
			fireworks.anchor.y = 0.5;
			
			// sprite.tint  = this.kittyColor; // 0xFF5599;
			// sprite.speed = .5;
			
			// Only show when wanted
			// sprite.alpha = 0;
			// sprite.stop();

			// PixiUtils.setupShortAnim(fireworks, {
			// 	fadeIn:  50,
			// 	play:    1150,
			// 	fadeOut: 200
			// });

			// Stop after one time thru
			fireworks.loop = true;
			fireworks.onLoop = () => {
				fireworks.stop();
				fireworks.alpha = 0;
				// if(!this.isDestroyed)
				// 	root.playTx();
			};

			// Auto-show on play
			const play = fireworks.play.bind(fireworks);
			fireworks.play = () => {
				play();
				fireworks.alpha = 1;
			};
			
		
			// const icon = new PIXI.Sprite(resources.icon_check.texture);
			// root.addChild(icon);
			// icon.scale = new PIXI.Point(scale, scale);
			// icon.anchor.x = 0.5;
			// icon.anchor.y = 0.65; // better centers the "Body" of the check over center of sparkle	
			// icon.x = 0;
			// icon.y = 0;
			// icon.alpha = 0.85;

			const dpi = window.devicePixelRatio,
				fontSize = (
					dpi <= 1.0 ? 16 :
					dpi <= 2.0 ? 14 :
					dpi <= 3.0 ? 12 : 10
				) * 5.5;/// .33; // .33 is button scale
					
			// console.log("[BasicMeter] fontSize:", fontSize);

			const baseTextStyle = {
				// This font is loaded in the react <KittyGameView> component
				fontFamily: "Dimbo-Regular, Arial",
				fontSize,
				lineHeight: fontSize*.8,
				fill: "white",
				stroke: '#000000',
				strokeThickness: 1 / .33,
				align: "center",
				dropShadow: true,
				dropShadowColor: "#000000",
				dropShadowBlur: 5 / .33,
				dropShadowAngle: Math.PI / 6,
				dropShadowDistance: 1 / .33,
			};

			const numberLabel = new PIXI.Text("6x", {
				...baseTextStyle,

				// fontSize: fontSize *.75, 
				// lineHeight: fontSize * 0.75 * .9,
				fill: "#ffffff",
			});
			root.addChild(numberLabel);
			numberLabel.anchor.x = 0.5;//0.5;
			numberLabel.anchor.y = 0.5;//0.5;
			numberLabel.x = 0;
			numberLabel.y = 0;
			

			this.multiStarRewardSparkle = root;
			root.x = window.innerWidth / 2;//  - root.width/2; //(this.sprites.icon_holder.width)  / 2;
			root.y = window.innerHeight * .67;// - root.height/2; //(this.sprites.icon_holder.height) / 2;
			
			root.sparkle = sparkle;
			// root.icon = icon;
			root.numberLabel = numberLabel;
			root.fireworks = fireworks;
			
			// for testing
			// sparkle.play();


			root.playTx = () => {
				if(this.isDestroyed || !this.meters.level)
					return;
					
				const current = {
					scale: 1,
					x: window.innerWidth/2,
					y: window.innerHeight * .67,
				};

				const dest = {
					scale: .25,
					x: this.meters.level.x + this.meters.level.width  * .17, // move over top of icon
					y: this.meters.level.y + this.meters.level.height * .6
				};

				const time = 1000;
				
				const tweenPromise = new Promise(resolve => {
					new TWEEN.Tween(current)
						.to(dest, time)
						.easing(TWEEN.Easing.Elastic.InOut)
						.onUpdate(() => {
							try { // fix https://sentry.io/organizations/sleepy-cat-game/issues/960100267
								root.scale.x = current.scale;
								root.scale.y = current.scale;
								root.position.x = current.x;
								root.position.y = current.y;
							} catch(e) {
								console.warn("Problem tweening:", e);
							}
						})
						.onComplete(() => resolve())
						.start();
					
					PixiUtils.touchTweenLoop();
				});

				tweenPromise.then(() => {
					sparkle.stop();
				});

				// Start fade out before end of tx
				setTimeout(() => {
					PixiUtils.fadeOut(root, 250);
				}, time - 250);
		
				return tweenPromise;
						 
			}

			root.alpha = 0;
			root.play = ({ rewardNumber = 2 }) => {
				
				// Reset root position
				root.scale.x = 1;
				root.scale.y = 1;
				root.position.x = window.innerWidth/2;
				root.position.y = window.innerHeight * .67;

				const tileThemeColors = {
					orange:   '#FCBC00', //
					// blue:     '#0EEFFF', // 
					purple:   '#D42AFF', //
					red:      '#F57171', //
					// silver:   '#F1F1F1', // silver
					yellow:   '#FFFF00', // 
					pink:     '#EF307C', // 
					// cream:    '#EF2F7C', // cream
				};

				const colors = Object.values(tileThemeColors);
				const idx = rewardNumber - 2,
					color = idx > -1 && idx < colors.length ? 
						colors[idx] : tileThemeColors.pink;

				sparkle.tint = fireworks.tint = parseInt(color.replace('#',''), 16);
				numberLabel.style.fill = color;
				
				sparkle.play();
				fireworks.play();
				numberLabel.text = rewardNumber + "x";
				PixiUtils.fadeIn(root).then(() => {
					setTimeout(() => {
						if(this.isDestroyed)
							return;

						root.playTx();
					}, 100);
				});
			};
		}

		
		layoutY += paddingY + lastMeter.height;

		window.addEventListener("resize", this._meterAdjustHandler = () => {
			this.meters.level.__hidingRect = null;
			// this.meters.score.__hidingRect = null; // reset for HideOverActorViewportPlugin updating
			// this.meters.score.x =
			this.meters.level.x = window.innerWidth - paddingX - lastMeter.width;
		}, false);

		// Fix odd rendering bug where last meter (pink) gets really weird "infinite" size/position
		setTimeout(() => this._meterAdjustHandler(), 100);
		

		// // TEST 
		// this.addObject(window.bubbleText = new BubbleText({
		// 	bubbleRes: resources.bubble_rect,
		// 	// text: "What wonderful things God hath wrought!",
		// 	text: "Welcome to Sleepy Cat! Collect all the stars on this level to learn more of what " + ServerStore.currentCat.name + " can do!\n\n(Click to close)",
		// 	position: {
		// 		x: window.innerWidth / 2,
		// 		y: window.innerHeight / 2,
		// 	}
		// }), gameContainer);


		// this.meters.level.click = this.meters.level.tap = () => {
		// 	if(this.currentMapName === '-' && window.confirm("Are you sure you want to give up on this level?")) {
		// 		this.getRandomMap(true); // generate new random map for next load
		// 		this.game.setScene('KittyFlySleep', { mapName: '-' });
		// 	}
		// };
		// this.meters.level.interactive = true;
		// this.meters.level.buttonMode = true;

		this.actor.on('stars', newStars => {
			this.buttons.shop.label.text = numberWithCommas(Math.ceil(newStars));		
		});
		
		this.actor.on('points', newPoints => {
			// textScore.text = "Score: " + Math.ceil(newScore);
			
			if(newPoints > highScore) {
				window.localStorage.setItem('kitty-highscore', highScore = Math.ceil(newPoints));
				// textHigh.text = 'High Score: ' + Math.ceil(highScore) ;
			}

			// this.meters.score.setValue(Math.ceil(newScore), Math.ceil(highScore));
			this.buttons.back.label.text = numberWithCommas(Math.ceil(newPoints)) + " pnts";
			
		});

		const gameEndScreen = window.gameEndScreen = () => {

			PixiUtils.fadeOut(this.liveGameContainer, 1000);
			PixiUtils.fadeIn(this.gameOverContainer, 1000);

			this.liveGameContainer.interactiveChildren = false;
			this.gameOverContainer.interactiveChildren = true;
		};

		this.actor.isKittyDead = false;

		this.actor.on('health', newHealth => {
			// textHealth.text = "Health: " + Math.ceil(newHealth) + '%';
			this.meters.health.setValue(Math.ceil(newHealth));

			if(newHealth <= 0 && !this.actor.isKittyDead) {
				this.actor.isKittyDead = true;
				
				gameEndScreen();

				// console.error("YOU DEAD");
			}
		});
		
		this.actor.on('power', ({ amount: newPower, itemDef }) => {
			this.updatePowerMeter(newPower, itemDef);
		});

	}

	startTimerMeter(length = 10000) {
		if (this.meters && 
			this.meters.timer)
			return this.meters.timer.startTimer(length);
		
		return Promise.resolve();
	}

	updatePowerMeter(newPower, itemDef) {
		if(!itemDef) {
			this.meters.power.alpha = 0;
			this.meters.power.pluginHidingLocked = true;
			this.meters.power.__hidden = true;
			
			if(newPower)
				console.warn("[updatePowerMeter] no itemDef given, cannot update power")
			// return;
		} else {
			this.meters.power.alpha = 1;
			this.meters.power.pluginHidingLocked = false;
			this.meters.power.__hidden = false;

			// textPower.text = "Bullets: " + Math.ceil(newPower);

			if(itemDef === this.actor.activeItem) {
				this.meters.power.opts.label = itemDef.name.replace('\n',' '); // label synced to pixi in setValue
				this.meters.power.setValue(Math.ceil(newPower), itemDef.maxItems);
					
				const icon = this.resources[itemDef.iconRes];
				if(icon !== this.meters.power.currentIconResource) {
					this.meters.power.setIcon(icon, itemDef.meterIconScale || 0.0625);
				}

				// TODO: Use better base color so tint works better
				this.meters.power.setTint(itemDef.baseTint);

				const fire = this.buttons && this.buttons.fire;
				if(fire && icon !== fire.currentIconResource) {
					fire.setIcon(fire.currentIconResource = icon);
					fire.label.text = itemDef.name;
				}
			}
		}

		if(!this.buttons) {
			// UI not setup yet
			return;
		}

		const numItems  = this.actor.numItems(),
			hasAnyItems      = numItems > 0,
			hasMultipleItems = numItems > 1,
			p1   = this.buttons.powerpillUp,
			p2   = this.buttons.powerpillDown,
			fire = this.buttons.fire,
			visibleAlpha = 0.7;

		// Only show up/down arrow if more than one item
		if(hasMultipleItems) {
			p1.pluginHidingLocked =
			p2.pluginHidingLocked = false;
			
			p1.__hidden = 
			p2.__hidden = false;
			
			p1.interactive =
			p1.buttonMode  =
			p2.interactive =
			p2.buttonMode  = true;
			
			// alpha will be auto-managed by plugin on next update()
			p1.alpha =
			p2.alpha = 
			p1.pluginVisibleAlpha = 
			p2.pluginVisibleAlpha = visibleAlpha

		} else {
			p1.pluginHidingLocked =
			p2.pluginHidingLocked = true;
			
			p1.__hidden = 
			p2.__hidden = true;

			p1.interactive =
			p1.buttonMode  =
			p2.interactive =
			p2.buttonMode  = false;

			// alpha will be auto-managed by plugin on next update()
			p1.alpha = p2.alpha = 0;
		}

		// Hide fire button if nothing to fire
		fire.pluginHidingLocked = !hasAnyItems;
		if(!hasAnyItems) {
			fire.alpha = 0;
			fire.__hidden = true;
		} else {
			fire.alpha = 
			fire.pluginVisibleAlpha = visibleAlpha;
			fire.__hidden = false;
		}
			
	}
	

	addParallaxBg() {
		const { resources, worldHeight, worldWidth, viewport, tileBaseSize } = this;

		const pbgLayers = [];

		let bgType = this.mapBg || 'mountains';
		if(bgType === 'tutorial')
			bgType = 'burst';

		if(bgType !== 'mountains' && !bgType.startsWith('bg_img') && !['water','stars'].includes(bgType))
			bgType = "bg_img_" + bgType + "_blue";

		// console.log({marker, bgType, level:this.currentLevelId});
		// console.log({bgType});
		
		// Used to change tile texture used to ice type if bg_img_ice
		this.bgType = bgType;

		const cloudLayer = [];
	
		if(['mountains'].includes(bgType)) {
			pbgLayers.push([this.sunSprite]);
			
			const numHighClouds = Math.ceil(worldHeight / 4000);
			for(let i=0;i<numHighClouds;i++) {

				// layer 2 - next layer - 0.5 as much movement as prev
				{
					const cloudHeight = resources.pbg_fcloud2.texture.orig.height;
					const cloud2 = new PIXI.extras.TilingSprite(resources.pbg_fcloud2.texture, worldWidth * 2, cloudHeight);

					cloud2.x = -tileBaseSize;//window.innerWidth / 2;
					cloud2.y = worldHeight - i * 2000 - 2500;//window.innerHeight / 2;
					this.addObject(cloud2);

					cloudLayer.push( cloud2 ); // array for more than one sprite in a layer
				}

				// layer 2 - next layer - 0.5 as much movement as prev
				{
					const cloudHeight = resources.pbg_fcloud1.texture.orig.height;
					const cloud2 = new PIXI.extras.TilingSprite(resources.pbg_fcloud1.texture, worldWidth * 2, cloudHeight);

					cloud2.x = -tileBaseSize;//window.innerWidth / 2;
					cloud2.y = worldHeight - i * 2000 - 1500;//window.innerHeight / 2;
					this.addObject(cloud2);

					cloudLayer.push( cloud2 ); // array for more than one sprite in a layer
				}
			}
		}

		// high clouds could theoretically be used with other BGs ...at least that's what I'm thinking, could be wrong 

		if(['mountains'].includes(bgType)) {
			{
				const texture = resources.pbg_mcloud1.texture;
				const texHeight = texture.orig.height;
				const sprite = new PIXI.extras.TilingSprite(texture, worldWidth * 2, texHeight);

				sprite.x = - tileBaseSize;//window.innerWidth / 2;
				sprite.y = worldHeight - 100;
				sprite.anchor.y = 1;
				this.addObject(sprite);

				cloudLayer.push( sprite ); // array for more than one sprite in a layer
			}

			// layer 1 - next layer - 0.5 as much movement as 0
			{
				const cloudHeight = resources.pbg_mcloud2.texture.orig.height;
				const cloud1 = new PIXI.extras.TilingSprite(resources.pbg_mcloud2.texture, worldWidth * 2, cloudHeight);

				cloud1.x = - tileBaseSize;//window.innerWidth / 2;
				cloud1.y = worldHeight - cloudHeight * 0.5;//window.innerHeight / 2;
				cloud1.anchor.y = 1;
				this.addObject(cloud1);

				cloudLayer.push( cloud1 ); // array for more than one sprite in a layer
			}

			pbgLayers.push(cloudLayer);

			// layer 0 - closest layer
			{
				const texture = resources.pbg_mtn_bg.texture;
				const texHeight = texture.orig.height;
				const sprite = new PIXI.extras.TilingSprite(texture, worldWidth * 2, texHeight);

				sprite.x = - tileBaseSize;//window.innerWidth / 2;
				sprite.y = worldHeight - tileBaseSize * 0.5 * 0.75 - 109 * 0.5 - 119 * 0.33;
				sprite.anchor.y = 1;
				this.addObject(sprite);

				pbgLayers.push([ sprite ]); // array for more than one sprite in a layer
			}

			{
				const texture = resources.pbg_mtn_mid.texture;
				const texHeight = texture.orig.height;
				const sprite = new PIXI.extras.TilingSprite(texture, worldWidth * 2, texHeight);

				sprite.x = - tileBaseSize;//window.innerWidth / 2;
				sprite.y = worldHeight - tileBaseSize * 0.45 * 0.75 - 109 * 0.5;
				sprite.anchor.y = 1;
				this.addObject(sprite);

				pbgLayers.push([ sprite ]); // array for more than one sprite in a layer
			}

			const frontLayer = [];
			{
				const texture = resources.pbg_mtn_fg.texture;
				const texHeight = texture.orig.height;
				const sprite = new PIXI.extras.TilingSprite(texture, worldWidth * 2, texHeight);

				sprite.x = - tileBaseSize;//window.innerWidth / 2;
				sprite.y = worldHeight - tileBaseSize * 0.35 * 0.75;
				sprite.anchor.y = 1;
				this.addObject(sprite);

				frontLayer.push(sprite); // array for more than one sprite in a layer
			}


			// Add a cover for bottom
			{
				const texture = resources.pbg_mtn_fg.texture;
				const texHeight = texture.orig.height;
				const sprite = new PIXI.extras.TilingSprite(texture, worldWidth * 2, texHeight);

				sprite.x = - tileBaseSize * 2;//window.innerWidth / 2;
				sprite.y = worldHeight;
				sprite.anchor.y = 1;
				this.addObject(sprite);

				frontLayer.push(sprite); // array for more than one sprite in a layer
			}

			pbgLayers.push(frontLayer);
		}

		// bgType = 'water';

		if(bgType.startsWith('bg_img_') || ['water','stars'].includes(bgType)) {
			if(bgType === 'water')
				bgType = 'water_bg_center';
			else
			if(bgType === 'stars')
				bgType = 'stars_bg';

			const sprite = new PIXI.Sprite(resources[bgType].texture);
			this.addObject(sprite);

			// if(bgType === 'bg_img_ice') {
			// 	// Move sun over top of bg
			// 	// this.sunSprite && this.addObject(this.sunSprite);
			// }

			sprite.anchor.x = 0.5;
			sprite.anchor.y = 0.5;

			// const scale = 1.25;
			// sprite.scale = new PIXI.Point(scale, scale);

			sprite.x = worldWidth / 2;
			sprite.y = worldHeight / 2;
			if(bgType.includes('water') || bgType === 'stars') {
				sprite.anchor.y = 1;
				sprite.y = viewport.worldHeight;
			} else 
				pbgLayers.push([sprite]); // layer must be a list of sprites to move as a single unit


			// Scale to fit game world
			const fitScale = 
				Math.max(worldWidth, worldHeight) 
				/ Math.min(sprite.texture.orig.width, sprite.texture.orig.height)
				* (!['water_bg_center','stars'].includes(bgType) ? 2:1); //(bgType === 'bg_img_burst' ? 2 : 1); // ensure coverage of corners as we rotate

			sprite.scale = new PIXI.Point(fitScale, fitScale);
			
			if(bgType.startsWith('bg_img_burst')) {
				this.game.app.ticker.add(this._bgTick = (time) => {
					if (sprite.transform)
						sprite.rotation += 0.0005;
				});
			}

			// push an empty layer in front of the bg img so it moves less
			if(!bgType.includes('water')) {
				pbgLayers.push([]);
				pbgLayers.push([]);
				pbgLayers.push([]);
			}

			this.bgImg = sprite;
		}

		// Move layers based on viewport movement
		let lastViewportLeft = viewport.left;//, moveTid;
		let lastViewportTop  = viewport.top;
		viewport.on('moved', this._viewportMoveHandler = () => {
			// clearTimeout(moveTid);
			// moveTid = setTimeout(()=> {
				const movedByX = viewport.left - lastViewportLeft;
				const movedByY = viewport.top  - lastViewportTop;
				// console.log('viewport moved, by:', movedBy);

				if(movedByX !== 0 || movedByY !== 0) {
					pbgLayers.forEach((list, idx) => {
						const negRate = ( pbgLayers.length - idx) / pbgLayers.length;

						const layerAdjustAmountX = movedByX * negRate * .75 + .01;
						const layerAdjustAmountY = movedByY * negRate * .75 + .01;
						// console.log("[pbg] layer:", idx, ", layerAdjustAmount:", layerAdjustAmount);
						list.forEach(sprite => {

							// dont tx if destroyed
							if( !sprite || 
								!sprite.transform ||
								!sprite._texture  ||
								!sprite._texture.orig ||
								!sprite._texture.orig.width)
								return;
								
							const newX      = sprite.x + layerAdjustAmountX,
								w           = sprite.width,
								ax          = sprite.anchor.x,
								rightSpace  = w * (1-ax),
								leftSpace   = w * ax,
								worldWidth  = viewport.worldWidth;

							const rightEdge = newX + rightSpace;
							const leftEdge  = newX - leftSpace;

							// console.log("[pbg] layer:", idx, ", layerAdjustAmount:", layerAdjustAmount,", newX:" , newX);

							if(leftEdge > 0 || rightEdge < worldWidth) {
								// console.log("Refusing:", { newX, worldWidth, leftEdge, rightEdge, rightSpace, leftSpace, w, ax });
							} else {
								sprite.x = newX;
							}

							const newY      = sprite.y + layerAdjustAmountY,
								h           = sprite.height,
								ay          = sprite.anchor.y,
								topSpace    = h * (1-ay),
								bottomSpace = h * ay,
								worldHeight = viewport.worldHeight;

							const topEdge    = newY + topSpace;
							const bottomEdge = newY - bottomSpace;

							// console.log("[pbg] layer:", idx, ", layerAdjustAmount:", layerAdjustAmount,", newX:" , newX);

							if(topEdge > 0 || bottomEdge < worldHeight) {
								// console.log("Refusing:", { newX, worldWidth, leftEdge, rightEdge, rightSpace, leftSpace, w, ax });
							} else {
								sprite.y = newY;
							}

						});
					});
				}

				lastViewportLeft = viewport.left;
				lastViewportTop  = viewport.top;
			// }, 0);
		});
	}

	addButtons() {
		if(true) {
			const resources = this.resources;
			// const ctrlScale = 0.65;
			// const buttonScale = new PIXI.Point(ctrlScale, ctrlScale);
			// const btnSizeX = (buttonTexture.orig.width * ctrlScale);
			const stdPadding = 12;

			const dpi = window.devicePixelRatio,
				fontSize = (
					dpi <= 1.0 ? 16 :
					dpi <= 2.0 ? 14 :
					dpi <= 3.0 ? 12 : 10
				) / .33; // .33 is button scale
					
			// console.log("[BasicMeter] fontSize:", fontSize);

			const buttonTextStyle = {
				// This font is loaded in the react <KittyGameView> component
				fontFamily: "Dimbo-Regular, Arial",
				fontSize,
				lineHeight: fontSize*.8,
				fill: "white",
				stroke: '#000000',
				strokeThickness: 1 / .33,
				align: "center",
				dropShadow: true,
				dropShadowColor: "#000000",
				dropShadowBlur: 5 / .33,
				dropShadowAngle: Math.PI / 6,
				dropShadowDistance: 1 / .33,
			};

			this.buttons = {};

			const makeButton = (res, scale, position, action) => {
				const button = new PIXI.Sprite(res.texture);
				button.x = position.x;
				button.y = position.y;
				button.scale = new PIXI.Point(scale, scale);
				button.interactive = true;
				button.buttonMode = true;
				button.alpha = PixiMatterContainer.EditorMode ? 0 : FIRE_BTN_ALPHA;
				button.click = button.tap = () => {
					if(action)
						action();
					buttonHaptic();
				};

				button.setIcon = function(iconRes, scale=null, alpha=1.0) {
					const button = this;
					if(!button || !button.transform || !iconRes || !iconRes.texture)
						return;

					let oldRotation = 0;
					
					if (button.__icon) {
						oldRotation = button.__icon.rotation;
						button.removeChild(button.__icon);
						button.__icon.destroy();
					}
					const icon = new PIXI.Sprite(iconRes.texture);
					button.__icon = icon;
					icon.anchor.x = 0.5;
					icon.anchor.y = 0.5;
					icon.x = button.width * .69;
					icon.y = button.height * .69;
					if(!scale) {
						scale = Math.min(button.width * button.scale.x * .99, button.height * 0.99) /
							Math.max(iconRes.texture.orig.width, iconRes.texture.orig.height);
					}

					icon.scale = new PIXI.Point(scale, scale);
					icon.alpha = alpha || 1;
					icon.rotation = oldRotation;
					button.addChild(icon);
				}

				this.addObject(button, this.liveGameContainer);

				return button;
			};



			// makeButton(resources.small_green_button, resources.slider_left, {
			// 	x: stdPadding,
			// 	y: btnBottomY
			// }, () => this.actor.move('left'));

			// makeButton(resources.small_yellow_button, resources.slider_top, {
			// 	x: window.innerWidth / 2 - btnSizeX / 2,
			// 	y: btnBottomY
			// }, () => this.actor.jump());


			

			// PowerPill down button 
			// eslint-disable-next-line no-lone-blocks
			{
				this.buttons.powerpillDown = makeButton(resources.slider_bottom, .22, {
					x: stdPadding * 2.5, // + this.buttons.dpad.width/2 - (this.resources.x_button.texture.orig.width * 0.75)/2,
					y: 0
				}, () => {
					buttonHaptic();
					this.buttons.fire.nextItem('down');
				});
				this.buttons.powerpillDown.filters = [new DropShadowFilter({
					distance: 0
				})];

				this.buttons.powerpillDown._updatePositionForWindow = () => {
					this.buttons.powerpillDown.__hidingRect = null;// reset for HideOverActorViewportPlugin updating
					this.buttons.powerpillDown.y = window.innerHeight - stdPadding - this.buttons.powerpillDown.height;
				};
				this.buttons.powerpillDown._updatePositionForWindow();
			}


			
			// Fire button
			// eslint-disable-next-line no-lone-blocks
			{
				// NB 2nd arg is SCALE
				this.buttons.fire = makeButton(resources.x_button, 0.7, {
					x: stdPadding, // + this.buttons.dpad.width/2 - (this.resources.x_button.texture.orig.width * 0.75)/2,
					y: 0
				}, async () => {
					const { actor } = this,
						{ activeItem: itemDef } = actor;

					// if has item
					if (itemDef) {
						// Self-evident...
						buttonHaptic();

						// Get qty in inventory
						const amount = actor.itemAmount(itemDef);

						// If amount remaining, call action handler
						if(amount > 0) {
							// Execute action and reduce quantity
							itemDef.action(actor, this);
							actor.setItemAmount(itemDef, amount - 1);

							// For auto-switching active item by kitty
							itemDef._lastActivated = Date.now();


							// Because the fire button could be activated in VERY rapid succession
							// e.g. enter key on repeat, we want to debounce the metric logging until
							// no activation of this item for ~500ms (at time of writing)
							// but we still count all activations of that item while deferring logging
							// and just log that value as the count
							if(!itemDef._maccum)
								itemDef._maccum = { count: 0 };
							clearTimeout(itemDef._maccum.tid);
							
							itemDef._maccum.count ++;
							itemDef._maccum.tid = setTimeout(() => {
								// Log count in props for MixPanel, and still pass as 2nd arg for easy SQL summing
								ServerStore.metric("game.level.kitty.item." + itemDef.id + ".used", itemDef._maccum.count, { count: itemDef._maccum.count });
								itemDef._maccum = null;
							}, 500);

						} else {

							// Vibrate longer when empty
							navigator.vibrate && navigator.vibrate(200);

							// Same logic here - because fire button could be activated in VERY rapid succession,
							// we use a timer here to debounce the market UI if they hit zero until the button 
							// stops being activated for ~500ms
							clearTimeout(itemDef._zeroedTimer)
							itemDef._zeroedTimer = setTimeout(async () => {
								ServerStore.metric("game.level.kitty.item." + itemDef.id + ".empty.purchase_offer.shown");

								// no quantity left, so prompt to purchase
								if(await MarketUtils.purchaseItem(itemDef)) {
									// If purchase succeeds, update UI elements
									this.updatePowerMeter(actor.currentPower, itemDef);
									// stars MAY have changed, update anyway
									this.buttons.shop.label.text = numberWithCommas(Math.ceil(actor.db.stars));
									
									ServerStore.metric("game.level.kitty.item." + itemDef.id + ".empty.purchase_offer.accepted");
								} else {
									ServerStore.metric("game.level.kitty.item." + itemDef.id + ".empty.purchase_offer.rejected");
								}
							}, 500);
						}
					}
				});
				this.buttons.fire.anchor.x = 0;
				this.buttons.fire.anchor.y = 0;
				this.buttons.fire.filters = [new DropShadowFilter({
					distance: 0
				})];

				const textLabel = new PIXI.Text("", {
					...buttonTextStyle,

					fontSize: fontSize *.75, 
					lineHeight: fontSize * 0.75 * .9,
					fill: "#ffffff",
				});
				textLabel.anchor.x = 0.5;//0.5;
				textLabel.anchor.y = 0;//0.5;
				textLabel.x = this.buttons.fire.texture.orig.width / 2;
				textLabel.y = this.buttons.fire.texture.orig.height * 0.75;
				this.buttons.fire.addChild(textLabel);
				this.buttons.fire.label = textLabel;
				
				
				// this.buttons.fire.rotation = toRadians(90);

				this.buttons.fire._updatePositionForWindow = () => {
					this.buttons.fire.__hidingRect = null;// reset for HideOverActorViewportPlugin updating
					// this.buttons.fire.y = window.innerHeight - stdPadding - this.buttons.fire.height; ///2 - this.buttons.dpad.height/2;
					this.buttons.fire.y = this.buttons.powerpillDown.y - stdPadding * 2 - this.buttons.fire.height;
				};
				this.buttons.fire._updatePositionForWindow();

				// this.buttons.fire.setIcon(resources.pill_red);

				// eslint-disable-next-line no-lone-blocks
				if(true) {
				
					const b = this.buttons.fire;
					b.nextItem = dir => {
						const inc = dir === 'up' ? 1 : -1;
						const items = this.actor.items();
						const activeRef = items.find(x => x.itemDef === this.actor.activeItem)
						const idx = items.indexOf(activeRef);
						if( idx < 0 ) {
							return b.setCurrentItem(items[0]);
						}
						let newIdx = idx + inc;
						if(newIdx < 0)
							newIdx = items.length - 1;
						if(newIdx > items.length - 1)
							newIdx = 0;
						return b.setCurrentItem(items[newIdx].itemDef);
					}

					b.setCurrentItem = (itemDef, ignoreMetric) => {
						// b.currentItem = itemDef;
						if(!itemDef)
							return;

						// Store metric
						if(!ignoreMetric)
							ServerStore.metric("game.level.kitty.item." + itemDef.id + ".selected");
						
						this.buttons.fire.label.text = itemDef.name;
						this.actor.setActiveItem(itemDef);
						b.setIcon(b.currentIconResource = this.resources[itemDef.iconRes]);
					};					
				}

				this.game.app.ticker.add(this._fireButtonTicker = () => {
					const icon = this.buttons.fire.__icon;
					if(icon && icon.transform)
						icon.rotation += 0.01
				});
			}


			// PowerPill up button 
			// eslint-disable-next-line no-lone-blocks
			{
				this.buttons.powerpillUp = makeButton(resources.slider_top, .22, {
					x: stdPadding * 2.5, // + this.buttons.dpad.width/2 - (this.resources.x_button.texture.orig.width * 0.75)/2,
					y: 0
				}, () => {
					// WelcomeScene.disableAutoStart = true;
					// this.game.setScene('WelcomeScene');
					// if(this.actor.hasPowerPill)
					// 	this.explosion();
					buttonHaptic();
					this.buttons.fire.nextItem('up');
				});
				this.buttons.powerpillUp.filters = [new DropShadowFilter({
					distance: 0
				})];

				this.buttons.powerpillUp._updatePositionForWindow = () => {
					this.buttons.powerpillUp.__hidingRect = null;// reset for HideOverActorViewportPlugin updating
					this.buttons.powerpillUp.y = this.buttons.fire.y - stdPadding * 2 - this.buttons.powerpillUp.height;

					// this.buttons.powerpillUp.y = this.buttons.fire.y
					// 	- this.buttons.fire.height * this.buttons.fire.scale.y 
					// 	// - stdPadding
					// 	- this.buttons.powerpillUp.height * this.buttons.powerpillUp.scale.y; ///2 - this.buttons.dpad.height/2;
					// this.buttons.powerpillUp.x = window.innerWidth  - stdPadding - this.buttons.powerpillUp.width; ///2 - this.buttons.dpad.height/2;
				};
				this.buttons.powerpillUp._updatePositionForWindow();
			}

			// Make a group for hiding all together...
			const hidingGroup = [
				this.buttons.powerpillDown,
				this.buttons.fire,
				this.buttons.powerpillUp
			];

			// used by HideOverActorViewportPlugin to hide these buttons as a group when one is hidden
			hidingGroup.forEach(obj => obj.pluginHidingGroup = hidingGroup);

			// Update icon
			this.buttons.fire.setCurrentItem(this.actor.activeItem, true/*ignoreMetric*/);

			// Dpad button
			// eslint-disable-next-line no-lone-blocks
			// {
			// 	const buttonTexture = resources.d_pad_1.texture;
			// 	const btnSizeY = (buttonTexture.orig.height * ctrlScale);
			
			// 	// NB 2nd arg is scale NOT alpha
			// 	this.buttons.dpad = makeButton(resources.d_pad_1, 0.625, {
			// 		x: 0,
			// 		y: 0
			// 	});// () => this.actor.move('right'));
				
			// 	this.buttons.dpad.filters = [new DropShadowFilter({
			// 		distance: 0
			// 	})];

			// 	this.buttons.dpad._updatePositionForWindow = () => {
			// 		this.buttons.dpad.__hidingRect = null;// reset for HideOverActorViewportPlugin updating
			// 		this.buttons.dpad.x = (window.innerWidth) / 2 - this.buttons.dpad.width  / 2 + stdPadding * 4;
			// 		// this.buttons.back.y = window.innerHeight - stdPadding - this.buttons.back.height; ///2 - this.buttons.dpad.height/2;
			// 		// this.buttons.dpad.x = window.innerWidth  - stdPadding - this.buttons.dpad.width; ///2 - this.buttons.dpad.height/2;
			// 		this.buttons.dpad.y = window.innerHeight - btnSizeY - stdPadding;
			// 	};
			// 	this.buttons.dpad._updatePositionForWindow();
			// 	this.buttons.dpad.alpha = PixiMatterContainer.EditorMode ? 0 : .55;
			// }

			// Back button 
			// eslint-disable-next-line no-lone-blocks
			{
				this.buttons.back = makeButton(resources.blank_button, 0.33, {
					x: 0, //stdPadding, // + this.buttons.dpad.width/2 - (this.resources.x_button.texture.orig.width * 0.75)/2,
					y: 0
				}, () => {
					// Stop stuff from changing as we fade out
					this.game.postToMatter('freezeMatter', true);
					
					this._worldStateLikelyChanged = true;
					// Logically, we would "await" this patch to ensure persistance before going to WelcomeScene.
					// However, the flow of patch with the immediate flag (true) set is synchronous up until we transmit
					// to the server via ServerStore, so there is no danger to letting setScene() continue, because 
					// by the time we hit the async portion (tx to server), we've already extracted the state from the level,
					// so it is safe to let the level destroy (we don't care about the return data from the server)
					this.patchLevelState({}, true);

					// Dump accumulators to metrics before we destroy the actor in the setScene() call
					this.actor.dumpPendingMetrics();

					ServerStore.metric("game.level.paused");

					// Send to welcome scene
					WelcomeScene.disableAutoStart = true;
					this.game.setScene('WelcomeScene');
				});
				this.buttons.back.filters = [new DropShadowFilter({
					distance: 0
				})];

				const iconMask = new PIXI.Sprite(resources.icon_white_list.texture);
				iconMask.tint = 0x0;
				iconMask.alpha = 0.625;
				iconMask.anchor.x = -0.02;// better centering...
				iconMask.anchor.y = -0.02; 
				iconMask.scale = new PIXI.Point(1 - 0.33, 1 - 0.33);
				iconMask.x = this.buttons.back.width  / 2;
				iconMask.y = this.buttons.back.height / 2;
				this.buttons.back.addChild(iconMask);
				this.buttons.back.icon = iconMask;

				const alertIndicator = new PIXI.Sprite(resources.small_red_button.texture);
				alertIndicator.scale = new PIXI.Point(0.33, 0.33);
				alertIndicator.anchor.x = 0.5;
				alertIndicator.anchor.y = 0.5;
				alertIndicator.x = this.buttons.back.texture.orig.width  * 0.75;
				alertIndicator.y = this.buttons.back.texture.orig.height * 0.12;
				this.buttons.back.addChild(alertIndicator);
				this.buttons.back.alertIndicator = alertIndicator;
				alertIndicator.alpha = 0;

				// Right now, the only thing this alert indicator shows is if we have a 
				// pending friend request. In future, we could expand this usage more.
				ServerStore.server().get('/friends/pending_count', null, { autoRetry: true })
					.then(result => result.pending > 0 && (alertIndicator.alpha = 1)); // result is {pending: Number}

				const textLabel = new PIXI.Text("0 pnts", {
					...buttonTextStyle,
					fill: "#ffffff",
				});
				textLabel.anchor.x = 0.5;//0.5;
				textLabel.anchor.y = 0;//0.5;
				textLabel.x = this.buttons.back.texture.orig.width / 2;
				textLabel.y = this.buttons.back.texture.orig.height * 0.75;
				this.buttons.back.addChild(textLabel);
				this.buttons.back.label = textLabel;
				// console.log(buttonTextStyle);


				// const iconContainer = new PIXI.Container();
				// this.buttons.back.addChild(iconContainer);
				// this.buttons.back.iconContainer = iconContainer;
				// iconContainer.x = this.buttons.back.texture.orig.width / 2;
				// iconContainer.y = this.buttons.back.texture.orig.height / 2;
				// // iconMaskBg.alpha = 0.625;

				// const iconMaskBg = new PIXI.Sprite(resources.icon_white_list.texture);
				// iconMaskBg.tint = 0x0;
				// // iconMaskBg.alpha = 0.625;
				// iconMaskBg.anchor.x = 0.5;// better centering...
				// iconMaskBg.anchor.y = 0.5; 
				// iconMaskBg.scale = new PIXI.Point(.74, .78); //1 - 0.05, 1 - 0.05);
				// iconMaskBg.x = 0; //this.buttons.back.width  / 2;
				// iconMaskBg.y = 0; //this.buttons.back.height / 2;
				// iconContainer.addChild(iconMaskBg);
				// this.buttons.back.iconBg = iconMaskBg;

				// const iconMask = new PIXI.Sprite(resources.icon_white_list.texture);
				// iconMask.tint = 0xffff00;
				// // iconMask.alpha = 0.625;
				// iconMask.anchor.x = 0.5;//-0.00;// better centering...
				// iconMask.anchor.y = 0.5;//-0.00; 
				// iconMask.scale = new PIXI.Point(1 - 0.33, 1 - 0.33);
				// iconMask.x = 0;//this.buttons.back.width  / 2;
				// iconMask.y = 0;//this.buttons.back.height / 2;
				// iconContainer.addChild(iconMask);
				// this.buttons.back.icon = iconMask;

				this.buttons.back.alpha = 0.9;

				this.buttons.back._updatePositionForWindow = () => {
					// this.buttons.back.y = window.innerHeight - stdPadding - this.buttons.back.height; ///2 - this.buttons.dpad.height/2;
					// this.buttons.back.x = window.innerWidth  - stdPadding - this.buttons.back.width; ///2 - this.buttons.dpad.height/2;
					this.buttons.back.y = this.meters.level.y + this.meters.level.height + stdPadding;
					this.buttons.back.x = window.innerWidth - this.buttons.back.width - stdPadding * 3.0125; // found by magic
				};
				this.buttons.back._updatePositionForWindow();
			}
			
			// Shop/stars button 
			// eslint-disable-next-line no-lone-blocks
			{
				this.buttons.shop = makeButton(resources.blank_button, 0.33, {
					x: 0, //stdPadding, // + this.buttons.dpad.width/2 - (this.resources.x_button.texture.orig.width * 0.75)/2,
					y: 0
				}, async () => {

					// Stop things from moving elsewhere in the scene
					// and stop various timers
					this.pauseGameplay();
		
					// Notify metric server
					ServerStore.metric("game.level.shop_popup.opened");

					// Request the popup show itself
					await this.shopPopup.show('items');

					// Notify
					ServerStore.metric("game.level.shop_popup.closed");

					// Unfreeze matter and resume game timers
					this.resumeGameplay();
				});
				this.buttons.shop.filters = [new DropShadowFilter({
					distance: 0
				})];

				const iconContainer = new PIXI.Container();
				this.buttons.shop.addChild(iconContainer);
				this.buttons.shop.iconContainer = iconContainer;
				iconContainer.x = this.buttons.shop.texture.orig.width / 2;
				iconContainer.y = this.buttons.shop.texture.orig.height / 2;
				// iconMaskBg.alpha = 0.625;

				const iconMaskBg = new PIXI.Sprite(resources.icon_white_star.texture);
				iconMaskBg.tint = 0x0;
				// iconMaskBg.alpha = 0.625;
				iconMaskBg.anchor.x = 0.5;// better centering...
				iconMaskBg.anchor.y = 0.5; 
				iconMaskBg.scale = new PIXI.Point(1 - 0.05, 1 - 0.05);
				iconMaskBg.x = 0; //this.buttons.shop.width  / 2;
				iconMaskBg.y = 0; //this.buttons.shop.height / 2;
				iconContainer.addChild(iconMaskBg);
				this.buttons.shop.iconBg = iconMaskBg;

				const iconMask = new PIXI.Sprite(resources.icon_white_star.texture);
				iconMask.tint = 0xffff00;
				// iconMask.alpha = 0.625;
				iconMask.anchor.x = 0.5;//-0.00;// better centering...
				iconMask.anchor.y = 0.5;//-0.00; 
				iconMask.scale = new PIXI.Point(1 - 0.33, 1 - 0.33);
				iconMask.x = 0;//this.buttons.shop.width  / 2;
				iconMask.y = 0;//this.buttons.shop.height / 2;
				iconContainer.addChild(iconMask);
				this.buttons.shop.icon = iconMask;

				const textLabel = new PIXI.Text(numberWithCommas(Math.ceil(this.actor.currentStars)), {
					...buttonTextStyle,
					fill: "#ffff00",
				});
				textLabel.anchor.x = 0.5;//0.5;
				textLabel.anchor.y = 0;//0.5;
				textLabel.x = this.buttons.shop.texture.orig.width / 2;
				textLabel.y = this.buttons.shop.texture.orig.height * 0.75;
				this.buttons.shop.addChild(textLabel);
				this.buttons.shop.label = textLabel;
				// console.log(buttonTextStyle);

				const alertIndicator = new PIXI.Sprite(resources.small_red_button.texture);
				alertIndicator.scale = new PIXI.Point(0.33, 0.33);
				alertIndicator.anchor.x = 0.5;
				alertIndicator.anchor.y = 0.5;
				alertIndicator.x = this.buttons.shop.texture.orig.width * 0.825;
				alertIndicator.y = this.buttons.shop.texture.orig.height * 0.125;
				this.buttons.shop.addChild(alertIndicator);
				this.buttons.shop.alertIndicator = alertIndicator;
				// TODO: Decide when/how to show
				alertIndicator.alpha = 0;

				this.buttons.shop.alpha = 0.9;

				this.buttons.shop._updatePositionForWindow = () => {
					this.buttons.shop.y = this.meters.level.y + this.meters.level.height + stdPadding;
					this.buttons.shop.x = this.buttons.back.x - this.buttons.shop.width - stdPadding * 1.625; // .625 found by magic
				};
				this.buttons.shop._updatePositionForWindow();
			}

			

			
		

			window.addEventListener("resize", this._buttonAdjustHandler = () => {
				this.buttons.back._updatePositionForWindow();
				this.buttons.shop._updatePositionForWindow();
				// this.buttons.dpad._updatePositionForWindow();
				// These must be updated in sequence because their Y positions are dependent on each other
				this.buttons.powerpillDown._updatePositionForWindow();
				this.buttons.fire._updatePositionForWindow();
				this.buttons.powerpillUp._updatePositionForWindow();
			}, false);
			
			
		}

		// get a reference to an element
		const canvas = this.game.app.view;
		
		// create a manager for that element
		this.hammerManager = new Hammer.Manager(canvas);
		
		// create a recognizer
		const Pan = new Hammer.Pan();
		const Tap = new Hammer.Tap(); //{ threshold: 5 }); // default threshold 10
		
		// add the recognizer
		this.hammerManager.add(Pan);
		this.hammerManager.add(Tap);

		let lastPanStart = null;
		// World = pan outside of spad
		// Dpad = pan inside dpad (use like joystick)
		// let lastPanStartType = 'world';
		
		// Setup dpad
		// const dpad = this.buttons.dpad;
		// if(dpad) {
		// 	const panPaddingScale = .05;
		// 	const panPadding = dpad.width * panPaddingScale;
			
		// 	dpad.panningRect = {
		// 		x1: dpad.x - panPadding,
		// 		y1: dpad.y - panPadding,
		// 		x2: dpad.x + dpad.width + panPadding * 2,
		// 		y2: dpad.y + dpad.height + panPadding * 2,
		// 		center: {
		// 			x: dpad.x + dpad.width / 2,
		// 			y: dpad.y + dpad.height / 2
		// 		},
		// 	};
		// } else {
		// 	console.warn("Probably will fail horribly - dpad is missing");
		// }

		// const nippleOpts = {
		// 	// multitouch: true,
		// 	// maxNumberOfNipples: 2
		// };

		// this.joystickManager = nipplejs.create(nippleOpts);

		// // console.log("created joystickManager:", this.joystickManager);
		// window.joystickManager = this.joystickManager;

		// let joystickVec = null;
		// this.joystickManager.on('start move end dir plain', (evt, data) => {
		// 	if(this.isDestroyed)
		// 		return;

		// 	// console.log("[joystickManager] evt=", evt, ", data=", data);
		// 	if(evt.type === 'move') {
		// 		const vec = rotate(0, 0, data.force * 3.5, 0, data.angle.degree);
		// 		// console.log("[joystick move]", vec, data);
		// 		joystickVec = vec;
		// 	} else
		// 	if(evt.type === 'start') {
		// 		clearInterval(this._joystickRepeatTid);
		// 		this._joystickRepeatTid = setInterval(() => {
		// 			if(this.isDestroyed)
		// 				return;

		// 			if(joystickVec) {
		// 				const velocity = { x: joystickVec[0], y: joystickVec[1] };

		// 				if(Math.abs(velocity.y) < 2) { // moving horizontal 
		// 					const y = this.actor.isJumping ? this.actor.obj.body.vy : 0,
		// 						movingVelocity = { x: velocity.x, y };
		// 					// console.warn("[" + ev.type + "] setMoving:", movingVelocity);
		// 					this.actor.setMoving(movingVelocity);
		// 				} else {
		// 					// console.warn("[" + ev.type + "] *JUMP*:", velocity);
		// 					if(Math.abs(velocity.x) < 2)
		// 						velocity.x = 0;	
		// 					this.actor.setJumping(velocity, true);
		// 				}

		// 				this.actor.showThrusters(true, 250);
		// 			}
		// 		}, 100);
		// 	} else
		// 	if(evt.type === 'end') {
		// 		clearInterval(this._joystickRepeatTid);
		// 	}
		// });

		// man1ager.on('added', function (evt, nipple) {
		// 	nipple.on('start move end dir plain', function (evt) {
		// 		// DO EVERYTHING
		// 	});
		// }).on('removed', function (evt, nipple) {
		// 	nipple.off('start move end dir plain');
		// });


		this.hammerManager.on('panstart panmove panend', this._hammerPan = ev => {
			// console.log("[panmove]", ev);

			// // console.log(ev);
			
			const velocityScaling = this.actor.movementScalingConstant * 2;
			
			//12.5 * (1 + paddingScale * 2); //this.tileSize / 2;
			// // const velX = ev.overallVelocityX,
			// // 	velY = ev.overallVelocityY;
			// const windowScaling = .75;
			// const velX = Math.min(1, Math.max(-1, ev.deltaX / (window.innerWidth  * windowScaling))),
			// 	  velY = Math.min(1, Math.max(-1, ev.deltaY / (window.innerHeight * windowScaling)));

			// const dpad = this.buttons.dpad;
			// if(!dpad.transform) {
			// 	// some sort of internal PIXI error, ignore
			// 	return;
			// }

			// const dpr = dpad.panningRect;

			const world = { x: ev.center.x, y: ev.center.y };

			// const ENABLE_JOYSTICK_MODE = false;
			if(ev.type === 'panstart') {

				// // the negative padding counteracts the padding the rect was created with inside the HideOverActorViewportPlugin
				// if(!this.buttons.fire.__hidingRect || // fix https://sentry.io/organizations/sleepy-cat-game/issues/986291307
				// 	this.buttons.fire.__hidingRect.includes(world.x, world.y, -HideOverActorViewportPlugin.DefaultPadding)) {
				// 	// console.log("Pan start inside fire button area, ignoring");
				// 	return;
				// }

				// if(ENABLE_JOYSTICK_MODE) {
				// 	if (world.x < dpr.x1 || world.x > dpr.x2 ||
				// 		world.y < dpr.y1 || world.y > dpr.y2) {
				// 		// console.warn("Hammer: " + ev.type + " outside of dpad, ignoring");
				// 		lastPanStartType = 'world';
				// 	}
				// 	else {
				// 		lastPanStartType = 'dpad';
				// 	}
				// }
				lastPanStart = world;			
			}

			if(!lastPanStart || ev.type === 'panend') {

				// clearInterval(this._dpadJoystickRepeatTid);

				this.actor.showThrusters(false);

				lastPanStart = null;
				return;
			}

			// if(lastPanStartType === 'world') {
			
				// const vec1 = [point.x, point.y];
				// const vec2 = [world.x, world.y];
				
				// const dist = subVecs(vec1, vec2);
				// // const velVec = norm(dist);
				// const velVec = [dist[0]/(dpad.width/2), dist[1]/(dpad.height/2)];

				// const velocityNorm = { x: -velVec[0], y: -velVec[1] };
				const velocityNorm = { x: ev.overallVelocityX, y: ev.overallVelocityY };
			

				const velocity = {
					x: velocityNorm.x * velocityScaling,
					y: velocityNorm.y * velocityScaling,
				};

				// console.log("[" + ev.type + "]", velocityNorm, point, world, ev, velocity);
				// if (Math.abs(velocityNorm.x) < .24 && 
				// 	Math.abs(velocityNorm.y) < .24) {
				// 	// console.warn("Center click");
				// 	return;
				// }

				// buttonHaptic();

				this.actor.aim(velocity);

				if(Math.abs(velocityNorm.y) < .01) { // moving horizontal 
					const y = this.actor.isJumping ? this.actor.obj.body.vy : 0,
						movingVelocity = { x: velocity.x, y };
					// console.warn("[" + ev.type + "] setMoving:", movingVelocity);
					this.actor.setMoving(movingVelocity);
				} else {
					// console.warn("[" + ev.type + "] *JUMP*:", velocity);
					if(Math.abs(velocityNorm.x) < .01)
						velocity.x = 0;	
					this.actor.setJumping(velocity, true);
				}

				this.actor.showThrusters(true, 250);
			
			// } else {

			// 	const fromPoint = dpr.center;
			// 	const toPoint = ev.center;

			// 	const vec1 = [fromPoint.x, fromPoint.y];
			// 	const vec2 = [toPoint.x,   toPoint.y];
				
			// 	const dist = subVecs(vec1, vec2);
			// 	// const velVec = norm(dist);
			// 	const velVec = [dist[0]/(dpad.width/2), dist[1]/(dpad.height/2)];

			// 	const velocityNorm = { x: -velVec[0], y: -velVec[1] };
			// 	// const velocityNorm = { x: ev.overallVelocityX, y: ev.overallVelocityY };
		
			// 	const velocity = {
			// 		x: velocityNorm.x * velocityScaling,
			// 		y: velocityNorm.y * velocityScaling,
			// 	};

			// 	// console.log("[dpad joystick mode] ", velocityNorm.x, velocityNorm.y, { velocity, velocityNorm, velVec, dist, fromPoint, toPoint, vec1, vec2 });

			// 	clearInterval(this._dpadJoystickRepeatTid);
			// 	this._dpadJoystickRepeatTid = setInterval(() => {
			// 		this.actor.showThrusters(true, 250);
			
			// 		if(Math.abs(velocityNorm.y) < .01) { // moving horizontal 
			// 			const y = this.actor.isJumping ? this.actor.obj.body.vy : 0,
			// 				movingVelocity = { x: velocity.x, y };
			// 			// console.warn("[" + ev.type + "] setMoving:", movingVelocity);
			// 			this.actor.setMoving(movingVelocity);
			// 		} else {
			// 			// console.warn("[" + ev.type + "] *JUMP*:", velocity);
			// 			if(Math.abs(velocityNorm.x) < .01)
			// 				velocity.x = 0;	
			// 			this.actor.setJumping(velocity, true);
			// 		}
			// 	}, 100);
			// }

		// 	const point = { x: this.actor.obj.x, y: this.actor.obj.y };
		// 	const world = this.viewport.toWorld(ev.center.x, ev.center.y);
			
		// 	const vec1 = [point.x, point.y];
		// 	const vec2 = [world.x, world.y];
			
		// 	const dist = subVecs(vec1, vec2);
		// 	const velVec = norm(dist);

		// 	const velocity = { x: -velVec[0], y: -velVec[1] };

		// 	// console.log("[tap bullet]", { mag: mag(dist), dist, point, center, world, vec1, vec2, velVec, velocity });

		// 	this.spawnBullet(point, velocity);
			
			
			
		// 	// this.actor.shoot();

		// 	/*
		// 	if(!this.actor.obj.transform)
		// 		return;

		// 	const point = { x: this.actor.obj.x, y: this.actor.obj.y };
		// 	const world = this.viewport.toWorld(ev.center.x, ev.center.y);
			
		// 	const vec1 = [point.x, point.y];
		// 	const vec2 = [world.x, world.y];
			
		// 	const dist = subVecs(vec1, vec2);
		// 	const velVec = norm(dist);

		// 	const velocity = { x: -velVec[0], y: -velVec[1] };

		// 	// console.log("[tap bullet]", { mag: mag(dist), dist, point, center, world, vec1, vec2, velVec, velocity });

		// 	this.spawnBullet(point, velocity);
		// 	*/
		});


		// this.hammerManager.on('tap', this._hammerTap = ev => {

		// 	// // console.log(ev);
		// 	const paddingScale = .05,
		// 		velocityBase = this.actor.movementScalingConstant,
		// 		velocityScaling = velocityBase * (1 + paddingScale * 2); //this.tileSize / 2;
		// 	// // const velX = ev.overallVelocityX,
		// 	// // 	velY = ev.overallVelocityY;
		// 	// const windowScaling = .75;
		// 	// const velX = Math.min(1, Math.max(-1, ev.deltaX / (window.innerWidth  * windowScaling))),
		// 	// 	  velY = Math.min(1, Math.max(-1, ev.deltaY / (window.innerHeight * windowScaling)));

		// 	const dpad = this.buttons.dpad;
		// 	if(!dpad.transform) {
		// 		// some sort of internal PIXI error, ignore
		// 		return;
		// 	}

		// 	const padding = dpad.width * paddingScale,
		// 		dpr = {
		// 			x1: dpad.x - padding,
		// 			y1: dpad.y - padding,
		// 			x2: dpad.x + dpad.width + padding * 2,
		// 			y2: dpad.y + dpad.height + padding * 2,
		// 			center: {
		// 				x: dpad.x + dpad.width / 2,
		// 				y: dpad.y + dpad.height / 2
		// 			},
		// 		};


		// 	const point = dpr.center;
		// 	const world = { x: ev.center.x, y: ev.center.y };

		// 	if (world.x < dpr.x1 || world.x > dpr.x2 ||
		// 		world.y < dpr.y1 || world.y > dpr.y2) {
		// 		// console.warn("Hammer: " + ev.type + " outside of dpad, ignoring");
		// 		return;
		// 	}
			
		// 	const vec1 = [point.x, point.y];
		// 	const vec2 = [world.x, world.y];
			
		// 	const dist = subVecs(vec1, vec2);
		// 	// const velVec = norm(dist);
		// 	const velVec = [dist[0]/(dpad.width/2), dist[1]/(dpad.height/2)];

		// 	const velocityNorm = { x: -velVec[0], y: -velVec[1] };
		

		// 	const velocity = {
		// 		x: velocityNorm.x * velocityScaling,
		// 		y: velocityNorm.y * velocityScaling,
		// 	};

		// 	// console.log("[" + ev.type + "]", velocityNorm, point, world, ev, velocity);
		// 	if (Math.abs(velocityNorm.x) < .24 && 
		// 		Math.abs(velocityNorm.y) < .24) {
		// 		// console.warn("Center click");
		// 		return;
		// 	}

		// 	buttonHaptic();

		// 	if(Math.abs(velocityNorm.y) < .33) { // moving horizontal 
		// 		const y = this.actor.isJumping ? this.actor.obj.body.vy : 0,
		// 			movingVelocity = { x: velocity.x > 0 ? velocityBase : -velocityBase, y };
		// 		// console.warn("[" + ev.type + "] setMoving:", movingVelocity);
		// 		this.actor.setMoving(movingVelocity);
		// 	} else {
		// 		// console.warn("[" + ev.type + "] *JUMP*:", velocity);
		// 		if(Math.abs(velocityNorm.x) < .33)
		// 			velocity.x = 0;	
		// 		velocity.y = velocity.y > 0 ? velocityBase : -velocityBase;
		// 		this.actor.setJumping(velocity);
		// 	}

		// 	if(this.isTutorial) {
		// 		setTimeout(() =>
		// 			BubbleText.popup(
		// 				"Hint: You can also swipe anywhere to move your kitty slow or fast...\n\n(Click to close)",
		// 				{ showOnceId: TutorialKeys.swipe }) &&
		// 				// Notify metric server
		// 				ServerStore.metric("game.level.tutorial.saw." + TutorialKeys.swipe),
		// 			1000);
		// 	}
		// });
	}

	innerWorldRect() {
		if(this._innerWorldRect)
			return this._innerWorldRect;

		const { tileSize } = this;
		const pad = tileSize * 1, // * .75, // * this.scale,
			yMin = this.ceilingInset * tileSize + pad,
			yMax = this.worldHeight - this.floorHeight * tileSize - pad;

		return this._innerWorldRect = { 
			yMin,
			yMax,
			xMin: pad,
			xMax: this.worldWidth - pad,
		}
	}

	_sanitizeCoords(x, y) {
		if(x.x || x.y) {
			y = x.y;
			x = x.x;
		}
		
		if(isNaN(x))
			x = 0;
		if(isNaN(y))
			y = 0;

		return { x, y };
	}

	constrainToWorld(x1, y1) {
		let { x, y } = this._sanitizeCoords(x1, y1);
		const r = this.innerWorldRect();

		if(window.f)
			console.log("[constrainToWorld] start:", { x, y, r, x1, y1 });
	
	
		const ROUND_ERR = 8.0;
		if (x - r.xMin < -ROUND_ERR) 
			x = r.xMin;

		if (y - r.yMin < -ROUND_ERR)
			y = r.yMin;

		if (x - r.xMax > ROUND_ERR)
			x = r.xMax;

		if (y - r.yMax > ROUND_ERR)
			y = r.yMax;

		return {x, y};
	}

	_checkActorBounds(x1, y1) {
		const { x: x2, y: y2 } = this._sanitizeCoords(x1, y1);

		// if(window.f)
			// console.log("[_checkActorBounds] start:", { x:x2, y: y2});

		const { x, y } = this.constrainToWorld(x2, y2);

		// if(window.f)
			// console.log("[_checkActorBounds] final:", { x, y});
		window.f = false;

		if(!PixiMatterContainer.EditorMode) {
			// clearTimeout(this._dpadTid);
			// this._dpadTid = setTimeout(() => {
			// 	const world = this.viewport.toScreen(x, y), 
			// 		dpad = this.buttons.dpad,	
			// 		dpr = dpad.panningRect,
			// 		fire = this.buttons.fire,
			// 		fpr = fire.panningRect,
			// 		pad = this.actor.obj.width * this.actor.scale * this.actor.obj.scale.x * .5; //this.tileSize * .5;
			// 	if (world.x >= dpr.x1 - pad && world.x <= dpr.x2 + pad &&
			// 		world.y >= dpr.y1 - pad && world.y <= dpr.y2 + pad) {
			// 		if(!dpad._hidden) {
			// 			dpad._hidden = true;
			// 			PixiUtils.fadeAlpha(this.buttons.dpad, DPAD_ALPHA, 0, 200);
			// 		}
			// 	} else {
			// 		if(dpad._hidden) {
			// 			dpad._hidden = false;
			// 			PixiUtils.fadeAlpha(this.buttons.dpad, 0, DPAD_ALPHA, 200);
			// 		}
			// 	}

			// 	if (world.x >= fpr.x1 - pad && world.x <= fpr.x2 + pad &&
			// 		world.y >= fpr.y1 - pad && world.y <= fpr.y2 + pad) {
			// 		if(!fire._hidden) {
			// 			fire._hidden = true;
			// 			PixiUtils.fadeAlpha(this.buttons.fire, FIRE_BTN_ALPHA, 0, 200);
			// 		}
			// 	} else {
			// 		if(fire._hidden) {
			// 			fire._hidden = false;
			// 			PixiUtils.fadeAlpha(this.buttons.fire, 0, FIRE_BTN_ALPHA, 200);
			// 		}
			// 	}
			// }, 1);

			const velY = parseFloat(this.actor.obj.body.vy).toFixed(2);
			const velX = parseFloat(this.actor.obj.body.vx).toFixed(2);
			if(velY < 0 || velY > 0.1 ||
			   velX < 0 || velX > 0.1) {
				this.stateLikelyChanged(true);
				this.updateTotalPlayingTime();
			}
		}

		return { x, y };
	}

	updateTotalPlayingTime() {
		if (!this.levelState || 
			!this.levelState._gamePlayTimeMark) {
			// Game not stated yet
			return;
		}

		const now   = Date.now(), 
			diff    = now - this.levelState._gamePlayTimeMark,
			seconds = Math.min(MAX_PLAYING_CHANGE_SECONDS, diff / 1000),
			current = parseFloat(this.levelState.totalPlayingTime || 0),
			totalPlayingTime = current + seconds;

		// console.warn("[updateTotalPlayingTime] ", { current, totalPlayingTime, seconds });
		
		this.levelState._gamePlayTimeMark = now;
		this.patchLevelState({ totalPlayingTime });

		// Only valid for this session (till user closes the app)
		// but give user credit for this level
		if (ServerStore.appSession.playingTime < 0.5)
			ServerStore.appSession.playingTime = totalPlayingTime;
		else
			ServerStore.appSession.playingTime += seconds;

		this.updateActivePointsDisplay();
	}

	async _persistLevelState() {
		if(this._worldStateLikelyChanged) {
			const state = this.serializeActiveWorldState();
			this.levelStateUpdates.worldState = state;
			this._worldStateLikelyChanged = false;
		}
		
		// console.warn("[_persistLevelState] patch:", this.levelStateUpdates);
		await ServerStore.persistLevelState(this.currentLevelId, this.levelStateUpdates);
		
		this.levelStateUpdates = {};
	}

	patchLevelState(statePatch, immediate=false) {
		Object.assign(this.levelState, statePatch);
		Object.assign(this.levelStateUpdates, statePatch);

		// console.log("[patchLevelState] this.levelState=", this.levelState, ", patch was:", statePatch);

		if(immediate)
			return this._persistLevelState();

		this.stateLikelyChanged();
	}


	stateLikelyChanged(worldStateChange=false) {
		this._stateLikelyChanged = true;
		if(worldStateChange)
			this._worldStateLikelyChanged = worldStateChange;

		if(!this._stateIntervalId) {
			this._stateIntervalId = setInterval(() => {
				const velY = parseFloat(this.actor.obj.body.vy).toFixed(2);
				if (velY >= 0 && velY <= 0.2) {
					if (this._stateLikelyChanged) {
						this._stateLikelyChanged = false;
						this._persistLevelState();
					}
				}
			}, 3000);
		}
	}

	async levelEndFlagRaising() {
		const { game, actor, doorblock, viewport } = this;

		// Remove this plugin now because we're hiding all chrome manually
		viewport.removePlugin('hideOverActor');
		
		// Hide all meters and buttons (the chrome over the game)
		[].concat(
			Object.values(this.buttons),
			Object.values(this.meters),
			this.endOfLevelSparkle,
			this.bouncyBalls
		).forEach(chrome => PixiUtils.fadeOut(chrome, 500));

		// Stop things from moving elsewhere in the scene
		game.postToMatter('freezeMatter', true);

		// Pause tuning FPS while we transition levels
		this.game.fpsAutoTuner && this.game.fpsAutoTuner.stop();

		const forever = 1000 * 30; // last way longer than the end of level animations

		actor.flagRaisingMode = true; // ignore updates
		actor.sprites && 
			actor.sprites.aimingLine && 
			actor.sprites.aimingLine.hide(true); // hide aimingLine if on (instantly)
		actor.showThrusters(false); // hide thrusters if on
		actor.setMouth('open', forever); // never close mouth...for 30 sec anyway
		actor.playSparkle(forever); // play a sparkle while zooming and while raising the flag

		return new Promise(async resolve => {
			// By returning a promise and using this shortcutClicked flag,
			// we can shortcut this end-of-level animation if the user clicks the game 
			// or presses ENTER. This works because we just resolve() the promise we returned,
			// but we also must set shortcutClicked to true since we use a lot of awaits
			// below. So we just have the code check this flag before awaiting.
			let shortcutClicked = false;

			// Set handlers for the "shoot" action (called in BasicKittyScene)
			// and the PIXI tap/click handlers on the root container
			this.liveGameContainer.interactive = true;
			this.shootIntercept = this.liveGameContainer.click = this.liveGameContainer.tap = () => {
				shortcutClicked = true;
				resolve();
			};
		
			// Play sound here so it plays during animation.
			// This sound is actually .use()d in doorblock (preloaded)
			// (Normally sound plays in _endFinalDamage, but we
			// dont want _endFinalDamage to run until we end the anim)
			// Note: Not awaiting this because we want to interleave this partway thru the zoom
			setTimeout(() => !shortcutClicked && SoundManager.play(SoundManager.ACHIEVEMENT), 750);

			// Do the zoom from wherever the viewport is over to the flag
			// Note: Not awaiting this because we want to start the flag raising even while zooming,
			// so we sleep() below for less time than the zoom time
			const zoomTime = 1750;
			// Note the use here (and below) of checking shortcutClicked before calling the next item
			// This is because the user could change shortcutClicked=true at any point since 
			// await doesn't (thankfully) block the event loop
			!shortcutClicked && this._animateViewportToFlag(zoomTime); // animate viewport to zoom in on flag
			
			// delay for a bit before raising flag
			// This 1250 timing makes the flag raising "shoot up" coinscide nicely with the SoundManager.ACHIEVEMENT 
			// "flare" at the end of the sound file
			!shortcutClicked && await PixiUtils.sleep(1250);
			!shortcutClicked && await doorblock.raiseFlag(1000);

			// Let the flag "wave" for a moment before starting our zoom-out
			!shortcutClicked && await PixiUtils.sleep(750);
			!shortcutClicked && this._animateViewportToFlag(zoomTime, 'out', TWEEN.Easing.Quintic.In)

			// Wait for a brief moment before returning control so that the zoom is perceived
			// by user before the level end overlay takes over
			!shortcutClicked && await PixiUtils.sleep(750);

			resolve();
		});
	}

	_animateViewportToFlag(time=1000/30*4, direction='in', easing=TWEEN.Easing.Quadratic.Out) {
		const { viewport, doorblock } = this;
		const  { left, top, worldScreenHeight, worldScreenWidth, worldWidth, worldHeight } = viewport,
			currentAspectRatio = worldScreenWidth / worldScreenHeight,
			desiredHeight      = doorblock.height * 2,
			desiredWidth       = desiredHeight * currentAspectRatio;

		// Stop following actor because we're going to animate movement below
		viewport.follow(false);

		const current = {
			width:  worldScreenWidth,
			height: worldScreenHeight,
			x: left,
			y: top
		}, dest = direction === 'in' ? {
			width:  desiredWidth,
			height: desiredHeight,
			x: doorblock.x - (desiredWidth / 2),
			y: doorblock.y, // + (doorblock.height * 1.5)
		}: { 
			width:  worldWidth,
			height: worldHeight,
			x: 0,
			y: 0
		}

		const tweenPromise = new Promise(resolve => {
			new TWEEN.Tween(current)
				.to(dest, time)
				.easing(easing || TWEEN.Easing.Quadratic.Out)
				.onUpdate(() => {
					viewport.fit(false, current.width, current.height);
					viewport.ensureVisible(current.x, current.y, current.width, doorblock.height);
				})
				.onComplete(() => resolve())
				.start();
			
			PixiUtils.touchTweenLoop();
		});

		return tweenPromise;
			     
		// viewport.fit(false, doorblock.width * 2, doorblock.height * 2);
		// viewport.ensureVisible(doorblock.x, doorblock.y - doorblock.height, doorblock.width * 2, doorblock.height * 2);

	}

	customizeViewport() {
		const { actor, viewport } = this;
		// Not attached by default - will have to detach in destroy
		viewport.follow(actor.obj); //, { radius: tileSize * 1  } );
		viewport.removePlugin('clamp');
		viewport.removePlugin('clampZoom');
		viewport.clamp({ direction: 'all' });
		const vpmax = 1;
		if(process.env.NODE_ENV !== 'development') {
			viewport.clampZoom({
				minWidth: 256,
				minHeight: 256,
				maxWidth:  viewport.worldWidth * vpmax,
				maxHeight: viewport.worldHeight * vpmax,
			});
			viewport.removePlugin('drag');
		}

		viewport.userPlugin('hideOverActor', new HideOverActorViewportPlugin(viewport, this));
		// viewport.drag({ direction: 'none' });

		// physics engine sometimes lets kitty go thru walls and out of bounds - reset kitty if that happens
		actor.checkBounds = this._checkActorBounds.bind(this);

		
		if(process.env.NODE_ENV === 'development-XX') {
			if(!this.basicSetupOptions.overrideDebugRender) {
				viewport.zoom(Math.max(window.innerWidth, window.innerHeight) * 1.5);
			}
		} else {
			if(!this.basicSetupOptions.overrideDebugRender) {
				// hide behind flag so we don't adjust every level change
				// if(!viewport._initialZoomAdjustForKitty) {
				// 	viewport._initialZoomAdjustForKitty = true;
		
				// 	// viewport.fit(false, window.innerWidth * 3, window.innerHeight * 3);

				// 	viewport.zoom(Math.min(window.innerWidth * 3, window.innerHeight * 3));
				// }

				viewport.fit(false, this.tileSize * 10, this.tileSize * 10);
				// viewport.fit(false, this.tileSize * 20, this.tileSize * 20);
			}
		}

		

		// viewport.ensureVisible(actor.obj.x - tileSize * 1.5, actor.obj.y - tileSize * 1.5, tileSize * 1.5, tileSize * 1.5);
		// viewport.moveCenter(actor.obj.x, actor.obj.y);

		viewport.on('zoomed', this._viewportZoomHandler = () => {
			
			// Not finding a good heuristic for followFlag, so disabling this for now...

			// const bounds = viewport.getVisibleBounds();
			// const width = bounds.width,
			// 	tileWidth = width / tileSize,
			// 	followFlag = tileWidth < 10; //bounds.width < viewport.worldWidth * .75; //window.innerWidth;
				
			// console.log("[viewport-zoomed] ", { followFlag, tileWidth, bounds, iw: window.innerWidth, ww: viewport.worldWidth });

			// if(followFlag) {
			// 	viewport.follow(actor.obj);
			// } else {
			// 	viewport.follow(false);
			// }
		});
	}

	destroy() {
		if(this.isDestroyed)
			return;

		this.isDestroyed = true;

		if (this.game && this.game.pushService) {
			this.game.pushService.customMessageDisplay = null;
		}

		if (BubbleText.openBubble)
			BubbleText.openBubble.close();

		clearInterval(this._stateIntervalId);
		clearInterval(this._gamePlayIntervalId);

		// Clean up hammer off of the shared canvas
		if (this.hammerManager) {
			this.hammerManager.off('panstart panmove panend', this._hammerPan);
			this.hammerManager.off('tap', this._hammerTap);
			this.hammerManager.destroy();
		}

		if (this.joystickManager) {
			this.joystickManager.off('start move end dir plain');
			this.joystickManager.destroy();
		}
		clearInterval(this._joystickRepeatTid);
		
		// Since we moved the viewport inside our scene, we have to move it back
		// because otherwise when liveGameContainer is destroyed, the viewport would
		// be destroyed, rendering the state of the Game UI unusable for any other scenes
		if (this.liveGameContainer) {
			this.liveGameContainer.removeChild(this.viewport);
			this.game.gameContainer.addChild(this.viewport);
		}

		if (this.viewport) {
			this.viewport.follow(false);
			this.viewport.off('moved', this._viewportMoveHandler);
			this.viewport.off('zoomed', this._viewportZoomHandler);
			this.viewport.removePlugin('hideOverActor');
		}

		this._collisionHandler && this.game.off('matterCollision', this._collisionHandler);
		window.removeEventListener("resize", this._meterAdjustHandler);
		window.removeEventListener("resize", this._buttonAdjustHandler);
		this.game.app.ticker.remove(this._bgTick);
		this.game.app.ticker.remove(this._fireButtonTicker);
		
		if (this.actor)
			this.actor.destroy();

		SoundManager.getPlayer(SoundManager.MUSIC_FOREST).stop();

		// if(window.isPhoneGap) {
		// 	document.removeEventListener('pause',  this._pgPaused);
		// 	document.removeEventListener('resume', this._pgResume);
		// }

		ServerStore.off(APP_RESUMED_EVENT, this._appResumedHandler);
		ServerStore.off(APP_PAUSED_EVENT,  this._appPausedHandler);

		super.destroy();
	}

	explosion(forceMagnitudeModifier = 0.2, maxDistance=512, filter = body => true) {
		// const engine = this.game.engine;
		// const bodies = Matter.Composite.allBodies(engine.world);
		const bodies = this.game.matterBodyCache();

		// Notify metric server
		ServerStore.metric("game.level.kitty.item." + (forceMagnitudeModifier > 0 ? "explosion" : "implosion"));

		vibrate([333]);
		
		const kb = this.actor.obj.body,
			kp = {
				x: kb.x,
				y: kb.y,
			};

        for (var i = 0; i < bodies.length; i++) {
			const body = bodies[i];

			if (!body) {
				// console.warn("[explosion] body #"+ i +" is null");
				continue;
			}

			const pixi = body.pixiContainer,
				  vec  = {
					x: body.x - kp.x,
					y: body.y - kp.y,
				};

			vec.sx = vec.x > 0 ? 1:-1;
			vec.sy = vec.y > 0 ? 1:-1;
			vec.dist = Math.sqrt(vec.x*vec.x + vec.y*vec.y);

			// console.log(vec, body.position, kp);

			if(pixi && vec.dist < maxDistance) {

				if(!filter || filter(body)) {
					
					if(!body.label.startsWith('$') && 
						body.label !== '<doorblock>') {
						pixi.setMatter('isStatic', false);
						// This prop is NOT updated by setMatter but we use it below
						body.isStatic = false;
					}
						
					if (body.label !== "[KittyActor]" &&
						!body.isStatic) { //} && body.y >= 500) {
						const forceMagnitude = forceMagnitudeModifier * 15;// * body.mass;
					
						const force = {
							x: forceMagnitude * vec.sx, 
							y: forceMagnitude * vec.sy,
						};
						
						pixi.applyForce({ x: body.x, y: body.y }, force);

						// console.log("- body", {i, force, body})

						if(body.label !== '<silver>') {
							if (pixi.damage)
								pixi.damage(this.actor.obj);

							if(body.label !== '<bad>')
								this.actor.tryToConsume(body.label, pixi.powerType);
						}
					}
				}
			} else {
				// console.log("body too far away:", body, vec);
			}
        }
    };
}