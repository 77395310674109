import firebase from 'firebase/app';
import 'firebase/messaging';
import { ServerStore } from './ServerStore';

function initFirebase() {
	// Initialize Firebase
	var config = {
		apiKey: "AIzaSyCHfmX9RdPEFbeb1ExiVDFe-ABeJKpIhP4",
		authDomain: "sleepycat-4553a.firebaseapp.com",
		databaseURL: "https://sleepycat-4553a.firebaseio.com",
		projectId: "sleepycat-4553a",
		storageBucket: "sleepycat-4553a.appspot.com",
		messagingSenderId: "89483797376"
	};
	
	firebase.initializeApp(config);

	return firebase;
}

class FirebaseManager {
	static handle = null;

	static async plugin(opts) {
		this.handle = new FirebaseManager(opts);
		this.handle.init();

		return this.handle;
	}

	constructor({ onMessage, onMessageClick, onPermissionNeeded }) {
		this.onMessage          = onMessage;
		this.onMessageClick     = onMessageClick;
		this.onPermissionNeeded = onPermissionNeeded;

		// Check on boot to see if the service worker left a present for us in the URL...
		if(window.location.hash.startsWith("#alert:")) {
			// Our service worker prefixes the data.click_action from alerts received 
			// while our page is ***CLOSED***.
			// This means that the page was NOT active, NOT OPEN.
			// If our page WAS OPEN, we would have receive a message post (lower in this file) with a type == 'notificationclick'.
			// The ONLY time we will (should...) get here is if our service worker couldn't find an open page
			// so here we must extract the click action from the URL and then fire onMessageClick, indicating a background click.

			const click_action = window.location.hash.replace(/^#alert:/, '');

			onMessageClick && onMessageClick({
				// Note: this is the ONLY property of the alert we emulate here.
				// If we find we need more data from the alert (like the ID, etc)
				// we must modify our service worker to encode it in the URL accordingly and then we 
				// must update this code above to extract it and put it here in this hash.
				click_action, 

				// Used by PushNotifyService to handle properly
				clickedInBackground: true,

				// Add flag so our logging metrics know where this came from
				fromFirebaseServiceWorkerBackground: true,
			});
		}
	}

	async init() {
		this.firebase = initFirebase();

		if (!firebase.messaging.isSupported()) {
			console.warn("[FirebaseManager] Firebase push messaging not supported on this browser");
			return;
		}

		this.messaging = this.firebase.messaging();

		// Retrieved from https://console.firebase.google.com/u/0/project/sleepycat-4553a/settings/cloudmessaging/android:com.josiahbryan.sleepycat?hl=en_US
		this.messaging.usePublicVapidKey('BHFe4guXgiC9hLz9OYPDbFaSeVgdcC1sEoY-Ei5j-jecg6a3AbZsa7417qe3lO2o96nQzz5bnw-FpnpzbgOeFGw');

		const requestPermission = async () => {

			let error;
			await this.messaging.requestPermission().catch(e => error = e);
			if(error) {
				console.error("[FirebasePushPlugin.requestPermission] error requesting permission:", error);
				return false;
			}

			return true;
		}

		const getToken = async () => {
			// Get Instance ID token. Initially this makes a network call, once retrieved
			// subsequent calls to getToken will return from cache.
			let error;
			const token = await this.messaging.getToken().catch(e => error = e);
			if(error) {
				console.error("[FirebasePushPlugin.getToken] error getting token:", error);
				return false;
			}

			if(!token) {
				console.warn("[FirebasePushPlugin.getToken] no token available, requesting permission");
				
				// Allow our PushNotifyService to block permission request until it has informed the user
				if(this.onPermissionNeeded)
					await this.onPermissionNeeded();

				if(await requestPermission())
					return getToken();

				return false;
			}

			// console.warn("[FirebasePushPlugin.getToken] got token:", token);

			return token;
		}
		
		// Callback fired if Instance ID token is updated.
		this.messaging.onTokenRefresh(async () => {
			let error;
			const token = this.messaging.getToken().catch(e => error = e);
			if(error) {
				console.warn('[FirebasePushPlugin.onTokenRefresh] Unable to retrieve refreshed token ', error);
				return;
			}

			// console.log('[FirebasePushPlugin.onTokenRefresh] Token refreshed.');

			// Send Instance ID token to app server.
			ServerStore.storePushToken(token);
		});
		
		// Handle incoming messages. Called when:
		// - a message is received while the app has focus
		// - the user clicks on an app notification created by a service worker
		//   `messaging.setBackgroundMessageHandler` handler.
		this.messaging.onMessage(payload => {
			// console.log('[FirebasePushPlugin.onMessage] **TODO** Handle message received: ', payload);

			this.onMessage && this.onMessage(payload.data);
		});

		// Handle messages clicked IN BACKGROUND - display of background messages is handled in public/firebase-messaging-sw.js
		if('serviceWorker' in navigator){
			// Handler for messages coming from the service worker
			navigator.serviceWorker.addEventListener('message', event => {
				// console.log("[FirebasePushPlugin] ServiceWorker Client Received Message: ", event.data);
				const msg = event.data;

				if(msg.type === "notificationclick") {
					this.onMessageClick && this.onMessageClick(msg.raw);
				}
			});
		}

		const token = await getToken();
		if(token) {
			ServerStore.storePushToken(token);
			return true;
		} else {
			console.warn("[FirebasePushPlugin.initMessaging] no token received, probably won't get push messages")
			return false;
		}
	}
}


export function initFirebasePushPlugin(opts) {
	if(process.env.NODE_ENV === 'production')
		FirebaseManager.plugin(opts);
}