import Scene from './Scene';

import * as PIXI from 'pixi.js';
// import * as Matter from 'matter-js'

import { PixiUtils } from '../../utils/PixiUtils';

import tile_silver from '../../assets/gametiles/tiles/silver/silver_thick_border.png';
import pbg_sky_color from '../../assets/parallaxingskybg/parallax_parts/sky_color.png';
import pbg_sky_color_top from '../../assets/parallaxingskybg/parallax_parts/sky_color_top.png';

const defaultBasicBorderOptions = {
	// Add blue sky background textures
	includeSky: true, 
	// Add border tiles first - if false, you must call addBorderTiles() directly when you want to add them
	addBorderTiles: true,
	// Tile texture to use for walls
	tileTextureUrl: tile_silver, 
	// passed to `Scene.loadResources` to load other resources at the same time so caller doesn't have to wait for another Promise
	otherResources: {}, 
	// overrides `game.debugRender` for this setup
	overrideDebugRender: false, 
	// Set to window.innerWidth if game.debugRender or overrideDebugRender is true
	worldWidth: 5000,   
	// Computed as 1.69 * worldWidth if not given
	worldHeight: null,
	// Size to scale all tiles down to
	tileSize: 72,
};

export default class BasicBorderedScene extends Scene {
	init() {
		this.setupBasicBorders();
	}

	/**
	 * Creates a basic bordered physical scene using the given tile for the walls (defaults to '../../assets/gametiles/tiles/silver/silver_thick_border.png').
	 * Can be customized using options given below, returns Promise that resolves once the scene has been created.
	 *
	 * @param {string} [options={
	 * 		// Add blue sky background textures
	 * 		includeSky: true, 
	 *      // Add border tiles first - if false, you must call addBorderTiles() directly when you want to add them
	 *       addBorderTiles: true,
	 * 		// Tile texture to use for walls - defaults to URL for '../../assets/gametiles/tiles/silver/silver_thick_border.png'
	 * 		tileTextureUrl: tile_silver, 
	 * 		// passed to `Scene.loadResources` to load other resources at the same time so caller doesn't have to wait for another Promise
	 * 		otherResources: {}, 
	 * 		// overrides `game.debugRender` for this setup
	 * 		overrideDebugRender: false, 
	 * 		// Set to window.innerWidth if game.debugRender or overrideDebugRender is true
	 * 		worldWidth: 5000,   
	 * 		// Computed as 1.69 * worldWidth if not given
	 * 		worldHeight: null,
	 * 		// Size to scale all tiles down to
	 * 		tileSize: 72,
	 * 	}]
	 * @returns {Promise} Resolves once basic bordered scene has been loaded with ref to `resources` from `PIXI.loader`
	 * @memberof BasicBorderedScene
	 */
	setupBasicBorders(options=defaultBasicBorderOptions) {
		options = Object.assign({}, defaultBasicBorderOptions, options || {});

		this.basicSetupOptions = options;

		const game = this.game;

		return this.loadResources({
			// kitty_blue,
			pbg_sky_color,
			pbg_sky_color_top,
			// pbg_sun,
			tileTextureUrl: options.tileTextureUrl,
			...options.otherResources
		}).then(resources => {

			const viewport = game.viewport;
			// const debugRender = options.overrideDebugRender;//game.debugRender;

			// const baseValue = options.worldWidth || 7500;
			// const worldWidth  = debugRender ? window.innerWidth : baseValue;
			// const worldHeight = debugRender ? window.innerHeight : options.worldHeight || baseValue * 1.69;

			const worldWidth  = 640;
			const worldHeight = 480;
			
			// Tiles are expected to be based on 256 tiles, so scale to ~Xpx tiles
			const tileBaseSize = 256;
			const scale = (options.tileSize || 72) / tileBaseSize;
			
			const tileTexture = resources.tileTextureUrl.texture;
			const tileSize    = tileTexture.orig.width * scale; // tile is square...

			// TODO: document these properties
			this.tileBaseSize    = tileBaseSize;
			this.scale           = scale;
			this.tileSize        = tileSize;
			this.viewport        = viewport;
			this.resources       = resources;			

			this.setWorldSize(worldWidth, worldHeight, false);
			
			// Add sky
			if(options.includeSky)
				this.addSky();

			// Add tiles
			if(options.addBorderTiles)
				this.addBorderTiles();

			// Adapt to new viewport world size
			game.setupViewportMask();

			// Return to any other promise listeners
			return resources;
		});
	}

	setWorldSize(worldWidth, worldHeight) {
		const { tileSize } = this;

		const worldTileWidth  = Math.ceil(worldWidth  / tileSize) + 1;
		const worldTileHeight = Math.ceil(worldHeight / tileSize) + 1;
		
		this.setWorldTileSize(worldTileWidth, worldTileHeight);
	}

	setWorldTileSize(worldTileWidth, worldTileHeight, resetViewportMask=true) {
		const { viewport, tileSize } = this;

		this.worldWidth      = viewport.worldWidth  = worldTileWidth  * tileSize;
		this.worldHeight     = viewport.worldHeight = worldTileHeight * tileSize;
		this.worldTileHeight = worldTileHeight;
		this.worldTileWidth  = worldTileWidth;

		if(resetViewportMask) {
			// Adapt to new viewport world size
			this.game.setupViewportMask();
		}
	}

	addSky() {
		const { resources, worldWidth, worldHeight } = this;

		const colorHeight = resources.pbg_sky_color.texture.orig.height;
				
		// bg1 = color gradient
		const bg1 = new PIXI.extras.TilingSprite(resources.pbg_sky_color.texture, worldWidth, colorHeight);
		bg1.x = 0;
		bg1.y = worldHeight - colorHeight;
		this.addObject(bg1);

		// bg2 = dark blue top color
		const bg2 = new PIXI.extras.TilingSprite(resources.pbg_sky_color_top.texture, worldWidth, worldHeight - colorHeight + 72);
		bg2.x = 0;
		bg2.y = 0;
		this.addObject(bg2);
	}

	/**
	 * Adds tiles around the border of the scene - called automatically by default in `setupBasicBorders()` unless `addBorderTiles` option is `false`.
	 *
	 * @memberof BasicBorderedScene
	 */
	addBorderTiles(ceilingInset=0, floorHeightAdjust=0, matterOnly=false) {

		const  tileTexture = this.resources.tileTextureUrl.texture;

		const { worldTileWidth, worldTileHeight, scale, tileSize, worldHeight, worldWidth, game } = this;

		// We could have used PixiUtils.createPhysicalTexture (we'll draw tiles regardless),
		// but to reduce the strain on the physics simulation, tell the physics simulation there are solid walls,
		// not individual blocks along each wall/ceiling/floor
		const nativeMatter = true;
		this.nativeMatterBodies = []; // for destroy later
		if(nativeMatter) {
			// left wall
			this.nativeMatterBodies.push(game.postToMatter('addBody', { size: [
				0,
				worldHeight / 2,
				tileSize,
				worldHeight ],
				options: { isStatic: true, label: "$left-wall$" }}));
			
			// right wall
			this.nativeMatterBodies.push(game.postToMatter('addBody', { size: [
				worldWidth,
				worldHeight / 2,
				tileSize,
				worldHeight ],
				options: { isStatic: true, label: "$right-wall$" }}));
			
			// ceiling
			this.nativeMatterBodies.push(game.postToMatter('addBody', { size: [
				worldWidth / 2,
				ceilingInset * tileSize,
				worldWidth + tileSize,
				tileSize ],
				options: { isStatic: true, label: "$ceiling$" }}));

			// floor
			this.nativeMatterBodies.push(game.postToMatter('addBody', { size: [
				worldWidth / 2,
				worldHeight - floorHeightAdjust * tileSize,
				worldWidth + tileSize,
				tileSize ],
				options: { isStatic: true, label: "$floor$" }}));
		}

		if(matterOnly)
			return;
			
		// NOTE: We are choosing to create `PIXI.Sprite`s directly instead of using `createPhysicalTexture`
		// to reduce burden on physics simulation. See note above.
		const instantiate = !nativeMatter ? PixiUtils.createPhysicalTexture : 
			(texture, position, sizeRelevantOptions, unused) => {
				const sprite = new PIXI.Sprite(texture);
				sprite.scale = new PIXI.Point(sizeRelevantOptions.scale, sizeRelevantOptions.scale);
				
				// ***NOTE***
				// MatterJS REQUIRES an anchor of .5/.5 -
				// all positioning internally in MatterJS assumes x/y is the center of the object.
				// Therefore, for our physical sprites, we have to also calculate things from center of object.
				sprite.anchor.x = 0.5;
				sprite.anchor.y = 0.5;
				sprite.x = position.x;
				sprite.y = position.y;

				return sprite;
			};

		for(let i=0; i<worldTileWidth + 1; i++) {
			// floor
			for(let j=0; j<floorHeightAdjust+1; j++) {
				this.addObject(instantiate(tileTexture, {
					x: i * tileSize,
					y: worldHeight - j * tileSize,
				}, { scale }, { isStatic: true }));
			}

			// ceiling
			for(let j=0; j<ceilingInset+1; j++) {
				this.addObject(instantiate(tileTexture, {
					x: i * tileSize,
					y: j * tileSize,
				}, { scale }, { isStatic: true }));
			}
		}

		for(let i=0; i<worldTileHeight + 1; i++) {
			// left wall
			this.addObject(instantiate(tileTexture, {
				x: 0,
				y: i * tileSize,
			}, { scale }, { isStatic: true }));

			// right wall
			this.addObject(instantiate(tileTexture, {
				x: worldWidth,
				y: i * tileSize,
			}, { scale }, { isStatic: true }));
		}
	}

	destroy() {
		super.destroy();
		(this.nativeMatterBodies || []).forEach(body => {
			this.game.postToMatter('removeBody', { body });
		});
	}
}