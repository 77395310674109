import * as PIXI from 'pixi.js';

import EventEmitter from 'events';

// Auto-created by the makepg script
import pgBuildManifest from './cordova-manifest.json';

// Utility to normalize webpack URLs to manifest keys
const normalize = url => url.replace(/^[./]+/g, '');
	// .replace(/\.[a-fA-F0-9]+(\.[^.]+)$/, '$1');

// Take the cordova manifest and make a hash lookup with the normalized keys
const manifestLookup = {};
Object.values(pgBuildManifest).forEach(urlWithHash => {
	const relativeUrlWithHash = normalize(urlWithHash);
	manifestLookup[relativeUrlWithHash] = true;
});

// Server that hosts the assets if not in the manifest
const publicServer = process.env.NODE_ENV === 'production' ?
	'https://sleepycatgame.com/' :
	window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');

// TODO: Autocreate new loader if loader already active when add() called
// ex: new PIXI.loaders.Loader()
// Ref https://pixijs.download/v4.6.0/docs/PIXI.loaders.Loader.html

const DEBUG_ASSET_LOADER = false;

export default class AssetLoader {

	static resources = {}; 

	static _events = new EventEmitter();
	static on(event, callback) {
		this._events.on(event, callback);
	}

	static off(event, callback) {
		this._events.off(event, callback);
	}

	static emit(event, data) {
		this._events.emit(event, data);
	}
	
	static addAll(resourceKeys, disableLoad=false) {
		const bufferedLoaderData = this._availableBufferedLoader();

		Object.keys(resourceKeys).forEach(key => {
			AssetLoader.add(key, resourceKeys[key], bufferedLoaderData);
		});

		if(!disableLoad)
			return this.load(bufferedLoaderData);
	}

	static _bufferedLoaders = [];
	static _bufferedLoaderDataIdCounter=0;
	static _availableBufferedLoader() {
		let bufferedLoaderData = this._bufferedLoaders.find(data => !data.isLoading);
		if(!bufferedLoaderData) {
			DEBUG_ASSET_LOADER && console.log("[AssetLoader._availableBufferedLoader] no non-loading loader found, creating new.", this._bufferedLoaders);
			
			this._bufferedLoaders.push(bufferedLoaderData = {
				id: ++this._bufferedLoaderDataIdCounter,
				isLoading: false,
				loader: new PIXI.loaders.Loader(),
			});

			// Listen for progress
			bufferedLoaderData.binding = bufferedLoaderData.loader.onProgress.add(data => this.emit('progress', data));
		}

		return bufferedLoaderData;
	}

	static add(key, url, bufferedLoaderData=null) {
		if(this.resources[key])
			return;

		try {
			(bufferedLoaderData || this._availableBufferedLoader())
				.loader.add(key, this.normalizeUrl(url));
		} catch(e) {
			console.warn("Error adding asset ", {key, url},": ", e);
		}
	}

	static load(bufferedLoaderData=null) {
		const data = (bufferedLoaderData || this._availableBufferedLoader());
		data.isLoading = true;
		DEBUG_ASSET_LOADER && console.log("[AssetLoader.load] Starting load on loader ", data);
		return new Promise(resolve => 
			data.loader.load(
				(loader, resources) => {
					data.isLoading = false;
					DEBUG_ASSET_LOADER && console.log("[AssetLoader.load] Load completed on loader ", data);
					// Use .assign instead of spread operator so we don't change
					// the .resources prop, which could be held by reference in other structures
					// E.g. if we did this.resources={...} it would invalide the ref,
					// whereas .assign() updates the ref
					Object.assign(this.resources, resources);
					resolve(this.resources);
				}
			));
	}

	static reset() {
		DEBUG_ASSET_LOADER && console.log("[AssetLoader.reset] Reset all loaders");
		this.resources = {};
		this._bufferedLoaders.forEach(data => data.loader.reset());
		this._bufferedLoaders = [];
	}

	static normalizeUrl(url) {
		if(!url || url.startsWith('data:'))
			return url;

		// const orig = url;
		if(process.env.NODE_ENV !== 'production')
			return url;

		if (window.isPhoneGap) {
			
			// Normalize to what the key would be in the manifest
			const relativeUrlWithHash = normalize(url);

			// If URL is in the manifest of the latest cordova build,
			// then assume it's safe to use locally without any modification
			if(!manifestLookup[relativeUrlWithHash]) {

				// URL not found in manifest, assume NOT included in cordova build,
				// so send over the network to the server
				url = publicServer + relativeUrlWithHash;

				// console.log("[normalizeUrl] Found asset NOT in manifest, sending to server:", `${orig} => ${url}`);//, manifestLookup);
			} else {
				// console.log("[normalizeUrl] OK ", `${url} (${relativeUrlWithHash})`);//, manifestLookup);
			}
		}

		// console.log("[normalizeUrl]", `${orig} => ${url}`);//, manifestLookup);
		return url;
	}

}



// if(!window.isPhoneGap        || 
// 	url.startsWith('http:')  ||
// 	process.env.NODE_ENV === 'development') {
// 	return url;
// }

