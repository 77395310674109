module.exports = {
	getCosSim,
	angle,
	rotate,
	toRadians,
	mag,
	norm,
	addVecs,
	subVecs,
	avg
};

// Vector utilities
function getCosSim(f1, f2) {
	return Math.abs(f1.reduce(function(sum, a, idx) {
		return sum + a*f2[idx];
	}, 0)/(mag(f1)*mag(f2))); //magnitude is 1 for all feature vectors
}

// https://stackoverflow.com/questions/9614109/how-to-calculate-an-angle-from-points
function angle(cx, cy, ex, ey) {
	var dy = ey - cy;
	var dx = ex - cx;
	var theta = Math.atan2(dy, dx); // range (-PI, PI]
	theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
	if (theta < 0) theta = 360 + theta; // range [0, 360)
	return theta;
}

// https://stackoverflow.com/questions/17410809/how-to-calculate-rotation-in-2d-in-javascript
function rotate(cx, cy, x, y, angle) {
    var radians = (Math.PI / 180) * angle,
        cos = Math.cos(radians),
        sin = Math.sin(radians),
        nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
        ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
    return [nx, ny];
}

// https://stackoverflow.com/questions/9705123/how-can-i-get-sin-cos-and-tan-to-use-degrees-instead-of-radians
function toRadians (angle) {
	return angle * (Math.PI / 180);
}



// Vector utilities
function mag(a) {
	return Math.sqrt(a.reduce((sum, val) =>
	  sum + val*val
	, 0));
  }

function norm(a) {
	var x = mag(a);
	return a.map(val => val/x);
  }

function addVecs(a, b) {
	return a.map((val, idx) => val + b[idx]);
  }

function subVecs(a, b) {
	return a.map((val, idx) => val - b[idx]);
  }

function avg(a) {
	return a.length ? 
		a.reduce((sum, val) => sum + val, 0) / a.length
		: null;
  }