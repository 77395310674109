// Modified from http://lea.verou.me/2016/12/resolve-promises-externally-with-this-one-weird-trick/
export function defer(cb = () => {}) {
	let res, rej;

	const promise = new Promise((resolve, reject) => {
		res = resolve;
		rej = reject;
		cb(resolve, reject);
	});

	promise.resolve = res;
	promise.reject  = rej;

	return promise;
};