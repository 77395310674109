import React from 'react';
import { Button } from 'reactstrap';

// Background for our login
import BasicBurstScene from "../BasicBurstScene";

// Login logic (loads FB SDK automatically)
import FacebookLogin from 'components/FacebookLogin';

// Our server logic
import { ServerStore, FB_APP_ID } from 'utils/ServerStore';
import { LoaderUtil } from 'utils/LoaderUtil';

// Styles for this page
import './login-scene.scss';

// Needed to init stripe
import { MarketUtils } from 'utils/MarketUtils';

// import RandomCatName from 'utils/RandomCatName';
// const catNameUtil = RandomCatName.default;

export default class LoginScene extends BasicBurstScene {
	init() {
		const next = token => ServerStore.attemptAutoLogin(token).then(success => {
			// console.log("result from attemptAutoLogin:", success);
			if(success)
				this.game.setScene('WelcomeScene');
			else
				this.continueLoginScreen();
		});

		// Hide the loading of Stripe (in MarketUtils)
		// until the app has booted.
		// However, we want to load this before any "purchase" events are triggered,
		// so we do this here, at the end of the main load
		MarketUtils.init();

		// // If PhoneGap plugin present, refresh token
		// if(window.facebookConnectPlugin) {
		// 	window.facebookConnectPlugin.getLoginStatus(({status, authResponse}) => {
		// 		if(status === "connected") {
		// 			next(authResponse.accessToken);
		// 		} else {
		// 			next();
		// 		}
		// 	});
		// } else {
			next();
		// }
	}

	continueLoginScreen() {
		super.init().then(() => {
			this.loaded();
		});
	}

	takeoverPrompt() {
		const code = prompt(":)");
		ServerStore.tryTakeoverCode(code).then(success => {
			if(success)
				this.game.setScene('WelcomeScene');
			else
				this.continueLoginScreen();
		});
	}

	// This is called when either auth'd the first time to FB, 
	// or on subsequent loads - this loads the user and cat from the server
	// and then sends the user to the welcome scene when done
	facebookConnected(response) {

		// return;
		
		// console.log(response);
		ServerStore.login(response.accessToken).then(store => {
			if(store) {
				ServerStore.metric('app.login.facebook.success');
				this.game.setScene('WelcomeScene');
			} else {
				ServerStore.metric('app.login.facebook.failure');
				alert("Could not authenticate with server");
			}
		})
	}

	noFbClick = () => {
		ServerStore.metric('app.login.anonymous.clicked');
		ServerStore.anonymousLogin().then(store => {
			if(store) {
				ServerStore.metric('app.login.anonymous.success');
				this.game.setScene('WelcomeScene');
			} else {
				ServerStore.metric('app.login.anonymous.failure');
				alert("Could not authenticate with server");
			}
		})
	}

	// When the background is loaded, set the overlay
	// on the game with this react element
	loaded() {
		LoaderUtil.loaded();
		
		this.game.setReactOverlay(this.upgradeRequired ?
			<div className="testing-login">
				Welcome back!<br/>
				<br/>
				Upgrading your kitty with magic sprinkles and unicorn smiles, please wait ...
			</div>
			:
			<div className="login-wrap">
				<div id="takeover-prompt" onClick={() => this.takeoverPrompt()}>
					<span className="testing-login">
						:)
					</span>
				</div>
				<FacebookLogin
					appId={FB_APP_ID}
					callback={res => this.facebookConnected(res)}
					render={renderProps => (<>{
						renderProps.isLoading ?
							<div className="testing-login">
								Loading magic sparkles...
							</div>
							:
						renderProps.hasError ? 
							<div className="testing-login">
								Sorry, {renderProps.error.message}
							</div>
							:
						renderProps.hasAuthResponse ? 
							<div className="testing-login">
								Waking up your kitty...
							</div>
						:
						<div className="login-needed">
							<h1>Sleepy Cat</h1>

							<button className="loginBtn loginBtn--facebook"
								onClick={() => {
									ServerStore.metric('app.login.facebook.click');
									renderProps.onClick()
								}}>
								Continue with facebook
							</button>

							<div className="benefits">
								Login with Facebook so you can play with your friends. We don’t post to Facebook.
							</div>
						</div>}
					</>)}

				/>

				<Button className="loginBtn loginBtn--nofb"
					onClick={this.noFbClick}>
					Continue without Facebook
				</Button>
			</div>
		);
	}
}